/* eslint-disable max-classes-per-file */
import { observable, action, makeObservable } from 'mobx';
import Cookies from 'js-cookie';
import GaUtil from '../utils/gaUtil';

class CommonStore {
  constructor() {
    makeObservable(this, {
      // Page title
      pageTitle: observable,
      setPageTitle: action,
      // Router classes
      additionalClasses: observable,
      addCybRouterClass: action,
      removeCybRouterClass: action,
      // Container classes
      additionalContainerClass: observable,
      addCybContainerClass: action,
      removeCybContainerClass: action,
      // Primary nav
      hidePrimaryNav: action,
      showPrimaryNav: action,
      primaryNavDisplay: observable,
      // Toast
      toastState: observable,
      triggerToast: action,
      resetToastState: action,
      shareState: observable,
      triggerShareComponent: action,
      confirmState: observable,
      resetConfirmState: action,
      triggerConfirm: action,
      isInImmersive: observable,
      setIsInImmersive: action,
      hideFooter: observable,
      setHideFooter: action,
      isReferralModalOpen: observable,
      setIsReferralModalOpen: action,
      isLeaderboardDrawerOpen: observable,
      setIsLeaderboardDrawerOpen: action,
      // Upgrade content description ID
      upgradeContentDescriptionId: observable,
      setUpgradeContentDescriptionId: action,
    });
    // Binds
    this.addCybRouterClass = this.addCybRouterClass.bind(this);
    this.removeCybRouterClass = this.removeCybRouterClass.bind(this);
    this.addCybContainerClass = this.addCybContainerClass.bind(this);
    this.removeCybContainerClass = this.removeCybContainerClass.bind(this);
  }

  upgradeContentDescriptionId = null;

  setUpgradeContentDescriptionId = (id) => {
    this.upgradeContentDescriptionId = id;
  };

  isReferralModalOpen = false;

  setIsReferralModalOpen = (isOpen) => {
    this.isReferralModalOpen = isOpen;
  };

  isLeaderboardDrawerOpen = false;

  setIsLeaderboardDrawerOpen = (isOpen) => {
    this.isLeaderboardDrawerOpen = isOpen;
  };

  /* Share modal management */

  shareState = {
    openShare: false,
    permalink: null,
    prefix: null,
    title: null,
    type: null,
  };

  triggerShareComponent = (item) => {
    if (!item) {
      this.shareState.openShare = { openShare: false };
    }
    this.shareState = { openShare: !this.shareState.openShare, title: item.title, permalink: item.permalink, prefix: item.prefix, type: item.type, username: item.username };
  };

  resetShareComponent = () => {
    this.shareState = {
      openShare: false,
      permalink: null,
      title: null,
      prefix: null,
      type: null,
      username: null,
    };
  };

  /* Toast Component management */

  toastState = {
    toastDisplay: false,
    color: null,
    icon: null,
    header: null,
    content: null,
  };

  /**
   * @param {string} status - The status of the toast (info, error, success)
   * @param {object} toastObj - The object to use to generate the toast
   * @param {string} toastObj.color - The color of the toast (blue, red, green)
   * @param {string} toastObj.header - The header of the toast (Notification, Error, Success!)
   * @param {string} toastObj.content - The content of the toast (Check your notifications for important updates., Something went wrong. Please try again., Action complete.)
   * @param {string} toastObj.icon - The icon of the toast (thumbs-up, thumbs-down, info, x-circle)
   * @param {number} duration - The time in milliseconds to display the toast
   */
  triggerToast = (status, toastObj, duration = 6000) => {
    // Set toast state
    this.toastState = {
      toastDisplay: true,
      color: this.getToasterColor(toastObj, status),
      header: this.getToasterHeader(toastObj, status),
      content: this.getToasterContent(toastObj, status),
      icon: this.getToasterIcon(toastObj, status),
    };
    // Delay to ensure it's rendered in, then set focus on toast
    setTimeout(() => {
      const toastContainer = document.getElementById('toast-container');
      if (toastContainer) {
        toastContainer.focus();
      }
    }, 300);
    // Reset toast state after duration
    setTimeout(() => {
      this.resetToastState();
    }, duration);
  };

  getToasterColor = (obj, status) => {
    // If the obj has a color property, use that
    if (obj?.color) {
      return obj.color;
    }
    // Otherwise, use the status
    switch (status) {
      case 'info':
        return 'blue';
      case 'error':
        return 'red';
      case 'success':
      default:
        return 'green';
    }
  };

  getToasterHeader = (obj, status) => {
    // If the obj has a header property, use that
    if (obj?.header) {
      return obj.header;
    }
    // If we have a 403 error, Set the header to Unauthorized
    if (obj?.errorCode && obj.errorCode === 403) {
      return 'Unauthorized';
    }
    // Otherwise, use the status
    switch (status) {
      case 'error':
        return 'Error';
      case 'info':
        return 'Notification';
      case 'success':
      default:
        return 'Success!';
    }
  };

  getToasterContent = (obj, status) => {
    // If the obj has a content property, use that
    if (obj?.content) {
      return obj.content;
    }
    // If we have a 403 error, Set the content to a default message
    if (status === 'error' && obj?.errorCode && obj.errorCode === 403) {
      return 'Sorry, you are unauthorized to perform this action.';
    }
    // Otherwise, use the status
    switch (status) {
      case 'error':
        return 'Something went wrong. Please try again.';
      case 'info':
        return 'Check your notifications for important updates.';
      case 'success':
      default:
        return 'Action complete.';
    }
  };

  getToasterIcon = (obj, status) => {
    // If the obj has an icon property, use that
    if (obj?.icon) {
      return obj.icon;
    }
    // If we have a 403 error, use x-circle
    if (status === 'error' && obj?.errorCode && obj.errorCode === 403) {
      return 'x-circle';
    }
    // Otherwise, use the status
    switch (status) {
      case 'error':
        return 'exclamation-circle';
      case 'info':
        return 'bell';
      case 'success':
      default:
        return 'checkmark-circle';
    }
  };

  resetToastState = () => {
    this.toastState = {
      toastDisplay: false,
      color: null,
      icon: null,
      header: null,
      content: null,
    };
  };

  /* Confirm Modal */

  confirmState = {
    confirmDisplay: false,
    content: null,
    cancel: null,
    continue: null,
    cancelBtn: null,
    confirmBtn: null,
    doNotShowAgainId: null,
    doNotShowAgainText: '',
    focusLockDelay: 0,
    isLoading: false,
    omitBgCancel: false,
  };

  setLoadingConfirmState = (value) => {
    this.confirmState = {
      ...this.confirmState,
      isLoading: value,
    };
  };

  resetConfirmState = () => {
    this.confirmState = {
      confirmDisplay: false,
      content: null,
      cancel: null,
      continue: null,
      cancelBtn: null,
      confirmBtn: null,
      doNotShowAgainId: null,
      doNotShowAgainText: '',
      focusLockDelay: 0,
      isLoading: false,
      omitBgCancel: false,
    };
  };

  triggerConfirm = (obj) => {
    let bypassConfirm = false;
    // Check if this confirm has an ID to track 'Do not show this again'
    if (obj.doNotShowAgainId) {
      // Check to see if they have a cookie tracking 'Do not shows'
      const dismissedConfirmsCookie = Cookies.get('dismissedConfirms');
      const dismissedConfirms = dismissedConfirmsCookie ? dismissedConfirmsCookie.split(',') : [];
      // Check if they've seen this already. If so, just proceed without showing confirm (act as if user clicked continue)
      if (dismissedConfirms.indexOf(obj.doNotShowAgainId) > -1) {
        bypassConfirm = true;
      }
    }

    if (bypassConfirm) {
      obj.continue();
    } else {
      this.confirmState = {
        ...obj,
        confirmDisplay: true,
      };
    }
  };

  getRequestLicenseLink(teamId) {
    if (!teamId) {
      return '';
    }
    return `/enterprise/${teamId}/request-license`;
  }

  getRequestAddOnLicenseLink(teamId) {
    if (!teamId) {
      return '';
    }
    return `/enterprise/${teamId}/request-cybrary-select`;
  }

  /* App Teamplate config */

  primaryNavDisplay = true; // whether or not we are hiding the primary nav

  hidePrimaryNav() {
    this.primaryNavDisplay = false;
  }

  showPrimaryNav() {
    this.primaryNavDisplay = true;
  }

  pageTitle = 'Cybrary';

  setPageTitle(pageTitle) {
    if (pageTitle) {
      this.pageTitle = pageTitle;
      GaUtil.fireGaPageView(pageTitle);
    }
  }

  additionalClasses = [];

  addCybRouterClass(className = '') {
    if (className.length && this.additionalClasses.indexOf(className) === -1) {
      this.additionalClasses.push(className);
    }
  }

  removeCybRouterClass(className = '') {
    this.additionalClasses = this.additionalClasses.filter((entry) => entry !== className);
  }

  additionalContainerClass = '';

  addCybContainerClass(className = '') {
    if (className.length && this.additionalContainerClass.indexOf(className) === -1) {
      this.additionalContainerClass += ` ${className}`;
    }
  }

  removeCybContainerClass(className = '') {
    this.additionalContainerClass = this.additionalContainerClass.replace(className, '');
  }

  isInImmersive = false;

  setIsInImmersive(isInImmersive) {
    this.isInImmersive = isInImmersive;
  }

  hideFooter = false;

  setHideFooter(bool) {
    this.hideFooter = bool;
  }
}

export default new CommonStore();
