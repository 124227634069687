import React, { useEffect, useMemo, useState } from 'react';
import { inject, observer } from 'mobx-react';
import { twMerge } from 'tailwind-merge';
import UpgradeButton from '../UpgradeButton/UpgradeButton';
import EnrollmentUtil from '../../utils/enrollmentUtil';
import Button from '../Button/Button';
import { useEnrollment } from '../../providers/EnrollmentProvider';
import FormatUtil from '../../utils/formatUtil';
import Loading from '../Loading/Loading';
import { useCareerPrograms } from '../../providers/CareerProgramsProvider';
import agents from '../../agents/agents';
import BugsnagUtil from '../../utils/bugsnagUtil';

function EnrollmentButtonComponent({
  userStore,
  enrollmentStore,
  commonStore,
  text,
  upgradeText = 'Upgrade to Start learning today',
  content,
  progressOverride,
  buttonClasses,
  color,
  icon,
  upgradeIcon,
  comingSoonIcon,
  data,
  options,
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [enrollmentProgress, setEnrollmentProgress] = useState(null);
  const { startEnrollment } = useEnrollment();
  const {
    currentProgramEnrollment,
    enrollInCareerProgram,
    enterImmersiveForProgram,
    unenrollFromCareerProgram,
    currentPageProgram: program,
    isUserEnrolledInCurrentPageProgram,
    isLoadingCurrentProgram,
  } = useCareerPrograms();

  const canAccessContent = useMemo(() => userStore.checkPermissions(content), [userStore, content]);

  // Set enrollment progress on mount
  useEffect(() => {
    (async () => {
      if (progressOverride || !content?.id || enrollmentProgress) {
        return;
      }
      // Check the root progress cache in the enrollment store first
      const cachedRootProgress = enrollmentStore.getEnrollmentProgressForContentDescriptionId(content?.id);
      if (cachedRootProgress) {
        setEnrollmentProgress(cachedRootProgress);
        return;
      }
      // If no root progress is found, get from the API
      try {
        setIsLoading(true);
        const enrollmentProgressResponse = await agents.catalog.getCourseProgress(content?.id);
        const { percent_completed, progress_event, score } = enrollmentProgressResponse?.content_progress || {};
        setIsLoading(false);
        setEnrollmentProgress({
          created_at: progress_event === 'unstarted' ? null : true,
          progress: percent_completed,
          score,
        });
      } catch (error) {
        BugsnagUtil.notifyWithNamedMetadata(error, 'content', {
          contentId: content?.id,
          contentType: content?.content_type?.name,
          contentTitle: content?.title,
        });
      } finally {
        setIsLoading(false);
      }
    })();
  }, [content?.id]);

  const isCareerProgram = EnrollmentUtil.isCareerProgram(content);

  const isAssessmentCourse = EnrollmentUtil.isAssessmentCourse(content);
  const isAssessmentCourseStarted = enrollmentProgress?.created_at;
  const isAssessmentCourseCompleted = content?.submitted && content?.score !== null && content?.score !== undefined;

  const type = FormatUtil.ucwords(content?.content_type?.nice_name || content?.content_type?.name);
  const isCourse = EnrollmentUtil.isTypeCourse(type);
  const isCollection = EnrollmentUtil.isTypeCollection(type);

  // progressOverride is important for showing child-level progress on parent-level content
  // i.e. we're enrolling in a collection, but the enrollment button references the progress of the course within the collection
  const progress = progressOverride || enrollmentProgress?.progress;
  const isComingSoon = content?.status === 'Coming Soon';

  const handleAssessmentRetakeEnroll = () => {
    commonStore.triggerConfirm({
      content: `Are you sure you want to retake the ${content?.['content-topic'] || ''} assessment?`,
      cancel: () => {
        commonStore.resetConfirmState();
      },
      continue: async () => {
        setIsLoading(true);
        // The following is a 'hacky' way to show a loading spinner on the button while we enroll.
        // This allows visual feedback to the user that their request to retake the assessment was received and is being processed.

        // eslint-disable-next-line no-param-reassign
        commonStore.confirmState.confirmBtn = <Loading wrapperClassName="py-0" className="w-4 h-4 border-2" color="white" />;
        // IMPORTANT: Pass exclude_progress_before: 'now' to ensure that the user's progress is not included in the enrollment.
        await startEnrollment(content, { exclude_progress_before: 'now', ...(data || {}) }, options);
        commonStore.resetConfirmState();
        setIsLoading(false);
      },
    });
  };

  const handleProgramEnroll = async () => {
    let message = (
      <>
        <span className="text-xl font-bold">You can only enroll in one path at a time.</span>
        <br />
        Would you like to change your current path?
        <br />
        <span className="text-xs">All progress on your current path will be saved for later.</span>
      </>
    );

    if (isUserEnrolledInCurrentPageProgram) {
      message = (
        <>
          Would you like to un-enroll from this path?
          <br />
          <span className="text-xs">All progress will be saved for later.</span>
        </>
      );
    }

    // No current enrollment = enroll them in the program, enter immersive
    if (!currentProgramEnrollment?.set_active_at) {
      const programResponse = await enrollInCareerProgram(program.id);
      await enterImmersiveForProgram(programResponse);
    } else {
      // Has current program enrollment = confirm with them that they want to change their program
      commonStore.triggerConfirm({
        confirmBtn: isUserEnrolledInCurrentPageProgram ? 'Un-enroll' : 'Enroll',
        cancelBtn: 'Cancel',
        content: <p className="text-lg">{message}</p>,
        cancel: () => commonStore.resetConfirmState(),
        continue: async () => {
          setIsLoading(true);
          commonStore.setLoadingConfirmState(true);

          if (isUserEnrolledInCurrentPageProgram) {
            await unenrollFromCareerProgram();
          } else {
            const programResponse = await enrollInCareerProgram(program.id);
            await enterImmersiveForProgram(programResponse);
          }

          commonStore.resetConfirmState();
          setIsLoading(false);
        },
      });
    }
  };

  const handleEnroll = async () => {
    // Assessments
    if (isAssessmentCourse && isAssessmentCourseCompleted) {
      handleAssessmentRetakeEnroll();
      return;
    }

    // Programs
    if (isCareerProgram) {
      handleProgramEnroll();
      return;
    }

    // Default / Other (Courses, Collections, etc...)
    setIsLoading(true);
    try {
      await startEnrollment(content, data, options);
    } catch {
      // do nothing, error/toast is handled in startEnrollment
      // P.S. Leave this catch block. It's important.
      // And don't you dare move the error handling here!
    } finally {
      setIsLoading(false);
    }
  };

  const defaultButtonText = useMemo(() => {
    // Assessments
    if (isAssessmentCourse) {
      // if there is a submission & score (including 0) on the course assessment, show retake button
      if (isAssessmentCourseCompleted) {
        return 'Retake';
      }
      // if there is no submission but there is a submitted-at date, show continue button
      if (isAssessmentCourseStarted) {
        return 'Continue';
      }
      // if there is no submission or score, show start button
      return 'Start';
    }

    // Programs
    if (isCareerProgram) {
      if (isUserEnrolledInCurrentPageProgram) {
        return 'Enrolled';
      }
      return 'Enroll';
    }

    // Courses, MicroCourse, Career Paths
    if (isCourse || isCollection) {
      if (!progress) {
        return 'Start';
      }
      if (progress < 100) {
        return 'Continue';
      }
      return 'Revisit';
    }

    // Assessments, Labs, and Tests for non-free users, everything else...
    return 'Launch';
  }, [type, progress, isAssessmentCourse, isAssessmentCourseCompleted, isAssessmentCourseStarted, isUserEnrolledInCurrentPageProgram, isCareerProgram]);

  const defaultButtonColor = useMemo(() => {
    // Assessments
    if (isAssessmentCourse && isUserEnrolledInCurrentPageProgram) {
      // if there is a submission & score (including 0) on the course assessment, show retake button
      return 'gray';
    }

    // Programs
    if (isCareerProgram) {
      return 'gray';
    }

    // Assessments, Labs, and Tests for non-free users, everything else...
    if (progress && progress > 100) {
      return 'gray';
    }
    return 'pink';
  }, [progress, isAssessmentCourse, isAssessmentCourseCompleted, isAssessmentCourseStarted, isUserEnrolledInCurrentPageProgram, isCareerProgram]);

  const defaultButtonIcon = useMemo(() => {
    const refreshIconConfig = { name: 'refresh', position: 'left', className: 'w-4 h-4 mr-1 group-hover:-rotate-90 transition-transform duration-300' };

    // Assessments - Completed show refresh icon
    if (isAssessmentCourse && isAssessmentCourseCompleted) {
      return refreshIconConfig;
    }

    // Courses, MicroCourse, Career Paths
    if (isCourse || isCollection) {
      if (progress && progress > 100) {
        return refreshIconConfig;
      }
    }

    return { name: 'play', position: 'left', className: 'w-5 h-5 mr-1' };
  }, [isComingSoon]);

  if (isComingSoon) {
    const comingSoonClasses = twMerge('justify-center whitespace-nowrap text-xs cursor-help', buttonClasses);
    return (
      <Button
        color="light-gray"
        className={comingSoonClasses}
        disabled
        icon={comingSoonIcon || { name: 'clock', className: 'w-4 h-4 mr-1' }}
        title="This content is not available yet"
      >
        Coming Soon
      </Button>
    );
  }
  const buttonClassList = twMerge('hover:font-extrabold transition-all duration-100', buttonClasses);

  // Allow the user store to perform the permissions check (takes licenses into account)
  if (content && !canAccessContent) {
    return (
      <UpgradeButton
        color={color || 'gray'}
        buttonClasses={buttonClassList}
        upgradeText={upgradeText || 'Upgrade to Start learning today'}
        icon={upgradeIcon || { name: 'unlock', position: 'left', className: 'w-4 h-4 mr-1' }}
        content={content}
      />
    );
  }

  return (
    <Button
      onClick={handleEnroll}
      color={color || defaultButtonColor}
      className={buttonClassList}
      loading={isLoading || (isCareerProgram && isLoadingCurrentProgram)}
      disabled={isLoading || (isCareerProgram && isLoadingCurrentProgram)}
      icon={icon || defaultButtonIcon}
      loadingProps={{ className: 'h-4 w-4 border-2 !border-t-transparent !border-b-transparent' }}
    >
      {text || defaultButtonText}
    </Button>
  );
}

/**
 * Main Enrollment Button Component for all content
 * @param {string} text - The text to display on the button. Overrides the default text based on content progress.
 * @param {string} upgradeText - The text to display on the button if the user needs to upgrade. Defaults to 'Upgrade to Start learning today'
 * @param {object} content - The content object to enroll in. Passed to Access Checks
 * @param {number} progressOverride - The progress to override the content progress with. 0-100. Only used to determine the button text.
 * @param {string} buttonClasses - The classes to apply to the button. Passed to <Button />
 * @param {string} color - The color of the button. Passed to <Button />
 * @param {Object} icon - The icon to display on the button. Passed to <Button />
 * @param {Object} upgradeIcon - The icon to display on the button when the user needs to upgrade. Passed to <UpgradeButton />
 * @param {Object} comingSoonIcon - The icon to display on the button when the content is coming soon. Passed to <Button />
 * @param {string} data.excludeProgressBefore - The date to exclude progress before. 'now' will exclude progress before the current time.
 * @param {number} options.preferredEnrollmentId - The enrollment id to use if the content is already enrolled in.
 * @param {number} options.preferredCollectionItemId - The collection item id to use if the content is already enrolled in.
 * @returns
 */
const EnrollmentButton = inject('userStore', 'enrollmentStore', 'commonStore')(observer(EnrollmentButtonComponent));
export default EnrollmentButton;
