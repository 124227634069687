import React, { useEffect } from 'react';
import { observer, inject } from 'mobx-react';
import withRouter from '../../components/Router/withRouter';
import Title from '../../components/Title/Title';
import Container from '../../components/Container/Container';
import Divider from '../../components/Divider/Divider';
import OrganizationLogo from '../../components/Enterprise/OrganizationLogo';
import ManageSso from '../../components/TeamsSso/ManageSso';
import './enterprise.css';
import ConfiguredDomains from '../../components/TeamsSso/ConfiguredDomains';
import ActionUtil from '../../utils/actionsUtil';
import ManageTeamFacingLeaderboard from './ManageTeamFacingLeaderboard';
import ManageAutoAssignLicenses from './ManageAutoAssignLicenses';
import ManageAutoJoinByEmailDomain from './ManageAutoJoinByEmailDomain';

const TeamSettings = inject(
  'authStore',
  'userStore',
  'commonStore'
)(
  observer(({ userStore, authStore, commonStore, match }) => {
    const { orgId } = match.params;
    const isDemo = orgId === 'demo';

    useEffect(() => {
      if (isDemo) {
        userStore.enterDemo();
      } else {
        userStore.setPreferredTeamInit('', orgId);
      }
      commonStore.setPageTitle(`Settings${isDemo ? ' Demo' : ''} | Cybrary`);
      authStore.fireAttributionEvent();
      ActionUtil.scrollToTop();

      return () => {
        if (isDemo) {
          userStore.exitDemo();
        }
      };
    }, [isDemo, orgId, userStore, commonStore, authStore]);

    useEffect(() => {
      userStore.setPreferredTeamInit('', orgId);
    }, [orgId, userStore]);

    const { team } = userStore;

    return (
      <Container size="md">
        <Title title="Settings" />
        <div className="grid grid-cols-1 gap-4 lg:grid-cols-2">
          <div>
            <h2 className="mb-4 text-2xl font-black">Team Logo</h2>
            <div className="w-48 h-48">
              <OrganizationLogo className="flex justify-center items-center w-full h-full" teamId={team?.id} logoUrl={team?.logo_url} restrictLogoEdit={isDemo} />
            </div>
          </div>
          <div>
            <h2 className="mb-4 text-2xl font-black">Team Email Domains</h2>
            <ConfiguredDomains domains={team?.domains} />
          </div>
        </div>
        <Divider />
        <div>
          <h2 className="mb-4 text-2xl font-black">Auto-Assign Licenses</h2>
          <ManageAutoAssignLicenses />
        </div>
        <Divider />
        <div>
          <h2 className="mb-4 text-2xl font-black">Auto-Join Team by Email Domain</h2>
          <ManageAutoJoinByEmailDomain />
        </div>
        <Divider />
        <div>
          <h2 className="mb-4 text-2xl font-black">Team Leaderboard</h2>
          <ManageTeamFacingLeaderboard team={team} />
        </div>
        <Divider />
        <h2 className="mb-4 text-2xl font-black">Team SSO</h2>
        <ManageSso team={userStore.team} commonStore={commonStore} />
      </Container>
    );
  })
);

export default withRouter(TeamSettings);
