import { inject, observer } from 'mobx-react';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import BugsnagUtil from '../../utils/bugsnagUtil';
import MultiSelect from '../../components/FormFields/MultiSelect';
import { getPackageNames } from '../../utils/formatUtil';
import agents from '../../agents/agents';

/**
 *
 * @param {int} memberUserId The team member's user id to manage licenses for.
 * @param {string} memberName The name of the team member to manage licenses for. Default is 'User'.
 * @param {array} memberLicenses The default licenses to show for the team member.
 * @param {function} onSave A function to call after the licenses are updated. Used to refresh data after save.
 * @returns
 */
function ManageMemberLicensesComponent({ commonStore, userStore, adminStore, memberUserId, memberName, memberLicenses, onSave }) {
  const [isLoading, setIsLoading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [value, setValue] = useState(memberLicenses);
  // Allow adminStore to override userStore's team for Cybrary Admin
  const team = adminStore?.teamDetailsData?.data || userStore?.team;
  const { permissions, reportsTeamGroupId } = team || {};
  const { canManageAdmins } = permissions || {};

  const licenseOptions = useMemo(() => {
    return team?.active_license_grants?.map((grant) => ({
      value: grant?.team_content_license_grant_id,
      text: grant?.name || getPackageNames(grant?.package_types),
    }));
  }, [team?.active_license_grants]);

  /**
   * @param {array} selections The license ids to grant or revoke.
   * @param {boolean} grant Whether to grant or revoke the licenses.
   */
  const changeLicenseType = (selections, grant = false) => {
    const licenseNames = selections?.map((selection) => licenseOptions.find((option) => option.value === selection)?.text).join(', ') || 'None';
    const toastMessage = `${memberName || 'This user'} has ${grant ? 'been granted the following licenses:' : 'had the following licenses revoked:'} ${licenseNames}`;
    setIsSaving(true);
    setIsLoading(true);
    // selections = [8915, 8916] = License IDs to grant or revoke
    agents.enterprise
      .manageLicenses(reportsTeamGroupId || team.id, { users: [memberUserId], grants: selections, grant })
      .then(async () => {
        setIsSaving(false);

        commonStore.triggerToast('success', {
          content: toastMessage,
        });
        // Add selections to value if grant is true, otherwise remove them
        if (grant) {
          setValue([...value, ...selections]);
        } else {
          setValue(value.filter((v) => !selections.includes(v)));
        }

        if (onSave && typeof onSave === 'function') {
          await onSave();
        }
      })
      .catch((e) => {
        commonStore.triggerToast('error', {
          errorCode: e.response.status,
        });
        BugsnagUtil.notify(e);
      })
      .finally(() => {
        setIsLoading(false);
        setIsSaving(false);
      });
  };

  const changeLicenseTypeConfirm = useCallback(
    (e, { value: selectedValue }, actionEvent) => {
      if (actionEvent.action === 'remove-value') {
        commonStore.triggerConfirm({
          content: (
            <>
              Are you sure you want to revoke <b>{actionEvent.removedValue.label}</b> from <b>{memberName || 'this user'}</b>?
            </>
          ),
          cancel: () => commonStore.resetConfirmState(),
          continue: () => {
            commonStore.resetConfirmState();
            changeLicenseType([actionEvent.removedValue.value], false);
          },
          confirmBtn: 'Remove License',
          cancelBtn: 'Cancel',
        });
      }
      if (actionEvent.action === 'select-option') {
        commonStore.triggerConfirm({
          content: (
            <>
              Are you sure you want to grant <b>{actionEvent.option.label}</b> to <b>{memberName || 'this user'}</b>?
            </>
          ),
          cancel: () => commonStore.resetConfirmState(),
          continue: () => {
            commonStore.resetConfirmState();
            changeLicenseType(selectedValue || [], true);
          },
          confirmBtn: 'Grant',
          cancelBtn: 'Cancel',
        });
      }
      if (actionEvent.action === 'clear') {
        commonStore.triggerConfirm({
          content: (
            <>
              Are you sure you want to revoke <b>all licenses</b> from <b>{memberName || 'this user'}</b>?
            </>
          ),
          cancel: () => commonStore.resetConfirmState(),
          continue: () => {
            commonStore.resetConfirmState();
            changeLicenseType(
              actionEvent.removedValues.map((v) => v.value), // [8915, 8916]
              false
            );
          },
          confirmBtn: 'Revoke All',
          cancelBtn: 'Cancel',
        });
      }
    },
    [commonStore, memberName, changeLicenseType, value, onSave]
  );

  const isButtonDisabled = isLoading || isSaving;

  // If the memberLicenses prop changes, update the value
  useEffect(() => {
    setValue(memberLicenses);
  }, [memberLicenses]);

  const placeholder = useMemo(() => {
    if (isSaving) {
      return 'Saving...';
    }
    if (isLoading) {
      return 'Loading...';
    }
    return 'No licenses';
  }, [isSaving, isLoading]);

  if (!canManageAdmins && !adminStore?.hasAccess) {
    // If we can't manage, just render the license names
    return (
      <div className="flex flex-col">
        {memberLicenses?.map((license) => {
          const licenseName = licenseOptions.find((option) => option.value === license)?.text;
          return <div key={license}>{licenseName}</div>;
        })}
      </div>
    );
  }

  return (
    <MultiSelect
      options={licenseOptions}
      onChange={changeLicenseTypeConfirm}
      value={!isButtonDisabled ? value : '...'}
      placeholder={placeholder}
      loading={isButtonDisabled}
      disabled={isButtonDisabled}
      name="manage-member-licenses"
      isSearchable={false}
      isClearable={false}
      classes="w-auto"
      wrapperClasses="pb-0"
      closeMenuOnSelect
    />
  );
}

const ManageMemberLicenses = inject('enterpriseStore', 'userStore', 'commonStore', 'adminStore')(observer(ManageMemberLicensesComponent));

export default ManageMemberLicenses;
