import React, { useMemo, useState } from 'react';
import Bugsnag from '@bugsnag/js';
import { inject, observer } from 'mobx-react';
import { useParams } from 'react-router-dom';
import Modal from '../Modal/Modal';
import Icon from '../Icon/Icon';
import Divider from '../Divider/Divider';
import DynamicForm from '../DynamicForm/DynamicForm';
import AdminBulkImport from '../AdminBulkImport/AdminBulkImport';
import Agents from '../../agents/agents';
import FormatUtil, { getPackageNames } from '../../utils/formatUtil';
import Button from '../Button/Button';
import If from '../If/If';
import { APP_FORM_IDS } from '../../constants';
import OrganizationLicenseGrants from '../../pages/Enterprise/OrganizationLicenseGrants';
import BugsnagUtil from '../../utils/bugsnagUtil';
import Collapsible from '../Collapsible/Collapsible';
import Header from '../Header/Header';

const teamRoles = (isGroupScope) => [
  {
    key: 'team-member',
    text: `${isGroupScope ? 'Group' : 'Team'} Member`,
    value: 'team-member',
  },
  {
    key: 'team-admin',
    text: `${isGroupScope ? 'Group' : 'Team'} Admin`,
    value: 'team-admin',
  },
  {
    key: 'team-reporting-admin',
    text: `${isGroupScope ? 'Group' : 'Team'} Reporting Admin`,
    value: 'team-reporting-admin',
  },
];

const getEmailsPayload = (data) => {
  const emails = [];
  if (data.emailList) {
    const emailsArr = data.emailList.split(',');
    emailsArr.forEach((email) => {
      if (!!email && email.length) {
        emails.push({
          email: email.trim(),
          role: data.role,
        });
      }
    });
  } else {
    for (let i = 1; i <= 3; i++) {
      const email = data[`email${i}`];
      const licenses = data[`license${i}`];
      const role = data[`role${i}`];
      if (!!email && email.length) {
        emails.push({
          email,
          role,
          licenses,
        });
      }
    }
  }
  return emails;
};

const copyToClipboard = (e) => {
  e.preventDefault();

  const target = e.target.value;
  const input = target ? document.querySelector('#copyToClipboard') : null;
  input.focus();
  input.select();

  try {
    document.execCommand('copy');
  } catch (err) {
    Bugsnag.notify(err);
  }
};

// Adds a comma delimited input field of Email addresses to add more than 3 emails at once
const getEmailListForm = (autoGrantSeats, toggleForm, omitLicenseSelection, licenseOptions) => {
  return {
    order: ['emailList', 'role', 'license', 'add', 'submit'],
    fields: {
      emailList: {
        type: 'textarea',
        placeholder: 'Add a comma delimited list of email addresses',
        label: 'Comma separated list of email addresses',
        labelClass: 'sr-only',
      },
      role: {
        type: 'select',
        placeholder: 'Choose role',
        options: teamRoles,
        defaultValue: 'team-member',
        label: 'Role',
        labelClass: 'sr-only',
      },
      license: {
        type: 'multi-select',
        options: licenseOptions,
        defaultValue: [],
        label: 'License',
        labelClass: 'sr-only',
        hidden: omitLicenseSelection,
        isClearable: false,
        isSearchable: false,
      },
      add: {
        type: 'insert',
        insertComponent: (
          <button onClick={toggleForm} type="button" className="inline-block py-2.5 px-5 mt-6 text-sm font-bold bg-gray-200 rounded-sm">
            Add individual emails
          </button>
        ),
      },
      submit: {
        type: 'button',
        label: 'Send Invitations',
        color: 'pink',
      },
    },
  };
};

const getInviteForm = (autoGrantSeats, toggleForm, omitLicenseSelection, isDemo, licenseOptions, isGroupScope) => {
  const roleOptions = teamRoles(isGroupScope);
  return {
    order: [
      ['email1', 'role1', 'license1'],
      ['email2', 'role2', 'license2'],
      ['email3', 'role3', 'license3'],
      ['submit', 'add'],
    ],
    fields: {
      email1: {
        type: 'email',
        placeholder: 'name@example.com',
        label: 'Email 1',
        labelClass: 'sr-only',
      },
      role1: {
        type: 'select',
        placeholder: 'Choose role',
        options: roleOptions,
        defaultValue: 'team-member',
        label: 'Role 1',
        labelClass: 'sr-only',
      },
      license1: {
        type: 'multi-select',
        options: licenseOptions,
        defaultValue: [],
        label: 'License 1',
        labelClass: 'sr-only',
        hidden: omitLicenseSelection,
        isClearable: false,
        isSearchable: false,
      },
      email2: {
        type: 'email',
        placeholder: 'name@example.com',
        label: 'Email 2',
        labelClass: 'sr-only',
      },
      role2: {
        type: 'select',
        placeholder: 'Choose role',
        options: roleOptions,
        defaultValue: 'team-member',
        label: 'Role 2',
        labelClass: 'sr-only',
      },
      license2: {
        type: 'multi-select',
        options: licenseOptions,
        defaultValue: [],
        label: 'License 2',
        labelClass: 'sr-only',
        hidden: omitLicenseSelection,
        isClearable: false,
        isSearchable: false,
      },
      email3: {
        type: 'email',
        placeholder: 'name@example.com',
        label: 'Email 3',
        labelClass: 'sr-only',
      },
      role3: {
        type: 'select',
        placeholder: 'Choose role',
        options: roleOptions,
        defaultValue: 'team-member',
        label: 'Role 3',
        labelClass: 'sr-only',
      },
      license3: {
        type: 'multi-select',
        options: licenseOptions,
        defaultValue: [],
        label: 'License 3',
        labelClass: 'sr-only',
        hidden: omitLicenseSelection,
        isClearable: false,
        isSearchable: false,
      },
      add: {
        type: 'insert',
        insertComponent: (
          <>
            <Divider horizontal className="flex my-8 md:hidden">
              <p>OR</p>
            </Divider>
            <button
              onClick={toggleForm}
              disabled={isDemo}
              type="button"
              className={`block w-full md:w-auto ml-auto p-2 py-2.5 px-5 text-sm font-bold bg-gray-200 rounded-sm ${isDemo ? 'cursor-not-allowed' : 'cursor-pointer'}`}
            >
              Bulk upload CSV
            </button>
          </>
        ),
      },
      submit: {
        type: 'button',
        label: 'Send Invitations',
        color: 'pink',
        className: isDemo ? 'cursor-not-allowed' : '',
        disabled: isDemo,
      },
    },
  };
};

function TemplateOption({ children }) {
  return <code className="p-1 text-sm bg-gray-200">{children}</code>;
}

const BulkInviteTools = inject(
  'commonStore',
  'userStore'
)(
  observer(({ commonStore, userStore, isGroupScope, handleSendInvite, toggleForm, omitLicenseSelection, licenseOptions }) => {
    const { orgId } = useParams();
    const { auto_grant_seats, name: teamName, active_license_grants } = userStore?.team || {};
    const getGroupsExportCSV = async () => {
      try {
        const teamHierarchy = await Agents.admin.getTeamHierarchy(orgId, '?format=csv');
        FormatUtil.downloadCSV(teamHierarchy, `${FormatUtil.lowerCaseHyphenText(teamName || orgId)}_groups.csv`);
      } catch (e) {
        Bugsnag.notify(e);
        commonStore.triggerToast('error', { content: 'Something went wrong. Unable to retrieve team groups at this time.' });
      }
    };

    if (isGroupScope) {
      return (
        <>
          <h3 className="text-lg font-bold text-black">Invite via email address</h3>
          <DynamicForm
            formId={APP_FORM_IDS.TEAM.INVITE}
            formName="modInvitationForm"
            form={getEmailListForm(auto_grant_seats, toggleForm, omitLicenseSelection, licenseOptions)}
            onSubmit={handleSendInvite}
            customClassName="invite-form-list"
          />
        </>
      );
    }

    const CSV_FIELDS = [
      {
        name: '*Email',
        description: `The user's email address they will receive the team invite at.`,
        options: [],
      },
      {
        name: '*Role',
        description: `The user's role in the team.`,
        options: ['Member', 'Reporting-Admin', 'Admin', 'Owner'],
      },
      // License (Yes/No) - deprecated
      {
        name: 'License (Yes/No)',
        description: `Setting to "Yes" will grant all license types the team has to the user upon joining the team. 
        To grant only specific licenses, use the "License 1", "License 2", "License 3", "License 4" columns. 
        If these specific licenses are provided, this Yes/No column will be ignored.`,
        options: ['Yes', 'No'],
      },
      // Job title
      {
        name: 'Job Title',
        description: 'The job title of the user within your organization. Saved to the users profile.',
        options: [],
      },
      // Supervisor (Yes/No)
      {
        name: 'Supervisor (Yes/No)',
        description: 'Wether or not the user is a supervisor for the team. Saved to the users profile.',
        options: [],
      },
      {
        name: 'Group 1 Name, Group 2 Name, Group 3 Name',
        description: (
          <>
            <p>
              The name of the group the user will be added to. Supports up to 3 groups (use &quot;Group 2 Name&quot;, &quot;Group 3 Name&quot; columns to add a user to additional
              groups).
            </p>
            <p className="my-1">
              To view all Group Names, download the{' '}
              <button className="text-cyb-pink-500 hover:underline" onClick={() => getGroupsExportCSV()}>
                Groups Report <Icon name="download" className="inline-block w-4 h-4" />
              </button>
              .
            </p>
          </>
        ),
        options: [],
      },
      {
        name: 'Group 1 Role, Group 2 Role, Group 3 Role',
        description: 'The role of the user in the respective group. Supports up to 3 roles (use "Group #2 Role", "Group #3 Role" columns respectively).',
        options: ['Member', 'Reporting-Admin', 'Admin'],
      },
      {
        name: 'License 1, License 2, License 3, License 4',
        description: `The licenses the user will be given upon joining the team. 
        Supports up to 4 licenses (use "License 2", "License 3", "License 4" columns to add additional licenses).`,
        options: active_license_grants.map((grant) => grant.name),
      },
    ];

    return (
      <div>
        <h3 className="text-lg font-bold text-black">Bulk User Invite</h3>
        <p className="text-sm lg:mb-4">
          Download and complete the CSV template to invite multiple members at once and grant them licenses, assign them to groups, provision them with a role, and more.
        </p>
        <Collapsible
          trigger={
            <div className="flex gap-x-1 items-center w-full text-sm font-bold hover:underline">
              View Template Fields Info <Icon name="help-icon" className="w-4 h-4" />
            </div>
          }
          triggerWhenOpen={
            <div className="flex gap-x-1 items-center w-full text-sm font-bold hover:underline">
              Hide Template Fields Info <Icon name="chevron-up" />
            </div>
          }
        >
          <span className="mb-2 text-xs text-gray-600">* Required</span>
          <div className="grid gap-x-4 gap-y-8 p-4 text-sm rounded-sm lg:grid-cols-1">
            {CSV_FIELDS.map((field) => (
              <div key={field.name} className="flex flex-col mb-4">
                <span className="text-lg font-bold">{field.name}</span>
                <If condition={field.description}>
                  <span className="text-sm">{field.description}</span>
                </If>
                <If condition={field.options.length}>
                  <span className="pt-2 text-xs">Supported Values:</span>
                  <TemplateOption>{field.options.join(', ')}</TemplateOption>
                </If>
              </div>
            ))}
          </div>
        </Collapsible>
        <AdminBulkImport
          teamId={orgId}
          importTypeDisplay="invite" // For UI display purposes
          formConfig={{
            formName: 'bulkUserInvite',
            submitText: 'Bulk Invite',
            instructions: (
              <div className="-mt-4 mb-4 text-sm ">
                <p className="mb-0 ">Upload the completed CSV template and click “Bulk Invite” to send many team invitations.</p>
              </div>
            ),
          }}
          importConfig={{
            templateFileName: 'cybrary_user_invite',
            routesObject: 'enterprise',
            importType: 'team-invite-v2', // For classification of import type in routes
            dataKey: 'import', // Post data key that CSV will be included in
            confirmText: 'Are you sure you want to invite these users? The Bulk Invite function will send Teams invitations to every email in the CSV template.',
            logDescription: 'Review and download past Bulk Invite CSVs.',
          }}
        />
      </div>
    );
  })
);

function FormInviteModalComponent({ modalOpen, modalClose, groupId, enterpriseStore, userStore, commonStore }) {
  const { orgId } = useParams();
  const { auto_grant_seats, active_license_grants } = userStore?.team || {};
  const { invitedTeamMembers, teamInviteUrl } = enterpriseStore;
  const { url } = teamInviteUrl || {};
  const [toggleInviteForm, setToggle] = useState(false);
  const isGroupScope = !!groupId;
  const isDemo = orgId === 'demo';

  // If the user does not have permission to manage licenses, we will not show the license selection
  const omitLicenseSelection = !userStore?.team?.permissions?.canManageAdmins || isGroupScope;

  const licenseOptions =
    active_license_grants?.map((grant) => ({
      key: grant.team_content_license_grant_id,
      text: grant.name || getPackageNames(grant.package_types),
      value: grant.team_content_license_grant_id,
    })) || [];

  // Toggles between a input forms/tools
  const toggleForm = () => {
    setToggle(!toggleInviteForm);
  };

  const handleSendInvite = (data) => {
    const emails = getEmailsPayload(data);
    if (emails?.length) {
      const id = groupId || orgId;
      enterpriseStore
        .getTeamInviteUrlByEmail(id, emails)
        .then(() => {
          // If we have invited team members table data, let's refresh that
          if (invitedTeamMembers?.data) {
            enterpriseStore.getInvitedTeamMembers(id);
          }
          commonStore.triggerToast('success', {
            content: 'Team invitations successfully sent!',
          });
        })
        .catch((e) => {
          BugsnagUtil.notify(e);
          commonStore.triggerToast('error', {
            content: 'Something went wrong. Unable to send invites at this time. Please confirm the user(s) are not already on this team.',
          });
        });
      modalClose();
    } else {
      commonStore.triggerToast('error', {
        content: 'Please enter at least one email address',
      });
    }
  };

  const inviteUrl = isDemo
    ? 'https://app.cybrary.it/team-invite/a6v45f44s356yuibutfd4s553wesdf?source=link'
    : url.replace('https://app.the-crossing.cybrary.it', 'http://localhost:3000');

  const modalTitle = useMemo(() => {
    if (toggleInviteForm && !isGroupScope) {
      return 'Add Members';
    }
    if (toggleInviteForm && isGroupScope) {
      return 'Add Members to Group';
    }
    if (isGroupScope) {
      return 'Invite to Group';
    }
    return 'Invite to Team';
  }, [toggleInviteForm, isGroupScope]);

  return (
    <Modal sizeClasses="sm:w-2/3 2xl:w-1/2 lg:pt-4 lg:px-4" open={modalOpen} toggle={modalClose} ariaLabelledBy="invite-modal">
      <div className="p-4 text-left">
        <Header as="h2">{modalTitle}</Header>
        <If condition={!omitLicenseSelection}>
          <OrganizationLicenseGrants showHeader={false} className="my-8" />
        </If>
        {toggleInviteForm && !isDemo ? (
          <BulkInviteTools
            isGroupScope={isGroupScope}
            handleSendInvite={handleSendInvite}
            toggleForm={toggleForm}
            omitLicenseSelection={omitLicenseSelection}
            licenseOptions={licenseOptions}
          />
        ) : (
          <DynamicForm
            formName="invitationForm"
            form={getInviteForm(auto_grant_seats, toggleForm, omitLicenseSelection, isDemo, licenseOptions, isGroupScope)}
            onSubmit={isDemo ? () => {} : handleSendInvite}
            customClassName="invite-form"
          />
        )}
        <Divider horizontal className="my-6">
          <p>OR</p>
        </Divider>
        <div>
          <h3 className="text-lg font-bold text-black">Invite via link</h3>
          <p className="lg:whitespace-nowrap">* Click the button to copy the url and send to the person you want to invite.</p>
          <div className="flex">
            <input
              id="copyToClipboard"
              aria-label="Team invite URL"
              type="text"
              defaultValue={inviteUrl}
              readOnly
              style={{ width: '89%', borderRadius: '5px' }}
              className="p-3 mr-3 w-full rounded-sm border border-gray-500"
            />
            <input
              type="button"
              value="Copy"
              onClick={copyToClipboard}
              className="flex-1 py-2.5 px-6 text-sm font-bold leading-5 text-center text-black bg-gray-200 hover:bg-gray-300 rounded-sm border-0 cursor-pointer"
            />
          </div>
        </div>
        <If condition={toggleInviteForm}>
          <Button color="gray" onClick={toggleForm} className="mt-8" icon={{ name: 'chevron-left', position: 'left', className: 'w-4 h-4' }}>
            Back to individual email invites
          </Button>
        </If>
      </div>
    </Modal>
  );
}

const FormInviteModal = inject('userStore', 'commonStore', 'enterpriseStore')(observer(FormInviteModalComponent));

export default FormInviteModal;
