import { useState } from 'react';
import Agents from '../agents/agents';

const useTeamPaths = () => {
  const [paths, setPaths] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchPaths = async (team) => {
    setLoading(true);
    try {
      const { id } = team;
      const query = '?excludeAvatao=1';
      const data = await Agents.enterprise.getCurricula(id, query);
      setPaths(data);
    } catch (e) {
      setError(e);
    } finally {
      setLoading(false);
    }
  };

  return {
    paths,
    loading,
    error,
    fetchPaths,
  };
};

export default useTeamPaths;
