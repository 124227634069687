import React, { useState, createContext, useMemo, useRef, useEffect } from 'react';
import Bugsnag from '@bugsnag/js';
import { useNavigate } from 'react-router-dom';
import usePaddleJS from '../hooks/checkout/usePaddleJS';
import { trackSnowplowEvent } from '../utils/snowplowUtil';
import { useAuth } from '../contexts/UseAuth';
import gaUtil from '../utils/gaUtil';
import BugsnagUtil from '../utils/bugsnagUtil';
import { PADDLE_DISCOUNT_ID_CIP_EDU_MIL_GOV, PADDLE_PRICE_ID_TEAM_ANNUAL } from '../constants';
import PaddleToasts from '../pages/Paddle/PaddleToasts';
import agents from '../agents/agents';
import useQueryParams from '../hooks/useQueryParams';
import ActionUtil from '../utils/actionsUtil';
import AuthUtil from '../utils/authUtil';

const stub = () => {
  throw new Error('You forgot to wrap your component in <CheckoutProvider>.');
};

const PaddleCheckoutContext = createContext({
  environment: process.env.REACT_APP_PADDLE_ENVIRONMENT,
  eventCallback: stub,
  paddle: null,
  registrationData: {},
  setRegistrationData: stub,
  registrationError: null,
  setRegistrationError: stub,
  checkoutData: [],
  checkoutError: null,
  checkoutLoading: false,
  userData: null,
  startNewCheckout: stub,
  updateCheckoutItems: stub,
  clearCheckoutError: stub,
  addCheckoutItem: stub,
  removeCheckoutItem: stub,
  swapCheckoutItems: stub,
  isUpdatingExistingTx: false,
  handleSuccessfulPurchase: stub,
  handleSuccessfulSubscriptionUpdate: stub,
});

/**
 *
 * @param {string} txid - transaction_id from Paddle
 * @param {string} attempt - number of attempts to sync the subscription
 */
const syncSubscription = async (txid, attempt = 1) => {
  try {
    await ActionUtil.sleep(attempt * 1000);
    await agents.paddle.syncSubscription(txid);
  } catch (error) {
    if (attempt < 10) {
      return syncSubscription(txid, attempt + 1);
    }
    throw error;
  }
  return true;
};

/**
 * Paddle Checkout Provider
 * @param {string} environment - Paddle environment (sandbox or production)
 * @param {Mobx} userStore - Mobx userStore
 * @param {Mobx} authStore - Mobx authStore
 * @param {Mobx} commonStore - Mobx commonStore
 * @param {Mobx} profileStore - Mobx profileStore
 * @param {*} children - React children
 * @returns <CheckoutContext.Provider />
 */
function PaddleCheckoutProvider({ environment, userStore, authStore, commonStore, profileStore, children }) {
  if (!environment) throw new Error('Must provide environment');
  const { refreshUser } = useAuth();
  const navigate = useNavigate();
  const queryParams = useQueryParams();
  // @queryParam _ptxn is the transactionId from an email link
  // @queryParam txid is the transactionId from a redirect we created
  const existingTransactionId = queryParams?._ptxn || queryParams?.txid;
  const checkoutConfig = useRef(0); // data representing the checkout config see: https://developer.paddle.com/v1/paddlejs/methods/paddle-setup
  const [registrationData, setRegistrationData] = useState({}); // data representing the users registration.
  const [registrationError, setRegistrationError] = useState(null); // data representing any registration errors.
  const [checkoutData, setCheckoutData] = useState({}); // data representing the checkout see: https://developer.paddle.com/v1/paddlejs/general/checkout-loaded
  const [checkoutError, setCheckoutError] = useState(null); // data representing any checkout errors see: https://developer.paddle.com/v1/paddlejs/general/checkout-error
  const [checkoutLoading, setCheckoutLoading] = useState(false); // Used to show loading spinner while checkout is loading
  const [isUpdatingExistingTx, setIsUpdatingExistingTx] = useState(false); // Used to show loading spinner while checkout is loading
  // Used to retry loading the checkout if it fails to load by storing the timeout id and clearing it on success.
  const persistentLoadingTimeoutId = useRef(0);
  // Used to store the registration data for account creation
  // ik.. you probably think this can just be read from the state, but it can't.
  // if you figure it out, call me. Until then, the deadline of this project is stupid so we're using a ref
  const registrationDataRef = useRef({});

  // When registration data changes, update the registrationDataRef
  useEffect(() => {
    registrationDataRef.current = registrationData;
  }, [registrationData]);

  /**
   * Previews the registration data to see if the email is available and all required fields are filled out
   * @param {object} parsedRegistrationData parsed registration data from the checkout form
   * @returns {Promise} preview data confirming if the registration data is valid or not
   */
  const previewRegisterNewUser = async (parsedRegistrationData) => {
    const { email, password, subscribe, tos, pp, referral, language } = parsedRegistrationData || {};
    const registrationPayload = {
      email,
      password,
      // password_verify is set to password b/c we removed confirm password field, but backend still expects password verify
      password_verify: password,
      subscribe: !!subscribe,
      tos: !!tos,
      pp: pp?.length,
      verificationMethod: 'code',
      referral,
      // Preferred language
      language: language || 'en',
    };
    return agents.auth.previewGoogleCloudRegister(registrationPayload);
  };

  /**
   * Register a new user after a successful purchase with the paddle transaction id and redirect url
   * @param {object} data checkoutData from paddle
   * @param {object} parsedRegistrationData parsed registration data from the checkout form
   * @param {string} redirectUrl url to redirect to after registration
   * @returns
   */
  const registerNewUser = async (data, parsedRegistrationData, redirectUrl) => {
    // If the user is not logged in, we need register their account and send them to verify their email next
    try {
      const { email, password, subscribe, tos, pp, captchav3, referral, language } = parsedRegistrationData || {};
      const { googleMarketplaceId } = queryParams;
      const registrationPayload = {
        email,
        password,
        // password_verify is set to password b/c we removed confirm password field, but backend still expects password verify
        password_verify: password,
        subscribe: !!subscribe,
        tos: !!tos,
        pp: pp?.length,
        captchav3,
        verificationMethod: 'code',
        // If we have a google marketplace id in the query params, pass it along
        googleMarketplaceId,
        // send paddleTransactionId from checkout
        paddleTransactionId: data?.transaction_id,
        referral,
        // Preferred language
        language: language || 'en',
      };
      // attempt to register account
      await agents.auth.googleCloudRegister(registrationPayload);
      // Hide purchase success toast
      commonStore.resetToastState();
      // Send user to verify their email, then to the redirect url
      // Add the new purchase query param so we adjust the verify account page accordingly
      navigate(`/verification-code?email=${parsedRegistrationData?.email}&redirect_to=${redirectUrl}&newPurchase=1`);
      return true;
    } catch (err) {
      PaddleToasts.showGeneralErrorToast(commonStore, new Error('Failed to register account after successful purchase'), 15000);
      // If we're here, the user has successfully purchased, but we failed to register their account.
      // This is about as bad as it gets... so lets just sound some alarms and hope someone notices
      // Send snowplow event
      trackSnowplowEvent({ category: 'Paddle', action: 'Checkout', label: 'Register Account Failed', property: data?.transaction_id });
      // Send bugsnag event
      BugsnagUtil.notifyWithNamedMetadata(err, 'Paddle', data);
      // Set registration error state
      setRegistrationError(err);
    }
    return false;
  };
  /**
   * Callback function for a successful subscription update.
   * @param {object} data - data representing the checkout
   * @returns {void}
   */
  const handleSuccessfulSubscriptionUpdate = async (data) => {
    try {
      // If we are updating an existing transaction, we want to redirect to the membership settings page and show a success toast
      navigate('/settings/membership?subscriptionUpdated=1');
      PaddleToasts.showSubscriptionUpdatedToast(commonStore);
    } catch (err) {
      BugsnagUtil.notifyWithNamedMetadata(err, 'Paddle', data);
      navigate(`/?updateError=1`);
      PaddleToasts.showGeneralErrorToast(commonStore, err);
    }
  };

  /**
   * Callback function for a successful checkout.
   * Fires attribution events and redirects the user to the correct page for the purchased items
   * @param {CheckoutData} data - data representing the checkout
   * @see https://developer.paddle.com/v1/paddlejs/general/checkout-completed
   */
  const handleSuccessfulPurchase = async (data) => {
    try {
      if (!userStore?.user) {
        PaddleToasts.showNewAccountPurchaseSuccessToast(commonStore);
      } else {
        PaddleToasts.showExistingAccountPurchaseSuccessToast(commonStore);
      }

      // Send attribution events to GTM
      gaUtil.pushEventToDataLayer({
        event: 'purchase',
        ecommerce: {
          transaction_id: data?.transaction_id || 'Unknown',
          affiliation: data?.payment?.method_details?.type || 'Unknown',
          value: data?.totals?.subtotal || 0, // Excludes tax, use 'total' for total
          currency: data?.currency_code || 'USD',
          coupon: data?.discount?.code || data?.discount?.id,
          items:
            data?.items?.map((item) => {
              const name = item.product?.name || 'Unknown';
              const cadence = item.billing_cycle?.interval || 'Unknown';
              return {
                item_id: item.price_id,
                item_name: `${name} ${cadence}`,
                currency: data?.currency_code || 'USD',
                price: item.totals?.subtotal || 0,
                discount: item.totals?.discount || 0,
                item_category: name,
                item_category2: cadence,
                quantity: item.quantity || 1,
              };
            }) || [],
        },
      });

      // Check if purchase contains a team subscription
      const isTeamsPurchase = data?.items?.find(({ price_id }) => price_id === PADDLE_PRICE_ID_TEAM_ANNUAL);
      // We dont have the team id yet, so we need to redirect to /enterprise/new and the router will put them into the correct team after its made.
      const redirectUrl = !isTeamsPurchase ? '/?freshCip=1' : '/enterprise/new?newSubscription=1';
      const txid = data?.transaction_id;
      // If the user is not logged in, we need register their account and send them to verify their email next
      if (!userStore?.user) {
        // Wait here for 10 seconds so the user can see the success toast
        await ActionUtil.sleep(10000);
        registerNewUser(data, registrationDataRef.current, redirectUrl);
        return;
      }
      // If the user is logged in, we can sync the subscription right away
      await syncSubscription(txid);
      // Reload user meta to get new subscription data into the user store
      await userStore.loadUserMeta();
      // Refresh user data
      await refreshUser();
      // Hide purchase success toast
      commonStore.resetToastState();
      // CIP Purchase: Redirect to home page with query params to show CIP
      if (!isTeamsPurchase) {
        if (userStore.isCip) {
          navigate(redirectUrl);
        } else {
          // Subscription failed to apply for some reason without a hard error from the backend
          throw new Error('User was not marked as CIP after successful purchase');
        }
        return;
      }
      // Teams Purchase: Redirect to teams dashboard if user is on a team
      if (isTeamsPurchase) {
        // Check if user was marked as on a team after a successful purchase
        if (userStore.isEnterprise && userStore.team) {
          // Wait here for 10 seconds so the user can see the success toast
          await ActionUtil.sleep(10000);
          // if so, send them to their new team dashboard
          navigate(`/enterprise/${userStore.team.id}/organization/members?newSubscription=1`);
        } else {
          // Subscription failed to apply for some reason without a hard error from the backend
          throw new Error('User was not marked as on a team after successful purchase');
        }
        return;
      }
      // Fallback redirect to dashboard if no other redirect is triggered
      navigate('/');
    } catch (err) {
      BugsnagUtil.notifyWithNamedMetadata(err, 'Paddle', data);
      navigate(`/?upgradeError=1`);
      PaddleToasts.showGeneralErrorToast(commonStore, err);
    }
  };

  /**
   * Main event handler for Paddle events emitted from iframe
   * @param {string} name - Name of the event
   * @param {object} data - Data object representing the event
   * @param {object} error - Error object representing the event
   */
  const eventCallback = ({ name, data, error, errors }) => {
    try {
      if (name) {
        // Track Paddle event in Snowplow
        trackSnowplowEvent({
          category: 'paddleCheckout',
          action: name,
          property: JSON.stringify(data), // Just give them the whole data object ¯\_(ツ)_/¯
        });
      }

      const errorToReport = error || errors?.[0];

      const normalizedErrorMessage = errorToReport?.message?.toLowerCase();

      switch (name) {
        case 'checkout.completed':
          setCheckoutData(data);
          if (existingTransactionId) {
            handleSuccessfulSubscriptionUpdate(data);
          } else {
            handleSuccessfulPurchase(data);
          }
          break;
        case 'checkout.error':
          Bugsnag.notify(error);

          // Paddle resolves postal code errors, no need to show these error messages
          if (!normalizedErrorMessage?.includes('postal code')) {
            PaddleToasts.showEventErrorToast(commonStore, errorToReport);
            setCheckoutError(error);
          }

          break;
        case 'checkout.loaded':
          clearTimeout(persistentLoadingTimeoutId.current); // Clear any existing persistent loading timeout
          setCheckoutData(data);
          break;
        default:
          // If we don't have a name, we don't care about the event
          if (name) {
            setCheckoutData(data);
          }

          if (error || errors) {
            const errorsArray = error ? [error] : errors;
            errorsArray.forEach((err) => {
              Bugsnag.notify(err);
            });

            // Paddle resolves postal code errors, no need to show these error messages
            if (!normalizedErrorMessage?.includes('postal code')) {
              PaddleToasts.showEventErrorToast(commonStore, errorsArray[0]);
              setCheckoutError(errorsArray[0]);
            }
          }
      }
      // If we don't have a name, we don't care about the event
      if (name) {
        setCheckoutLoading(false);
      }
    } catch (err) {
      // Send any errors during event handling to Bugsnag with current checkout data
      BugsnagUtil.notifyWithNamedMetadata(err, 'Paddle', checkoutData);
      PaddleToasts.showGeneralErrorToast(commonStore, err);
    }
  };
  const { paddle } = usePaddleJS({ environment, eventCallback });

  /**
   * Attempts to persistently open a paddle checkout with the given config, retrying if it fails up to MAX_ATTEMPTS times
   * @param {Object} config Paddle checkout config object
   * @param {number} attempt Current attempt number
   */
  const openCheckout = (config, attempt = 1) => {
    const MAX_ATTEMPTS = 3; // Try loading checkout up to X times
    const TIMEOUT_DELAY = 5000; // How long to wait in between attempts

    paddle.Checkout.open(config);

    // To counter a known bug with passing a discountId to an item that doesn't support it,
    // we need to run a timeout to retry opening if the checkout fails to load after a certain amount of time.
    // Store a refrence to the timeout so we can clear it if the checkout loads successfully before the timeout fires.
    persistentLoadingTimeoutId.current = setTimeout(() => {
      // If we had a discountId, try again without it.
      if (config.discountId) {
        openCheckout({ ...config, discountId: null }, attempt + 1);
        // If we have a discountId, we don't want to show the error to the user, so we need to delay it a bit
        setCheckoutError(new Error('We were unable to apply the discount automatically at this time. Please try manually adding it during checkout instead (if applicable).'));
        return;
      }

      // At this point we failed to load the checkout, and it wasn't due to a discountId
      // Lets try to open the checkout again before giving up
      if (attempt < MAX_ATTEMPTS) {
        openCheckout(config, attempt + 1);
        return;
      }

      // If we get here, we failed to open the checkout multiple times, so we need to let the user know and give up
      BugsnagUtil.notifyWithNamedMetadata(new Error('Failed to load checkout'), 'Paddle', { ...checkoutConfig.current });
      PaddleToasts.showErrorOpeningCheckoutToast(commonStore);
    }, TIMEOUT_DELAY);
  };

  /**
   * Discounts are passed in as query params.
   *
   * PRICE_ID SPECIFIC DISCOUNTS
   * ?discountId.{key}={value}
   * ?discountId.pri_12345=dsc_12345
   * ?discountId.pri_12345=dsc_12345&discountId.pri_67890=dsc_67890
   *
   * GENERAL DISCOUNT
   * ?discountId={value}
   *
   * The key is the `price_id` of the item the discount applies to, and the value is the matching `discount_id`.
   * We will try to find a matching discount for the first item in the checkout, then we'll try to use the general discountId as a fallback.
   *
   */
  const getQueryParamDiscountId = (priceId) => {
    return queryParams[`discountId.${priceId}`] || queryParams.discountId; // Fallback to just `discountId` if we don't have a price_id
  };

  /**
   * Begins the checkout process.
   *
   * This can be called with only an items array to create a new checkout,
   * or with a transactionId to create a checkout for a transaction you previously created.
   * @param {Object} settings Object representing checkout settings. ex: { displayMode: 'overlay', locale: 'en', successUrl: '...', etc... }
   * @param {Array} items Array of objects representing items to be purchased. ex: [{ priceId: 'pri_98h398ncv', quantity: 1 }]
   * @param {string<Paddle ID>} transactionId Use this instead of an items array to create a checkout for a transaction you previously created.
   * @param {string<Paddle ID>} discountId Paddle ID of a discount to apply to this checkout. Use to pre-populate a discount.
   * @param {Object} customData Custom key-value data to include with the checkout. Must be valid JSON and contain at least one key.
   * @param {string} email Email address to pre-populate in the checkout. Must be a valid email address.
   * @see https://developer.paddle.com/v1/paddlejs/methods/paddle-checkout-open for a full list of options
   */
  const startNewCheckout = async ({ settings = null, items = null, transactionId = null, discountId = null, customData = {}, email = null }) => {
    try {
      if (!paddle || (!items?.length && !transactionId)) return; // don't do anything if paddle isn't ready or there's no items/tx to purchase
      if (!email && !userStore.user?.email) throw new Error('Missing email for checkout init'); // don't do anything if we don't have an email to pre-populate
      setCheckoutLoading(true);
      setIsUpdatingExistingTx(false);
      const { user } = userStore;
      const geoLocation = await authStore.getGeoInfo();

      // If we're opening an existing checkout, just open it and return
      // We can only pass in a transactionId if we're opening an existing checkout, not if we're creating a new one.
      if (transactionId) {
        setIsUpdatingExistingTx(true);
        paddle.Checkout.open({
          transactionId,
        });
        return;
      }

      // add .edu .gov and .mil discounts
      // If the user is not logged in, we need to check the checkout email they are registering with
      let isEduGovMilUser = false;
      if (!userStore?.user) {
        isEduGovMilUser = AuthUtil.isEduGovMilUser({ primary: { email, verified: true } });
      } else {
        // If the user is logged in, use their accounts from the profileStore
        await profileStore.getUserAccounts();
        const { data: accounts } = profileStore?.profileData?.accounts || {};
        isEduGovMilUser = AuthUtil.isEduGovMilUser(accounts);
      }
      const eduGovMilDiscountId = isEduGovMilUser ? PADDLE_DISCOUNT_ID_CIP_EDU_MIL_GOV : null;
      const queryParamDiscountId = getQueryParamDiscountId(items[0].priceId);

      // Add userId to customData if it exists
      if (userStore?.user?.id) {
        // eslint-disable-next-line no-param-reassign
        customData.userId = userStore.user.id;
      }

      // Add teamId to customData if it exists
      if (userStore?.team?.id) {
        // eslint-disable-next-line no-param-reassign
        customData.teamId = userStore.team.id;
      }

      // Create a new checkout, storing the config so we can rebuild it later
      checkoutConfig.current = {
        settings,
        // We can only pass in items or a transactionId, not both. Give preference to transactionId if both are provided.
        items: transactionId ? null : items,
        transactionId: transactionId || null,
        // We can only pass in a discountId if we're creating a new checkout, not if we're opening an existing one.
        discountId: queryParamDiscountId || discountId || eduGovMilDiscountId,
        // Pass in any custom data, and append the user ID to it
        customData,
        // Pre-fill as much user data as we can from onboarding / geo info
        customer: {
          email: email || user?.email,
          address: {
            countryCode: queryParams?.countryCode || geoLocation?.countryCode || '',
            postalCode: geoLocation?.zip || '',
          },
        },
      };

      // Initial attempt to open the checkout
      openCheckout(checkoutConfig.current);
    } catch (err) {
      BugsnagUtil.notifyWithNamedMetadata(err, 'Paddle', { settings, items, transactionId, discountId, customData });
      PaddleToasts.showErrorOpeningCheckoutToast(commonStore);
      setCheckoutLoading(false);
    }
  };

  /**
   * Used to Add, Remove, or Update QTY of the items in the checkout.
   * This prevents a new checkout from being created and allows the user to update their cart without losing their checkout session.
   * @param {Array} items Array of objects representing the new items to be purchased. ex: [{ priceId: 'pri_98h398ncv', quantity: 1 }]
   * @returns
   * @see https://developer.paddle.com/v1/paddlejs/methods/paddle-checkout-updateitems
   */
  const updateCheckoutItems = async (items) => {
    try {
      setCheckoutError(null);
      setCheckoutLoading(true);
      // add .edu .gov and .mil discounts
      // If the user is not logged in, we need to check the checkout email they are registering with
      let isEduGovMilUser = false;
      if (!userStore?.user) {
        isEduGovMilUser = AuthUtil.isEduGovMilUser({ primary: { email: checkoutConfig?.current?.customer?.email, verified: true } });
      } else {
        // If the user is logged in, use their accounts from the profileStore
        await profileStore.getUserAccounts();
        const { data: accounts } = profileStore?.profileData?.accounts || {};
        isEduGovMilUser = AuthUtil.isEduGovMilUser(accounts);
      }
      const eduGovMilDiscountId = isEduGovMilUser ? PADDLE_DISCOUNT_ID_CIP_EDU_MIL_GOV : null;
      const queryParamDiscountId = getQueryParamDiscountId(items[0].priceId);
      // Grab any discountId from the existing checkout first, and query params as a fallback for the first item
      const discountId = checkoutData.discountId || queryParamDiscountId || eduGovMilDiscountId || null;
      // Try to update the checkout with the new items and existing discountId
      // THIS CAN FAIL IF THE NEW ITEMS AREN'T VALID FOR THE EXISTING DISCOUNT ID
      paddle.Checkout.updateCheckout({ items, discountId });
    } catch (err) {
      BugsnagUtil.notifyWithNamedMetadata(err, 'Paddle', { items });
      PaddleToasts.showErrorUpdatingCheckoutToast(commonStore);
      setCheckoutLoading(false);
    }
  };

  /**
   * Adds an item to the checkout.
   * This is a wrapper around updateCheckoutItems that handles the logic of adding an item to the existing items array.
   * @param {Object} item Object representing the new item to be purchased. ex: { priceId: 'pri_98h398ncv', quantity: 1 }
   */
  const addCheckoutItem = (item) => {
    updateCheckoutItems([...checkoutData.items, item]);
  };

  /**
   * Removes an item from the checkout.
   * This is a wrapper around updateCheckoutItems that handles the logic of removing an item from the existing items array.
   * @param {string<Price ID>} priceId The priceId of the item to be removed ex: 'pri_98h398ncv'
   */
  const removeCheckoutItem = (priceId) => {
    const newItems = checkoutData.items.filter((i) => i.priceId !== priceId);
    updateCheckoutItems(newItems);
  };

  /**
   * Swaps the priceId of an item in the checkout.
   * This is a wrapper around updateCheckoutItems that handles the logic of swapping the priceId of an item in the existing items array.
   * @param {string<Price ID>} currentPriceId The current priceId of the item to be swapped ex: 'pri_98h398ncv'
   * @param {string<Price ID>} newPriceId The new priceId of the item to be swapped ex: 'pri_98h398ncv'
   */
  const swapCheckoutItems = (currentPriceId, newPriceId) => {
    const newItems = checkoutData.items.map((item) => {
      // Find the item we want to swap and update it's priceId
      if (item.price_id === currentPriceId) {
        return { priceId: newPriceId, quantity: item.quantity };
      }
      // otherwise return the item in the cart as-is
      return { priceId: item.price_id, quantity: item.quantity };
    });
    updateCheckoutItems(newItems);
  };

  /**
   * Clears the checkout error state
   * @returns
   * @see https://developer.paddle.com/v1/paddlejs/methods/paddle-checkout-clearerror
   */
  const clearCheckoutError = () => {
    setCheckoutError(null);
  };

  const values = useMemo(
    () => ({
      environment,
      paddle,
      registrationData,
      setRegistrationData,
      registrationError,
      setRegistrationError,
      checkoutData,
      checkoutError,
      clearCheckoutError,
      setCheckoutError,
      checkoutLoading,
      setCheckoutLoading,
      startNewCheckout,
      updateCheckoutItems,
      addCheckoutItem,
      removeCheckoutItem,
      swapCheckoutItems,
      isUpdatingExistingTx,
      registerNewUser,
      previewRegisterNewUser,
    }),
    [
      environment,
      paddle,
      registrationData,
      setRegistrationData,
      registrationError,
      setRegistrationError,
      checkoutData,
      checkoutError,
      clearCheckoutError,
      setCheckoutError,
      checkoutLoading,
      setCheckoutLoading,
      startNewCheckout,
      updateCheckoutItems,
      addCheckoutItem,
      removeCheckoutItem,
      swapCheckoutItems,
      isUpdatingExistingTx,
      registerNewUser,
      previewRegisterNewUser,
    ]
  );

  return <PaddleCheckoutContext.Provider value={values}>{children}</PaddleCheckoutContext.Provider>;
}
export { PaddleCheckoutContext, PaddleCheckoutProvider };
