import React from 'react';
import If from '../If/If';
import Container from '../Container/Container';
import RichTextRenderer from '../Immersive/Content/RichTextRenderer';
import CareerProgramStudyGuideWidget from './CareerProgramStudyGuideWidget';
import CareerProgramsSkillsTrackerWidget from './CareerProgramsSkillsTrackerWidget';
import TopForumTopicsForCategoryWidget from '../Forums/Widgets/TopForumTopicsForCategoryWidget';
import { useCareerPrograms } from '../../providers/CareerProgramsProvider';

function CareerProgramOverviewTab() {
  const { currentPageProgram: program } = useCareerPrograms();
  const { discourseCategorySlug } = program?.content_item?.meta || {};

  const richDescription = program?.content_item?.meta?.richDescription?.content;

  return (
    <Container size="fluid" className="pt-4 pb-5 w-full lg:flex" omitPadding>
      {/** Rich Text Description */}
      <Container className="flex-col pr-5 space-y-2 lg:w-3/5" omitPadding>
        <If condition={richDescription?.length}>
          <RichTextRenderer content={richDescription} />
        </If>
        <If condition={!richDescription?.length}>
          <p>{program?.shortDescription}</p>
        </If>
      </Container>
      {/** Right Sidebar */}
      <Container className="flex-col space-y-2 lg:w-[500px]" omitPadding>
        {/** Study Guide */}
        <CareerProgramStudyGuideWidget />
        {/** Skills / Readiness Tracker */}
        <CareerProgramsSkillsTrackerWidget />
        {/** Top Forum Topics */}
        <If condition={discourseCategorySlug}>
          <TopForumTopicsForCategoryWidget discourseCategorySlug={discourseCategorySlug} border maxTopics={4} />
        </If>
      </Container>
    </Container>
  );
}

export default CareerProgramOverviewTab;
