import React, { useState, useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import queryString from 'query-string';
import { useParams, useNavigate } from 'react-router-dom';
import withRouter from '../../components/Router/withRouter';
import Title from '../../components/Title/Title';
import ContentOverlay from '../../components/ContentOverlay/ContentOverlay';
import TeamsGoalsWizard from '../../components/Goals/TeamsGoalsWizard';
import GoalsList from '../../components/Goals/GoalsList';
import GoalsFilters from '../../components/Goals/GoalsFilters';
import GoalDetails from '../../components/Goals/GoalDetails';
import ResultsSummary from '../../components/AdminTable/ResultsSummary';
import Container from '../../components/Container/Container';
import FormatUtil from '../../utils/formatUtil';
import ActionUtil from '../../utils/actionsUtil';
import If from '../../components/If/If';
import { USERPILOT_EVENTS } from '../../constants';
import UPTrigger from '../../components/UPTrigger/UPTrigger';

function DeleteGoalConfirmText({ goalData, columns, goalStatus }) {
  const dueTypeIdx = FormatUtil.getColIndex(columns, 'due_type');
  const isRecurring = goalData[dueTypeIdx] && goalData[dueTypeIdx].value === 'recurring';
  const isUpcoming = goalStatus === 'upcoming';
  let mainText = 'Are you sure you want to delete this goal? This action cannot be undone';
  if (isUpcoming) {
    mainText += '.';
  } else {
    mainText += ' and all goal progress will be lost.';
  }
  let secondaryText = null;
  if (isRecurring) {
    if (isUpcoming) {
      secondaryText = 'The current active recurring goal will no longer recur.';
    } else {
      secondaryText = 'This will delete all upcoming occurrences of this goal.';
    }
  }
  return (
    <>
      <p className={secondaryText ? 'mb-2' : 'mb-0'}>{mainText}</p>
      {secondaryText && <p className="mb-0">{secondaryText}</p>}
    </>
  );
}

const getGroups = (hierarchy, includeReporting) => {
  const groups = [];
  hierarchy.forEach((group) => {
    const children = getGroups(group.children, includeReporting);
    if (group.permissions && (group.permissions.manage === 'edit' || (includeReporting && group.permissions.reports))) {
      groups.push(group.id);
    }
    groups.push(...children);
  });
  return groups;
};

const getGroupScopeQuery = (teamHierarchy, isGoalList, team) => {
  let query = '';
  if (team?.permissions?.canManageTeam) {
    return query;
  }
  if (isGoalList && team?.permissions?.canViewReports === 'all') {
    return query;
  }
  if (teamHierarchy && teamHierarchy.length) {
    const groupsArray = getGroups(teamHierarchy, !!isGoalList);
    query = groupsArray.length ? `groupIds[]=${groupsArray.join('&groupIds[]=')}` : '';
  }

  return query;
};

const filterGroupsByPermissions = (group) => group.permissions.manage === 'edit';

const TeamGoals = inject(
  'commonStore',
  'userStore',
  'authStore',
  'goalsStore',
  'enterpriseStore',
  'teamHierarchyStore'
)(
  observer(({ commonStore, userStore, authStore, goalsStore, enterpriseStore, teamHierarchyStore }) => {
    const { orgId } = useParams();
    const navigate = useNavigate();
    const { team } = userStore;
    const isDemo = !team?.id || team?.id === 'demo';

    const [sliderOpen, setSliderOpen] = useState(false);
    const [sliderView, setSliderView] = useState(null);
    const [goalId, setGoalId] = useState(null);
    const [isExtendingGoal, setIsExtendingGoal] = useState(false);

    const [goalsListingTeamGroupId, setGoalsListingTeamGroupId] = useState(null);
    const [goalListGroupScopeQuery, setGoalListGroupScopeQuery] = useState('');
    const [goalsAdminTeamGroupId, setGoalsAdminTeamGroupId] = useState(null);

    const { teamGoals, setQueryParams, setGoalOwnersList } = goalsStore;

    const getTeamGoals = async () => {
      let viewParam = '';
      if (goalListGroupScopeQuery?.length) {
        viewParam += `?${goalListGroupScopeQuery}`;
      }

      return goalsStore.getTeamGoals(goalsListingTeamGroupId || team?.id, viewParam);
    };

    const deleteGoal = (deleteGoalId) => {
      goalsStore
        .deleteGoal(deleteGoalId, goalsAdminTeamGroupId)
        .then(() => {
          commonStore.triggerToast('success', { content: 'Goal deleted.' });
          getTeamGoals();
        })
        .catch(() => {
          commonStore.triggerToast('error', { content: 'Something went wrong. Unable to delete this goal at this time.' });
        });
    };

    const handleFilterChange = (key, val) => {
      goalsStore.setQueryParams('teamGoals', key, val);
      getTeamGoals();
    };

    const toggleSlider = (view, sliderGoalId, sliderOpenState) => {
      setGoalId(sliderGoalId);
      setSliderView(view);
      setSliderOpen(sliderOpenState || !sliderOpen);
      const queryParams = queryString.parse(window.location.search);
      if (queryParams.goal) {
        delete queryParams.goal;
        navigate({
          search: queryString.stringify(queryParams),
        });
      }
      if (queryParams.action && queryParams.action === 'add') {
        navigate({
          search: '',
        });
      }
    };

    const cancelConfirm = (callback) => {
      const handleConfirm = () => {
        if (goalId && sliderView !== 'details') {
          setSliderView('details');
          setSliderOpen(true);
        } else {
          setSliderView(null);
          setSliderOpen(false);
          setIsExtendingGoal(false);
        }
        if (callback) {
          callback();
        }
      };
      if (!goalsStore.addEditGoalPrestine) {
        commonStore.triggerConfirm({
          content: 'Are you sure you want to cancel? Your goal will not be saved',
          cancel: () => commonStore.resetConfirmState(),
          confirmBtn: 'Continue',
          continue: () => {
            commonStore.resetConfirmState();
            goalsStore.resetAddEditGoalPrestine();
            handleConfirm();
          },
        });
      } else {
        handleConfirm();
      }
    };

    const toggleEditGoal = (goalData, columns, newGoalId, extendingGoal) => {
      let goal = newGoalId;
      if (!goal) {
        const idIdx = FormatUtil.getColIndex(columns, 'id');
        goal = goalData[idIdx] && goalData[idIdx].value ? goalData[idIdx].value : null;
      }
      if (goal) {
        if (extendingGoal) {
          setIsExtendingGoal(true);
        }
        toggleSlider('edit', goal, true);
      }
    };

    const toggleEditGoalDueDate = (goalData, columns, newGoalId) => {
      return toggleEditGoal(goalData, columns, newGoalId, true);
    };

    const deleteGoalConfirm = (goalData, columns) => {
      const idIdx = FormatUtil.getColIndex(columns, 'id');
      const deleteGoalId = goalData[idIdx] && goalData[idIdx].value ? goalData[idIdx].value : null;
      if (deleteGoalId) {
        commonStore.triggerConfirm({
          content: <DeleteGoalConfirmText goalData={goalData} columns={columns} goalStatus={teamGoals.queryParams?.status} />,
          cancel: () => commonStore.resetConfirmState(),
          confirmBtn: 'Continue',
          continue: () => {
            commonStore.resetConfirmState();
            deleteGoal(deleteGoalId);
          },
        });
      }
    };

    const init = () => {
      const { getPaths, getCertifications, getGoalParticipantsData, getGoalOwnersData } = goalsStore;
      setGoalsListingTeamGroupId(team?.permissions.canViewReports === 'all' ? team?.id : team?.goalsTeamGroupId);
      setGoalsAdminTeamGroupId(team?.goalsTeamGroupId);

      const queryParams = queryString.parse(window.location.search);
      if (queryParams.goal) {
        setGoalId(queryParams.goal);
        setSliderOpen(true);
        setSliderView('details');
      }
      if (queryParams?.action === 'add') {
        setGoalId(null);
        setSliderView('add');
        setSliderOpen(true);
      }
      if (queryParams?.outcome_type) {
        goalsStore.setQueryParams('teamGoals', 'outcomeTypes', [queryParams.outcome_type]);
      }
      if (queryParams?.owner_id) {
        goalsStore.setQueryParams('teamGoals', 'ownerIds', [queryParams.owner_id]);
      }
      if (queryParams?.status) {
        goalsStore.setQueryParams('teamGoals', 'status', queryParams.status);
      }
      getPaths(team?.id, team?.package_types);
      getCertifications();
      teamHierarchyStore.getTeamHierarchy(team).then((teamHierarchy) => {
        const newGoalListGroupScopeQuery = getGroupScopeQuery(teamHierarchy, true, team);
        setGoalListGroupScopeQuery(newGoalListGroupScopeQuery);
        const goalOptionsGroupScopeQuery = getGroupScopeQuery(teamHierarchy, false, team);
        const formattedGoalOptionsGroupScopeQuery = goalOptionsGroupScopeQuery && goalOptionsGroupScopeQuery.length ? `?${goalOptionsGroupScopeQuery}` : '';
        getGoalParticipantsData(team?.id, formattedGoalOptionsGroupScopeQuery);
        getTeamGoals().then((goals) => {
          setGoalOwnersList('teamGoals', goals || [], true);
        });
        getGoalOwnersData(team?.id, team?.permissions.canManageTeam, teamHierarchyStore.trees[team?.id].data);
        teamHierarchyStore.setHierarchySelectOptions(team?.id, filterGroupsByPermissions, null, null, true);
      });
    };

    useEffect(() => {
      if (isDemo) {
        userStore.enterDemo();
        goalsStore.enterDemo();
      } else {
        userStore.setPreferredTeamInit('', orgId, init);
      }
      authStore.fireAttributionEvent();
      commonStore.setPageTitle(`Team Goals${isDemo ? ' Demo' : ''} | Cybrary`);
      ActionUtil.scrollToTop();

      return () => {
        goalsStore.setDefaultGoalOwners();
        goalsStore.setDefaultGoalParticipants();
        goalsStore.setDefaultPaths();
        goalsStore.setDefaultLearningActivities();
        enterpriseStore.setDefaultGroupList();
        goalsStore.setDefaultCertifications();
        goalsStore.setDefaultTeamGoals();
        if (isDemo) {
          goalsStore.exitDemo();
          userStore.exitDemo();
        }
      };
    }, [orgId, team]);

    if (!team) {
      return null;
    }

    const wizardTitle = `${goalId ? 'Edit' : 'Create'} Goal`;
    const containerOverlayWidth = sliderView === 'add' ? 'w-[70vw]' : 'w-[40vw]';
    const containerOverlayMaxWidth = sliderView === 'details' ? 'max-w-xl' : 'max-w-5xl';

    return (
      <Container size="lg" className="team-goals">
        <div className="goals-management">
          <div className="items-center sm:flex">
            <div className="flex-1">
              <Title title="Goals" />
              <p className="text-sm text-gray-600">Goals help you track your team&apos;s learning and the on-the-job outcomes you care about.</p>
            </div>
            <If condition={team?.permissions?.canManageAssignments}>
              <div className="flex items-start my-4">
                <button
                  className={`py-2.5 px-6 text-sm font-bold leading-5 text-center text-white hover:text-white bg-cyb-pink-500 hover:bg-cyb-pink-600 rounded-sm ${
                    isDemo ? 'cursor-not-allowed' : ''
                  }`}
                  onClick={() => toggleSlider('add')}
                  disabled={isDemo}
                >
                  + Add new
                </button>
                <UPTrigger guideId={USERPILOT_EVENTS.ADD_GOAL_BUTTON} className="-mt-5 -ml-1" />
              </div>
            </If>
          </div>
          <GoalsFilters
            className="pt-8"
            onOwnerFilterChange={handleFilterChange}
            onOutcomeFilterChange={handleFilterChange}
            ownerOptions={teamGoals.ownersList.slice()}
            ownerValues={teamGoals.queryParams?.ownerIds}
            isOwnerDisabled={isDemo}
            isLoading={teamGoals.loading}
            outcomeValues={teamGoals.queryParams?.outcomeTypes}
            isOutcomeDisabled={isDemo}
            statusValue={teamGoals.queryParams?.status}
            onStatusFilterChange={handleFilterChange}
            isStatusDisabled={isDemo}
            isStatusLoading={teamGoals.loading}
          />
          <ResultsSummary srOnly numOfResults={teamGoals && teamGoals.goalsCount} />
          <GoalsList
            viewGoal={isDemo ? () => {} : (viewGoalId) => toggleSlider('details', viewGoalId)}
            editGoal={isDemo ? () => {} : toggleEditGoal}
            editGoalDueDate={isDemo ? () => {} : toggleEditGoalDueDate}
            deleteGoal={isDemo ? () => {} : deleteGoalConfirm}
            setQueryParams={setQueryParams}
            teamGoals={teamGoals}
            getTeamGoals={isDemo ? () => {} : getTeamGoals}
            team={team}
          />
          <ContentOverlay omitPadding open={sliderOpen} dismiss={cancelConfirm} width={containerOverlayWidth} maxWidth={containerOverlayMaxWidth} ariaLabelledBy="goal-title">
            <If condition={sliderView === 'add'}>
              <div className="py-4">
                <div className="px-8 mb-9">
                  <Title title={wizardTitle} titleId="goal-title" omitPadding wrapperClasses="mb-4" />
                  <p className="hidden text-sm text-gray-600 md:block">
                    Goals help you track your team&apos;s learning and the on-the-job outcomes you care about.
                    <br />
                    Follow this step-by-step guide to create a goal for your team.
                  </p>
                </div>
                <TeamsGoalsWizard
                  team={team}
                  teamGroupId={goalsAdminTeamGroupId}
                  goalId={goalId}
                  onSubmit={() => {
                    toggleSlider();
                    getTeamGoals();
                  }}
                  filterGroupsByPermissions={filterGroupsByPermissions}
                  queryParams={queryString.parse(window.location.search)}
                />
              </div>
            </If>
            <If condition={sliderView === 'details' || sliderView === 'edit'}>
              <GoalDetails
                goalId={goalId}
                groupScopeQuery={goalListGroupScopeQuery}
                progressTeamGroupId={goalsListingTeamGroupId}
                editTeamGroupId={goalsAdminTeamGroupId}
                teamId={orgId}
                toggleEditGoal={toggleEditGoal}
                onEdit={() => {
                  toggleSlider('details', goalId, true);
                  getTeamGoals();
                }}
                editView={sliderView === 'edit'}
                editCancel={(callback) => cancelConfirm(callback)}
                filterGroupsByPermissions={filterGroupsByPermissions}
                isExtendingGoal={isExtendingGoal}
                teamAdminView
              />
            </If>
          </ContentOverlay>
        </div>
      </Container>
    );
  })
);

export default withRouter(TeamGoals);
