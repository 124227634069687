import React, { useState, useEffect } from 'react';
import { twMerge } from 'tailwind-merge';
import fieldClasses from './fieldClasses';

function Select({ id, wrapperClasses, name, options, placeholder, required, error, disabled, ariaDescribedBy, onChange, defaultValue, ariaLabel, showDropdownIcon }) {
  const [input, setInput] = useState(defaultValue || '');

  useEffect(() => {
    onChange(undefined, { name, value: input });
  }, [input]);

  // Reset the input value if the default value changes
  useEffect(() => {
    onChange(undefined, { name, value: defaultValue });
  }, [defaultValue]);

  const classes = twMerge('relative', wrapperClasses);
  return (
    <div className={classes}>
      <select
        id={id}
        name={name}
        disabled={!!disabled}
        className={fieldClasses(error, disabled)}
        onChange={(e) => setInput(e.target.value)}
        value={input}
        aria-label={ariaLabel}
        aria-required={required}
        aria-describedby={ariaDescribedBy}
      >
        {placeholder && <option value="">{placeholder}</option>}

        {options.map((option) => (
          <option key={option.label || option.text} value={option.value}>
            {option.label || option.text}
          </option>
        ))}
      </select>
      {showDropdownIcon && (
        <div className="flex absolute inset-y-0 right-0 items-center px-2 text-gray-600 pointer-events-none">
          <svg className="w-5 h-5 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
            <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
          </svg>
        </div>
      )}
    </div>
  );
}

export default Select;
