import React, { useState } from 'react';
import { inject, observer } from 'mobx-react';
import Checkbox from '../../components/FormFields/Checkbox';
import Loading from '../../components/Loading/Loading';
import StyledError from '../../components/Error/StyledError';

const ManageTeamFacingLeaderboard = inject(
  'enterpriseStore',
  'commonStore',
  'userStore'
)(
  observer(({ userStore, commonStore, enterpriseStore, team }) => {
    const { show_team_leaderboard, id: orgId } = team || {};
    const [isSaving, setIsSaving] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(false);

    const isTeamLeaderboardShown = show_team_leaderboard ? <span className="text-cyb-green-600">Enabled</span> : <span className="text-cyb-red-700">Disabled</span>;

    const isDemo = !orgId || orgId === 'demo';

    const editTeamSettings = (setting) => {
      setIsSaving(true);
      enterpriseStore
        .editOrgSettings(orgId, setting)
        .then(async () => {
          setIsSaving(false);
          commonStore.triggerToast('success', {
            content: setting.show_team_leaderboard ? 'Team leaderboard successfully enabled!' : 'Team leaderboard successfully disabled!',
          });
          setIsLoading(true);
          await userStore.loadAndSetTeamData(orgId);
        })
        .catch((e) => {
          setError(e);
          commonStore.triggerToast('error', {
            errorCode: e.response.status,
          });
        })
        .finally(() => {
          setIsSaving(false);
          setIsLoading(false);
        });
    };

    const toggleConfirm = (_e, { checked }) => {
      let confirmText;
      if (checked) {
        confirmText = 'Are you sure you want to allow members access to the team leaderboard?';
      } else {
        confirmText = 'Are you sure you want to disable members access to the team leaderboard?';
      }
      commonStore.triggerConfirm({
        content: confirmText,
        cancel: () => commonStore.resetConfirmState(),
        continue: () => {
          commonStore.resetConfirmState();
          editTeamSettings({ show_team_leaderboard: checked });
        },
      });
    };

    if (isLoading) {
      return <Loading message="Loading" />;
    }

    if (isSaving) {
      return <Loading message="Saving..." />;
    }

    if (error) {
      return <StyledError error={error} />;
    }

    return (
      <div className="flex items-center mt-4 text-sm">
        <Checkbox
          className="flex items-start mr-6 mb-2 disabled:cursor-not-allowed"
          onChange={isDemo ? undefined : toggleConfirm}
          checked={Boolean(show_team_leaderboard)}
          ariaLabelledBy="team-leaderboard-access-toggle"
          toggle
          disabled={isDemo}
        />
        <p id="team-leaderboard-access-toggle">Allow members access to the team leaderboard: {isTeamLeaderboardShown}</p>
      </div>
    );
  })
);

export default ManageTeamFacingLeaderboard;
