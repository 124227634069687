import React from 'react';
import { observer } from 'mobx-react';
import { twMerge } from 'tailwind-merge';
import Header from '../Header/Header';
import ArchivedLabel from '../ContentUpdates/ArchivedLabel';
import BetaLabel from '../Label/BetaLabel';
import FormatUtil from '../../utils/formatUtil';
import Label from '../Label/Label';
import EnrollmentButton from '../BrowsePromo/EnrollmentButton';
import ProgressBar from '../ProgressBar/ProgressBar';
import Icon from '../Icon/Icon';
import AddLink from '../AddLink/AddLink';
import LockedContainer from '../Container/LockedContainer';

const CurriculumItem = observer(({ curriculumItem, content, isLocked = false }) => {
  const {
    content_description_title,
    content_description_type,
    learning_seconds_total,
    learning_seconds_completed,
    progress,
    content_description_thumbnail_url,
    content_description_archived_at,
  } = curriculumItem;

  const formattedImg = `${content_description_thumbnail_url}?w=260&q=60`;
  const timeRemaining = learning_seconds_total - learning_seconds_completed;
  const isArchived = !!content_description_archived_at;
  const browseLink = `/browse/${curriculumItem?.content_description_permalink}`;
  const browseLinkTitle = `View ${content_description_title}`;
  const classes = twMerge('py-0 md:pr-6 mb-12 md:mb-4 bg-gray-100 ', !isLocked ? 'border border-gray-400' : '');
  return (
    <LockedContainer isFullWidth isLocked={isLocked} msg="This item is locked.">
      <div className={classes}>
        <div className="flex flex-col space-x-0 md:flex-row md:space-x-4">
          <div className="m-auto">
            <AddLink to={browseLink} title={browseLinkTitle}>
              <img src={formattedImg} alt="" className="max-w-full md:max-w-[260px]" />
            </AddLink>
          </div>
          <div className="flex flex-col grow justify-evenly p-4 md:px-0">
            <AddLink to={browseLink} title={browseLinkTitle}>
              <Header as="h2" className="mb-2">
                {content_description_title}
              </Header>
            </AddLink>
            <div>
              <Label basic>{content_description_type}</Label>
              {isArchived && <ArchivedLabel />}
              {curriculumItem?.tags?.includes('Beta') && <BetaLabel />}
            </div>
            <div className="flex flex-col justify-start">
              {progress < 100 && (
                <div className="flex items-center py-2 text-xs text-gray-600">
                  <Icon name="clock" className="mr-1 w-4 h-4 opacity-[0.7]" /> {FormatUtil.formatTime(timeRemaining, 'hm')} Remaining
                </div>
              )}
              {progress >= 100 && (
                <div className="flex items-center py-2 text-xs text-gray-600">
                  <Icon name="check" className="mr-1 w-4 h-4 opacity-[0.5]" />
                  Complete
                </div>
              )}
              <div className="w-full md:w-48">
                <ProgressBar progress={Math.min(progress, 100)} showProgressLabel />
              </div>
            </div>
          </div>
          <div className="my-auto">
            <EnrollmentButton
              content={content}
              options={{ preferredCollectionItemId: curriculumItem.content_description_id }}
              progressOverride={progress}
              buttonClasses="w-full md:w-auto md:min-w-[120px]"
            />
          </div>
        </div>
      </div>
    </LockedContainer>
  );
});

export default CurriculumItem;
