import React, { useRef, useEffect, useState } from 'react';
import { twMerge } from 'tailwind-merge';

export default function Sticky({ children, stickyClasses, unStickyClasses, disableSticky }) {
  const stickyRef = useRef(null);
  const [isStuck, setIsStuck] = useState(false);

  const observerCallback = (entries) => {
    const [entry] = entries;
    setIsStuck(!entry.isIntersecting);
  };

  useEffect(() => {
    const observer = new IntersectionObserver(observerCallback, { threshold: 1 });
    if (stickyRef?.current) {
      observer.observe(stickyRef.current);
    }
    return () => (stickyRef?.current ? observer.unobserve(stickyRef.current) : null);
  }, [stickyRef]);

  // The -1px top is to offset the 1px threshold in the IntersectionObserver options
  const classes = isStuck && !disableSticky ? twMerge('sticky -top-[1px] z-20 bg-white', stickyClasses) : unStickyClasses;

  return (
    <div className={classes} ref={stickyRef}>
      {children}
    </div>
  );
}
