import React, { useEffect, useState } from 'react';
import queryString from 'query-string';
import { useNavigate } from 'react-router-dom';

import Modal from '../Modal/Modal';
import Header from '../Header/Header';
import Icon from '../Icon/Icon';
import Button from '../Button/Button';

export default function NewTeamPurchaseMessage({ clearSubscriptionStatus }) {
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const queryParams = queryString.parse(window.location.search);
    const isNewSubscription = !!queryParams.newSubscription;
    // Clear the storage subscription status so the user doesn't end up here again
    clearSubscriptionStatus();
    if (isNewSubscription) {
      setShowModal(isNewSubscription);
      delete queryParams.newSubscription;
      navigate({
        search: queryString.stringify(queryParams),
      });
    }
  }, []);

  return (
    <Modal position="center" size="sm" open={showModal} toggle={() => setShowModal(!showModal)} ariaLabelledBy="new-team-subscription">
      <div className="p-8 text-center">
        <Icon name="user-group" className="mx-auto w-24 h-24 text-cyb-pink-500 fill-current" />
        <Header as="h2" id="new-team-subscription" className="mb-4">
          Welcome to Cybrary for Teams!
        </Header>
        <p className="text-lg text-gray-700">One of our customer support team members will contact you within the next business day to help finish setting up your team.</p>
        <Button className="mt-4" onClick={() => setShowModal(false)}>
          Get Started
        </Button>
      </div>
    </Modal>
  );
}
