import React, { createContext, useContext, useState, useMemo, useCallback, useEffect, useRef } from 'react';
import Bugsnag from '@bugsnag/js';
import { useLocation, useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import { Userpilot } from 'userpilot';
import moment from 'moment';
import GaUtil from '../utils/gaUtil';
import Agents from '../agents/agents';
import PermalinkUtil from '../utils/permalinkUtil';
import { BLACKLISTED_ONBOARDING_PAGES, DEFAULT_COOKIE_SETTINGS, USERPILOT_EVENTS } from '../constants';
import { useAuth } from '../contexts/UseAuth';
import { trackSnowplowEvent } from '../utils/snowplowUtil';
import { useProfileContext } from './ProfileProvider';

const UserOnboardingContext = createContext();

const SP_EVENT_CATEGORY = 'OnboardingStep';

export const USER_ONBOARDING_STEPS = {
  INITIAL: 'initial',
  CIP_FORM: 'cipForm',
  TEAM_FORM: 'teamForm',
  CONTENT_RECOMMENDATIONS: 'contentRecommendations',
  SELECT_PACKAGE: 'selectPackage',
  FINISHED: 'finished',
};

export const USER_ONBOARDING_OPTIONS = {
  membershipFor: {
    TEAM: 'team',
    CIP: 'cip',
  },
  cipGoal: {
    LAUNCH_MY_CAREER: 'Launch My Career',
    GET_A_CERTIFICATION: 'Get a Certification',
    UPSKILL_AND_PRACTICE: 'Upskill & Practice',
  },
  cipExperience: {
    NEW: "I'm brand new",
    EXPERIENCED: "I've got some IT experience",
  },
  cipYearsOfExperience: {
    LESS_THAN_2: '0-2 years',
    MORE_THAN_2: '2+ years',
  },
};

export const RECOMMENDATIONS_OPTIONS = {
  LAUNCH_MY_CAREER: {
    key: 'LAUNCH_MY_CAREER',
    title: 'Start or Advance My Career',
    icon: 'rocket-2',
    value: 'Launch My Career',
    navigateTo: '/browse/paths/career-paths',
  },
  GET_A_CERTIFICATION: {
    key: 'GET_A_CERTIFICATION',
    title: 'Prepare for a Certification Exam',
    icon: 'ribbon-2',
    value: 'Get a Certification',
    navigateTo: '/browse/paths/cert-prep-paths',
  },
  UPSKILL_AND_PRACTICE: {
    key: 'UPSKILL_AND_PRACTICE',
    title: 'Learn a New Skill',
    icon: 'growth',
    value: 'Upskill & Practice',
    navigateTo: '/browse/paths/skill-paths',
  },
};

export const MEMBERSHIP_FOR_OPTIONS = {
  INDIVIDUALS: {
    key: 'cip',
    title: 'Individual',
    description: 'Built for individuals looking to explore Cybrary and learn more about cybersecurity.',
    className: '',
    icon: 'user',
    buttonText: "I'm here for myself",
    featuresTitle: 'Get limited access to the Cybrary catalog, including:',
    features: [
      { hasAccess: true, description: 'Start or advance my career in cybersecurity' },
      { hasAccess: true, description: 'Prepare for certification exams' },
      { hasAccess: true, description: 'Learn new skills' },
      { hasAccess: true, description: 'Earn badges and CEUs/CPEs' },
      { hasAccess: true, description: 'Identify skill gaps via hands-on assessments' },
      { hasAccess: true, description: 'Get community support' },
      { hasAccess: true, description: 'Share my Learner Profile' },
    ],
  },
  TEAMS: {
    key: 'team',
    title: 'Team / Business',
    description: 'Built for organizations looking to reduce their cyber risk through hands-on training for their cybersecurity teams.',
    className: '',
    icon: 'user-group',
    buttonText: "I'm here for my business",
    buttonColor: 'purple',
    iconClassName: 'fill-purple-700',
    featuresTitle: 'Get full access to the complete Cybrary catalog, as well as Teams-exclusive features:',
    features: [
      { hasAccess: true, description: 'Monitor and track team performance' },
      { hasAccess: true, description: 'Baseline and remediate my team with custom learning recommendations' },
      { hasAccess: true, description: 'Upskill my team with role-aligned career paths' },
      { hasAccess: true, description: 'Prepare my team for certification exams' },
      { hasAccess: true, description: 'Stay informed about emerging threats' },
      { hasAccess: true, description: 'Create custom learning paths' },
      { hasAccess: true, description: 'Assign and manage training goals' },
    ],
  },
};

export const ONBOARDING_PACKAGE_OPTIONS = {
  FREE: {
    key: 'FREE',
    title: 'Free',
    icon: 'user',
    titleClasses: 'text-3xl text-black',
    iconClassName: 'fill-black',
    className: '',
    buttonText: 'Start For Free',
    buttonColor: 'gray',
    description: 'For individuals looking to explore Cybrary and learn more about cybersecurity.',
    priceSubtitle: 'Limited Access',
    featuresTitle: 'Get limited access to the Cybrary catalog, including:',
    features: [
      {
        hasAccess: true,
        description: '10 Lessons per day',
      },
      {
        hasAccess: true,
        description: 'Select Courses and Labs from Career Paths and Skill Paths',
      },
      {
        hasAccess: true,
        description: 'Select Cert Prep Courses for 20+ Industry Certifications',
      },
      {
        hasAccess: true,
        description: 'Community Forums',
      },
      {
        hasAccess: false,
        description: 'Private Discord Community',
      },
    ],
  },
  CIP: {
    key: 'CIP',
    navigateTo: `${process.env.REACT_APP_V2_SITE_URL}/upgrade`,
    title: 'Insider Pro',
    icon: 'user',
    className: 'border-cyb-pink-500 border-4',
    titleClasses: 'text-3xl',
    description: 'For individuals looking for their next career opportunity in cybersecurity.',
    gradientBorder: true,
    bannerText: 'Most Popular',
    buttonText: 'Select Package',
    featuresTitle: 'Get full access to the complete Cybrary catalog, including:',
    priceSubtitle: 'Billed Annually',
    features: [
      {
        hasAccess: true,
        description: 'Everything included in the Free tier',
      },
      {
        hasAccess: true,
        description: 'Unlimited Daily Lessons',
      },
      {
        hasAccess: true,
        description: 'All Career Paths and Skill Paths',
      },
      {
        hasAccess: true,
        description: 'All Cert Prep Paths',
      },
      {
        hasAccess: true,
        description: 'Hands-On Assessments',
      },
      {
        hasAccess: true,
        description: 'Practice Exams',
      },
      {
        hasAccess: true,
        description: 'Threat Actor Campaigns',
      },
      {
        hasAccess: true,
        description: 'CVE Briefings',
      },
      {
        hasAccess: true,
        description: 'Challenges',
      },
      {
        hasAccess: true,
        description: 'Private Discord Community',
      },
      {
        hasAccess: true,
        description: 'Unlimited CEUs',
      },
    ],
  },
  TEAMS: {
    key: 'TEAMS',
    navigateTo: `${process.env.REACT_APP_V2_SITE_URL}/upgrade`,
    title: 'Teams',
    titleClasses: 'text-3xl text-black',
    icon: 'user-group',
    iconClassName: 'fill-purple-700',
    buttonColor: 'purple',
    buttonText: 'Select Package',
    description: 'For organizations looking to reduce their cyber risk through hands-on training for their teams.',
    featuresTitle: 'Get full access to the complete Cybrary catalog, as well as Teams-exclusive features:',
    priceSubtitle: 'Per User, Billed Annually',
    features: [
      {
        hasAccess: true,
        description: 'Everything included in the CIP tier',
      },
      {
        hasAccess: true,
        description: 'Risk Impact Monitoring',
      },
      {
        hasAccess: true,
        description: 'Framework-aligned Progress Tracking',
      },
      {
        hasAccess: true,
        description: 'Baselining',
      },
      {
        hasAccess: true,
        description: 'User and Group Management',
      },
      {
        hasAccess: true,
        description: 'Goals',
      },
      {
        hasAccess: true,
        description: 'Custom Paths',
      },
      {
        hasAccess: true,
        description: 'Analytics',
      },
      {
        hasAccess: true,
        description: 'NICE and DoD 8140-aligned Content Collections',
      },
    ],
  },
};

function UserOnboardingProvider({ children, user }) {
  const profileData = user?.onboarding_data || {};
  /** Hooks */
  const location = useLocation();
  const auth = useAuth();
  const navigate = useNavigate();
  const { personalProfileData } = useProfileContext();
  const { username } = personalProfileData || {};

  const [step, setStep] = useState(USER_ONBOARDING_STEPS.INITIAL);
  const [isSubmitting, setIsSubmitting] = useState(false); // used when saving data
  const [isLoading, setIsLoading] = useState(false); // used when reloading user data

  const previousStep = useRef(step); // useRef to avoid state updates when values change

  /* Initial step */
  const [teamOrCip, setTeamOrCip] = useState('');

  /* CIP/Team Form Field data */
  const [savedFormFields, setSavedFormFields] = useState({});

  /** Recommendations step */
  const [cipGoal, setCipGoal] = useState('');

  /** Select Package step */
  const [packageSelected, setPackageSelected] = useState('');

  /* Legacy fields */
  const [cipExperience, setCipExperience] = useState('');
  const [cipYearsOfExperience, setCipYearsOfExperience] = useState('');

  /** Abstractions */
  const isTeams = teamOrCip === USER_ONBOARDING_OPTIONS.membershipFor.TEAM;

  const isUserOnboardingCompleted = useMemo(() => {
    if (!user?.id) {
      return false;
    }

    // LEGACY ONBOARDING HANDLING
    // Old Accounts tend to have old malformed data that can cause issues in production
    // For these we need to check if the user has a first name, if they do, they are considered onboarded
    const accountCreatedDate = new Date(user?.registered_at);
    const oneYearAgo = moment().subtract(1, 'year').toDate();
    const isOlderThanOneYear = accountCreatedDate < oneYearAgo;
    if (isOlderThanOneYear) {
      // Just make sure they have a first name, otherwise, they are considered incomplete and should onboard
      return !!user?.first_name;
    }

    // Onboarding is considered completed if the user has `membershipFor` set
    // This field should only be set when the user completes the onboarding flow
    // This allows prefilling the onboarding form with the user's data from checkout
    return !!profileData?.membershipFor;
  }, [user, profileData]);

  const saveUserOnboardingData = useCallback(
    async (formFields) => {
      try {
        setIsSubmitting(true);

        // !IMPORTANT!
        // Profile data contains the username so we need to re-save it
        // alongside out onboarding data in the personal object
        // We should have a username loaded from profile data
        let currentUsername = username;
        // If we don't have a username, try to get it from the profile data
        if (!currentUsername) {
          setIsLoading(true);
          const currentProfileData = await Agents.profile.getProfile();
          currentUsername = currentProfileData?.data?.personal?.username || '';
          setIsLoading(false);
        }
        const payload = {
          // default payload the backend accepts, however it does not want null values including empty strings
          personal: {
            firstName: '',
            lastName: '',
            country: '',
            username: '',
            phone: '', // only required for teams
          },
          // This field is the wild wild west... it can be anything... but it is required
          onboarding_data: {
            membershipFor: '', // required for both
            title: '', // required for both... referred to as "role" for cip and "title" for teams... but the key is the same
            goal: '', // required for both
            experienceLevel: '', // only for cip but can be null depending on flow/route
            teamLead: '', // is in one cip flow but not the other... is also in teams form...
            yearsExperience: '', // only for cip but can be null depending on flow/route
            department: '', // both cip and teams... quasi required
            company: '', // both cip and teams... quasi required
            business_email: '', // only teams
          },
        };

        /** Personal */
        payload.personal = {
          firstName: formFields.firstName,
          lastName: formFields.lastName,
          country: formFields.country,
          username: currentUsername,
          phone: formFields?.phone || '', // only required for teams, but conditionally required for cip team leads
        };

        /** Onboarding Data */
        payload.onboarding_data = {
          title: formFields.title,
          goal: isTeams ? '' : cipGoal,
          membershipFor: teamOrCip,
          teamLead: formFields.teamLead,
          company: formFields?.company || '', // only required for teams, but conditionally required for cip
          department: formFields?.department || '', // quasi required for both, but conditionally for cip team leads
          teamSize: formFields?.teamSize || '', // quasi required for both, but conditionally for cip team leads
        };

        /** TEAMS - Add Business Email */
        if (isTeams) {
          payload.onboarding_data.business_email = formFields?.business_email || '';
        }

        /** CIP - Add experience if present */
        if (!isTeams) {
          payload.onboarding_data.experienceLevel = formFields?.experienceLevel || '';
          payload.onboarding_data.yearsExperience = formFields?.yearsExperience || '';
        }

        // Save the onboarding data to profile
        await Agents.profile.updateProfile(payload);

        // Push new profile data to GA/GTM
        // These are old, I think the create_profile event is the one we want to use
        GaUtil.fireEvent('CourseEnrollment', 'PQL', 'Onboarding');
        GaUtil.pushEventToDataLayer({
          event: 'create_profile',
          userId: user?.id,
          profile_country: payload?.personal?.country || '',
          what_brings: isTeams ? 'Team' : 'Career',
          // title in our form is the equivalent of role in GA
          role: payload?.onboarding_data?.title || '',
        });
      } catch (error) {
        Bugsnag.notify(error, (errorEvent) => {
          const { errorMessage } = errorEvent.errors[0];
          // eslint-disable-next-line no-param-reassign
          errorEvent.context = `User Onboarding Provider: ${errorMessage}`;
          errorEvent.addMetadata('saveUserOnboardingData', {
            cipGoal,
            cipExperience,
            cipYearsOfExperience,
            teamOrCip,
          });
        });
      } finally {
        setIsSubmitting(false);
      }
    },
    [teamOrCip, cipGoal, cipExperience, cipYearsOfExperience]
  );

  const exitUserOnboarding = useCallback(async () => {
    setIsLoading(true);

    // Do this first to get fresh user data
    await auth.refreshUser();

    // Track when a user exits onboarding. label is the step the modal was closed on.
    trackSnowplowEvent({ category: SP_EVENT_CATEGORY, action: 'closedModal', label: step });

    // Fire off post-onboarding userpilot guide (if any)
    Userpilot.track(USERPILOT_EVENTS.FINISHED_ONBOARDING);

    setIsLoading(false);
  }, [auth, step]);

  const isOnboardingAllowedOnThisPage = useMemo(() => {
    // Do not show on teams invite pages
    const isTeamInvitePage = /^\/team-invite*/.test(location.pathname);
    const isTeamJoinPage = /^\/team-join*/.test(location.pathname);
    if (isTeamInvitePage || isTeamJoinPage) {
      return false;
    }
    // Do not show on pages that are blacklisted for onboarding
    const cleanPathName = PermalinkUtil.removeTrailingSlash(location.pathname);
    return !BLACKLISTED_ONBOARDING_PAGES.includes(cleanPathName);
  }, [location]);

  const shouldEnterUserOnboarding = useMemo(() => {
    return !isUserOnboardingCompleted && isOnboardingAllowedOnThisPage && !!user?.id;
  }, [isUserOnboardingCompleted, isOnboardingAllowedOnThisPage, user]);

  // Set a cookie to hide the username modal for 36 hours if it is this user's first time here
  useEffect(() => {
    if (!isUserOnboardingCompleted) {
      Cookies.set('hide_username_modal', true, {
        ...DEFAULT_COOKIE_SETTINGS,
        path: '/',
        // expires in 36 hours
        expires: 1.5,
      });
    }
  }, [isUserOnboardingCompleted]);

  // Handle tracking of onboarding steps
  useEffect(() => {
    if (step) {
      // Track the last step that was completed
      if (step !== previousStep.current) {
        trackSnowplowEvent({ category: SP_EVENT_CATEGORY, action: 'completed', label: previousStep.current });
      }

      if (step === 'navigate') return;
      // Track the step that was shown
      trackSnowplowEvent({ category: SP_EVENT_CATEGORY, action: 'shown', label: step });
      // Update the last step for next event fire
      previousStep.current = step;
    }
  }, [step]);

  // Handle entering onboarding
  useEffect(() => {
    if (shouldEnterUserOnboarding) {
      navigate('/onboarding');
    }
  }, [shouldEnterUserOnboarding, navigate]);

  // Try to fetch profile data if it is not present or loading
  useEffect(() => {
    if (!profileData && !isLoading) {
      setIsLoading(true);
      auth.refreshUser().finally(() => {
        setIsLoading(false);
      });
    }
  }, [profileData, isLoading, auth]);

  const values = useMemo(
    () => ({
      step,
      teamOrCip,
      cipGoal,
      cipExperience,
      cipYearsOfExperience,
      savedFormFields,
      isSubmitting,
      isLoading,
      shouldEnterUserOnboarding,
      isUserOnboardingCompleted,
      setStep,
      setTeamOrCip,
      setCipGoal,
      setCipExperience,
      setCipYearsOfExperience,
      setSavedFormFields,
      saveUserOnboardingData,
      exitUserOnboarding,
      user,
      profileData,
      packageSelected,
      setPackageSelected,
    }),
    [
      step,
      teamOrCip,
      cipGoal,
      cipExperience,
      cipYearsOfExperience,
      savedFormFields,
      isSubmitting,
      isLoading,
      shouldEnterUserOnboarding,
      isUserOnboardingCompleted,
      setStep,
      setTeamOrCip,
      setCipGoal,
      setCipExperience,
      setCipYearsOfExperience,
      setSavedFormFields,
      saveUserOnboardingData,
      exitUserOnboarding,
      user,
      profileData,
      packageSelected,
      setPackageSelected,
    ]
  );

  return <UserOnboardingContext.Provider value={values}>{children}</UserOnboardingContext.Provider>;
}

export const useUserOnboarding = () => useContext(UserOnboardingContext);
export default UserOnboardingProvider;
