import React, { useState, useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import queryString from 'query-string';
import withRouter from '../Router/withRouter';
import Container from '../Container/Container';
import GoalsTiles from './GoalsTiles';
import ContentOverlay from '../ContentOverlay/ContentOverlay';
import GoalDetails from './GoalDetails';
import GoalsFilters from './GoalsFilters';
import Title from '../Title/Title';
import GoalsUtil from '../../utils/goalsUtil';

const MyGoals = inject(
  'enrollmentStore',
  'goalsStore'
)(
  observer((props) => {
    const [detailsOpen, setDetailsOpen] = useState(false);
    const [goalId, setGoalId] = useState(null);

    const getGoalStatusParam = (view) => {
      if (view === 'incomplete') {
        return 'in_progress';
      }
      if (view === 'upcoming') {
        return 'scheduled';
      }
      return view;
    };

    const getUserGoals = (refreshOwnerFilterList) => {
      const { view, goalsStore } = props;
      const { getUserGoals: fetchUserGoals, hasTrackingGoal, userGoals, lookForNewTrackingGoal, startTrackingGoalPolling, trackingGoalInit, setGoalOwnersList } = goalsStore;
      const queryParams = `?status=${getGoalStatusParam(view)}`;

      fetchUserGoals(queryParams).then((goals) => {
        if (refreshOwnerFilterList) {
          setGoalOwnersList('userGoals', goals || []);
        }
        if (view === 'incomplete' && !hasTrackingGoal && trackingGoalInit && userGoals.data && userGoals.data.length) {
          lookForNewTrackingGoal(userGoals.data);
          startTrackingGoalPolling();
        }
      });
    };

    useEffect(() => {
      getUserGoals(true);
      props.goalsStore.setDefaultGoalData();

      const queryParams = queryString.parse(window.location.search);
      if (queryParams.goal) {
        setDetailsOpen(true);
        setGoalId(queryParams.goal);
      }

      return () => {
        props.goalsStore.setDefaultUserGoals();
      };
    }, []);

    useEffect(() => {
      props.goalsStore.setQueryParams('userGoals', 'ownerIds', []);
      getUserGoals(true);
    }, [props.view]);

    const toggleDetails = (newGoalId) => {
      setGoalId(newGoalId || null);
      setDetailsOpen(!detailsOpen);

      const queryParams = queryString.parse(window.location.search);
      if (queryParams.goal) {
        delete queryParams.goal;
        props.navigate({
          search: queryString.stringify(queryParams),
        });
      }
    };

    const continueLearning = (goal) => {
      GoalsUtil.continueLearning(goal, props.goalsStore, props.navigate);
    };

    const handleFilterChange = (key, val) => {
      props.goalsStore.setQueryParams('userGoals', key, val);
      getUserGoals();
    };

    const { updateGoalTracking, trackingGoal } = props.goalsStore;
    const { loading, error, data, ownersList, queryParams } = props.goalsStore.userGoals;

    return (
      <Container size="lg">
        <Title title="Goals" classes="inline-block" />
        <GoalsFilters
          className="pb-4 lg:pb-8 lg:mb-8"
          onOwnerFilterChange={handleFilterChange}
          ownerOptions={ownersList.slice()}
          ownerValues={queryParams.ownerIds.slice()}
          isLoading={loading}
        />
        <GoalsTiles
          data={GoalsUtil.getNonBaselineGoals(data)}
          viewGoal={toggleDetails}
          continueLearning={continueLearning}
          setTrackingGoal={updateGoalTracking}
          trackingGoal={trackingGoal}
          getPrimaryGoalMetric={GoalsUtil.getPrimaryGoalMetric}
          getPracticeTestStatus={GoalsUtil.getPracticeTestStatus}
          loading={loading}
          error={error}
        />
        <ContentOverlay open={detailsOpen} dismiss={toggleDetails} width="w-[50vw]" ariaLabelledBy="goal-title">
          <GoalDetails goalId={goalId} continueLearning={continueLearning} />
        </ContentOverlay>
      </Container>
    );
  })
);

export default withRouter(MyGoals);
