import React, { useState } from 'react';
import Bugsnag from '@bugsnag/js';
import { observer, inject } from 'mobx-react';
import { Fade, Zoom } from 'react-awesome-reveal';
import { Switch } from '@headlessui/react';
import Avatar from '../Avatar';
import agents from '../../../agents/agents';
import Button from '../../Button/Button';

/**
 * @param {string} avatarUrl - the url of the user's avatar
 * @param {string} userName - the user's username
 * @param {string} firstName - the user's first name
 * @param {string} lastName - the user's last name
 * @param {string} role - the user's role (e.g. student, architect, etc... derived from onboarding data)
 * @param {string} userLocation - the user's location (e.g. San Francisco, CA)
 * @param {string} websiteUrl - the user's website url
 * @param {string} pronouns - the user's pronouns
 * @param {function} handleClick - the function to handle the edit button click
 * @param {boolean} isEditable - whether or not the profile should be editable
 * @param {boolean} isPublic - whether or not the profile is public
 * @param {object} commonStore - where the toast toast and trigger confirm modal live
 * @returns <ProfileHeaderSection /> top section of profile page with avatar, and user info
 */
const ProfileHeaderSection = inject('commonStore')(
  observer(({ avatarUrl, userName, firstName, lastName, role, userLocation, websiteUrl, pronouns, handleClick, isEditable, isPublic = false, commonStore }) => {
    const userFullName = `${firstName} ${lastName}`;
    const [isPublicProfile, setIsPublicProfile] = useState(isPublic);

    const updateProfilePrivacy = async (newState) => {
      try {
        const response = await agents.profile.updateProfilePrivacy({ is_public: newState });
        if (response === true) {
          commonStore.triggerToast('success', {
            content: `Profile privacy status updated successfully`,
          });
        }
      } catch (error) {
        Bugsnag.notify(error);
        commonStore.triggerToast('error', {
          content: `Error changing profile privacy status`,
        });
        setIsPublicProfile(!newState);
      }
    };

    const toggleProfilePrivacy = (isShown) => {
      const confirmContent = (
        <p>
          Are you Sure? Selecting this option makes your <strong>profile {!isPublicProfile ? 'public' : 'private'}</strong>
        </p>
      );
      setIsPublicProfile(isShown);
      commonStore.triggerConfirm({
        content: confirmContent,
        cancel: () => {
          setIsPublicProfile(!isShown);
          commonStore.resetConfirmState();
        },
        continue: () => {
          updateProfilePrivacy(isShown);
          commonStore.resetConfirmState();
        },
      });
    };

    return (
      <Fade duration={1000} triggerOnce>
        <div id="profile-header-container" className="items-center md:flex md:space-x-6">
          <div id="avatar-container" className="shrink-0 text-center">
            <Zoom duration={300} triggerOnce>
              <Avatar
                isEditable={isEditable}
                pfpUrl={avatarUrl}
                avatarWrapperClasses="mx-auto w-32 h-32 rounded-full border md:ml-auto border-cyb-pink-500"
                editIconClasses="absolute right-0 bottom-6 p-1 w-10 h-10 text-gray-400 group-hover:text-gray-800 bg-white rounded-full cursor-pointer"
              />
              {!!userName && (
                <h1 id="profile-header-name" className="mt-3 text-2xl font-bold text-cyb-pink-500 md:hidden">
                  @{userName}
                </h1>
              )}
            </Zoom>
          </div>
          <div id="profile-header-info" className="w-full">
            <div id="first-row" className="flex justify-between">
              {!!userName && (
                <h1 id="profile-header-name" className="hidden mb-2 text-2xl font-bold text-cyb-pink-500 md:block">
                  @{userName}
                </h1>
              )}
              {!!isEditable && (
                <Button icon={{ name: 'pencil', position: 'right' }} onClick={handleClick} color="transparent-gray">
                  Edit Profile
                </Button>
              )}
            </div>
            <div className="flex justify-between items-center mb-3">
              <div className="flex items-center">
                <h2 id="profile-header-full-name" className="pr-2 text-xl font-medium">
                  {userFullName}
                </h2>
                {!!pronouns && (
                  <h3 id="profile-pronouns" className="text-xs italic font-normal text-gray-600">
                    ({pronouns})
                  </h3>
                )}
              </div>
              {/* Public Profile toggle switch */}
              {!!isEditable && (
                <div className="flex justify-center items-center pt-2 text-xs font-normal border-t border-gray-400">
                  <p className="mb-0">Public Profile:</p>
                  <p className={`ml-2 mb-0 mr-4 ${isPublicProfile ? 'text-green-700' : 'text-red-700'} font-bold`}>{isPublicProfile ? ' ON' : ' OFF'}</p>
                  <Switch
                    checked={isPublicProfile}
                    onChange={toggleProfilePrivacy}
                    className={`border-1 bg-white ${isPublicProfile ? 'border-green-700' : 'border-red-700'} relative inline-flex h-4 w-11 items-center rounded-full`}
                  >
                    <span className="sr-only">Use setting</span>
                    <span
                      aria-hidden="true"
                      className={`${isPublicProfile ? 'bg-green-700 translate-x-7' : 'bg-red-700 translate-x-[.1rem]'}
            pointer-events-none inline-block h-3 w-3 transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out`}
                    />
                  </Switch>
                </div>
              )}
            </div>
            <div id="position-and-location" className="flex">
              <p id="profile-header-job-title" className="text-base">
                {role}
              </p>
              {!!userLocation && !!role && <p className="px-2"> | </p>}
              {!!userLocation && (
                <p id="profile-header-location" className="text-base">
                  {userLocation}
                </p>
              )}
            </div>
            {!!websiteUrl && (
              <a href={websiteUrl} className="text-cyb-pink-500 hover:underline" target="_blank" rel="noreferrer">
                {websiteUrl}
              </a>
            )}
          </div>
        </div>
      </Fade>
    );
  })
);
export default ProfileHeaderSection;
