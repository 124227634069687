import React, { useCallback } from 'react';
import { observer, inject } from 'mobx-react';
import Container from '../Container/Container';
import AdminTable from '../AdminTable/AdminTable';
import CertificationAdditionalContent from '../Certifications/CertificationAdditionalContent';
import { useCareerPrograms } from '../../providers/CareerProgramsProvider';
import { formatTableColumns } from '../Baseline/shared';
import If from '../If/If';
import { useBadgeModal } from '../../providers/BadgeModalProvider';
import Badge from '../Profile/ProfileTabs/BadgesTab/Badge';
import { useProfileContext } from '../../providers/ProfileProvider';
import Loading from '../Loading/Loading';
import AddLink from '../AddLink/AddLink';
import PermalinkUtil from '../../utils/permalinkUtil';
import DashboardWidgetTitle from '../Dashboard/DashboardWidgetTitle';
import { useEnrollment } from '../../providers/EnrollmentProvider';
import EnrollmentButton from '../BrowsePromo/EnrollmentButton';

/**
 * Computed column headers for career program path table
 * @param {boolean} isAssessmentPaths - denotes that paths are assessment paths
 * @returns TABLE_COLUMN_HEADERS
 */
const TABLE_COLUMN_HEADERS = ({ isAssessmentPaths = false, hasTopics = false }) => [
  {
    key: 'path_id',
    type: 'string',
    showCol: false,
    sortKey: 'path_id',
  },
  {
    key: 'content_id',
    type: 'string',
    showCol: false,
    sortKey: 'content_id',
  },
  {
    key: 'title',
    type: 'string',
    showCol: true,
    display: 'Title',
    sortKey: 'title',
  },
  {
    key: 'topic',
    type: 'string',
    showCol: hasTopics,
    display: 'Topic',
    sortKey: 'topic',
  },
  // We only want to show the domains columns if we don't have topics
  {
    key: 'domain',
    type: 'string',
    showCol: !hasTopics,
    display: 'Domain',
    sortKey: 'domain',
  },
  {
    key: 'activity_type',
    type: 'string',
    showCol: true,
    display: 'Activity Type',
    sortKey: 'activity_type',
  },
  {
    key: 'xp',
    type: 'string',
    showCol: true,
    display: 'XP',
    sortKey: 'xp',
  },
  {
    key: 'score',
    type: 'string',
    showCol: isAssessmentPaths,
    display: 'Score',
    sortKey: 'score',
  },
  {
    key: 'progress',
    type: 'string',
    showCol: !isAssessmentPaths,
    display: 'Progress',
    sortKey: 'progress',
  },
  {
    key: 'action',
    type: 'string',
    showCol: true,
    display: 'Action',
    sortKey: 'action',
  },
  {
    key: 'content_link',
    type: 'string',
    showCol: false,
    sortKey: 'content_link',
  },
];

function RelatedContent({ relatedContent }) {
  return (
    <div className="mt-4">
      <h3 className="text-2xl font-black text-center">Keep advancing your career with related content!</h3>
      <div className="flex flex-row justify-center items-center mt-16">
        <CertificationAdditionalContent content={relatedContent} inline omitHeader />
      </div>
    </div>
  );
}

/**
 * Transform path content data to compliant format for table data
 *
 * @param {CareerProgramPaths} paths
 * @param {CareerProgramEnrollment} currentProgramEnrollment - current career program enrollment
 * @param {boolean} isAssessmentPaths - denotes that paths are assessment paths
 * @param {object} enrollmentProgressMap - enrollment progress map from enrollmentStore
 * @returns {tableData} { columns: [], tableData: [][] }
 */
const generateTableData = ({ mappedPaths, currentProgramEnrollment, isAssessmentPaths, enrollmentProgressMap }) => {
  const [tableData] = mappedPaths.map((path) => {
    const pathEnrollment = currentProgramEnrollment?.enrollments?.find((enrollment) => enrollment.content_description_id === path.id);
    return path.content_item?.children?.map((content) => {
      const contentEnrollment = pathEnrollment?.descendant_enrollments?.find((item) => item.content_description_id === content.id) || enrollmentProgressMap?.[`id_${content.id}`];

      let topic;
      const topicTerm = content?.terms_info?.find((entry) => entry.includes('Topics|'));
      if (topicTerm) {
        const split = topicTerm.split('|');
        const value = split[1];
        topic = value;
      }

      let domain;
      const domainTerm = content?.terms_info?.find((entry) => entry.includes('Domains|'));
      if (domainTerm) {
        const split = domainTerm.split('|');
        const value = split[1];
        domain = value;
      }

      const xpEarned = Math.min(contentEnrollment?.experience_points_earned || 0, content.experience_points_total);
      const xpProgress = Math.round((xpEarned / content.experience_points_total) * 100);
      const xpEarnedTotal = `${xpEarned}/${content.experience_points_total}`;
      // Use progress from enrollment if available, otherwise calculate from xp
      let progressLabel = `${contentEnrollment?.progress || xpProgress}%`;
      if (contentEnrollment?.completed_at) {
        progressLabel = '100%';
      }

      const score = Math.round(contentEnrollment?.score || 0 * 100);
      const scoreLabel = score ? `${score}%` : '-';

      return [
        // path_id
        {
          value: path.id,
          type: 'string',
        },
        // content_id
        {
          value: content.id,
          type: 'string',
        },
        // title
        {
          value: content.title,
          type: 'string',
        },
        // topic
        {
          value: isAssessmentPaths ? content.title : topic,
          type: 'string',
        },
        // domain
        {
          value: domain,
          type: 'string',
        },
        // activity_type
        {
          value: content.content_type?.nice_name,
          type: 'string',
        },
        // xp
        {
          value: xpEarnedTotal,
          type: 'string',
        },
        // score
        {
          value: scoreLabel,
          type: 'string',
        },
        // progress
        {
          value: progressLabel,
          type: 'string',
        },
        // content_link
        {
          value: content.permalink,
          type: 'string',
        },
      ];
    });
  });

  // We only want to show the domains columns if we have topics
  const hasTopics = tableData?.some((row) => row[3].value);

  return {
    columns: TABLE_COLUMN_HEADERS({ isAssessmentPaths, hasTopics }),
    tableData,
  };
};

const renderColumn = (row, fullRow, _headings, column, renderUserActionButton) => {
  if (row.value !== undefined && column.showCol) {
    const permalink = fullRow?.[9]?.value;
    const type = fullRow?.[5]?.value;
    const contentId = fullRow?.[1]?.value;
    const pathId = fullRow?.[0]?.value;
    switch (column.key) {
      case 'action':
        return renderUserActionButton(pathId, contentId);
      case 'title':
        if (permalink && type) {
          return (
            <AddLink to={PermalinkUtil.formatInApp(permalink, type)} title={`View ${row.value}`} className="hover:underline">
              {row.value}
            </AddLink>
          );
        }
        return row.value;
      case 'topic':
        return (
          <AddLink
            to={`/browse/refined?terms_info=topics%7C${encodeURIComponent(String(row.value).toLowerCase().replaceAll(' ', '-'))}`}
            key={row.value}
            title={`Explore more ${row.value} content`}
            className="hover:underline"
          >
            {row.value}
          </AddLink>
        );
      case 'domain':
        return (
          <AddLink
            to={`/browse/refined?terms_info=domains%7C${encodeURIComponent(String(row.value).toLowerCase().replaceAll(' ', '-'))}`}
            key={row.value}
            title={`Explore more ${row.value} content`}
            className="hover:underline"
          >
            {row.value}
          </AddLink>
        );
      default:
        return row.value;
    }
  }
  return '';
};

const CareerProgramOutlineTab = inject(
  'userStore',
  'enrollmentStore',
  'commonStore'
)(
  observer(({ userStore, enrollmentStore }) => {
    const { isLoadingCareerProgramBrowseData, currentProgramEnrollment, currentPageProgram: program, isLoadingCurrentProgram } = useCareerPrograms();
    const { badges, isLoading: isLoadingBadgeData } = useProfileContext();
    const isLoadingPaths = !program?.permalink || isLoadingCareerProgramBrowseData?.[program.permalink];
    const paths = program?.content_item?.children;
    const relatedContent = program?.content_item?.related;
    const { open: openBadgeModal } = useBadgeModal();
    const { startEnrollment } = useEnrollment();

    const onClickStart = useCallback(
      async ({ item, preferredEnrollmentId, excludeProgress = false, preferredCollectionItemId = null }) => {
        const options = { preferredEnrollmentId, preferredCollectionItemId };
        const data = excludeProgress ? { exclude_progress_before: 'now' } : null;
        startEnrollment(item, data, options);
      },
      [startEnrollment]
    );

    const renderUserActionButton = useCallback(
      (pathId, contentId) => {
        const path = paths.find((item) => item.id === pathId);
        const content = path?.content_item?.children?.find((contentItem) => contentItem.id === contentId);
        if (!content) return null;
        const pathEnrollment = currentProgramEnrollment?.enrollments?.find((enrollment) => enrollment.content_description_id === pathId);
        const pathEnrollmentId = pathEnrollment?.id;
        const isFinished = pathEnrollment?.completed_content_description_ids?.includes(content?.id);
        const isAssessment = content?.content_type?.nice_name === 'Assessment';
        // We want to start the activity if the path only contains one activity as its child (Cert prep paths)
        const isSingleActivityPath = path?.content_item?.children?.length === 1 && path?.content_item?.children[0]?.content_type?.is_activity;
        // Assessments and single activity paths should be enrolled directly, not through the path
        const isDirectEnrollment = isAssessment || isSingleActivityPath;
        const targetItem = isDirectEnrollment ? content : path;
        const preferredEnrollmentId = isDirectEnrollment ? null : pathEnrollmentId;
        const preferredCollectionItemId = isDirectEnrollment ? null : content?.id;

        // Programs are special in that we enroll the user into the collection
        // But the button is within the scope of the child of the collection
        return (
          <EnrollmentButton
            content={targetItem}
            options={{ preferredEnrollmentId, preferredCollectionItemId }}
            color="gray"
            data={isFinished ? { exclude_progress_before: isAssessment ? 'now' : null } : null}
            text={isFinished ? 'Revisit' : null}
            upgradeText="Upgrade"
            buttonClasses="py-1.5 text-xs font-semibold px-3 mx-auto min-w-[90px] whitespace-nowrap"
            icon={{}}
            upgradeIcon={{}}
            comingSoonIcon={{}}
          />
        );
      },
      [paths, currentProgramEnrollment, userStore?.is_free, userStore?.isCip, userStore?.isUserOnActiveTeam, onClickStart]
    );

    const handleRenderColumn = useCallback((row, fullRow, _headings, column) => renderColumn(row, fullRow, _headings, column, renderUserActionButton), [renderUserActionButton]);

    const formatColumns = useCallback(
      (isAssessmentPath) => formatTableColumns(TABLE_COLUMN_HEADERS(isAssessmentPath), ['xp', 'score', 'progress', 'action', 'title', 'topic', 'domain'], handleRenderColumn),
      [handleRenderColumn]
    );

    return (
      <Container size="fluid" className="flex flex-col flex-1 pt-6 w-full" omitPadding>
        <If condition={isLoadingPaths || isLoadingBadgeData || isLoadingCurrentProgram}>
          <div className="flex flex-col justify-center items-center w-full h-96">
            <Loading />
          </div>
        </If>
        <If condition={Boolean(program?.content_item?.requirement_badge_ids?.length && !isLoadingPaths && !isLoadingBadgeData && !isLoadingCurrentProgram)}>
          {/** Loop requirement_badge_ids because its guaranteed to be in order from the backend */}
          {badges?.length &&
            program?.content_item?.requirement_badge_ids?.map((badgeId) => {
              const badge = badges?.find((b) => b.id === badgeId);
              const mappedPaths = paths?.filter((path) => path?.badge_ids?.includes(badge?.id));
              const pathRequiredBadges = mappedPaths?.flatMap((path) => path?.badge_ids);
              const pathEnrollment = currentProgramEnrollment?.enrollments?.find((enrollment) => enrollment.content_description_id === mappedPaths?.[0]?.id);
              // determine if these are assessment paths, we only need to check the first path
              const isAssessmentPaths = mappedPaths?.[0]?.content_type.human_readable_name === 'Assessment Path';
              const tableData = mappedPaths
                ? generateTableData({
                    mappedPaths,
                    currentProgramEnrollment,
                    isAssessmentPaths,
                    enrollmentProgressMap: enrollmentStore.enrollmentProgressMap,
                  })
                : { columns: TABLE_COLUMN_HEADERS(), tableData: [] };

              const columns = formatColumns(isAssessmentPaths);
              return (
                <div className="flex flex-col mb-12" key={badge.id}>
                  <div className="flex justify-between">
                    {/** Title & Description */}
                    <div className="flex flex-col pb-3">
                      <DashboardWidgetTitle title={badge?.study_guide_title} link={pathEnrollment?.id ? `/immersive/${pathEnrollment?.id}` : null} />
                      <p className="py-2 text-sm text-neutral-500">{badge?.outline_description || badge?.study_guide_description}</p>
                    </div>
                    {/** Path Badges */}
                    <If condition={pathRequiredBadges?.length}>
                      <div className="flex space-x-2">
                        {pathRequiredBadges.map((pathRequiredBadgeId) => {
                          const pathRequiredBadge = badges?.find((b) => b.id === pathRequiredBadgeId);
                          if (!pathRequiredBadge) return null;
                          return (
                            <Badge
                              badge={pathRequiredBadge}
                              key={pathRequiredBadge?.id}
                              className="flex items-center h-auto lg:h-auto"
                              imgClassName="w-12 h-12"
                              onClick={() => openBadgeModal(pathRequiredBadge)}
                              noBorder
                              noTitle
                              noDescription
                            >
                              <img src={pathRequiredBadge.unlocked_image_url} alt={pathRequiredBadge.name} className="mr-2 w-8 h-8" />
                              <span className="text-sm font-semibold sr-only">{pathRequiredBadge.name}</span>
                            </Badge>
                          );
                        })}
                      </div>
                    </If>
                  </div>
                  {/** Table */}
                  <div className="flex">
                    <AdminTable
                      wrapperClassName="m-0 w-full"
                      className="table-fixed"
                      headings={tableData.columns}
                      data={tableData.tableData}
                      tableLoading={isLoadingPaths}
                      formatColumns={columns}
                      colWidths={[
                        { width: '25%', idx: 2 }, // Title
                        { width: '20%', idx: 3 }, // Topic
                        { width: '20%', idx: 4 }, // Domain (Only shown if no Topics)
                        { width: '10%', idx: 5 }, // Activity Type
                        { width: '7%', idx: 6 }, // XP
                        { width: '7%', idx: 7 }, // Score
                        { width: '7%', idx: 8 }, // Progress
                        { width: '10%', idx: 9 }, // Action
                      ]}
                      customColAlign={{ center: [6, 7, 8, 9] }}
                      accordionMinRows={5}
                    />
                  </div>
                </div>
              );
            })}
        </If>
        <If condition={Boolean(relatedContent?.length && !isLoadingPaths && !isLoadingCurrentProgram)}>
          <RelatedContent relatedContent={relatedContent} />
        </If>
      </Container>
    );
  })
);

export default CareerProgramOutlineTab;
