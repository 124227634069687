import React, { useEffect, useState } from 'react';
import { observer, inject } from 'mobx-react';
import Bugsnag from '@bugsnag/js';
import AdminTable from '../AdminTable/AdminTable';
import Table from '../Table/Table';
import SearchInput from '../Search/SearchInput';
import Button from '../Button/Button';
import Checkbox from '../FormFields/Checkbox';
import FormatUtil from '../../utils/formatUtil';
import SelectionChip from '../SelectTiles/SelectionChip';
import Agents from '../../agents/agents';

function SelectedMembersList({ selectedMembers, handleClick }) {
  if (!selectedMembers || !selectedMembers.length) {
    return null;
  }
  return (
    <div className="mt-8">
      <p className="sr-only">Selected Members:</p>
      <div className="flex flex-wrap gap-2">
        {selectedMembers.map((item) => {
          return <SelectionChip key={item.id} handleClose={() => handleClick(item.id)} text={item.name} id={item.id} />;
        })}
      </div>
    </div>
  );
}

const GroupAddMembers = inject(
  'enterpriseStore',
  'commonStore'
)(
  observer(({ enterpriseStore, commonStore, groupId, submitCallback, parentGroupName }) => {
    const [selectedMembers, setSelectedMembers] = useState([]);
    const [submitting, setSubmitting] = useState(false);

    useEffect(() => {
      enterpriseStore.getMemberTableDataForAdd(groupId);
      return () => {
        enterpriseStore.setDefaultMemberTableDataForAdd();
      };
    }, []);

    const pagChangePg = (pg) => {
      enterpriseStore.setMemberTableDataForAddParam('activePg', pg);
      enterpriseStore.getMemberTableDataForAdd(groupId);
    };

    const setSearch = (e) => {
      enterpriseStore.setMemberTableDataForAddParam('searchQuery', e.target.value);
    };

    const checkSendSearch = (e) => {
      // Check to see if we pressed enter key
      const key = e.which || e.keyCode;
      if (key === 13) {
        enterpriseStore.getMemberTableDataForAdd(groupId, true);
      }
    };

    const addUsersToGroup = async () => {
      if (selectedMembers.length) {
        const memberIds = selectedMembers.map((member) => member.id);
        setSubmitting(true);
        try {
          await Agents.enterprise.addTeamMember(groupId, { users: memberIds });
          commonStore.triggerToast('success', {
            content: 'Users added to group',
          });
          setSubmitting(false);
          if (submitCallback) {
            submitCallback();
          }
          // After adding user to new group, get new data set with updated info
          enterpriseStore.setDefaultMemberTableDataForAdd();
          enterpriseStore.getTeamMemberTableData(groupId, true, true);
        } catch (e) {
          Bugsnag.notify(e);
          setSubmitting(false);
          commonStore.triggerToast('error', {
            content: 'Something went wrong. Unable to add users to group.',
          });
        }
      }
    };

    const removeMemberSelection = (id) => {
      const filteredMembers = selectedMembers.filter((member) => member.id !== id);
      setSelectedMembers(filteredMembers);
    };

    const getAddToGroupColumn = (row, headings) => {
      const userIdColIdx = FormatUtil.getColIndex(headings, 'id');
      const userId = userIdColIdx > -1 ? row[userIdColIdx].value : null;
      const userNameColIdx = FormatUtil.getColIndex(headings, 'name');
      const userName = userNameColIdx > -1 ? row[userNameColIdx].value : null;

      if (!userId) {
        return null;
      }
      const isChecked = selectedMembers.filter((member) => member.id === userId).length > 0;
      return (
        <Table.Cell>
          <Checkbox
            id={userId}
            value={userId}
            name={userName}
            checked={isChecked}
            onChange={() => (isChecked ? removeMemberSelection(userId) : setSelectedMembers([...selectedMembers, { id: userId, name: userName }]))}
            className="mb-0"
            ariaLabel={`Add ${userName} to group`}
          />
        </Table.Cell>
      );
    };

    return (
      <div className="text-left">
        <div className="flex justify-between">
          <Button onClick={addUsersToGroup} disabled={!selectedMembers.length || submitting} loading={submitting}>
            Add {selectedMembers.length} to Group
          </Button>
          <SearchInput width="w-full max-w-sm" placeholder="Search" onChange={setSearch} onKeyPress={checkSendSearch} />
        </div>
        {parentGroupName ? (
          <p style={{ marginTop: '1em' }}>
            These members will also be added to <span style={{ fontWeight: 700 }}>{parentGroupName}</span>
          </p>
        ) : null}
        <SelectedMembersList selectedMembers={selectedMembers} handleClick={removeMemberSelection} />
        <AdminTable
          /* Table Data */
          headings={enterpriseStore.memberTableDataForAdd.tableHeadings}
          data={enterpriseStore.memberTableDataForAdd.tableData}
          tableLoading={enterpriseStore.memberTableDataForAdd.loading}
          tableError={enterpriseStore.memberTableDataForAdd.error}
          addCustomCol={[
            {
              method: getAddToGroupColumn,
              position: 0,
              header: 'Row Actions',
              omitHeading: true,
            },
          ]}
          /* Pagination options */
          pagNumPgs={enterpriseStore.memberTableDataForAdd.pagPagesCount}
          pagChangePg={pagChangePg}
          pagActivePg={enterpriseStore.memberTableDataForAdd.queryParams.activePg}
          numOfResults={enterpriseStore.memberTableDataForAdd.recordsCount}
          showResultsSummary={enterpriseStore.memberTableDataForAdd.queryParams.searchQuery && enterpriseStore.memberTableDataForAdd.queryParams.searchQuery.length}
        />
      </div>
    );
  })
);

export default GroupAddMembers;
