import React, { useEffect, useState } from 'react';
import { observer, inject } from 'mobx-react';
import { useParams } from 'react-router-dom';
import VisibilitySensor from 'react-visibility-sensor';
import Icon from '../../components/Icon/Icon';
import Container from '../../components/Container/Container';
import Divider from '../../components/Divider/Divider';
import ContentTabs from '../../components/ContentTabs/ContentTabs';
import StickyContentTabs from '../../components/ContentTabs/StickyContentTabs';
import Overview from '../../components/Path/Overview';
import Outline from '../../components/Path/Outline';
import EnrollmentButton from '../../components/BrowsePromo/EnrollmentButton';
import OptionsMenu from '../../components/OptionsMenu/OptionsMenu';
import CircularProgress from '../../components/ProgressBar/CircularProgress';
import ContentHeader from '../../components/ContentHeader/ContentHeader';
import useContentCollectionPath from '../../hooks/useContentCollectionPath';
import PageLoadingState from '../../components/Loading/PageLoadingState';
import { getOptions } from '../../utils/pathsUtil';

const tabs = [
  {
    id: 'overview',
    name: 'Overview',
    scrollSelector: '#overview',
    heightSwitch: 0.5,
  },
  {
    id: 'outline',
    name: 'Outline',
    scrollSelector: '#outline',
    heightSwitch: 0.8,
  },
];

const CybrarySelectCollectionPage = inject(
  'userStore',
  'commonStore',
  'authStore'
)(
  observer(({ userStore, commonStore, authStore }) => {
    const params = useParams();
    const paramPermalink = params && params.permalink ? params.permalink : null;
    const { loading, error, path, coursesData } = useContentCollectionPath(paramPermalink, 'cybrarySelect');
    const [activeTab, setActiveTab] = useState('overview');
    const [stickyTab, setStickyTab] = useState(false);
    const [showHeader, setShowHeader] = useState(true);
    const { title, thumbnail_url, permalink, progressData, percentCompleted, type, progressEvent, enrollment_id, content_status } = path || {};
    const comingSoon = content_status === 'Coming Soon';
    const browseUrl = '/browse/cybrary-select';
    const shareOptions = { permalink, title, type };
    const started = progressEvent && progressEvent === 'started';
    const threatType = 'Cybrary Select';
    const isFreeUser = !userStore?.user?.is_paid;

    useEffect(() => {
      commonStore.setPageTitle(`${title || ''} | Cybrary`);
      if (started) {
        setShowHeader(false);
        setStickyTab(true);
        setActiveTab('outline');
      }
    }, [path]);

    useEffect(() => {
      authStore.fireAttributionEvent();
    }, []);

    return (
      <PageLoadingState loading={loading} error={error}>
        {stickyTab && (
          <StickyContentTabs
            tabs={tabs}
            setActiveTab={setActiveTab}
            activeTab={activeTab}
            backButtonUrl={browseUrl}
            commonStore={commonStore}
            item={path}
            progress={progressData}
            title={title}
            typeText={threatType}
            type={type}
            image={thumbnail_url}
            circularProgress={
              <CircularProgress classes={`${started ? 'block' : 'hidden'} ml-4 w-12 h-12 mx-auto`} strokeWidth={4} percentageValue={percentCompleted}>
                <Icon name="trophy" className="mx-auto w-6 h-6 text-gray-600" />
              </CircularProgress>
            }
            permalink={permalink || ''}
            buttons={!comingSoon ? <EnrollmentButton content={path} buttonClasses="w-full lg:w-auto" /> : null}
          />
        )}
        <Container size="cards-container" className="px-4 md:px-16" omitPadding>
          {showHeader && (
            <ContentHeader
              contentType="Cybrary Select"
              contentTitle={title}
              contentImageUrl={thumbnail_url}
              backLink={browseUrl}
              buttons={
                <>
                  {!comingSoon ? <EnrollmentButton content={path} /> : null}
                  <div className="ml-2">
                    <OptionsMenu options={getOptions(commonStore, shareOptions)} iconContainerClassName="border hover:bg-gray-200 border-gray-400 px-2" />
                  </div>
                </>
              }
              backLinkText="Back To Cybrary Select Paths"
            />
          )}

          <div className="mt-10">
            {showHeader && (
              <VisibilitySensor onChange={(isVisible) => setStickyTab(!isVisible)} partialVisibility offset={{ top: 240 }}>
                <div className="mb-8 lg:border-b-xs lg:border-gray-400">
                  <ContentTabs activeTab={activeTab} tabs={tabs} setActiveTab={setActiveTab} wrapperClasses="mt-12 border-t-xs lg:border-t-0" />
                </div>
              </VisibilitySensor>
            )}

            {started && (
              <div className="block mb-8 lg:hidden lg:border-b-xs lg:border-gray-400">
                <ContentTabs tabs={tabs} activeTab={activeTab} setActiveTab={setActiveTab} wrapperClasses="mt-12 border-t-xs lg:border-t-0" />
              </div>
            )}

            <div id="overview" className="scroll-mt-32 lg:flex">
              <Overview path={path} />
            </div>

            <Divider marginTop="mt-8" marginBottom="mb-8" />
            <div id="outline" className="scroll-mt-36">
              <Outline pathEnrollmentId={enrollment_id} courses={coursesData} path={path} isFreeUser={isFreeUser} />
            </div>
          </div>
        </Container>
      </PageLoadingState>
    );
  })
);

export default CybrarySelectCollectionPage;
