// controller component to fire info toast notifications through the commonStore

import React, { useEffect, useState } from 'react';

import { inject, observer } from 'mobx-react';
import AddLink from '../AddLink/AddLink';

const TeamToastNotifications = inject(
  'commonStore',
  'enterpriseStore',
  'userStore'
)(
  observer(({ commonStore, enterpriseStore, userStore }) => {
    const { triggerToast } = commonStore;
    const { teamData, user } = userStore;

    const joinRequests = enterpriseStore.joinRequests.data?.tableData;

    // Only show the toast notification once
    const [hasFiredToast, setHasFiredToast] = useState(false);

    const isDemo = teamData?.id === 'demo';

    // On mount
    useEffect(() => {
      if (!user || !teamData?.id || isDemo) {
        return;
      }
      // Get join requests for the active team
      enterpriseStore.getJoinRequests(teamData?.id);
    }, [user, teamData]);

    // Handler to fire toast notifications
    useEffect(() => {
      if (!user || !teamData?.id || isDemo) {
        return;
      }
      // If we have already fired the toast, don't do anything
      if (hasFiredToast) {
        return;
      }
      // If we have join requests, fire a toast notification
      if (joinRequests?.length) {
        setHasFiredToast(true);
        triggerToast('info', {
          header: 'Pending Join Requests',
          content: (
            <div>
              <AddLink to={`/enterprise/${teamData.id}/organization/members?joinRequestsOpen=1`}>
                View {joinRequests.length} pending join request{joinRequests.length > 1 ? 's' : ''} for your team.
              </AddLink>
            </div>
          ),
          duration: 10000,
        });
      }
    }, [user, teamData, joinRequests?.length]);

    return null;
  })
);

export default TeamToastNotifications;
