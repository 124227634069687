import React from 'react';
import { Fade } from 'react-awesome-reveal';
import { inject, observer } from 'mobx-react';
import UserOnboardingStep from '../../UserOnboardingStep';
import DynamicForm from '../../../DynamicForm/DynamicForm';
import { USER_ONBOARDING_STEPS, useUserOnboarding } from '../../../../providers/UserOnboardingProvider';
import { COUNTRIES, APP_FORM_IDS, MANAGER_PLUS_ROLES, PRACTITIONER_ROLES, SHARED_DEPARTMENTS, SHARED_ROLES, TEAM_LEADS, TEAM_LEAD_ROLES } from '../../../../constants';
import OnboardingFormContainer from '../../OnboardingFormContainer';

const isTeamLead = (value) => TEAM_LEAD_ROLES.includes(value);
const isPractitioner = (value) => PRACTITIONER_ROLES.includes(value);
const isManagerPlus = (value) => MANAGER_PLUS_ROLES.includes(value);

const CIP_ROLES = [
  ...SHARED_ROLES,
  {
    text: 'Student',
    value: 'Student',
  },
];

function CIPFormStepComponent({ userStore }) {
  const { setSavedFormFields, teamOrCip, cipGoal, cipExperience, cipYearsOfExperience, saveUserOnboardingData, setStep, isSubmitting, isLoading, user, profileData } =
    useUserOnboarding();

  const { isSATOnly } = userStore;

  const requiredForNonSAT = isSATOnly ? [] : ['required'];

  const CIP_ONBOARDING_FORM_FIELDS = {
    order: [
      ['firstName', 'lastName'],
      'country',
      ['title', 'teamLead'],
      'membershipFor',
      'goal',
      'experienceLevel',
      'yearsExperience',
      ['phone', 'company'],
      'department',
      'submit',
    ],
    fields: {
      firstName: {
        id: 'onboarding-cip-first-name',
        type: 'text',
        label: 'First Name',
        validations: ['required'],
        defaultValue: user?.first_name,
      },
      lastName: {
        id: 'onboarding-cip-last-name',
        type: 'text',
        label: 'Last Name',
        validations: ['required'],
        defaultValue: user?.last_name,
      },
      country: {
        id: 'onboarding-cip-country',
        type: 'select',
        label: 'Country',
        placeholder: 'Select a country',
        defaultValue: user?.country || 'US',
        options: COUNTRIES,
        validations: ['required'],
      },
      title: {
        id: 'onboarding-cip-title',
        type: 'select',
        label: 'Role',
        placeholder: 'Select a role',
        /** this form field is similar to teams with the addition of students */
        options: CIP_ROLES,
        validations: requiredForNonSAT,
        defaultValue: profileData?.title,
      },
      teamLead: {
        id: 'onboarding-cip-do-you-lead-a-team',
        type: 'select',
        label: 'Do you lead a team?',
        placeholder: 'Select an option',
        options: TEAM_LEADS,
        validations: requiredForNonSAT,
        defaultValue: profileData?.teamLead,
      },
      company: {
        id: 'onboarding-cip-company',
        type: 'text',
        label: 'Company',
        /** Quasi required for Team Leads 🤷 */
        quasiRequired: !isSATOnly,
        conditions: (formState) => isTeamLead(formState.teamLead) || isPractitioner(formState.title) || isManagerPlus(formState.title),
        /**
         * Actually required for practitioners and managers+
         * Trailing comma is required or last item is dropped during validation
         */
        validations: isSATOnly ? [] : [`required_if:title,${[...PRACTITIONER_ROLES, ...MANAGER_PLUS_ROLES].map((role) => `"${role}"`).join(',')},}`],
        defaultValue: profileData?.company,
      },
      department: {
        id: 'onboarding-cip-department',
        type: 'select',
        label: 'Department',
        placeholder: 'Select Department',
        defaultValue: profileData?.department,
        options: SHARED_DEPARTMENTS,
        /** Quasi required for Team Leads 🤷 */
        quasiRequired: !isSATOnly,
        conditions: (formState) => isTeamLead(formState.teamLead) || isPractitioner(formState.title) || isManagerPlus(formState.title),
        /**
         * Actually required for practitioners and managers+
         * Trailing comma is required or last item is dropped during validation
         */
        validations: isSATOnly ? [] : [`required_if:title,${[...PRACTITIONER_ROLES, ...MANAGER_PLUS_ROLES].map((role) => `"${role}"`).join(',')},}`],
      },
      phone: {
        // the id is done like this intentionally for DS purposes
        id: 'onboarding-cip-phone',
        className: 'w-full',
        type: 'phone',
        label: 'Phone Number',
        conditions: (formState) => isTeamLead(formState.teamLead) || isPractitioner(formState.title) || isManagerPlus(formState.title),
        /**
         * Only require this field for team leads and managers+
         * Trailing comma is required or last item is dropped during validation
         */
        validations: isSATOnly ? [] : [`required_if:title,${[...TEAM_LEAD_ROLES, ...MANAGER_PLUS_ROLES].map((role) => `"${role}"`).join(',')},}`],
        defaultValue: user?.phone,
      },
      goal: {
        id: 'onboarding-cip-goal',
        type: 'text',
        className: 'hidden',
        defaultValue: cipGoal,
      },
      membershipFor: {
        id: 'onboarding-cip-membership-for',
        type: 'text',
        className: 'hidden',
        defaultValue: teamOrCip,
      },
      yearsExperience: {
        id: 'onboarding-cip-years-experience',
        type: 'text',
        className: 'hidden',
        defaultValue: cipYearsOfExperience,
      },
      experienceLevel: {
        id: 'onboarding-cip-experience-level',
        type: 'text',
        className: 'hidden',
        defaultValue: cipExperience,
      },
      submit: {
        type: 'button',
        color: 'pink',
        label: 'Submit',
        className: 'mx-auto',
        loading: isSubmitting || isLoading,
      },
    },
  };

  const handleStep = async (formFields) => {
    setSavedFormFields(formFields);
    await saveUserOnboardingData(formFields);
    if (isSATOnly) {
      setStep(USER_ONBOARDING_STEPS.FINISHED);
    } else {
      setStep(USER_ONBOARDING_STEPS.CONTENT_RECOMMENDATIONS);
    }
  };

  return (
    <UserOnboardingStep title="Create Your Profile">
      <Fade triggerOnce duration={500}>
        <OnboardingFormContainer>
          <DynamicForm form={CIP_ONBOARDING_FORM_FIELDS} formId={APP_FORM_IDS.ONBOARDING.CIP} onSubmit={handleStep} />
        </OnboardingFormContainer>
      </Fade>
    </UserOnboardingStep>
  );
}

const CIPFormStep = inject('userStore')(observer(CIPFormStepComponent));

export default CIPFormStep;
