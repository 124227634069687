import React, { useEffect, useMemo } from 'react';
import { twMerge } from 'tailwind-merge';
import { inject, observer } from 'mobx-react';
import moment from 'moment';
import AddLink from '../AddLink/AddLink';
import ProfileStreaksAndSocials from '../Profile/ProfileStreaksAndSocials/ProfileStreaksAndSocials';
import { useProfileContext } from '../../providers/ProfileProvider';
import If from '../If/If';
import Loading from '../Loading/Loading';
import DashboardWidgetTitle from './DashboardWidgetTitle';
import Tooltip from '../Tooltip/Tooltip';
import Icon from '../Icon/Icon';
import { getPackageNames } from '../../utils/formatUtil';
import LoadingText from '../Loading/LoadingText';
import Header from '../Header/Header';

function ProfileTitle() {
  return <DashboardWidgetTitle title="Profile" link="/profile" className="hidden mr-0 sm:mr-4 lg:flex lg:mr-0" />;
}

const ProfileLicenses = inject('userStore')(
  observer(({ userStore }) => {
    const licenses = userStore?.licenses;
    const userTeams = userStore?.userTeams;
    return licenses?.map((license) => {
      const licenseColor = license?.team_id ? 'text-gray-700 hover:text-gray-800' : 'text-cyb-pink-500 hover:text-cyb-pink-600';
      const licenseName = license?.name || getPackageNames(license?.package_types);
      const expiresAt = license?.expires_at ? moment(license?.expires_at).format('MMMM Do, YYYY') : 'No expiration date';
      const teamName = license?.team_id ? userTeams?.[license?.team_id]?.name : 'No team';
      return (
        <Tooltip
          key={license?.package_types?.join('-')}
          content={
            <div className="flex flex-col p-2 space-y-2">
              <div className="flex flex-col">
                <span className="font-semibold">License:</span>
                <span className="font-normal whitespace-nowrap">{licenseName}</span>
              </div>
              <If condition={license?.team_id}>
                <div className="flex flex-col">
                  <span className="font-semibold">Team:</span>
                  <span className="font-normal whitespace-nowrap">{teamName}</span>
                </div>
              </If>
              <div className="flex flex-col">
                <span className="font-semibold">Expires:</span>
                <span className="font-normal whitespace-nowrap">{expiresAt}</span>
              </div>
            </div>
          }
          triggerContent={
            <AddLink to="/settings/membership">
              <Icon name="license" className={`w-6 h-6 ${licenseColor}`} />
            </AddLink>
          }
        />
      );
    });
  })
);

/**
 * Profile widget to show user profile image, name, and some stats about their profile
 * @param {string} className - additional classes to add to the component
 * @returns <ProfileWidget />
 */
const ProfileWidget = inject('userStore')(
  observer(({ userStore, className, compact = false }) => {
    const { userProfileStats, user, licenses } = userStore;
    const { personalProfileData, isLoading } = useProfileContext();
    const { username } = personalProfileData || {};
    const { daily_activity_streak = 0, level, experience_points } = userProfileStats || {};
    useEffect(() => {
      (async () => {
        // reload userProfileStats whenever the component mounts
        await userStore.loadUserProfileStats();
      })();
    }, []);
    const registeredAt = useMemo(() => moment(new Date(user?.registered_at)).format('MMMM Do, YYYY'), [user?.registered_at]);
    const isLicensed = useMemo(() => licenses?.length > 0, [licenses?.length]);
    return (
      <div className={twMerge('p-2 lg:p-4 flex flex-col justify-evenly', className)}>
        {/** Loading State */}
        <If condition={isLoading && !personalProfileData}>
          <ProfileTitle />
          <Loading message="Loading profile..." wrapperClassName="w-full h-36 my-1" />
        </If>
        <If condition={personalProfileData}>
          <div className="flex items-center pb-0 w-full border-0 border-slate-200 lg:block lg:min-h-[80px] lg:border-b-2">
            <ProfileTitle />
            <div className="flex justify-start items-center w-full lg:m-2">
              <AddLink to="/profile" title="View profile">
                <img
                  src={user?.avatar_url}
                  alt="avatar"
                  className="grow-0 mr-8 ml-4 w-16 h-16 rounded-full border border-cyb-pink-500
                  transition-transform duration-300 scale-125 hover:scale-105 lg:mr-4 lg:ml-0 lg:scale-100"
                />
              </AddLink>
              <div className="flex flex-col justify-center ml-2 w-full">
                <Header as="h2" className="mb-0">
                  {user?.real_name || 'No Name Set'}
                </Header>
                <If condition={username}>
                  <AddLink to="/profile?edit=1" title="Edit profile" className="flex items-center space-x-1">
                    <Icon name="edit" className="mt-1 w-4 h-4" />
                    <span className="font-bold text-cyb-pink-500 lg:text-xl text-md">{username}</span>
                  </AddLink>
                </If>
                <If condition={isLoading && !username}>
                  <LoadingText className="w-3/5 h-4" />
                </If>

                <div className="flex justify-between space-x-4 text-xs lg:text-sm xs:space-x-1">
                  <span className="flex items-center space-x-1 text-gray-600 lg:text-xs">
                    <span className="font-normal">Joined</span>
                    <span className="font-normal whitespace-nowrap">{registeredAt}</span>
                  </span>
                  <span className="flex flex-col justify-center pr-4 xs:flex-row xs:space-x-1">
                    <If condition={isLicensed}>
                      <ProfileLicenses />
                    </If>
                    <If condition={!isLicensed}>
                      <span className="italic font-normal whitespace-nowrap">{userStore?.team?.id ? 'Enterprise Unlicensed' : 'Free'}</span>
                    </If>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <If condition={!compact}>
            <div className="flex justify-center items-center pt-4 h-12">
              <ProfileStreaksAndSocials currentStreak={daily_activity_streak} currentLevel={level} currentXp={experience_points || '0'} />
            </div>
          </If>
        </If>
      </div>
    );
  })
);
export default ProfileWidget;
