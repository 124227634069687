import React from 'react';
import { twMerge } from 'tailwind-merge';
import Icon from '../Icon/Icon';
import Checkbox from '../FormFields/Checkbox';
import BoxIconField from './BoxIconField';

class BoxIconMultiSelect extends React.Component {
  state = { value: [] };

  componentDidMount() {
    if (this.props.defaultValue && this.props.defaultValue.length) {
      this.props.defaultValue.forEach((val) => {
        this.handleChange(undefined, {
          name: this.props.name,
          value: val,
        });
      });
    }
  }

  handleClick = (e, data) => {
    this.handleChange(e, data);
  };

  handleChange = (e, data) => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }
    // Manage LOCAL state of the radio group, while calling the parents onChange function.
    const existingIndex = this.state.value.indexOf(data.value);
    const newState = { ...this.state };
    if (existingIndex === -1) {
      // We are checking! Add the value to the array
      newState.value.push(data.value);
    } else {
      // We are UN-checking! Remove the value from the array
      newState.value.splice(existingIndex, 1);
    }
    this.setState(newState);
    this.props.onChange(e, { name: data.name, value: newState.value });
  };

  render() {
    const { fieldsPerRow, options, name } = this.props;
    // Generate our radio fields from the provided options.
    if (!options) {
      return null;
    }

    const checkboxesOuput = options.map((currOption) => {
      const option = { ...currOption };
      const label = option.text ? option.text : option.value;
      option.name = name;
      const isSelected = this.state.value.indexOf(option.value) !== -1;
      const iconStyle = isSelected ? { color: '#E2037A' } : { color: '#000' };
      const iconColor = option.iconColor || 'text-black';
      const labelClasses = twMerge('text-black text-sm', option.labelClasses);
      return (
        <BoxIconField
          key={currOption.key}
          fieldsPerRow={fieldsPerRow}
          option={option}
          isSelected={isSelected}
          handleClick={this.handleClick}
          icon={<Icon name={option.icon} className={`inline w-6 h-6 stroke-2 ${iconColor}`} style={iconStyle} fitted />}
          activeBoxClasses={option?.activeBoxClasses}
          inactiveBoxClasses={option?.inactiveBoxClasses}
          boxClasses={option?.boxClasses}
        >
          <Checkbox
            label={label}
            value={option.value}
            name={name}
            checked={this.state.value.indexOf(option.value) !== -1}
            onChange={this.handleClick}
            className="inline-block"
            inputClasses="hidden"
            labelClasses={labelClasses}
          />
        </BoxIconField>
      );
    });

    return <div className="flex flex-wrap justify-center items-center mx-auto w-full md:w-3/4 lg:w-5/6 2xl:w-4/5">{checkboxesOuput}</div>;
  }
}

export default BoxIconMultiSelect;
