export function getRoleOptions(isOrgOwner, groupScope /** , canManageAdmins */) {
  const roleOptions = [
    {
      key: 'team-member',
      text: `${groupScope ? 'Group ' : ''}Member`,
      value: 'team-member',
    },
    {
      key: 'team-reporting-admin',
      text: `${groupScope ? 'Group ' : ''}Reporting Admin`,
      value: 'team-reporting-admin',
    },
    {
      key: 'team-admin',
      text: `${groupScope ? 'Group ' : ''}Admin`,
      value: 'team-admin',
    },
  ];

  // Group owners are not a thing, so we don't need to show them
  if (isOrgOwner && !groupScope) {
    roleOptions.push({
      key: 'org-owner',
      text: `${groupScope ? 'Group ' : ''}Owner`,
      value: 'org-owner',
    });
  }

  return roleOptions;
}

export default { getRoleOptions };
