import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import queryString from 'query-string';
import withRouter from '../../components/Router/withRouter';
import Loading from '../../components/Loading/Loading';
import ReportsContainer from '../../components/Reports/ReportsContainer';
import Container from '../../components/Container/Container';
import './reports.css';
import FormatUtil from '../../utils/formatUtil';
import Download from '../../Icons/Download';
import AddLink from '../../components/AddLink/AddLink';

const reportOptions = {
  login: {
    name: 'Login Activity',
    showTable: true,
    showGraph: true,
    showUserFilter: true,
    showDateFilter: true,
    showDateRangeFilter: true,
    showOptions: true,
    showGroupFilter: true,
    graphKey: 'Logins',
  },
  course: {
    name: 'Course Activity',
    graphId: 'content-type-interval',
    showTable: true,
    showGraph: true,
    showUserFilter: true,
    showDateFilter: true,
    showDateRangeFilter: true,
    showOptions: true,
    showGroupFilter: true,
    graphKey: 'Courses Completed',
  },
  'course-completion': {
    name: 'Course Completion',
    showTable: true,
    showGraph: false,
    showUserFilter: true,
    showGroupFilter: true,
    showDateFilter: true,
    showDateRangeFilter: true,
    showOptions: true,
    columnMutations: {
      certificate(data) {
        const downloadUrl = FormatUtil.formatCertificateDownloadUrl(data);
        return (
          <AddLink target="_blank" to={downloadUrl} className="flex gap-x-2 items-center text-xs font-bold leading-5 text-center rounded-sm cursor-pointer">
            <Download classes="w-4 h-4" /> Download <span className="sr-only"> Certificate of completion</span>
          </AddLink>
        );
      },
    },
  },
  'curricula-overview': {
    name: 'Paths Overview',
    showTable: true,
    showGraph: false,
    showOptions: true,
    showUserFilter: true,
    showDateFilter: true,
    showDateRangeFilter: true,
    showGroupFilter: true,
    defaultSortCol: 'content_description_title',
    defaultSortDirection: 'asc',
  },
  'curricula-content': {
    name: 'Paths Summary',
    showTable: true,
    showGraph: false,
    showOptions: true,
    showUserFilter: true,
    showDateFilter: true,
    showDateRangeFilter: true,
    showGroupFilter: true,
    defaultSortCol: 'content_description_title',
    defaultSortDirection: 'asc',
  },
  assignment: {
    name: 'Assignment Activity',
    showTable: true,
    showGraph: false,
    showOptions: true,
    showUserFilter: true,
    showDateFilter: false,
    showDateRangeFilter: false,
    showGroupFilter: false,
    tableOptions: 'name-assignment-link',
  },
  'assignment-details': {
    name: 'Assignment Details',
    showTable: true,
    showGraph: false,
    showOptions: false,
    showUserFilter: false,
    showDateFilter: false,
    showDateRangeFilter: false,
    showGroupFilter: false,
    defaultSortCol: 'progress',
    defaultSortDirection: 'desc',
  },
  'content-type': {
    name: '',
    showTable: true,
    showGraph: false,
    showOptions: true,
    showUserFilter: true,
    showDateFilter: true,
    showDateRangeFilter: true,
    showGroupFilter: true,
    defaultSortCol: 'content_description_title',
    defaultSortDirection: 'asc',
  },
  content: {
    name: 'Content Details',
    showTable: true,
    showGraph: false,
    showOptions: true,
    showUserFilter: true,
    showDateFilter: true,
    showDateRangeFilter: true,
    showGroupFilter: true,
  },
  'learning-activities': {
    name: 'Learning Activities',
    id: 'activities',
    graphId: 'activities-enrolled-interval',
    showTable: true,
    showGraph: true,
    showOptions: true,
    showUserFilter: true,
    showDateFilter: true,
    showDateRangeFilter: true,
    showGroupFilter: true,
    defaultSortCol: 'name',
    graphKey: 'Activities Enrolled',
    defaultSortDirection: 'asc',
  },
  'learning-hours': {
    name: 'Learning Hours',
    id: 'learning-hours-member',
    graphId: 'learning-hours-interval',
    showTable: true,
    showGraph: true,
    showOptions: true,
    showUserFilter: true,
    showDateFilter: true,
    showDateRangeFilter: true,
    showGroupFilter: true,
    graphKey: 'Learning Hours Completed',
    defaultSortCol: 'name',
    defaultSortDirection: 'asc',
  },
  ceu: {
    name: 'CEU',
    id: 'ceus-member',
    graphId: 'ceus-interval',
    showTable: true,
    showGraph: true,
    showOptions: true,
    showUserFilter: true,
    showDateFilter: true,
    showDateRangeFilter: true,
    showGroupFilter: true,
    graphKey: 'CEUs Earned',
    defaultSortCol: 'name',
    defaultSortDirection: 'asc',
  },
  'leaderboard-course': {
    name: 'Top Course Enrollments',
    showTable: true,
    showGraph: false,
    showOptions: true,
    showUserFilter: true,
    showDateFilter: true,
    showDateRangeFilter: true,
    graphKey: 'Learning Hours',
    defaultSortCol: 'enrollments',
    defaultSortDirection: 'desc',
  },
  'leaderboard-member': {
    name: 'Top Members',
    showTable: true,
    showGraph: false,
    showOptions: true,
    showUserFilter: false,
    showDateFilter: true,
    showDateRangeFilter: true,
    graphKey: 'Learning Hours',
    defaultSortCol: 'total_enrollments',
    defaultSortDirection: 'desc',
  },
  'status-per-member': {
    name: 'Member Activity',
    showTable: true,
    showGraph: false,
    showUserFilter: true,
    showDateFilter: false,
    showDateRangeFilter: false,
    showOptions: true,
    showGroupFilter: true,
    graphKey: 'Member Activity',
  },
  'team-performance': {
    name: 'Master Performance Report',
    showTable: false,
    showGraph: false,
    showUserFilter: false,
    showDateFilter: true,
    showDateRangeFilter: false,
    showOptions: true,
    showGroupFilter: false,
    beta: true,
  },
  'leaderboard-virtual-lab': {
    name: 'Top Virtual Labs',
    showTable: true,
    showGraph: false,
    showOptions: true,
    showUserFilter: false,
    showDateFilter: true,
    showDateRangeFilter: true,
  },
  'leaderboard-assessment': {
    name: 'Top Assessments',
    showTable: true,
    showGraph: false,
    showOptions: true,
    showUserFilter: false,
    showDateFilter: true,
    showDateRangeFilter: true,
  },
};

const Reports = inject(
  'reportsStore',
  'userStore',
  'authStore',
  'enterpriseStore'
)(
  observer(
    class Reports extends Component {
      state = {
        orgId: null,
      };

      componentDidMount() {
        this.props.authStore.fireAttributionEvent();
        this.props.userStore.setPreferredTeamInit('', this.props.match.params.orgId, this.init);
        this.setQueryParams();
      }

      componentDidUpdate(prevProps) {
        this.props.userStore.setPreferredTeamInit(prevProps.match.params.orgId, this.props.match.params.orgId, this.init);
      }

      componentWillUnmount() {
        this.props.reportsStore.setDefaultReportItem();
        this.props.enterpriseStore.setDefaultGroupProfileData();
      }

      init = (orgId) => {
        const queryParams = queryString.parse(window.location.search);
        if (queryParams.groupId) {
          this.props.userStore.setPreferredGroup(orgId, 1 * queryParams.groupId);
          this.setGroupFilterData(orgId, 1 * queryParams.groupId);
        }
        // If user can only view reports at group level, set the groupName in the store
        // Needed for Scheduled Reports so group name is passed and displayed when scheduling -- Only for UX
        else if (this.props.userStore.team?.permissions.canViewReports === 'group' && this.props.userStore.team?.preferredGroup) {
          // If we have a preferredGroup, let's get the group name so we can set it here so it shows as a selected filter chip
          this.setGroupFilterData(orgId, this.props.userStore.team?.preferredGroup);
        } else {
          this.setState({ orgId });
        }
      };

      setGroupFilterData = (teamId, groupId) => {
        return this.props.enterpriseStore
          .getGroupProfileData(groupId)
          .then(() => {
            this.props.reportsStore.setQueryParams('groupName', this.props.enterpriseStore.groupProfileData.profileData.name);
            this.props.reportsStore.setQueryParams('filterAud', 'group');
            this.props.reportsStore.setQueryParams('filterAudId', groupId);
            this.setState({ orgId: teamId }); // Setting the org in state is what initializes reports. Need to wait to do so when ready
          })
          .catch(() => {
            this.setState({ orgId: teamId });
          });
      };

      setQueryParams = () => {
        const queryParams = queryString.parse(window.location.search);
        const paramKeys = Object.keys(queryParams);
        if (paramKeys.length) {
          this.setContentTypeName(queryParams);
          paramKeys.forEach((param) => {
            // If we have a query param with userId and userName, set the values into search params to populate the search box
            if (param === 'userId' && !!queryParams.userName) {
              this.props.reportsStore.setUserFilter(1 * queryParams[param], queryParams.userName);
            }
            this.props.reportsStore.setQueryParams(param, queryParams[param]);
          });
        }
      };

      setContentTypeName = (params) => {
        reportOptions['content-type'].name = params.contentType;
      };

      scheduleReport = (url) => {
        this.props.navigate(url);
      };

      checkShowGroupFilter = (reportId) => {
        const queryParams = queryString.parse(window.location.search);
        if ((reportId === 'learning-hours' || reportId === 'learning-activities') && (queryParams.userId || queryParams.assignment)) {
          return false;
        }
        return reportOptions[this.props.match.params.id].showGroupFilter;
      };

      render() {
        const { team } = this.props.userStore;

        if (!this.state.orgId || !team?.reportsTeamGroupId) {
          return (
            <Container>
              <Loading message="Loading..." />
            </Container>
          );
        }
        const reportConfig = reportOptions[this.props.match.params.id];

        if (reportConfig) {
          const {
            name,
            id,
            graphId,
            showGraph,
            graphKey,
            showTable,
            showOptions,
            showUserFilter,
            showDateFilter,
            showDateRangeFilter,
            defaultSortCol,
            defaultSortDirection,
            columnMutations,
            beta,
          } = reportConfig;
          const queryParams = queryString.parse(window.location.search);
          const showGroupFilter = this.checkShowGroupFilter(this.props.match.params.id);
          const reportId = name === 'Assessment' ? 'assessment-simple' : id || this.props.match.params.id;
          return (
            <ReportsContainer
              reportTitle={name}
              reportId={reportId} // Some reports need an updated ID, but don't want url to change
              graphId={graphId || id || this.props.match.params.id} // Graphs use a different report ID than tables
              setQueryParams={this.setQueryParams}
              showGraph={showGraph}
              graphKey={graphKey}
              showTable={showTable}
              reportParams={queryParams}
              showOptions={showOptions}
              canViewReports={team?.permissions.canViewReports}
              showUserFilter={showUserFilter}
              showDateFilter={showDateFilter}
              showDateRangeFilter={showDateRangeFilter}
              showGroupFilter={showGroupFilter}
              orgId={this.state.orgId}
              team={team}
              teamGroupId={team?.reportsTeamGroupId}
              tableOptions={{
                defaultSortCol,
                defaultSortDirection,
                columnMutations,
              }}
              scheduleReport={this.scheduleReport}
              isBeta={beta}
            />
          );
        }
        return null;
      }
    }
  )
);

export default withRouter(Reports);
