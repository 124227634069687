import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import AdminTable from '../../components/AdminTable/AdminTable';
import StyledError from '../../components/Error/StyledError';
import Container from '../../components/Container/Container';
import { formatEmailToLink, formatGroupsToLink, formatLicensedOnToModal, formatLicenseToDropdown, formatNameToLink, formatRoleToDropdown } from '../../utils/tableUtil';
import FormatUtil from '../../utils/formatUtil';
import './enterprise.css';
import Icon from '../../components/Icon/Icon';

const OrganizationMembersTable = inject(
  'enterpriseStore',
  'commonStore',
  'userStore'
)(
  observer(({ enterpriseStore, commonStore, userStore }) => {
    const data = enterpriseStore.teamMemberTableData;
    const { orgId } = useParams();
    const isDemo = orgId === 'demo';
    const navigate = useNavigate();

    if (data.error) {
      return (
        <Container>
          <StyledError error={data.error} />
        </Container>
      );
    }
    if (!data.totalRecords && !data.loading) {
      return (
        <div className="flex flex-col justify-center items-center py-8 my-12">
          <Icon name="teams" className="pb-2 w-24 h-24 text-gray-600" />
          <p className="ml-4 text-sm text-gray-600">No results found!</p>
          <p className="ml-4 text-sm text-gray-600">Try adjusting your filters or search query.</p>
        </div>
      );
    }
    return (
      <AdminTable
        /* Table Data */
        headings={data.tableHeadings}
        data={data.tableData}
        tableLoading={data.loading && !data?.tableData?.length}
        tableError={data.error}
        displayCheckBox
        checkboxLabelCol="email"
        headerCheckBoxFunc={(e, { checked }) => {
          enterpriseStore.setAllSelectedRows('teamMemberTableData', checked);
        }}
        headerClickFunc={isDemo ? () => {} : (header) => enterpriseStore.sortTeamMemberTableData(header, orgId)}
        sortCol={data.queryParams.sortCol}
        sortDirection={data.queryParams.sortDirection}
        rowCheckBoxFunc={(e, { checked }, idx) => {
          enterpriseStore.setCheckboxState(enterpriseStore.teamMemberTableData, idx, checked);
        }}
        headerCheckBoxState={data.headerCheckBox}
        checkBoxesState={data.ckBoxesState}
        pagNumPgs={data.pagPagesCount}
        pagChangePg={isDemo ? () => {} : (page) => enterpriseStore.setTeamMemberTablePage(page, orgId)}
        pagActivePg={data.queryParams.activePg}
        formatColumns={[
          {
            method: (col, row) => formatGroupsToLink(col, row, orgId, commonStore, navigate),
            colIdx: FormatUtil.getColIndex(data.tableHeadings, 'groups'),
          },
          {
            method: (col, row) => formatNameToLink(col, row, orgId),
            colIdx: FormatUtil.getColIndex(data.tableHeadings, 'name'),
          },
          {
            method: (col, row) => formatRoleToDropdown(col, row, orgId, enterpriseStore),
            colIdx: FormatUtil.getColIndex(data.tableHeadings, 'role'),
          },
          {
            method: (col, row) => formatLicenseToDropdown(col, row, orgId, enterpriseStore, userStore),
            colIdx: FormatUtil.getColIndex(data.tableHeadings, 'license'),
          },
          {
            method: (col) => formatEmailToLink(col),
            colIdx: FormatUtil.getColIndex(data.tableHeadings, 'email'),
          },
          {
            method: (col, row) => formatLicensedOnToModal(col, row, orgId, commonStore, navigate),
            colIdx: FormatUtil.getColIndex(data.tableHeadings, 'licensed_date'),
          },
        ]}
        numOfResults={data.totalRecords}
        className="my-0 mt-2 mb-24 w-full"
        wrapperClassName="my-0 mt-2"
        loadingAsTable
      />
    );
  })
);

export default OrganizationMembersTable;
