import React from 'react';
import { isObservableArray } from 'mobx';
import CheckboxGroup from './CheckboxGroup';
import OrderedList from './OrderedList';
import SingleImageUpload from '../FormFields/SingleImageUpload';
import SingleFileUpload from '../FormFields/SingleFileUpload';
import TextAreaCount from './TextAreaCount';
import CheckboxBoolean from './CheckboxBoolean';
import Button from '../Button/Button';
import Captcha from '../FormFields/Captcha';
import Input from '../FormFields/Input';
import QuantityInput from '../FormFields/QuantityInput';
import Select from '../FormFields/Select';
import PhoneInputField from '../FormFields/PhoneInputField';
import RadioButtons from '../FormFields/RadioButtons';
import DatePickerField from './DatePickerField';
import TextArea from '../FormFields/TextArea';
import TextToggle from '../FormFields/TextToggle';
import RatingStarsField from '../FormFields/RatingStarsField';
import FieldHelpText from '../FormFields/FieldHelpText';
import MultiSelect from '../FormFields/MultiSelect';
import Label from '../FormFields/Label';
import { getCN } from '../../utils/formatUtil';
import BoxIconSingleSelect from './BoxIconSingleSelect';
import BoxIconMultiSelect from './BoxIconMultiSelect';
import Address from '../FormFields/Address';
import TypeAhead from '../FormFields/TypeAhead';
import accessibilityUtil from '../../utils/accessibilityUtil';
import Icon from '../Icon/Icon';
import GoogleReCaptchaV3Button from '../Button/GoogleReCaptchaV3Button';
import TextSelectCreate from '../FormFields/TextSelectCreate';

function FormField({ isFieldGroup, children, required, ariaDescribedBy, fieldSetClasses }) {
  if (isFieldGroup) {
    return (
      <fieldset aria-required={required} aria-describedby={ariaDescribedBy} className={fieldSetClasses}>
        {children}
      </fieldset>
    );
  }
  return children;
}

function Field(props) {
  const {
    name,
    type,
    label,
    options,
    onChange,
    defaultValue,
    customRenderer,
    addProvider,
    disabled,
    icon,
    dropWidth,
    dropHeight,
    imgPreview,
    popupText,
    acceptedTypes,
    dropZoneText,
    onRate,
    maxLength,
    formState,
    className,
    formId,
    action,
    loading,
    onClick,
    color,
    buttonClassName,
    fieldId,
    helpText,
    ariaDescribedBy,
  } = props;
  let field = null;
  const buttonsDisabled = !formState?._buttons_enabled;
  const isValid = formState?._isValid;
  const disabledField = disabled || false;
  let buttonIcon = {};
  switch (type) {
    // Old V2 ReCAPTCHA, only saving for legacy forms
    case 'captcha':
      field = <Captcha {...props} id={fieldId} disabled={disabledField} ariaDescribedBy={ariaDescribedBy} />;
      break;
    // New V3 ReCAPTCHA, should be used for all new forms
    case 'submit-captcha-v3':
      return (
        <GoogleReCaptchaV3Button
          {...props}
          type="submit"
          ariaDescribedBy={ariaDescribedBy}
          id={fieldId}
          label={label}
          disabled={!isValid}
          loading={loading}
          className={className}
          color={color}
        />
      );

    case 'select':
      field = (
        <Select
          {...props}
          id={fieldId}
          disabled={disabledField}
          ariaDescribedBy={ariaDescribedBy}
          defaultValue={isObservableArray(defaultValue) ? defaultValue.slice() : defaultValue}
        />
      );
      break;

    case 'multi-select':
      field = <MultiSelect {...props} id={fieldId} multi ariaDescribedBy={ariaDescribedBy} />;
      break;

    case 'radio':
      field = <RadioButtons {...props} id={fieldId} radios={options} ariaDescribedBy={ariaDescribedBy} />;
      break;

    case 'checkbox':
      field = <CheckboxGroup {...props} id={fieldId} label={label} options={options} ariaDescribedBy={ariaDescribedBy} />;
      break;

    case 'datePicker':
      field = <DatePickerField {...props} id={fieldId} ariaDescribedBy={ariaDescribedBy} />;
      break;

    case 'singleFileUpload':
      field = (
        <SingleFileUpload
          {...props}
          id={fieldId}
          popupText={popupText} // popup text on dropzone hover
          dropZoneText={dropZoneText} // Text to appear within dropzone
          acceptedTypes={acceptedTypes} // e.g. "image/jpeg, image/png, image/gif, image/bmp"
          ariaDescribedBy={ariaDescribedBy}
        />
      );
      break;

    case 'singleImageUpload':
      field = (
        <div className="flex justify-center">
          <SingleImageUpload
            {...props}
            id={fieldId}
            dropWidth={dropWidth} // width for dropzone container
            dropHeight={dropHeight} // height for dropzone container
            preview={imgPreview} // bool - If not showing preview, will just list file name below dropzone
            popupText={popupText} // popup text on dropzone hover
            dropZoneText={dropZoneText} // Text to appear within dropzone
            icon={icon} // Icon to appear within dropzone (above text)
            acceptedTypes={acceptedTypes} // e.g. "image/jpeg, image/png, image/gif, image/bmp"
            ariaDescribedBy={ariaDescribedBy}
          />
        </div>
      );
      break;

    case 'orderedlist':
      field = <OrderedList {...props} id={fieldId} customRenderer={customRenderer} addProvider={addProvider} ariaDescribedBy={ariaDescribedBy} />;
      break;

    case 'textarea':
      field = <TextArea {...props} id={fieldId} disabled={disabledField} ariaDescribedBy={ariaDescribedBy} />;
      break;

    case 'textarea-count':
      field = <TextAreaCount {...props} id={fieldId} onChange={onChange} maxLength={maxLength || null} ariaDescribedBy={ariaDescribedBy} />;
      break;

    case 'quantity':
      field = <QuantityInput {...props} id={fieldId} disabled={!!disabled} ariaDescribedBy={ariaDescribedBy} />;
      break;
    case 'button':
      if (icon) {
        if (typeof icon === 'string') {
          buttonIcon.name = icon;
        } else if (typeof icon === 'object') {
          buttonIcon = { ...icon };
        }
      }
      field = (
        <div>
          <Button
            {...props}
            id={formId ? `${formId}_${fieldId}` : fieldId}
            type="submit"
            text={label}
            color={color || 'gray'}
            disabled={disabled || buttonsDisabled}
            loading={loading || false}
            icon={{ position: 'left', ...buttonIcon }}
            className={className}
          />
          <FieldHelpText helpText={helpText} id={fieldId} ariaDescribedBy={ariaDescribedBy} />
        </div>
      );
      break;

    case 'phone':
      field = (
        <PhoneInputField
          {...props}
          className={className}
          id={fieldId}
          ariaDescribedBy={ariaDescribedBy}
          country={formState._inferred_country}
          countryOptionsOrder={['US', '|', '...']}
        />
      );
      break;

    case 'boolean':
      field = <CheckboxBoolean {...props} id={fieldId} ariaDescribedBy={ariaDescribedBy} disabled={disabledField} />;
      break;

    case 'boxIconMultiSelect':
      field = <BoxIconMultiSelect {...props} id={fieldId} options={options} onChange={onChange} name={name} defaultValue={defaultValue} />;
      break;

    case 'boxIconSingleSelect':
      field = <BoxIconSingleSelect {...props} id={fieldId} options={options} onChange={onChange} name={name} defaultValue={defaultValue} />;
      break;

    case 'link':
      field = (
        <div>
          <button
            type="button"
            aria-label={label || icon['aria-label']}
            id={fieldId}
            onClick={!disabled ? onClick : () => false}
            className={buttonClassName || 'cursor-pointer py-2.5 px-6'}
          >
            {label || null}
            {icon ? <Icon name={icon.name} className={icon.className} /> : null}
          </button>
        </div>
      );
      break;

    case 'ratingStars':
      field = <RatingStarsField {...props} id={fieldId} onRate={onRate} defaultRating={defaultValue} ariaDescribedBy={ariaDescribedBy} formId={formId} />;
      break;

    case 'insert':
      field = <div>{props.insertComponent}</div>;
      break;

    case 'text-toggle':
      field = <TextToggle {...props} id={fieldId} disabled={disabledField} trigger={action} ariaDescribedBy={ariaDescribedBy} />;
      break;

    case 'address':
      field = <Address {...props} id={fieldId} />;
      break;

    case 'textSelectCreate':
      field = <TextSelectCreate {...props} id={fieldId} isDisabled={disabledField || props.isDisabled} isLoading={loading || props.isLoading} />;
      break;

    case 'typeAhead':
      field = <TypeAhead {...props} id={fieldId} />;
      break;

    case 'text':
    case 'number':
    case 'hidden':
    case 'password':
    default:
      field = <Input {...props} ariaDescribedBy={ariaDescribedBy} type={type || 'text'} id={fieldId} disabled={disabledField} />;
      break;
  }
  return field;
}

/**
 * Form select field
 * @param input
 * @param rest
 * @returns {*}
 * @constructor
 */
class DynamicField extends React.Component {
  componentDidMount() {
    if (typeof this.props.onInit === 'function' && this.props.defaultValue) {
      this.props.onInit(this.props.name, this.props.defaultValue);
    }
    if (this.props.type === 'captcha') {
      // Ensure that the button toggle is set to false the first time we render this
      this.props.toggleButtons(false);
    }
  }

  componentWillUnmount() {
    // Let the parent form know to remove the value from state, since this field is gone.
    this.props.cleanup(this.props.name);
  }

  render() {
    const { id, name, type, label, description, error, required, hidden, tooltip, style, labelClass, alignment, quasiRequired } = this.props;

    const fieldId = id || name;
    const helpText = description;
    const ariaId = fieldId
      ? fieldId
          .split(/(?=[A-Z])/)
          .join('_')
          .toLowerCase()
      : null;
    const wrapperClass = getCN(`
      mb-4
      ${(hidden || type === 'hidden') && 'hidden'}
    `);
    const ariaDescribedBy = accessibilityUtil.ariaDescribedById(ariaId, error, helpText);
    const isFieldGroup = type === 'radio' || type === 'ratingStars';
    const fieldSetClasses = type === 'ratingStars' ? 'w-full' : '';
    return (
      <div className={wrapperClass}>
        <FormField isFieldGroup={isFieldGroup} required={required} ariaDescribedBy={ariaDescribedBy} fieldSetClasses={fieldSetClasses}>
          <Label
            type={type}
            id={id}
            label={label}
            required={required}
            quasiRequired={quasiRequired}
            tooltip={tooltip}
            style={style}
            className={labelClass}
            alignment={alignment}
            ratings={type === 'ratingStars'}
            displayAsLegend={isFieldGroup}
          />
          <div className="w-full">
            {['boxIconSingleSelect', 'boxIconMultiSelect'].includes(type) && <FieldHelpText helpText={helpText} error={error} ariaId={ariaId} />}

            <Field {...this.props} fieldId={fieldId} ariaId={ariaId} helpText={helpText} ariaDescribedBy={ariaDescribedBy} />

            {!['boxIconSingleSelect', 'boxIconMultiSelect'].includes(type) && <FieldHelpText helpText={helpText} error={error} ariaId={ariaId} />}
          </div>
        </FormField>
      </div>
    );
  }
}

export default DynamicField;
