import React, { useState } from 'react';
import ActivityLogWrapper from './ActivityLogWrapper';

const getActivityFilters = (events = []) => {
  return events.map((event) => ({ label: event, value: event }));
};

function TeamOwnerActivityLog({ team }) {
  const [filterOptions, setFilterOptions] = useState([]);

  return (
    <ActivityLogWrapper
      team={team}
      agentObj="enterprise"
      filters={getActivityFilters(filterOptions)}
      setFilterOptions={setFilterOptions}
      headerMappings={{ User: 'Name', Message: 'Details' }}
      activityLogClasses="mt-8"
      filterType="horizontal"
    />
  );
}

export default TeamOwnerActivityLog;
