import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import { isEnterpriseUser, isFreeUser } from '../utils/navUtil';
import { HELP_DESK_LINKS } from '../constants';

/*
  Nav items here are shared between enterprise and non-enterprise users 
  based on specific display logic located inside of each object
*/
function myLearningChildren(completed) {
  const completedSuffix = completed ? '/completed' : '';

  const items = [
    {
      label: 'All',
      href: `/my-learning${completedSuffix}`,
      id: uuidv4(),
    },
    {
      label: 'Career Paths',
      href: `/my-learning/career-paths${completedSuffix}`,
      id: uuidv4(),
    },
    {
      label: 'Collections',
      href: `/my-learning/collections${completedSuffix}`,
      id: uuidv4(),
    },
    {
      label: 'Courses',
      href: `/my-learning/courses${completedSuffix}`,
      id: uuidv4(),
    },
    {
      label: 'Virtual Labs',
      href: `/my-learning/virtual-labs${completedSuffix}`,
      id: uuidv4(),
    },
    {
      label: 'Assessments',
      href: `/my-learning/assessments${completedSuffix}`,
      id: uuidv4(),
    },
    {
      label: 'Practice Tests',
      href: `/my-learning/practice-tests${completedSuffix}`,
      id: uuidv4(),
    },
    {
      label: 'Challenges',
      href: `/my-learning/challenges${completedSuffix}`,
      id: uuidv4(),
    },
    {
      divider: true,
      id: uuidv4(),
      display: isEnterpriseUser,
    },
    {
      label: 'Custom Paths',
      href: `/my-learning/curriculum${completedSuffix}`,
      id: uuidv4(),
      display: isEnterpriseUser,
    },
    {
      label: 'Assignments',
      href: `/my-learning/assignments${completedSuffix}`,
      id: uuidv4(),
      display: isEnterpriseUser,
    },
    {
      label: 'Goals',
      href: `/my-learning/goals${completedSuffix}`,
      id: uuidv4(),
      display: isEnterpriseUser,
    },
  ];

  if (completed) {
    items.push(
      {
        divider: true,
        id: uuidv4(),
      },
      {
        label: 'Certificates',
        href: `/my-learning/certificates`,
        id: uuidv4(),
      }
    );
  }

  return items;
}

function GenericIcon() {
  return <span>Icon</span>;
}

const profileChildren = [
  {
    label: 'Profile',
    href: '/profile',
    startsWith: true,
    id: uuidv4(),
  },
  {
    label: 'Invite a Friend',
    href: '/refer-a-friend',
    startsWith: true,
    id: uuidv4(),
  },
  {
    label: 'Settings',
    href: '/settings/account',
    startsWith: true,
    id: uuidv4(),
  },
  {
    label: 'Membership',
    href: '/settings/membership',
    startsWith: true,
    id: uuidv4(),
  },
  {
    divider: true,
    noFollowingHeader: true,
    id: uuidv4(),
  },
  {
    label: 'My Learning',
    href: '/my-learning',
    id: uuidv4(),
  },
];

export default {
  items: [
    {
      label: 'Upgrade',
      href: '/upgrade',
      button: true,
      type: 'upgrade',
      // this should be a bool OR a function returning a bool!
      hide: false,
      id: uuidv4(),
    },
    {
      label: 'Slack',
      // href: '/slack',
      icon: <GenericIcon />,
      hideLabel: true,
      hide: true,
      id: uuidv4(),
    },
    {
      label: 'Help',
      href: HELP_DESK_LINKS.HOMEPAGE,
      icon: <GenericIcon />,
      hideLabel: true,
      id: uuidv4(),
      display: (userStore) => !userStore?.isSATOnly,
    },
    {
      label: 'Notifications',
      href: '/notification-center',
      icon: <GenericIcon />,
      hideLabel: true,
      id: uuidv4(),
    },
    {
      label: 'Profile',
      href: '/profile',
      // We need a way to provide the avatar!
      avatar: true,
      id: uuidv4(),
      children: [
        {
          heading: true,
          label: 'Account',
          id: uuidv4(),
        },
        {
          label: 'Profile',
          href: '/profile',
          id: uuidv4(),
          children: profileChildren,
        },
        {
          label: 'My Learning',
          href: '/my-learning',
          id: uuidv4(),
          startsWith: true,
          children: [
            {
              label: 'In Progress',
              href: '/my-learning',
              id: uuidv4(),
              defaultMobileOpen: true,
              startsWith: true,
              excludes: ['/completed', '/upcoming', '/expired', '/assessments/results'],
              children: myLearningChildren(false),
            },
            {
              label: 'Saved Items',
              href: '/bookmarks',
              id: uuidv4(),
            },
            {
              label: 'Completed',
              href: '/my-learning/completed',
              id: uuidv4(),
              includes: '/completed',
              children: myLearningChildren(true),
            },
            {
              label: 'Upcoming',
              href: '/my-learning/goals/upcoming',
              id: uuidv4(),
              includes: '/goals/upcoming',
              display: isEnterpriseUser,
              children: [
                {
                  label: 'Goals',
                  href: `/my-learning/goals/upcoming`,
                  id: uuidv4(),
                  display: isEnterpriseUser,
                },
              ],
            },
            {
              label: 'Expired',
              href: '/my-learning/goals/expired',
              id: uuidv4(),
              display: isEnterpriseUser,
              startsWithOmit: '/my-learning',
              children: [
                {
                  label: 'Goals',
                  href: `/my-learning/goals/expired`,
                  id: uuidv4(),
                  display: isEnterpriseUser,
                },
              ],
            },
          ],
          aliases: {
            '/bookmarks': '/my-learning/bookmarks',
          },
        },

        {
          label: 'Forums',
          icon: 'forums',
          href: process.env.REACT_APP_FORUMS_URL,
          target: '_blank',
          id: uuidv4(),
          display: (userStore) => !userStore?.isSATOnly,
        },

        {
          label: 'Invite a Friend',
          href: '/refer-a-friend',
          startsWith: true,
          id: uuidv4(),
          children: profileChildren,
        },
        {
          label: 'Membership',
          href: '/settings/membership',
          startsWith: true,
          id: uuidv4(),
          additionalLabels: [
            {
              type: 'default',
              label: 'Free',
              display: isFreeUser,
              id: uuidv4(),
            },
            {
              type: 'default',
              label: 'Teams',
              display: isEnterpriseUser,
              id: uuidv4(),
            },
            {
              type: 'upgrade',
              label: 'Upgrade',
              id: uuidv4(),
            },
          ],
          children: profileChildren,
        },
        {
          label: 'Settings',
          href: '/settings/account',
          startsWith: true,
          id: uuidv4(),
          children: profileChildren,
        },
        {
          divider: true,
          hideOnMobile: true,
          id: uuidv4(),
        },
        {
          label: 'Logout',
          href: '/logout',
          id: uuidv4(),
        },
      ],
    },
  ],
};
