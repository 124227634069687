import React from 'react';
import AddLink from '../AddLink/AddLink';
import Icon from '../Icon/Icon';
import If from '../If/If';
import LoadingText from '../Loading/LoadingText';
import FormatUtil from '../../utils/formatUtil';
import { useCareerPrograms } from '../../providers/CareerProgramsProvider';
import { CONTENT_TYPES } from '../../constants';
import EnrollmentButton from '../BrowsePromo/EnrollmentButton';

/**
 * Slug component for linking back to program type based on ID
 * @param {Object} program - Program object
 * @returns
 */
function CareerProgramTypeSlug({ program }) {
  let programType = 'Cybrary Program';
  let programTypeHref = '#';
  const programId = program?.content_type?.id;
  switch (programId) {
    case CONTENT_TYPES.CAREER_PROGRAM.id:
      programType = 'Career Paths';
      programTypeHref = '/browse/paths/career-paths';
      break;
    case CONTENT_TYPES.SKILL_PROGRAM.id:
      programType = 'Skill Paths';
      programTypeHref = '/browse/paths/skill-paths';
      break;
    case CONTENT_TYPES.CERT_PREP_PROGRAM.id:
      programType = 'Certification Prep';
      programTypeHref = '/browse/paths/cert-prep-paths';
      break;
    default:
      break;
  }
  return (
    <AddLink to={programTypeHref}>
      <span className="opacity-[0.4]">{programType}</span>
    </AddLink>
  );
}

function CareerProgramHero() {
  const { currentPageProgram: program } = useCareerPrograms();

  return (
    <div
      style={{
        backgroundImage: `url(${program?.content_item?.meta?.backgroundImage})`,
        backgroundColor: 'rgba(0, 0, 0, 1)',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundSize: 'cover',
      }}
    >
      <div className="bg-black bg-gradient-to-r from-black lg:bg-black/60">
        <div className="flex flex-col justify-between p-4 mx-auto space-y-12 max-w-340 text-white lg:flex-row lg:py-12 lg:px-16 lg:space-y-0 2xl:px-0">
          <div className="flex flex-col">
            <div className="mb-5">
              <CareerProgramTypeSlug program={program} />
              <span className="mx-3 opacity-[0.4]">/</span>
              <span>{program?.title || ''}</span>
            </div>
            <div className="self-start pt-4 pr-15">
              <h2 className="mb-3 text-2xl font-semibold">
                <LoadingText isLoading={!program?.title} className="w-128 h-4">
                  {program?.title}
                </LoadingText>
              </h2>
              <p>
                <LoadingText isLoading={!program?.title} className="w-full">
                  {program?.short_description}
                </LoadingText>
              </p>
            </div>
          </div>
          <div className="flex flex-col items-center min-w-[300px]">
            <div
              className="w-28 h-28"
              style={{
                backgroundImage: `url(${program?.content_item?.meta?.heroGraphic})`,
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
                backgroundSize: 'contain',
              }}
            />
            <If condition={program}>
              <div className="flex items-center mt-6">
                <If condition={Boolean(program?.experience_points_total)}>
                  <div className="mr-5 text-xs font-semibold">
                    <span>XP {FormatUtil.breakLargeNumber(program?.experience_points_total)}</span>
                  </div>
                </If>
                <If condition={Boolean(program?.ceu_count)}>
                  <div className="flex items-center mr-5 text-xs font-semibold">
                    <span>
                      {FormatUtil.pluralize(program?.ceu_count || 0, 'CEU')} {program?.ceu_count}
                    </span>
                  </div>
                </If>
                <If condition={Boolean(program?.level?.name)}>
                  <div className="flex items-center mr-5 text-xs font-semibold">
                    <Icon name="speedometer" className="mr-1 w-3 h-3 opacity-[0.7] fill-current" />
                    <span>{program?.level?.name}</span>
                  </div>
                </If>
                <If condition={Boolean(program?.duration_seconds)}>
                  <div className="flex items-center text-xs font-semibold">
                    <Icon name="clock" className="mr-1 w-3 h-3 opacity-[0.7] fill-current" />
                    <span>{FormatUtil.formatTime(program?.duration_seconds, 'hma')}</span>
                  </div>
                </If>
              </div>
              <div className="mt-6 w-full">
                <EnrollmentButton content={program} buttonClasses="w-full min-w-[270px]" />
              </div>
            </If>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CareerProgramHero;
