import React, { useState } from 'react';
import { inject, observer } from 'mobx-react';
import Loading from '../../components/Loading/Loading';
import StyledError from '../../components/Error/StyledError';
import Checkbox from '../../components/FormFields/Checkbox';

const ManageAutoJoinByEmailDomain = inject(
  'enterpriseStore',
  'commonStore',
  'userStore'
)(
  observer(({ userStore, commonStore, enterpriseStore }) => {
    const { id: orgId, domains, auto_invite } = userStore?.team || {};
    const [isSaving, setIsSaving] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(false);
    const hasDomainsConfigured = domains?.length;
    const isDemo = !orgId || orgId === 'demo';
    const editTeamSettings = (setting) => {
      setIsSaving(true);
      enterpriseStore
        .editOrgSettings(orgId, setting)
        .then(async () => {
          setIsSaving(false);
          commonStore.triggerToast('success', {
            content: setting.auto_invite
              ? 'Members will be automatically added to the team if they have one of the allowed email domains.'
              : 'Members will no longer be able to join the team on their own.',
          });
          setIsLoading(true);
          await userStore.loadAndSetTeamData(orgId);
        })
        .catch((e) => {
          setError(e);
          commonStore.triggerToast('error', {
            errorCode: e.response.status,
          });
        })
        .finally(() => {
          setIsSaving(false);
          setIsLoading(false);
        });
    };

    const toggleConfirm = (_e, { checked }) => {
      let confirmText;
      if (checked) {
        confirmText = (
          <p>
            Are you sure you want to allow new members to join your team on their own if they use an email domain that belongs to your organization?
            <br />
            <br />
            <span className="font-bold">Members will be automatically added to the team if they have one of the allowed email domains.</span>
          </p>
        );
      } else {
        confirmText = (
          <p>
            Are you sure you want to disable members from joining the team on their own?
            <br />
            <br />
            <span className="font-bold">Members will no longer be able to join the team on their own.</span>
          </p>
        );
      }
      commonStore.triggerConfirm({
        content: confirmText,
        cancel: () => commonStore.resetConfirmState(),
        continue: () => {
          commonStore.resetConfirmState();
          editTeamSettings({ auto_invite: checked });
        },
      });
    };

    if (isLoading) {
      return <Loading message="Loading" />;
    }

    if (isSaving) {
      return <Loading message="Saving..." />;
    }

    if (error) {
      return <StyledError error={error} />;
    }

    return (
      <>
        <div className="flex items-center mt-4 text-sm">
          <Checkbox
            className="flex items-start mr-6 mb-2 disabled:cursor-not-allowed"
            onChange={isDemo ? undefined : toggleConfirm}
            checked={Boolean(auto_invite)}
            toggle
            disabled={isDemo}
          />
          <p id="auto-assign-licenses-toggle">
            Members will be automatically added to the team if they have one of the allowed email domains:{' '}
            {auto_invite ? <span className="text-cyb-green-600">Enabled</span> : <span className="text-cyb-red-700">Disabled</span>}
          </p>
        </div>
        <div className="px-6 pt-6">
          <h2 id="email-domains" className="mb-4 text-xl font-bold">
            Allowed Email Domains
          </h2>
          {hasDomainsConfigured ? (
            <>
              {domains?.map((domain) => {
                if (!domain.enabled) {
                  return false;
                }
                return <div key={domain.domain} className="mt-2">{`@${domain.domain}`}</div>;
              })}
              <p className="mt-4 text-sm text-gray-600">
                <em>To add additional email domains, please contact your Customer Success Manager</em>
              </p>
            </>
          ) : (
            <p className="mt-4 text-sm text-gray-600">
              <em>You don&apos;t have any email domains setup yet. To help your teammates easily join Cybrary, please contact your Customer Success Manager</em>
            </p>
          )}
        </div>
      </>
    );
  })
);

export default ManageAutoJoinByEmailDomain;
