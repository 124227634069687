import React from 'react';
import EnrollmentButton from '../BrowsePromo/EnrollmentButton';
import { useCustomPathsContext } from '../../providers/CustomPathsProvider';

/**
 * @params - userStore
 * @params - enrollmentStore
 * @params - commonStore
 * @params - item - path object
 * @returns - EnrollmentButton component with styles and behavior specific to custom paths
 */
function CustomPathsEnrollmentCta({ item = {} }) {
  const { getIsEnrolled } = useCustomPathsContext();

  /** item by default from curriculum doesn't include content_type.name which is needed by the enrollment button component */
  const modifiedItem = {
    ...item,
    content_type: {
      ...item?.content_type,
      name: 'curriculum',
      nice_name: 'Curriculum',
    },
  };
  const isEnrolled = getIsEnrolled(item?.id);

  let buttonStyles = 'font-bold py-2 px-6 text-xs hover:cursor-pointer rounded';
  if (isEnrolled) {
    buttonStyles += ' bg-cyb-pink-500 hover:bg-cyb-pink-600 text-white';
  } else {
    buttonStyles += ' bg-gray-200 hover:bg-gray-300 text-black';
  }
  const buttonText = isEnrolled ? 'Continue' : 'Start';
  return <EnrollmentButton content={modifiedItem} text={buttonText} buttonClasses={buttonStyles} />;
}
export default CustomPathsEnrollmentCta;
