import React, { useState, useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { Navigate } from 'react-router-dom';
import withRouter from '../../components/Router/withRouter';
import Container from '../../components/Container/Container';
import Title from '../../components/Title/Title';
import Loading from '../../components/Loading/Loading';
import Agents from '../../agents/agents';
import CurriculumItem from '../../components/Assignments/CurriculumItem';
import If from '../../components/If/If';
import BugsnagUtil from '../../utils/bugsnagUtil';

const GoalPath = inject(
  'authStore',
  'enrollmentStore',
  'commonStore'
)(
  observer(({ authStore, commonStore, match }) => {
    // Grab enrollmentId and contentId from the URL
    const enrollmentId = match.params.id === 'unstarted' ? null : parseInt(match.params.id, 10);
    const contentId = parseInt(match.params.contentId, 10);

    const [isLoading, setIsLoading] = useState(true);
    const [enrollmentItem, setEnrollmentItem] = useState(null);
    const [content, setContent] = useState(null);

    useEffect(() => {
      authStore.fireAttributionEvent();
      // Determine method for getting goal details based on whether we have enrollment ID or not
      const agentMethod = enrollmentId ? 'getEnrollmentDetails' : 'getChildProgress';
      const agentArg = enrollmentId || contentId || null;

      // Get content details
      (async () => {
        try {
          // Load content
          const contentResponse = await Agents.catalog.getContentDescriptionById(contentId);
          setContent(contentResponse);

          // Load enrollment
          const enrollmentResponse = await Agents.enrollments[agentMethod](agentArg, '?includeEmpty=1');
          setEnrollmentItem(enrollmentResponse);
        } catch (error) {
          BugsnagUtil.notifyWithNamedMetadata(error, 'GoalPath', { enrollmentId, contentId });
          commonStore.triggerToast('error', { content: 'Error loading goal path, please try again.' });
        } finally {
          setIsLoading(false);
        }
      })();
    }, []);

    if (isLoading) {
      return (
        <Container className="pt-10">
          <Loading message="Loading your path..." />
        </Container>
      );
    }

    if (enrollmentItem) {
      return (
        <Container size="lg">
          <Title title={content.title} />
          <If condition={enrollmentItem.length}>
            {enrollmentItem?.map((curriculumItem) => {
              return <CurriculumItem key={curriculumItem.id || curriculumItem.content_description_id} curriculumItem={curriculumItem} content={content} />;
            })}
          </If>
        </Container>
      );
    }

    // If done loading and no content to show, redirect user back to goals (likely an error)
    return <Navigate to="/goals" />;
  })
);

export default withRouter(GoalPath);
