import React from 'react';
import { inject, observer } from 'mobx-react';
import CareerProgramsProvider from './CareerProgramsProvider';
import CustomPathsProvider from './CustomPathsProvider';
import ForumsSidebarProvider from './ForumsSidebarProvider';
import ProfileProvider from './ProfileProvider';
import BadgeModalProvider from './BadgeModalProvider';
import CybMeasureProvider from './CybMeasureProvider';
import UserOnboardingProvider from './UserOnboardingProvider';
import TeamOnboardingProvider from './TeamOnboardingProvider';
import PromoProvider from './PromoProvider';
import ActivityLimitProvider from './ActivityLimitProvider';
import EnrollmentProvider from './EnrollmentProvider';

/**
 * Global Providers that wrap the entire application.
 * This exists to help keep App.js clean while wrapping the app in multiple providers.
 * @param {*} children - React children
 */
const GlobalProviders = inject(
  'userStore',
  'enrollmentStore'
)(
  observer(({ children, userStore, enrollmentStore }) => {
    return (
      <EnrollmentProvider>
        <ActivityLimitProvider>
          <ProfileProvider userStore={userStore}>
            <UserOnboardingProvider user={userStore?.user}>
              <TeamOnboardingProvider userStore={userStore}>
                <PromoProvider>
                  <CustomPathsProvider userStore={userStore} enrollmentStore={enrollmentStore}>
                    <BadgeModalProvider>
                      <ForumsSidebarProvider>
                        <CybMeasureProvider userStore={userStore}>
                          <CareerProgramsProvider user={userStore.user}>{children}</CareerProgramsProvider>
                        </CybMeasureProvider>
                      </ForumsSidebarProvider>
                    </BadgeModalProvider>
                  </CustomPathsProvider>
                </PromoProvider>
              </TeamOnboardingProvider>
            </UserOnboardingProvider>
          </ProfileProvider>
        </ActivityLimitProvider>
      </EnrollmentProvider>
    );
  })
);

export default GlobalProviders;
