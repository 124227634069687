import React, { useState } from 'react';
import MobilePrimarySidebar from './MobilePrimarySidebar';
import AddLink from '../../AddLink/AddLink';
import Icon from '../../Icon/Icon';
import HamburgerMenu from '../../Navigation/Icons/HamburgerMenu';
import PercentageBar from '../../Analytics/PercentageBar';
import InstructionsRenderer from '../Content/InstructionsRenderer';
import ChallengeAssessmentStart from '../Content/Assessment/ChallengeAssessmentStart';

function MobilePrimaryNavigation({
  activity,
  userStore,
  title,
  progress,
  type,
  permalink,
  permalinkPrefix,
  desktopTabMenu,
  enableHamburgerMenu,
  hasIncompleteItems,
  tasks = null,
  disableNav = false,
}) {
  const [isMobilePrimarySidebarOpen, setMobilePrimarySidebar] = useState(false);

  const toggleMobilePrimarySidebar = () => {
    setMobilePrimarySidebar(enableHamburgerMenu ? !isMobilePrimarySidebarOpen : false);
  };

  const tabs = [...desktopTabMenu];
  if (tasks) {
    tabs.unshift({
      id: 'tasks',
      label: 'Tasks',
      type: 'tasks',
    });
  }
  // Add instructions to the tabs if this is clab

  const { isClab, activityContent, isCaseStudy, hasCybAssessment, cybAssessmentId } = activity || {};
  if ((isClab && activityContent) || isCaseStudy) {
    tabs.unshift({
      id: 'instructions',
      type: 'component',
      label: 'Instructions',
      component: (
        <div className="p-6 mt-16">
          <InstructionsRenderer activity={activity} content={activityContent} userStore={userStore} />
          {hasCybAssessment && <ChallengeAssessmentStart id={cybAssessmentId} isCaseStudy={isCaseStudy} />}
        </div>
      ),
    });
  }

  return (
    <>
      <div className="flex justify-between items-center w-full h-16 bg-white border-b-xs border-gray-400 lg:hidden">
        <div className="flex items-center pb-3 mx-4 mt-4 mb-0">
          <AddLink to="/" aria-label="Dashboard">
            <Icon name="chevron-left" className="inline-block w-5 h-5 text-gray-600 hover:text-black cursor-pointer" />
          </AddLink>
          <div>
            <HamburgerMenu
              classes={`block h-8 w-8 ml-4 ${enableHamburgerMenu ? 'cursor-pointer' : 'cursor-not-allowed'} stroke-current text-gray-600 hover:text-black`}
              onClick={toggleMobilePrimarySidebar}
            />
          </div>
          <h1 title={title} className="overflow-hidden ml-4 max-w-prose text-lg font-black text-ellipsis line-clamp-2 lg:max-w-1.85xs xl:max-w-sm">
            {title}
          </h1>
          <div className="flex items-center ml-4">
            <div className="overflow-hidden my-3 mx-auto mr-2 w-18 h-2 text-left bg-gray-300 rounded-lg sm:w-24">
              <PercentageBar color="#df057b" maxHeight="0.5rem" width={`${progress}%`} />
            </div>
            <p className="text-xs text-gray-600">{progress}%</p>
          </div>
        </div>
      </div>
      <MobilePrimarySidebar
        isOpen={isMobilePrimarySidebarOpen}
        toggleMobilePrimarySidebar={toggleMobilePrimarySidebar}
        activity={activity}
        title={title}
        type={type}
        permalink={permalink}
        permalinkPrefix={permalinkPrefix}
        desktopTabMenu={tabs}
        hasIncompleteItems={hasIncompleteItems}
        tasks={tasks}
        disableNav={disableNav}
      />
    </>
  );
}

export default MobilePrimaryNavigation;
