import React from 'react';
import moment from 'moment';
import { observer } from 'mobx-react';
import Icon from '../Icon/Icon';
import GraphTableToggle from '../Analytics/GraphTableToggle';
import Title from '../Title/Title';
import Loading from '../Loading/Loading';
import StyledError from '../Error/StyledError';
import AddLink from '../AddLink/AddLink';
import Container from '../Container/Container';
import Label from '../Label/Label';

function AssignmentDueDate(data) {
  if (!data || !data.due_date || !data.status) {
    return null;
  }
  return (
    <div className="text-gray-600">
      <span className="font-semibold text-black">Due:</span> {moment(new Date(`${data.due_date} UTC`)).format('MM/DD/YY')}{' '}
      <Label className="mt-0 align-middle" basic color={data.status === 'Active' ? 'green' : 'red'}>
        {data.status}
      </Label>
    </div>
  );
}

export default observer(
  class AssignmentDashboardHeading extends React.Component {
    showDeleteConfirm = (id) => {
      this.props.commonStore.triggerConfirm({
        content: 'Are you sure you want to delete this assignment?',
        cancel: () => this.props.commonStore.resetConfirmState(),
        continue: () => {
          this.props.commonStore.resetConfirmState();
          this.props.deleteAssignment(id);
        },
      });
    };

    getAssignedToLink = (data) => {
      if (data.assignee_type === 'group') {
        return (
          <>
            <AddLink
              className="hover:font-semibold text-black hover:text-black underline hover:underline"
              to={`/enterprise/${this.props.orgId}/organization/dashboard?group=${data.assignee_id}`}
            >
              {' '}
              {data.assignee_name}
            </AddLink>
            , <span className="font-semibold text-black"> {data.total}</span> users
          </>
        );
      }
      if (data.assignee_type === 'user') {
        return (
          <AddLink
            className="hover:font-semibold text-black hover:text-black underline hover:underline"
            to={`/enterprise/${this.props.orgId}/organization/member/${data.assignee_id}/dashboard`}
          >
            {' '}
            {data.assignee_name}
          </AddLink>
        );
      }
      return (
        <>
          <span className="font-semibold text-black"> {data.total}</span> users
        </>
      );
    };

    render() {
      const { setGraphView, graphView } = this.props;
      const { loading, error, data } = this.props.data;
      if (loading) {
        return (
          <Container>
            <Loading message="Loading..." />
          </Container>
        );
      }
      if (error) {
        return (
          <Container className="my-4">
            <StyledError error={error} />
          </Container>
        );
      }
      return (
        <div className="px-0 pb-[3rem] mb-4 border-none header-container">
          <div className="block md:flex">
            {data.thumbnail_url && (
              <div className="w-1/6 header-image">
                <img src={data.thumbnail_url} alt="Assignment" />
              </div>
            )}
            <div className={`${data.img ? 'w-5/6' : 'w-full'} profile-data`}>
              <div className="block header-content">
                <div className="w-full">
                  <div className="items-end mt-16 mb-12 md:flex">
                    <div className="flex-1">
                      <Title title={data.name} breadcrumbs={this.props.breadcrumbs} omitPadding />
                    </div>
                    {this.props.userStore.team?.permissions.canManageAssignments ? (
                      <div className="mt-4 md:mt-0 md:text-right">
                        <AddLink className="inline-block mr-4" to={`/enterprise/${this.props.orgId}/assignment/edit/${this.props.assignmentId}`}>
                          <button className="py-2.5 px-6 text-sm font-bold leading-5 text-center text-white hover:text-white bg-cyb-pink-500 hover:bg-cyb-pink-600 rounded-sm">
                            Edit
                          </button>
                        </AddLink>
                        <button
                          onClick={() => this.showDeleteConfirm(this.props.assignmentId)}
                          className="inline-block py-2.5 px-6 text-sm font-bold leading-5 text-center text-black bg-gray-200 rounded-sm"
                        >
                          Delete
                        </button>
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className="w-full">
                  <div className="header-info">
                    <AddLink className="text-cyb-pink-500 hover:text-black underline" to={`/enterprise/${this.props.orgId}/paths/show/${data.curriculum_id}`}>
                      <Icon name="newspaper" className="inline-block mr-2 w-4 h-4" />
                      {data.curriculum_name}
                    </AddLink>
                    <AssignmentDueDate data={data} />
                    <p className="text-gray-600">
                      <span className="font-semibold text-black">Assigned to:</span> {this.getAssignedToLink(data)}
                    </p>
                    <p className="text-gray-600">
                      <span className="font-semibold text-black">Created:</span> {moment(data.created_at).format('MM/DD/YY')}
                    </p>
                  </div>
                </div>
              </div>
              <GraphTableToggle onChange={setGraphView} checked={graphView} />
            </div>
          </div>
        </div>
      );
    }
  }
);
