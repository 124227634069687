import React from 'react';
import DropdownFilter from '../Dropdown/DropdownFilter';
import { GOAL_OUTCOME_NICE_NAMES } from '../../constants';
import Sticky from '../Sticky/Sticky';
import If from '../If/If';

// Outcome options are a reverse of GOAL_OUTCOME_NICE_NAMES
const outcomeOptions = Object.entries(GOAL_OUTCOME_NICE_NAMES).map(([key, value]) => ({
  label: value,
  value: key,
  text: value,
}));

const statusOptions = [
  { label: 'Active', value: 'active', text: 'Active' },
  { label: 'Upcoming', value: 'upcoming', text: 'Upcoming' },
  { label: 'Completed', value: 'completed', text: 'Completed' },
];

function GoalsFilters({
  className,
  isLoading,
  ownerOptions,
  ownerValues,
  onOwnerFilterChange,
  isOwnerDisabled,
  outcomeValues,
  onOutcomeFilterChange,
  isOutcomeDisabled,
  statusValue,
  onStatusFilterChange,
  isStatusDisabled,
}) {
  return (
    <Sticky unStickyClasses={`${className}`} stickyClasses="mb-4 py-2 border-b border-gray-400">
      <p className="mb-2 text-lg font-semibold">Filters:</p>
      <div className="grid grid-cols-12 gap-4">
        <If condition={onStatusFilterChange}>
          <div className="col-span-12 md:col-span-6 lg:col-span-2">
            <DropdownFilter
              ariaLabel="Filter by Status"
              name="filter-goals-status"
              placeholder="Status"
              options={statusOptions}
              value={statusValue || 'active'}
              onChange={({ value }) => onStatusFilterChange?.('status', value)}
              disabled={isStatusDisabled || isLoading}
              loading={isLoading}
              hideSelectedOptions={false}
            />
          </div>
        </If>
        <div className="col-span-12 md:col-span-6 lg:col-span-4">
          <DropdownFilter
            ariaLabel="Filter by owner"
            name="filter-goals"
            placeholder="Filter by Owner"
            options={ownerOptions}
            value={ownerValues}
            onChange={({ value }) => onOwnerFilterChange?.('ownerIds', value)}
            isMulti
            disabled={isOwnerDisabled || isLoading}
            loading={isLoading}
          />
        </div>
        <If condition={onOutcomeFilterChange}>
          <div className="col-span-12 md:col-span-12 lg:col-span-6">
            <DropdownFilter
              ariaLabel="Filter by outcome"
              name="filter-goals-outcome"
              placeholder="Filter by Outcome"
              options={outcomeOptions}
              value={outcomeValues}
              onChange={({ value }) => onOutcomeFilterChange?.('outcomeTypes', value)}
              isMulti
              disabled={isOutcomeDisabled || isLoading}
              loading={isLoading}
            />
          </div>
        </If>
      </div>
    </Sticky>
  );
}
export default GoalsFilters;
