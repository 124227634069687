import React, { useState, useEffect } from 'react';
import Bugsnag from '@bugsnag/js';
import { observer, inject } from 'mobx-react';
import { Fade } from 'react-awesome-reveal';
import ProfileHeaderSection from '../../components/Profile/ProfileHeaderSection/ProfileHeaderSection';
import ProfileStreaksAndSocials from '../../components/Profile/ProfileStreaksAndSocials/ProfileStreaksAndSocials';
import ProfileTabs from '../../components/Profile/ProfileTabs/ProfileTabs';
import Container from '../../components/Container/Container';
import If from '../../components/If/If';
import EditProfile from '../../components/Profile/EditProfile/EditProfile';
import { useProfileContext } from '../../providers/ProfileProvider';
import Loading from '../../components/Loading/Loading';
import ActionUtil from '../../utils/actionsUtil';
import useQueryParams from '../../hooks/useQueryParams';

function certificationOptionTransformer(certItems = []) {
  return certItems?.map((item) => {
    return { id: item.id, text: item.title, value: item.title, url: item.url, thumbnailUrl: item.thumbnail_url };
  });
}

/**
 * @param {object} userStore - the user store where the user's streak data exists
 * @param {object} certificateStore - the certificate store where the user's cert of completion data exist
 * @param {object} commonStore - the common store where the toast function exists
 * @param {object} searchStore - the search store where the certification prep data exists to be used for the certification options
 * @returns <ProfilePage /> the new profile page
 */
const Profile = inject(
  'userStore',
  'certificateStore',
  'commonStore',
  'searchStore'
)(
  observer(({ userStore, certificateStore, commonStore, searchStore }) => {
    /** two active views: profile and edit-profile */
    const [activeView, setActiveView] = useState('profile');
    const [isStatsLoading, setIsStatsLoading] = useState(false);

    /**  used for certification options */
    const { searchItems } = searchStore || {};
    const { certificationPrep } = searchItems?.data || {};
    /**  The actual certification prep items (algolia hits) */
    const certificationPrepItems = certificationPrep?.hits || [];
    const transformedOptions = certificationOptionTransformer(certificationPrepItems);
    const { isLoading, personalProfileData, certificationsProfileData, badges, isPublic } = useProfileContext();

    const { edit } = useQueryParams();

    const { userProfileStats } = userStore || {};
    const {
      daily_activity_streak = 0,
      completed_courses,
      completed_labs,
      completed_learning_hours,
      completed_assessments,
      level,
      experience_points,
      experience_points_taxonomy: xpTaxonomy,
    } = userProfileStats || {};

    useEffect(() => {
      commonStore.setPageTitle('Profile | Cybrary');
      certificateStore.getCertificates();

      /** load search items on mount */
      searchStore.loadSearchItems({ hitsPerPage: 100 });

      ActionUtil.scrollToTop();

      if (edit) {
        setActiveView('edit-profile');
      }

      /** get user profile stats */
      (async () => {
        /** make sure we have userProfileStats loaded first before setting loading to false */
        if (!userProfileStats) {
          try {
            setIsStatsLoading(true);
            await userStore.loadUserProfileStats();
          } catch (error) {
            Bugsnag.notify(error, 'Error loading user profile stats');
          } finally {
            setIsStatsLoading(false);
          }
        }
      })();
    }, []);

    const {
      avatar_url = '',
      firstName = '',
      lastName = '',
      location = '',
      bio = '',
      username = '',
      pronouns = '',
      facebook = '',
      instagram = '',
      linkedin = '',
      github = '',
      twitter = '',
      website = '',
      tagline = '',
    } = personalProfileData || {};

    const handleClickShare = () => {
      commonStore.resetShareComponent();
      commonStore.triggerShareComponent({
        permalink: username,
        prefix: 'profile',
        type: 'profile',
        title: username,
        username,
      });
    };

    /** Certification Completion Tab data */
    const { certificates } = certificateStore;
    const isCertsLoading = certificateStore.isCertificateLoading;
    const isProfileView = activeView === 'profile';
    const isEditProfileView = activeView === 'edit-profile';

    if (isLoading)
      return (
        <Container size="md">
          <Loading message="Loading profile..." />
        </Container>
      );

    return (
      <Container size="md">
        <If condition={isProfileView}>
          <Fade triggerOnce cascade damping={0.3}>
            <div id="profile-header-section" className="mt-16">
              <ProfileHeaderSection
                userName={username}
                firstName={firstName}
                lastName={lastName}
                avatarUrl={avatar_url}
                pronouns={pronouns}
                websiteUrl={website}
                role={tagline}
                userLocation={typeof location === 'string' ? location : location?.format}
                handleClick={() => setActiveView('edit-profile')}
                isPublic={isPublic}
                isEditable
              />
            </div>
            <div id="streak-and-socials-container" className="mt-4">
              <ProfileStreaksAndSocials
                currentStreak={daily_activity_streak}
                linkedin={linkedin}
                facebook={facebook}
                twitter={twitter}
                github={github}
                instagram={instagram}
                currentLevel={level}
                currentXp={experience_points}
                handleClickShare={handleClickShare}
              />
            </div>
            {!!bio && (
              <div id="profile-bio-container" className="mt-4">
                <p id="profile-bio">{bio}</p>
              </div>
            )}
          </Fade>
        </If>
        <If condition={isEditProfileView}>
          <div id="edit-profile-container" className="mt-16">
            <Fade triggerOnce>
              <EditProfile handleCloseEditForm={() => setActiveView('profile')} />
            </Fade>
          </div>
        </If>
        {/** Everything below the header */}
        <If condition={!isEditProfileView}>
          <div id="tabs-container" className="mt-4">
            <ProfileTabs
              certificationsProfileData={certificationsProfileData}
              certificates={certificates}
              isCertsLoading={isCertsLoading}
              completedCourses={completed_courses}
              completedLearningHours={completed_learning_hours}
              completedLabs={completed_labs}
              passedAssessments={completed_assessments}
              isStatsLoading={isStatsLoading}
              certOptions={transformedOptions}
              isEditable
              xpTaxonomy={xpTaxonomy}
              badges={badges}
              username={username}
            />
          </div>
        </If>
      </Container>
    );
  })
);

export default Profile;
