import React, { useEffect, useMemo } from 'react';
import { observer, inject } from 'mobx-react';
import moment from 'moment';
import Icon from '../Icon/Icon';
import ContentHero from './ContentHero';
import ContentBody from './ContentBody';
import FeedbackModal from '../Feedback/FeedbackModal';
import FormatUtil from '../../utils/formatUtil';
import BrowseUtil from '../../utils/browseUtil';
import StyleUtil from '../../utils/styleUtil';
import PermalinkUtil from '../../utils/permalinkUtil';
import InstructorCard, { VendorCard } from '../Item/InstructorCard';
import GoalSection from './GoalSection';
import ContentBadge from './ContentBadge';
import If from '../If/If';

function IncludedInList({ items, niceName }) {
  if (!items || !items.length || !items.map) {
    return null;
  }

  return (
    <>
      <div className="mt-2 mb-1 font-semibold text-black">{niceName}</div>
      <ul className="list-inside">
        {items.map((item) => {
          return (
            <li key={item.title}>
              <a className="text-black hover:text-cyb-pink-500 underline hover:underline" href={PermalinkUtil.formatInApp(item.permalink, item.type)}>
                {item.title}
              </a>
            </li>
          );
        })}
      </ul>
    </>
  );
}

function ComprehensiveLearning({ comprehensiveCourses, comprehensiveCareerPaths }) {
  if ((!comprehensiveCourses || !comprehensiveCourses.length) && (!comprehensiveCareerPaths || !comprehensiveCareerPaths.length)) {
    return null;
  }

  return (
    <div className="mt-4 mb-8">
      <h3 className="text-2xl font-black">Comprehensive Learning</h3>
      <p className="mb-4 text-gray-600">See the full benefits of our immersive learning experience with interactive courses and guided career paths.</p>
      <IncludedInList items={comprehensiveCareerPaths} niceName="Career Paths" />
      <IncludedInList items={comprehensiveCourses} niceName="Courses" />
    </div>
  );
}

function getCollectionPageCtaText(type, item) {
  switch (item?.nice_name) {
    case 'Certification Prep':
      return `This ${FormatUtil.formatContentType(type)} is part of Certification Prep for:${' '}`;
    case 'Cybrary Select':
      return `This ${FormatUtil.formatContentType(type)} is part of a Cybrary Select Path:${' '}`;
    default:
      return `This ${FormatUtil.formatContentType(type)} is part of a ${item.nice_name}:${' '}`;
  }
}

function Collection({ collection, type }) {
  if (!collection || !collection.length) return null;

  // remove any duplicate collections by permalink
  const deDuppedCollection = FormatUtil.dedupArrayOfObjects(collection, 'permalink');

  // map over array, make sure no duplicates, make sure no TAP... only Career Path and Certification Prep
  return deDuppedCollection.map((item, index) => {
    const href = `/browse/${item.permalink}`;

    // if there is more then one collection, don't add the padding top
    const paddingTop = index > 0 ? '' : 'pt-12';
    const collectionText = getCollectionPageCtaText(type, item);

    // return all dedupped collections that aren't Threat Actor Path
    return item.nice_name !== 'Threat Actor Path' ? (
      <div key={item.permalink} className={`${paddingTop} mb-4 lg:mb-0`}>
        <p className="flex items-center pt-4">
          <Icon name="road" className="mr-2 w-6 h-6" />
          <div className="flex items-center">
            <p className="pr-2 mb-0">{collectionText}</p>
            <a href={href} className="ml-1 text-black hover:text-cyb-pink-500 underline hover:underline">
              {item.title}
            </a>
          </div>
        </p>
      </div>
    ) : null;
  });
}

function ProviderCardWrapper({ provider }) {
  if (provider.name === 'Cybrary') {
    return null;
  }
  if (provider.type === 'Instructor') {
    return (
      <InstructorCard
        key={provider.name}
        type={provider.type}
        name={provider.name}
        thumbnail={provider.image || provider.thumbnail}
        permalink={provider.permalink}
        title={provider.title}
        short_bio={provider.short_bio}
        instructor_level={provider.instructor_level || provider.type}
      />
    );
  }
  if (['Vendor', 'Alliance'].indexOf(provider.type) !== -1) {
    return <VendorCard key={provider.name} name={provider.name} image={provider.image || provider.thumbnail} href={provider.permalink} shortDescription={provider.short_bio} />;
  }
  return null;
}

function ProvidersSections({ providers }) {
  if (!providers || !providers.length) {
    return null;
  }

  const providersObj = {
    instructors: [],
    alliances: [],
    voiceOverArtists: [],
    others: [],
  };
  // Get each provider type in own array
  providers.forEach((provider) => {
    let providerType = 'others';
    if (provider.type && provider.type === 'Instructor' && provider.instructor_level !== 'Voice Over Artist') {
      // Display Instructor as Instructor
      if (!providersObj.instructors.length) {
        providersObj.instructors.push(
          <h3 key="instructed by" className="text-2xl font-black">
            Instructor
          </h3>
        );
      }
      providerType = 'instructors';
    } else if (provider.type && provider.type === 'Instructor' && provider.instructor_level === 'Voice Over Artist') {
      // Display Instructor as Voice Over Artist if Instructor Level is Voice Over Artist
      if (!providersObj.voiceOverArtists.length) {
        providersObj.voiceOverArtists.push(
          <h3 key="voice over by" className="text-2xl font-black">
            Voice Over Artist
          </h3>
        );
      }
      providerType = 'voiceOverArtists';
    } else if (provider.type && provider.type === 'Alliance') {
      // Display Alliance as Provider
      if (!providersObj.alliances.length) {
        providersObj.alliances.push(
          <h3 key="delivered by" className="text-2xl font-black">
            Provider
          </h3>
        );
      }
      providerType = 'alliances';
    } else if (!providersObj.others.length && provider.name !== 'Cybrary') {
      // Display Learning Partner Label for vendors except when Cybrary is Vendor...
      providersObj.others.push(
        <h3 key="learning partner" className="text-2xl font-black">
          Learning Partner
        </h3>
      );
    }
    providersObj[providerType].push(<ProviderCardWrapper provider={provider} key={provider.name} />);
  });
  return Object.keys(providersObj).map((provider) => {
    if (!providersObj[provider].length) {
      return null;
    }
    return (
      <div className="mt-4 mb-8" key={provider}>
        {providersObj[provider]}
      </div>
    );
  });
}

function DynamicCert({ groupsInfo }) {
  const certCopy = BrowseUtil.getCertifications();
  const onlyCertifications = BrowseUtil.checkForCertifications(groupsInfo);
  const newCertCopy = onlyCertifications.length ? BrowseUtil.omitDynamicCertifications(onlyCertifications, certCopy) : null;
  if (newCertCopy && newCertCopy.length) {
    return (
      <div className="mt-4 mb-8">
        <h3 className="text-2xl font-black">Certification Body</h3>
        {newCertCopy.map((cert) => {
          return <VendorCard key={cert.name} name="Certification Body" image={cert.imgPath} />;
        })}
      </div>
    );
  }
  return null;
}

function CourseComponentsWrapper({ hasMultipleModalities, modalities, userIsFree, courseIsFree }) {
  if (!hasMultipleModalities || !modalities) {
    return null;
  }
  return (
    <div className="mt-4 mb-8">
      <h3 className="text-2xl font-black">Course Components</h3>
      <CourseComponents modalities={modalities} userIsFree={userIsFree} courseIsFree={courseIsFree} />
    </div>
  );
}

function CourseComponents({ modalities, userIsFree, courseIsFree }) {
  return Object.keys(modalities).map((modality, idx) => {
    const modalityData = modalities[modality];
    const modalityMeta = FormatUtil.getModalityMeta(modality);
    if (!modalityMeta.description) {
      return null;
    }
    let borderPink = false;
    if (modality === 'Video') {
      // If this modality is a Video and the course is NOT free, but user is = pink icon
      if (!courseIsFree && userIsFree) {
        borderPink = true;
      }
    } else if (modalityData.hasPaidActivity && userIsFree) {
      // If this modality is NOT a Video and modality has a paid activity, but user is free = pink icon
      borderPink = true;
    }
    return (
      <React.Fragment key={modality}>
        <div className="mt-4 mb-8">
          <div className="grid grid-cols-12 gap-4">
            <div className="flex col-span-4 justify-center items-center">
              <div className={`w-12 h-12 rounded-full flex items-center justify-center bg-gray-600 ${borderPink ? 'border-2 border-cyb-pink-500' : ''}`}>
                <Icon name={modalityMeta.icon} className="w-6 h-6 text-white" />
              </div>
            </div>
            <div className="flex col-span-8 items-center text-sm">
              <span>{modalityMeta.description}</span>
            </div>
          </div>
        </div>
        {idx !== Object.keys(modalities).length - 1 ? <div className="w-full h-1 border-b-xs border-gray-400" /> : null}
      </React.Fragment>
    );
  });
}

function CertCompletion() {
  return (
    <div className="mt-4 mb-8">
      <h3 className="text-2xl font-black">Certificate of Completion</h3>
      <div className="p-4 my-4 rounded-sm border-xs border-gray-400">
        <img
          height="200"
          width="300"
          src="https://images.ctfassets.net/kvf8rpi09wgk/7a9Hi5dmSSnzuNJqMTcqUC/6fc52822c1bfab1989c1ed3949c2f390/Cybrary-Cert.png?h=400"
          alt=""
          className="mx-auto"
        />
        <p className="pt-2 text-gray-600">This course offers a certificate after completion of the entire course</p>
      </div>
    </div>
  );
}

const BrowsePromo = inject(
  'userStore',
  'authStore',
  'commonStore',
  'catalogStore',
  'enrollmentStore',
  'certificateStore'
)(
  observer(({ userStore, catalogStore, certificateStore, item, type, provider, learningActivities, certificationOfferText, enrollmentStore, contentTypeDurations }) => {
    const { courseProgress } = certificateStore;

    useEffect(() => {
      if (item?.id) {
        enrollmentStore.getEnrollmentData(item?.id); // Get Enrollment data using ID
      }
    }, [item?.id]);

    const typeStyle = useMemo(() => {
      const fallbackBgImage = item?.thumbnail_url ? `${item?.thumbnail_url}?w=800&q=55` : null;
      const metaImage = item?.content_item?.meta?.backgroundImage || fallbackBgImage;
      return StyleUtil.getTypeStyle(type, metaImage);
    }, [item, type]);

    const isComingSoon = item?.status && item.status === 'Coming Soon';

    const noInstructor = useMemo(() => {
      if (!provider?.length) {
        return true;
      }
      return provider.some((providerItem) => providerItem.type && providerItem.type === 'Instructor');
    }, [provider]);

    const description = item?.post_content || item?.long_description || item?.short_description;
    const shortDescription = FormatUtil.formatLongText(item.short_description, 350);
    const fallbackBackgroundImage = item?.thumbnail_url ? `${item?.thumbnail_url}?w=800&q=55` : null;
    const backgroundImage = !!typeStyle && typeStyle.backgroundImage ? typeStyle.backgroundImage : fallbackBackgroundImage;
    // If we have a cert, or we are coming soon with no instructor, we are displaying a second column
    const showSecondColumn = !!(certificationOfferText || isComingSoon || !noInstructor || provider.length);
    const groupsInfo = item.groups_info ? item.groups_info : null;
    const activityTypes = FormatUtil.getActivityTypes(learningActivities);
    if (Object.keys(activityTypes).indexOf('Article Activity') > -1) {
      // We don't care about articles in this case
      delete activityTypes['Article Activity'];
    }
    const modalities = FormatUtil.getModalitiesByContentType(activityTypes);
    const hasMultipleModalities = Object.keys(modalities).length > 1;
    const { isFree } = userStore;
    const { comprehensiveLearningCourses, comprehensiveLearningCareerPaths, collection } = catalogStore;

    return (
      <div className="app-page">
        <div style={{ backgroundImage: `url(${backgroundImage})`, backgroundRepeat: 'no-repeat', backgroundPosition: 'center', backgroundSize: 'cover' }}>
          <div className="bg-black bg-gradient-to-r from-black lg:bg-black/60">
            <div className="mx-auto max-w-screen-xl">
              <ContentHero shortDescription={shortDescription} item={item} contentTypeDurations={contentTypeDurations} />
            </div>
          </div>
        </div>
        <div className="mx-auto max-w-screen-xl">
          <div className="grid grid-cols-1 lg:grid-cols-6 lg:gap-8">
            {/* DESKTOP DISPLAY */}
            <div className="hidden col-span-1 px-4 lg:block lg:col-span-4">
              <Collection collection={collection} type={type} />
              <ContentBody
                item={item}
                learningActivities={learningActivities}
                description={description}
                shortDescription={shortDescription}
                typeStyle={typeStyle}
                progress={courseProgress}
              />
            </div>
            <If condition={showSecondColumn}>
              <div className="hidden col-span-2 px-4 pt-12 lg:block">
                <ContentBadge badgeId={item.badge_id} />
                <GoalSection groupsInfo={groupsInfo} type={type} permalink={item.permalink} contentDescriptionId={item?.content_item?.content_description_id} />
                <ProvidersSections providers={provider} />
                <DynamicCert groupsInfo={groupsInfo} />
                <CourseComponentsWrapper hasMultipleModalities={hasMultipleModalities} modalities={modalities} userIsFree={isFree} courseIsFree={item?.is_free} />
                <If condition={item?.has_certificate}>
                  <CertCompletion />
                </If>
                <ComprehensiveLearning comprehensiveCourses={comprehensiveLearningCourses} comprehensiveCareerPaths={comprehensiveLearningCareerPaths} />
              </div>
            </If>
            {/* MOBILE DISPLAY */}
            <div className="block px-4 lg:hidden">
              <ContentBody
                item={item}
                progress={courseProgress}
                learningActivities={learningActivities}
                description={description}
                shortDescription={shortDescription}
                typeStyle={typeStyle}
              />
            </div>
            <If condition={showSecondColumn}>
              <div className="block px-4 lg:hidden">
                <Collection collection={collection} type={type} />
                <ContentBadge badgeId={item?.badge_id} />
                <GoalSection type={type} groupsInfo={groupsInfo} permalink={item.permalink} contentDescriptionId={item?.content_item?.content_description_id} />
                <ProvidersSections providers={provider} />
                <DynamicCert groupsInfo={groupsInfo} />
                <CourseComponentsWrapper hasMultipleModalities={hasMultipleModalities} modalities={modalities} userIsFree={isFree} courseIsFree={item?.is_free} />
                <If condition={item?.has_certificate}>
                  <CertCompletion />
                </If>
                <ComprehensiveLearning comprehensiveCourses={comprehensiveLearningCourses} comprehensiveCareerPaths={comprehensiveLearningCareerPaths} />
              </div>
            </If>
          </div>
          <If condition={item?.released_at_timestamp}>
            <p className="px-4 pt-8 text-xs">Released: {moment.unix(item.released_at_timestamp).format('M/D/YYYY')}</p>
          </If>
        </div>
        <FeedbackModal />
      </div>
    );
  })
);

export default BrowsePromo;
