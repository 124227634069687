import { observable, action, makeObservable } from 'mobx';
import Cookies from 'js-cookie';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import jwt from 'jwt-decode';
import Bugsnag from '@bugsnag/js';
import { makePersistable } from 'mobx-persist-store';
import AuthUtil from '../utils/authUtil';
import { DEFAULT_COOKIE_SETTINGS, GEO_COOKIE_NAME, GEO_COOKIE_PARAMS, GEO_DEFAULT_DATA, GEO_LOOKUP_URL } from '../constants';

/**
 * Store for authentication logic
 */

const AUTH_COOKIE_NAME = 'sessionToken';
const AUTH_USER_META = 'user-meta';
const AUTH_USER_TEAMS = 'user-teams';
const AUTH_LOCAL_STORAGE_KEY = 'jwt';
const AUTH_LOCAL_STORAGE_REFRESH_TOKEN = 'refreshToken';
const AUTH_ID_TOKEN_COOKIE_NAME = 'id_hint';
const ATTRIBUTION_COOKIE_NAME = 'cyb_attr';
const COOKIE_PROPS = { ...DEFAULT_COOKIE_SETTINGS, path: '/', expires: 0.25 };
const attributionApiBaseURL = `${process.env.REACT_APP_ATTRIBUTION_URL}`;

class AuthStore {
  token = window.localStorage.getItem(AUTH_LOCAL_STORAGE_KEY);

  refreshToken = window.localStorage.getItem(AUTH_LOCAL_STORAGE_REFRESH_TOKEN);

  constructor() {
    makeObservable(this, {
      token: observable,
      firebaseUser: observable,
      setFirebaseUser: action,
      getGeoInfo: action,
      getPlanRegion: action,
      fetchGeoData: action,
      refreshToken: observable,
      setToken: action,
      geoData: observable,
      enrollmentSyncStatus: observable,
      completeEnrollmentSync: action,
    });
    makePersistable(this, { name: 'AuthStore', properties: ['enrollmentSyncStatus'], storage: window.sessionStorage, debugMode: false });
    // For developing locally, when we don't have a cookie set, get
    // the jwt from local storage.
    if (!this.getToken() && window.localStorage.getItem('jwt') && AuthUtil.isDevEnvironment()) {
      this.setToken(window.localStorage.getItem('jwt'));
    }

    // For consistency's sake, set the JWT if it's not present in local storage
    if (this.getToken() && !window.localStorage.getItem('jwt')) {
      window.localStorage.setItem('jwt', this.getToken());
    }
  }

  enrollmentSyncStatus = false;

  geoData = null;

  geoDataLoading = false;

  firebaseUser = null;

  setFirebaseUser = (user) => {
    this.firebaseUser = user;
  };

  setToken = (token) => {
    this.token = token;
    window.localStorage.setItem('jwt', token);
    Cookies.set(AUTH_COOKIE_NAME, true, COOKIE_PROPS);
  };

  /**
   * Gets token from Firebase - Will either be current token, or if expired, Firebase will refresh and provide new token
   */
  getToken = async () => {
    if (!this.firebaseUser) {
      return this.token;
    }

    try {
      const token = await this.firebaseUser.getIdToken();
      this.setToken(token);
      return token;
    } catch (e) {
      Bugsnag.notify(e);
      return this.token;
    }
  };

  clearSession = () => {
    this.setToken(undefined);
    // Due to different methods of auth in other apps
    window.localStorage.removeItem('jwt');
    window.localStorage.removeItem('user-meta');
    window.localStorage.removeItem('user-teams');
    window.localStorage.removeItem('content-packages');
    Cookies.remove(AUTH_COOKIE_NAME, COOKIE_PROPS);
    Cookies.remove(AUTH_ID_TOKEN_COOKIE_NAME, COOKIE_PROPS);
    // Also remove any stray app.cybrary.it session token cookies
    Cookies.remove(AUTH_COOKIE_NAME);
    Cookies.remove(AUTH_USER_META, COOKIE_PROPS);
    Cookies.remove(AUTH_USER_TEAMS, COOKIE_PROPS);
    // Remove all preferredGroup storage items
    Object.keys(window.sessionStorage)
      .filter((item) => /^preferredGroup/.test(item))
      .forEach((item) => window.sessionStorage.removeItem(item));
  };

  completeEnrollmentSync() {
    this.enrollmentSync = true;
  }

  /**
   * Get a users Geographical information
   */
  getGeoInfo = async () => {
    if (this.geoData == null) {
      this.geoData = false;
      this.geoData = await this.fetchGeoData();
    }
    return this.geoData;
  };

  // expects geoData to be loaded, or defaults to US. Used in render() function of Checkout
  getPlanRegion = () => this.geoData?.planRegion || this.geoData?.countryCode || 'US';

  fetchGeoData = async () => {
    return new Promise((resolve) => {
      const geoCache = Cookies.get(GEO_COOKIE_NAME);
      // If we've already got the geo info, just resolve the promise with the value now
      if (geoCache) {
        const geoData = JSON.parse(geoCache);
        resolve({ ...geoData, planRegion: geoData.countryCode || 'US' });
      } else {
        // If we are still here, let's attempt to fetch the geo info
        axios({
          method: 'get',
          url: GEO_LOOKUP_URL,
          timeout: 10000, // 10 seconds timeout
          headers: {
            'Content-Type': 'application/json',
          },
        })
          .then((response) => {
            if (response && response.status === 'success') {
              const geoData = { ...response, planRegion: response?.countryCode || 'US' };
              // Cache geoData response
              Cookies.set(GEO_COOKIE_NAME, JSON.stringify(geoData), GEO_COOKIE_PARAMS);
              resolve(geoData);
            } else {
              resolve(GEO_DEFAULT_DATA);
            }
          })
          .catch((error) => {
            Bugsnag.notify(error, (event) => {
              // eslint-disable-next-line no-param-reassign
              event.context = 'GEO Location Error';
            });
            resolve(GEO_DEFAULT_DATA);
          });
      }
    });
  };

  /**
   * Return the attribution UUID for this user, setting it if necessary.
   */
  getAttributionUuid = () => {
    let uuid = Cookies.get(ATTRIBUTION_COOKIE_NAME);
    if (uuid) {
      return uuid;
    }

    const lifetime = new Date(new Date().getTime() + 24 * 3600 * 30 * 18 * 1000); // 18 months
    const options = {
      ...COOKIE_PROPS,
      expires: lifetime,
    };
    uuid = uuidv4();
    Cookies.set(ATTRIBUTION_COOKIE_NAME, uuid, options);

    return uuid;
  };

  getCookie(name) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) {
      return parts.pop().split(';').shift();
    }

    return null;
  }

  /**
   * Fire the attribution event.
   */
  fireAttributionEvent = (event, data = {}, providedUrl = null) => {
    return new Promise((resolve) => {
      // Don't fire if we don't have an attribution URL, shouldn't really happen
      if (process.env.REACT_APP_ATTRIBUTION_URL) {
        const uuid = this.getAttributionUuid();
        const url = providedUrl || window.location.href;
        const { referrer } = document;
        this.getGeoInfo()
          .then((geo) => {
            this.getToken().then((token) => {
              const countryCode = geo && geo.countryCode ? geo.countryCode : 'US';
              const eventData = {
                location: countryCode,
                event: event || 'pageview',
                url,
                _fbc: this.getCookie('_fbc'),
                _fbp: this.getCookie('_fbp'),
                referrer,
                tracking_id: uuid,
                ...data,
              };
              // Removing a circular dependency by just directly calling the attribution post
              axios({
                method: 'post',
                url: `${attributionApiBaseURL}/events`,
                data: eventData,
                headers: { Authorization: `Bearer ${token}` },
              })
                .then(() => {
                  resolve(true);
                })
                .catch(() => {
                  resolve(false);
                });
            });
          })
          .catch(() => {
            resolve(false);
          });
      } else {
        resolve(false);
      }
    });
  };

  decodeAdminPermissions(claim) {
    const user = jwt(this.token);
    const claims = user.data ? user.data.claims : user.claims;
    return claims && claims.includes(claim);
  }

  hasCybraryAdminAccess() {
    return this.decodeAdminPermissions('cybrary-admin');
  }

  hasAdminUserLookupAccess() {
    return this.decodeAdminPermissions('cybrary-user-lookup');
  }

  hasComingSoonPreviewAccess() {
    return this.decodeAdminPermissions('coming-soon-preview');
  }
}

export default new AuthStore();
