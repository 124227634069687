import React, { useState, useEffect } from 'react';
import FocusLock from 'react-focus-lock';
import { inject, observer } from 'mobx-react';
import Icon from '../Icon/Icon';
import AddLink from '../AddLink/AddLink';
import FormatUtil from '../../utils/formatUtil';
import ScreenClose from '../ScreenClose/ScreenClose';
import Header from '../Header/Header';
import If from '../If/If';
import Chip from '../Chip/Chip';
import ErrorBoundary from '../ErrorBoundary/ErrorBoundary';
import { useImmersive } from '../../providers/ImmersiveProvider';

function checkIsModuleCompleted(module) {
  for (let i = 0; i < module.activities.length; i++) {
    const activity = module.activities[i];
    // return false if any one of the activities is not completed
    if (!activity.complete) {
      return false;
    }
  }
  return true;
}

function getTopMargin(isSidebar, noTopMargin) {
  if (noTopMargin) {
    return '';
  }
  return isSidebar ? 'mt-32' : 'mt-16';
}

function Outline({ currentActivity, isSidebar, isIncompleteOutline, noTopMargin = false, disableNav = false }) {
  const [outlineDropDown, setOutlineDropDown] = useState(false);
  const [moduleIds, setModuleIds] = useState({ active: null, open: [] });
  const [activityId, setActivityId] = useState(null);
  const { item: activeItem, outlineContainerRef, scrollToOutlineContent, outline } = useImmersive();
  useEffect(() => {
    setActivityId(currentActivity);
    // find the initial module to set active
    if (!moduleIds.active) {
      const { outline: items } = outline;
      items.forEach((module) => {
        if (module.active) {
          setModuleIds({ active: module.id, open: [module.id] });
        }
      });
    }
  }, [currentActivity]);

  function handleModuleClick(id) {
    const hasModuleIndex = moduleIds.open.indexOf(id) !== -1;

    if (hasModuleIndex) {
      const openModules = moduleIds.open.filter((moduleId) => moduleId !== id);
      setModuleIds({ active: id, open: openModules });
    } else {
      setModuleIds({ active: id, open: [...moduleIds.open, id] });
    }
  }

  const outlineDropDownClasses = `block bg-white h-full w-full ${getTopMargin(isSidebar, noTopMargin)}`;

  // on mount, scroll to the active moduleId
  useEffect(() => {
    if (outlineContainerRef.current) {
      const firstActiveOutlineModule = outline.outline.find((item) => item.active);
      scrollToOutlineContent({ itemId: activeItem?.content_description_id, moduleId: firstActiveOutlineModule.id });
    }
  }, [outlineDropDown]);

  return (
    <>
      <ScreenClose isOpen={outlineDropDown} handleClose={() => setOutlineDropDown(false)} />
      <FocusLock autoFocus={false} disabled={!outlineDropDown} returnFocus>
        <div id="outline-dropdown" className={outlineDropDownClasses}>
          <ActivityListWrapper
            activityId={activityId}
            moduleIds={moduleIds}
            handleModuleClick={(id) => handleModuleClick(id)}
            isIncompleteOutline={isIncompleteOutline}
            disableNav={disableNav}
          />
        </div>
      </FocusLock>
    </>
  );
}

function getContentSubtitles(content) {
  const subtitles = [];

  // Type
  const type = content?.content_type?.nice_name;
  if (type) {
    subtitles.push(type);
  }
  // XP
  const xp = content.xp || content.experience_points_total;
  if (xp) {
    subtitles.push(`${xp} XP`);
  }
  // Duration
  const duration = content.duration || content.duration_seconds;
  if (duration) {
    subtitles.push(FormatUtil.formatTime(duration, 'hma'));
  }
  // Topic
  const topic = content.terms_info?.find((term_info) => term_info.startsWith('Topics|'))?.split('|')[1];
  if (topic) {
    subtitles.push(topic);
  }
  // Optional
  if (content.optional) {
    subtitles.push('Optional');
  }
  return subtitles;
}

function ModuleTitle({ module, openModule, index, active, isOpen, isIncompleteOutline, courseTitle }) {
  const { id: moduleId, title, duration, experience_points_total } = module;
  const isActive = active === moduleId;
  const isModuleCompleted = checkIsModuleCompleted(module);
  const incompleteOutlineModule = isIncompleteOutline ? '' : 'group-hover:text-black';
  const moduleClass = `${isActive && !isIncompleteOutline ? 'text-black font-extrabold' : `text-gray-600 ${incompleteOutlineModule} font-bold`} text-left text-lg mr-3`;

  return (
    <button
      aria-label={title}
      aria-controls={`activities-${index + 1}`}
      aria-expanded={isOpen}
      onClick={() => openModule(moduleId)}
      className={`flex items-center justify-between w-full pl-7 pr-4 ${!isIncompleteOutline ? 'cursor-pointer' : ''} group`}
    >
      <div>
        <div className="flex items-center mb-2 text-2xs leading-3">
          <span className="font-semibold text-gray-600 uppercase">Module {index + 1}</span>
          {!!experience_points_total && <span className="pl-1 ml-1 text-gray-600 border-l-xs border-gray-400">{experience_points_total} XP</span>}
          {!!duration && <span className="pl-1 ml-1 text-gray-600 border-l-xs border-gray-400">{FormatUtil.formatTime(duration, 'hma')}</span>}
          {isModuleCompleted && (
            <span className="pl-1 ml-1">
              <Icon accessibilityProps={{ focusable: false, 'aria-label': 'Completed' }} name="check" className="w-5 h-5 text-green-500" />
            </span>
          )}
        </div>
        <If condition={!!title && title !== courseTitle}>
          <p className={moduleClass}>{title}</p>
        </If>
      </div>
      {!isIncompleteOutline && (
        <Icon
          accessibilityProps={{ focusable: false, 'aria-label': isOpen ? 'Open' : 'Closed' }}
          name={isOpen ? 'chevron-up' : 'chevron-down'}
          className="flex-none mr-5 ml-auto w-5 h-5 text-gray-600 group-hover:text-black"
        />
      )}
    </button>
  );
}

function ActivityIconComponent({ userStore, active, activity, disableNav = false, isComingSoon = false }) {
  const isLicensedForContent = userStore.checkPermissions(activity);
  let activityIcon = isLicensedForContent ? 'circle' : 'lock';
  const isActive = active === activity.id;
  let iconClasses = isActive && !activity.isFree ? 'text-black' : 'text-gray-400';

  if (disableNav && !isActive) {
    activityIcon = 'lock';
  }
  if (isComingSoon) {
    activityIcon = 'clock';
    iconClasses = 'text-gray-600';
  }
  return (
    <div>
      {activity.complete ? (
        <Icon accessibilityProps={{ focusable: false, 'aria-label': 'Completed' }} name="check" className="mr-2 -ml-1 w-5 h-5 text-green-500" />
      ) : (
        <Icon accessibilityProps={{ focusable: false, 'aria-label': activity.isFree ? 'Incomplete' : 'Locked' }} name={activityIcon} className={`h-4 w-4 mr-2 ${iconClasses}`} />
      )}
    </div>
  );
}

const ActivityIcon = inject('userStore')(observer(ActivityIconComponent));

function ActivityList({ activities, moduleIndex, active, isIncompleteOutline, disableNav = false, isComingSoon = false }) {
  const moduleNumber = moduleIndex ? moduleIndex + 1 : 1;

  return (
    <ul id={`activities-${moduleNumber}`} className="mt-3">
      {activities.map((activity) => {
        const activeActivity = active === activity.id;
        // Links are either disabled or real, based on disableNav property.  Disabled links are just null returning functions
        let linkProperties = disableNav ? { onClick: () => null } : { to: activity.href || activity.url };
        if (isComingSoon) {
          linkProperties = { onClick: () => null, title: 'This content is still being worked on, check back soon!' };
        }

        let activityClasses = 'text-gray-600 hover:bg-gray-200 hover:text-black hover:font-semibold';
        if (activeActivity && !isIncompleteOutline) {
          activityClasses = 'text-black hover:text-black bg-gray-200 font-semibold';
        }
        if (!activeActivity && (disableNav || isComingSoon)) {
          activityClasses += ' cursor-not-allowed';
        }

        const subtitles = getContentSubtitles(activity);
        return (
          <li key={activity.id}>
            <ErrorBoundary FallbackComponent={ErrorBoundary.FallbackComponents.FallbackDisplaySmall}>
              <AddLink {...linkProperties} className={`w-full flex flex-col ${activityClasses} text-sm py-4 pl-7 pr-4 hover:bg-gray-200`}>
                <div className="flex items-start">
                  <ActivityIcon active={active} activity={activity} disableNav={disableNav} isComingSoon={isComingSoon} />
                  <span className="truncate">{activity.title}</span>
                </div>
                {isComingSoon && <span className="mt-0 ml-6 text-2xs italic text-cyb-pink-500">Coming Soon</span>}
                {!!subtitles?.length && (
                  <div className={`flex items-center ${activeActivity ? 'text-black' : 'text-gray-600'} text-2xs ml-6 mt-0`}>
                    <span>{subtitles.join(' | ')}</span>
                  </div>
                )}
              </AddLink>
            </ErrorBoundary>
          </li>
        );
      })}
    </ul>
  );
}

function ActivityListWrapper({ activityId, moduleIds, handleModuleClick, isIncompleteOutline, disableNav = false }) {
  const { addCollectionItemContainerRef, addModuleContainerRef, outline } = useImmersive();
  const items = isIncompleteOutline ? outline.incompleteOutline : outline.outline;
  const isCollectionOutline = outline?.collectionOutline;
  const normalizedCollectionOutline = isCollectionOutline ? outline.collectionOutline : [{ ...outline, outline: items }];

  return (
    <>
      {normalizedCollectionOutline.map((courseOutline) => {
        const isComingSoon = courseOutline?.status === 'Coming Soon';
        const type = courseOutline?.content_type?.nice_name;
        const length = FormatUtil.formatTime(courseOutline?.duration_seconds || 0, 'hma');
        const subtitles = [type, length].filter((thingy) => !!thingy);
        const topic = courseOutline?.terms_info?.find((term_info) => term_info.startsWith('Topics|'))?.split('|')[1];
        let bgImgUrl = courseOutline?.content_item?.meta?.backgroundImage;
        if (bgImgUrl) {
          bgImgUrl = `${bgImgUrl}?w=400`;
        }
        return (
          <>
            <div
              className="sticky top-32 py-2 mt-0 bg-center bg-cover lg:top-16"
              style={{
                backgroundImage: `url(${bgImgUrl})`,
                backgroundColor: `rgba(0, 0, 0, ${bgImgUrl ? 0.4 : 1})`,
                backgroundBlendMode: 'overlay',
                backgroundSize: '100%',
              }}
              key={courseOutline.id}
            >
              <ErrorBoundary FallbackComponent={ErrorBoundary.FallbackComponents.FallbackDisplaySmall}>
                <div className="flex justify-between items-center space-x-2">
                  {/** Subtitles */}
                  <If condition={!!subtitles?.length}>
                    <div className="flex items-center pt-1.5 pl-4 text-xs text-white">
                      <span>{subtitles.join(' | ')}</span>
                    </div>
                  </If>
                  <div className="flex items-center pt-1 pr-4 space-x-2">
                    {/** Topic */}
                    <If condition={!!topic}>
                      <Chip color="gray" className="text-2xs rounded-none">
                        {topic}
                      </Chip>
                    </If>
                  </div>
                </div>
                <Header as="h2" className="pb-1 pl-4 mt-2 mb-0 text-xl font-extrabold text-white">
                  {courseOutline?.title}
                </Header>
              </ErrorBoundary>
            </div>
            <div ref={addCollectionItemContainerRef} data-collection-item-id={courseOutline.id} key={courseOutline.id}>
              {courseOutline?.outline?.map((content, index) => {
                if (['Cybrary Live Session', 'VideoActivity', 'Video Activity', 'video'].includes(content.type)) {
                  return (
                    <div ref={addModuleContainerRef} key={content.id} data-module-id={content.id}>
                      <ActivityList
                        disableNav={disableNav}
                        isComingSoon={isComingSoon}
                        key={content.id}
                        activities={content.activities}
                        active={activityId}
                        isIncompleteOutline={isIncompleteOutline}
                      />
                    </div>
                  );
                }
                const isOpenModule = isIncompleteOutline ? true : moduleIds.open.indexOf(content.id) !== -1;
                return (
                  <div key={content.id} className="pt-6 pb-4" ref={addModuleContainerRef} data-module-id={content.id}>
                    <ErrorBoundary FallbackComponent={ErrorBoundary.FallbackComponents.FallbackDisplaySmall}>
                      <ModuleTitle
                        index={index}
                        module={content}
                        openModule={handleModuleClick}
                        active={moduleIds.active}
                        isOpen={isOpenModule}
                        isIncompleteOutline={isIncompleteOutline}
                        itemId={courseOutline.id}
                        courseTitle={courseOutline.title}
                      />
                      {content.activities && content.activities.length && isOpenModule && (
                        <ActivityList
                          disableNav={disableNav}
                          isComingSoon={isComingSoon}
                          activities={content.activities}
                          moduleIndex={index}
                          active={activityId}
                          isIncompleteOutline={isIncompleteOutline}
                        />
                      )}
                    </ErrorBoundary>
                  </div>
                );
              })}
            </div>
          </>
        );
      })}
    </>
  );
}

export default Outline;
