import React, { useEffect, useState } from 'react';
import { observer, inject } from 'mobx-react';
import Cookies from 'js-cookie';
import queryString from 'query-string';
import Bugsnag from '@bugsnag/js';
import { useParams, useNavigate } from 'react-router-dom';
import Loading from '../../components/Loading/Loading';
import Segment from '../../components/Segment/Segment';
import Agents from '../../agents/agents';
import Container from '../../components/Container/Container';

const TeamInvitePage = inject(
  'authStore',
  'commonStore'
)(
  observer(({ authStore, commonStore }) => {
    const { uuid, key } = useParams();
    const navigate = useNavigate();

    const [message, setMessage] = useState('Joining team...');

    useEffect(() => {
      authStore.fireAttributionEvent();

      const queryParams = queryString.parse(window.location.search);
      if (!uuid || !key) {
        navigate('/?refreshUser=1');
      } else {
        const data = {
          inviteToken: key,
          teamPermalink: uuid,
          source: queryParams.source || null,
        };

        Cookies.remove('team_invite', { path: '/', domain: '.cybrary.it' });
        setMessage('Joining team...');

        Agents.enterprise
          .joinTeam(data)
          .then(async (res) => {
            commonStore.triggerToast('success', { content: `Successfully joined team: ${res.name}` });
            setMessage('Loading your content...');
            // Hard refresh the page to get fresh user data
            window.location = '/';
          })
          .catch((err) => {
            commonStore.triggerToast('error', { content: `Unable to join team at this time.` });
            Bugsnag.notify(err);
            navigate('/?refreshUser=1&error=join-team');
          });
      }
    }, [authStore, uuid, key, navigate]);

    return (
      <Container>
        <Segment className="my-16 border-none">
          <Loading message={message} />
        </Segment>
      </Container>
    );
  })
);

export default TeamInvitePage;
