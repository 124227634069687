import React, { useMemo } from 'react';
import { twMerge } from 'tailwind-merge';
import { Fade } from 'react-awesome-reveal';
import Container from '../Container/Container';
import Header from '../Header/Header';
import Icon from '../Icon/Icon';
import If from '../If/If';
import Button from '../Button/Button';
import Price from '../Checkout/Price';
import LoadingText from '../Loading/LoadingText';

function PackageHighlight({ hasAccess, description }) {
  const iconName = hasAccess ? 'check' : 'x';
  const iconClasses = hasAccess ? 'text-cyb-green-600' : 'text-cyb-red-600';
  return (
    <li className="flex items-center mb-2">
      <Icon name={iconName} className={twMerge('mr-2 w-4 h-4 shrink-0', iconClasses)} />
      <p className="text-sm font-normal text-left">{description}</p>
    </li>
  );
}
/**
 * Single clickable button for use in the onboarding model steps
 * @param icon name of icon
 * @param title text shown below icon
 * @param titleClassName classes to add to the title
 * @param description text shown below title
 * @param descriptionClassName classes to add to the description
 * @param onClick callback function of button click
 * @param className classes to add to main component
 * @param iconClassName classes to add to the icon itself
 * @param membershipHighlights array of strings that describe the membership highlights
 * @param packageHighlights array of objects that describe the package highlights. Each object has two keys, hasAccess @boolean and description @string
 */
function UserOnboardingStepButton({
  icon,
  title,
  titleClassName,
  description = '',
  descriptionClassName,
  onClick,
  className,
  iconClassName,
  membershipHighlights = [],
  packageHighlights = [],
  featuresTitle = '',
}) {
  const wrapperClasses = `mb-4 lg:m-8 md:m-4 px-4 lg:px-2 xl:px-8 w-full h-full py-4 cursor-pointer rounded border 
  flex flex-col items-center bg-white border-4 border-cyb-pink-500 rounded-[24px] group transition-transform duration-300 transform hover:scale-[1.02] shrink-0`;
  const hoverClasses = 'peer-focus-visible:outline-1 peer-focus-visible:outline-blue-400 peer-focus-visible:outline peer-focus-visible:border-cyb-pink-500';
  const titleClasses = twMerge('mb-4 text-xl font-bold text-center text-black', titleClassName);
  const descriptionClasses = twMerge('text-xs', descriptionClassName);

  const hasMembershipHighlights = membershipHighlights?.length > 0;
  const hasPackageHighlights = packageHighlights?.length > 0;

  return (
    <button className={twMerge(wrapperClasses, hoverClasses, className)} aria-hidden="true" onClick={onClick} title={title}>
      <If condition={!!icon}>
        <Icon name={icon} className={twMerge('mb-4 w-24 h-24 fill-cyb-pink-500 transform transition-transform duration-300 group-hover:scale-105', iconClassName)} />
      </If>
      <p className={titleClasses}>{title}</p>

      <If condition={hasMembershipHighlights}>
        <If condition={!!featuresTitle}>
          <p className="mb-2 text-sm text-left">{featuresTitle}</p>
        </If>
        <ul className="mb-1 list-disc list-inside">
          {membershipHighlights.map((highlight) => (
            <li key={highlight} className="text-sm font-normal">
              {highlight}
            </li>
          ))}
        </ul>
      </If>
      <If condition={hasPackageHighlights}>
        <If condition={!!featuresTitle}>
          <p className="hidden mb-2 text-sm text-left sm:block">{featuresTitle}</p>
        </If>
        <ul className="hidden mb-1 sm:block h-content w-content">
          <Fade triggerOnce cascade damping={0.25}>
            {packageHighlights.map((highlight) => (
              <PackageHighlight key={highlight.description} hasAccess={highlight.hasAccess} description={highlight.description} />
            ))}
          </Fade>
        </ul>
      </If>
      <If condition={!!description}>
        <p className={descriptionClasses}>{description}</p>
      </If>
    </button>
  );
}

/**
 * Single clickable button for use in the onboarding model steps
 * @param icon name of icon
 * @param title text shown below icon
 * @param titleClassName classes to add to the title
 * @param description text shown below title
 * @param descriptionClassName classes to add to the description
 * @param onClick callback function of button click
 * @param className classes to add to main component
 * @param iconClassName classes to add to the icon itself
 * @param membershipHighlights array of strings that describe the membership highlights
 * @param packageHighlights array of objects that describe the package highlights. Each object has two keys, hasAccess @boolean and description @string
 */
function UserOnboardingStepButtonModern({
  icon,
  title,
  titleClassName,
  description = '',
  descriptionClassName,
  onClick,
  className,
  iconClassName,
  membershipHighlights = [],
  packageHighlights = [],
  featuresTitle = '',
  bannerText = '',
  buttonText = '',
  buttonClassName = '',
  buttonColor = 'pink',
  price = '',
  priceCurrencyCode = 'USD',
  priceClasses = '',
  isPriceLoading = false,
  priceSubtitle = '',
}) {
  const containerClasses =
    'w-full lg:w-auto py-1 lg:py-4 px-2 lg:px-0 xl:px-4 mb-4 lg:m-2 flex flex-col group transition-transform duration-300 transform hover:scale-[1.02] shrink-0';
  const wrapperClasses = `px-4 h-full w-full py-4 cursor-pointer flex flex-col bg-[#FBFBFB] border-1 border-gray-600 `;
  const hoverClasses = 'peer-focus-visible:outline-1 peer-focus-visible:outline-blue-400 peer-focus-visible:outline peer-focus-visible:border-cyb-pink-500';
  const titleClasses = twMerge('mb-2 text-3xl', titleClassName);
  const descriptionClasses = twMerge('text-sm text-left', descriptionClassName);
  const buttonClasses = twMerge('mb-8 w-full', buttonClassName);

  const hasMembershipHighlights = membershipHighlights?.length > 0;
  const hasPackageHighlights = packageHighlights?.length > 0;

  return (
    <div className={containerClasses}>
      {/** Banner Text */}
      <If condition={!!bannerText}>
        <div className="flex justify-between items-baseline p-2 pb-1 w-full text-white bg-cyb-pink-500 rounded-t-[24px]">
          <Header as="h2" className="mx-auto mb-0 text-xl">
            {bannerText}
          </Header>
        </div>
      </If>
      <button className={twMerge(wrapperClasses, hoverClasses, className, bannerText ? '!mt-0' : 'lg:!mt-11 lg:mb-6')} aria-hidden="true" onClick={onClick} title={title}>
        {/** Title and Icon */}
        <div className="flex justify-between items-baseline mb-4 w-full">
          <Header as="h2" className={titleClasses}>
            {title}
          </Header>
          <If condition={!!icon}>
            <Icon name={icon} className={twMerge('mb-4 w-8 h-8 fill-cyb-pink-500 transform transition-transform duration-300 group-hover:scale-105', iconClassName)} />
          </If>
        </div>

        {/** Description */}
        <If condition={!!description}>
          <p className={descriptionClasses}>{description}</p>
        </If>

        {/** Price */}
        <If condition={!!price && !isPriceLoading}>
          <Price price={price} currencyCode={priceCurrencyCode} className={priceClasses} />
          <If condition={!!priceSubtitle}>
            <p className="text-xs text-left text-gray-600">{priceSubtitle}</p>
          </If>
        </If>
        <If condition={isPriceLoading}>
          <LoadingText className={twMerge('mb-2 w-12 h-4', priceClasses)} />
        </If>

        {/** Buy Now CTA */}
        <If condition={!!buttonText}>
          <Button className={buttonClasses} onClick={onClick} color={buttonColor}>
            {buttonText}
          </Button>
        </If>

        {/** Features */}
        <If condition={hasMembershipHighlights}>
          <If condition={!!featuresTitle}>
            <p className="mb-8 ml-0 text-sm">{featuresTitle}</p>
          </If>
          <ul className="mb-1 list-disc list-inside">
            {membershipHighlights.map((highlight) => (
              <li key={highlight} className="text-sm font-normal">
                {highlight}
              </li>
            ))}
          </ul>
        </If>
        <If condition={hasPackageHighlights}>
          <If condition={!!featuresTitle}>
            <p className="hidden mb-6 text-sm text-left sm:block">{featuresTitle}</p>
          </If>
          <ul className="mb-1 ml-4 w-full h-content">
            <Fade triggerOnce cascade damping={0.25}>
              {packageHighlights.map((highlight) => (
                <PackageHighlight key={highlight.description} hasAccess={highlight.hasAccess} description={highlight.description} />
              ))}
            </Fade>
          </ul>
        </If>
      </button>
    </div>
  );
}

/**
 * Header component for use in onboarding steps
 * @param title the text to display
 */
function UserOnboardingStepTitle({ title, className }) {
  return (
    <Header as="h2" className={twMerge('px-8 pb-4 m-auto text-2xl md:text-4xl font-black text-center', className)}>
      {title}
    </Header>
  );
}
function UserOnboardingStepDescription({ description, className }) {
  return (
    <Header as="h3" className={twMerge('px-8 pb-4 m-auto text-xl md:text-2xl font-black text-center', className)}>
      {description}
    </Header>
  );
}
/**
 * Container for a single step in the onboarding flow
 * For use in the onboarding modal
 * @param title text shown at top of step
 */
function UserOnboardingStep({ title, children, className }) {
  const classes = useMemo(() => {
    return twMerge('p-6', className);
  }, [className]);
  return (
    <Fade triggerOnce>
      <Container className={classes}>
        <UserOnboardingStepTitle title={title} />
        {children}
      </Container>
    </Fade>
  );
}
/** Export child components for reuse in custom onboarding displays */
UserOnboardingStep.Button = UserOnboardingStepButton;
UserOnboardingStep.ButtonModern = UserOnboardingStepButtonModern;
UserOnboardingStep.Title = UserOnboardingStepTitle;
UserOnboardingStep.Description = UserOnboardingStepDescription;

export default UserOnboardingStep;
