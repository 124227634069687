import React, { useEffect, useState } from 'react';
import { observer, inject } from 'mobx-react';
import { useNavigate } from 'react-router-dom';
import Icon from '../Icon/Icon';
import Header from '../Header/Header';
import AdminTable from '../AdminTable/AdminTable';
import Segment from '../Segment/Segment';
import FormInviteModal from './FormInviteModal';
import OrganizationMemberTableActions from './OrganizationMemberTableActions';
import FormatUtil from '../../utils/formatUtil';
import StyledError from '../Error/StyledError';
import GroupAddMembers from './GroupAddMembers';
import CreateGroup from './CreateGroup';
import Button from '../Button/Button';
import Modal from '../Modal/Modal';
import Container from '../Container/Container';
import { formatEmailToLink, formatGroupRole, formatLicensedOnToModal, formatLicenseToDropdown, formatNameToLink } from '../../utils/tableUtil';
import If from '../If/If';

const GroupMembers = inject(
  'enterpriseStore',
  'commonStore',
  'userStore'
)(
  observer(
    ({
      enterpriseStore,
      commonStore,
      userStore,
      groupId,
      orgId,
      parentId,
      parentGroupName,
      viewOnly,
      omitDashboardLinks,
      customRoleConfirmHandler,
      customRemoveConfirmHandler,
    }) => {
      const [inviteModalOpen, setInviteModalOpen] = useState(false);
      const [addMembersModalOpen, setAddMembersModalOpen] = useState(false);
      const [editGroupModalOpen, setEditGroupModalOpen] = useState(false);

      const isDemo = orgId === 'demo';
      const navigate = useNavigate();
      useEffect(() => {
        enterpriseStore.setDefaultTeamInviteUrl();
        enterpriseStore.setDefaultTeamMemberTableData();
        if (groupId) {
          enterpriseStore.getTeamMemberTableData(groupId, false, false);
          enterpriseStore.getGroupProfileData(groupId);
        }

        return () => {
          enterpriseStore.setDefaultTeamMemberTableData();
          enterpriseStore.setDefaultGroupList();
          enterpriseStore.setDefaultGroupProfileData();
          enterpriseStore.setDefaultTeamInviteUrl();
        };
      }, [groupId]);

      const { groupProfileData, teamMemberTableData } = enterpriseStore;
      const { role, license, searchQuery } = teamMemberTableData.queryParams;

      if (groupProfileData.error) {
        return (
          <Container>
            <StyledError error={groupProfileData.error} />
          </Container>
        );
      }
      return (
        <Segment className="border-none organization-group-members">
          <div className="lg:grid lg:grid-cols-12">
            <div className="lg:col-span-9">
              <div className="flex gap-x-4 items-start">
                <Header id="group-title" as="h2">
                  {groupProfileData?.profileData?.name}
                </Header>
                <If condition={!viewOnly && userStore.team?.permissions?.canManageTeam}>
                  <button onClick={() => setEditGroupModalOpen(true)}>
                    <Icon name="edit" className="w-5 h-5 text-cyb-pink-500" />
                  </button>
                  <Modal open={editGroupModalOpen} toggle={() => setEditGroupModalOpen(false)} arialLabel="Edit group">
                    <div className="py-8 px-4">
                      <CreateGroup
                        teamId={parentId}
                        groupId={groupId}
                        view="edit"
                        modalView
                        callback={() => {
                          setEditGroupModalOpen(false);
                          enterpriseStore.getGroupProfileData(groupId);
                          enterpriseStore.getTeamMemberTableData(groupId, false, true);
                        }}
                      />
                    </div>
                  </Modal>
                </If>
              </div>
            </div>
            {!viewOnly && (
              <div className="lg:col-span-3 lg:text-right invite-btn">
                <FormInviteModal modalOpen={inviteModalOpen} modalClose={() => setInviteModalOpen(false)} groupId={groupId} />
                <Button
                  className="mr-2"
                  onClick={() => {
                    if (!enterpriseStore.teamInviteUrl.url) {
                      enterpriseStore.getTeamInviteUrl(groupId);
                    }
                    setInviteModalOpen(true);
                  }}
                >
                  + Invite to Group
                </Button>
                <Button color="gray" onClick={() => setAddMembersModalOpen(true)}>
                  Add Members
                </Button>
                <Modal open={addMembersModalOpen} toggle={() => setAddMembersModalOpen(false)} ariaLabel="Add members to group">
                  <div className="px-4 pt-12">
                    <GroupAddMembers groupId={groupId} submitCallback={() => setAddMembersModalOpen(false)} parentGroupName={parentGroupName} />
                  </div>
                </Modal>
              </div>
            )}
          </div>
          <OrganizationMemberTableActions
            groupId={groupId}
            groupScope
            customRoleConfirmHandler={customRoleConfirmHandler}
            customRemoveConfirmHandler={customRemoveConfirmHandler}
            viewOnly={!!viewOnly}
            disableSticky
          />
          <AdminTable
            headings={teamMemberTableData.tableHeadings}
            data={teamMemberTableData.tableData}
            tableLoading={teamMemberTableData.loading && !teamMemberTableData?.tableData?.length}
            loadingAsTable
            tableError={teamMemberTableData.error}
            displayCheckBox={!viewOnly}
            headerCheckBoxFunc={(e, { checked }) => {
              enterpriseStore.setAllSelectedRows('teamMemberTableData', checked);
            }}
            headerClickFunc={isDemo ? () => {} : (header) => enterpriseStore.sortTeamMemberTableData(header, groupId)}
            sortCol={teamMemberTableData.queryParams.sortCol}
            sortDirection={teamMemberTableData.queryParams.sortDirection}
            rowCheckBoxFunc={(e, { checked }, idx) => {
              enterpriseStore.setCheckboxState(enterpriseStore.teamMemberTableData, idx, checked);
            }}
            headerCheckBoxState={teamMemberTableData.headerCheckBox}
            checkBoxesState={teamMemberTableData.ckBoxesState}
            pagNumPgs={teamMemberTableData.pagPagesCount}
            pagChangePg={(page) => enterpriseStore.setTeamMemberTablePage(page, groupId)}
            pagActivePg={teamMemberTableData.queryParams.activePg}
            formatColumns={[
              {
                method: formatGroupRole,
                colIdx: FormatUtil.getColIndex(teamMemberTableData.tableHeadings, 'role'),
              },
              {
                method: omitDashboardLinks ? null : (col, row) => formatNameToLink(col, row, orgId),
                colIdx: FormatUtil.getColIndex(teamMemberTableData.tableHeadings, 'name'),
                params: [orgId],
              },
              {
                method: formatEmailToLink,
                colIdx: FormatUtil.getColIndex(teamMemberTableData.tableHeadings, 'email'),
              },
              {
                method: (col, row) => formatLicenseToDropdown(col, row, groupId, enterpriseStore, userStore),
                colIdx: FormatUtil.getColIndex(teamMemberTableData.tableHeadings, 'license'),
              },
              {
                method: (col, row) => formatLicensedOnToModal(col, row, orgId, commonStore, navigate),
                colIdx: FormatUtil.getColIndex(teamMemberTableData.tableHeadings, 'licensed_date'),
              },
            ]}
            numOfResults={teamMemberTableData.totalRecords}
            showResultsSummary={role || license || groupId || (searchQuery && searchQuery.length)}
          />
        </Segment>
      );
    }
  )
);

export default GroupMembers;
