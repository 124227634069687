import React from 'react';
import { FooterBottom } from '../Footer/Footer';
import BlankTemplate from '../BlankTemplate/BlankTemplate';
import CybraryLogo from '../CybraryLogo/CybraryLogo';

function InterstitialTemplate({ children }) {
  return (
    <>
      <div className="flex flex-col justify-between items-center py-4 h-screen">
        <div className="flex flex-col gap-y-24 items-center w-full">
          <CybraryLogo href="/" />
          {children}
        </div>
        <FooterBottom />
      </div>
      <BlankTemplate />
    </>
  );
}

export default InterstitialTemplate;
