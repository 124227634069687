import primary from './primaryNav';
import primaryDesktopRight from './primaryDesktopRight';
import secondaryDesktopRight from './secondaryDesktopRight';

const nav = {
  primary,
  primaryDesktopRight,
  secondaryDesktopRight,
};

let flattened = null;

// Look at the array of parent items and return true if the provided href is in the array under the href key
export function hasHref(items, href, level, startsWith) {
  for (let i = 0; i < items.length; i++) {
    const hrefMatches = startsWith ? href.indexOf(items[i].href) === 0 : items[i].href === href;
    if (hrefMatches && items[i].level === level) {
      return true;
    }
  }
  return false;
}

export function ensureFlattenedHrefExists(href) {
  if (!flattened[href]) {
    flattened[href] = [];
  }
}

export function flattenThirdLevel(thirdLevelItems, userStore, topLevelHref, secondLevelHref) {
  if (thirdLevelItems) {
    for (let k = 0; k < thirdLevelItems.length; k++) {
      const thirdLevelItem = thirdLevelItems[k];
      const { href: href3, hrefReplace: hrefReplace3 } = thirdLevelItem;
      const thirdLevelHref = hrefReplace3 ? hrefReplace3(href3, userStore) : href3;
      if (thirdLevelHref) {
        ensureFlattenedHrefExists(thirdLevelHref);
        // Add the top level parent
        flattened[thirdLevelHref].push({
          href: topLevelHref,
          level: 0,
        });
        // Add the second level parent
        flattened[thirdLevelHref].push({
          href: secondLevelHref,
          level: 1,
        });
      }
    }
  }
}

export function flattenSecondLevel(secondLevelItems, userStore, topLevelHref) {
  if (secondLevelItems) {
    for (let j = 0; j < secondLevelItems.length; j++) {
      const secondLevelItem = secondLevelItems[j];
      const { href: href2, children: thirdLevelItems, hrefReplace: hrefReplace2, omitFlattening } = secondLevelItem;
      if (!omitFlattening) {
        const secondLevelHref = hrefReplace2 ? hrefReplace2(href2, userStore) : href2;
        if (secondLevelHref) {
          ensureFlattenedHrefExists(secondLevelHref);
          // Add the parent to this href
          flattened[secondLevelHref].push({
            href: topLevelHref,
            level: 0,
          });
          flattenThirdLevel(thirdLevelItems, userStore, topLevelHref, secondLevelHref);
        }
      }
    }
  }
}

export function flattenedNav(userStore) {
  // Ensure this is only run once
  if (flattened) {
    return flattened;
  }
  flattened = {};
  const { items } = nav.primary;
  for (let i = 0; i < items.length; i++) {
    // Process the top level items
    const topLevelItem = items[i];
    const { href: href1, children: secondLevelItems, hrefReplace } = topLevelItem;
    const topLevelHref = hrefReplace ? hrefReplace(href1, userStore) : href1;
    ensureFlattenedHrefExists(topLevelHref);
    // Handle the second level if we have any
    flattenSecondLevel(secondLevelItems, userStore, topLevelHref);
  }
  return flattened;
}

export function checkCurrentUrl(href, startsWith, startsWithOmit, includes, excludes, location, aliases = {}) {
  const alias = aliases[location.pathname + location.search];

  const path = alias || location.pathname;

  let hrefMatches = path === href;

  if (includes) {
    // includes can be an array of strings or a single string, normalize to array and check if any match
    const includesArray = Array.isArray(includes) ? includes : [includes];
    hrefMatches = includesArray.some((includesPath) => path.includes(includesPath));
  } else if (startsWithOmit) {
    const strippedPath = path.replace(startsWithOmit, '');
    const strippedHref = href.replace(startsWithOmit, '');

    hrefMatches = strippedPath.indexOf(strippedHref) === 0;
  } else if (startsWith) {
    hrefMatches = path.indexOf(href) === 0;

    if (excludes) {
      hrefMatches = hrefMatches && excludes.every((term) => !path.includes(term));
    }
  } else if (!alias && !!path && !!location.search) {
    hrefMatches = path + location.search === href;
  }

  return hrefMatches;
}

// Return true if this nav item is currently active
export function isActive(item, level, userStore, location) {
  const { href: providedHref, startsWith, hrefReplace, startsWithOmit, includes, excludes, aliases } = item;

  const href = hrefReplace ? hrefReplace(providedHref, userStore, location) : providedHref;
  if (checkCurrentUrl(href, startsWith, startsWithOmit, includes, excludes, location, aliases)) {
    return true;
  }
  const path = location.pathname;
  // Will only run once, as flattened is statically cached
  const flattenedMap = flattenedNav(userStore);

  const parentsOfHref = flattenedMap[path] || [];

  return hasHref(parentsOfHref, href, level, startsWith);
}

export function getProfileSecondaryItems() {
  const { items } = nav.primaryDesktopRight;
  return items.filter((item) => item.label === 'Profile')[0].children;
}

export function findSecondaryNavItems(userStore, location) {
  // Look at the primary nav items, and determine if one is active...
  const { items: primaryItems } = nav.primary;
  const rightItems = getProfileSecondaryItems();
  const items = [...primaryItems, ...rightItems];
  const children = [];
  for (let i = 0; i < items.length; i++) {
    const item = items[i];
    // Pass arbitrarily high level to ensure we always get all children
    if (isActive(item, 0, userStore, location) && item.children && item.children.length) {
      children.push(...item.children);
    }
  }

  return children;
}

export function getActiveSecondaryNavItem(userStore, location) {
  // Look at the primary nav items, and determine if one is active...
  const secondaryItems = findSecondaryNavItems(userStore, location);

  for (let i = 0; i < secondaryItems.length; i++) {
    const item = secondaryItems[i];
    if (isActive(item, 1, userStore, location)) {
      return item;
    }
  }

  return null;
}

export default nav;
