import React from 'react';
import { twMerge } from 'tailwind-merge';
import { getCN } from '../../utils/formatUtil';
import './Loading.css';

function Loading({ color, message, children, className, messageClassName, wrapperClassName, square }) {
  const messageClass = getCN(twMerge(`text-md mt-2 text-gray-600 animate-pulse`, messageClassName));

  const loadingClass = getCN(`
    animate-spin rounded-full bg-transparent
    ${className || 'h-8 w-8 border-4'}
    ${(!color || color === 'black') && 'border-y-gray-400 border-x-transparent'}
    ${color === 'white' && 'border-t-gray-100 border-gray-500'}
    ${color === 'pink' && 'border-t-cyb-pink-500 border-gray-300'}
  `);

  const wrapperClass = getCN(`flex-col flex justify-center items-center py-2 ${wrapperClassName}`);

  return (
    <div className={wrapperClass}>
      {square ? <div className="loading-square" /> : <div className={loadingClass} />}
      {message && <div className={messageClass}>{message}</div>}
      {children}
    </div>
  );
}

export default Loading;
