import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { twMerge } from 'tailwind-merge';
import UserOnboardingStep from '../../UserOnboardingStep';
import { USER_ONBOARDING_STEPS, USER_ONBOARDING_OPTIONS, MEMBERSHIP_FOR_OPTIONS, useUserOnboarding } from '../../../../providers/UserOnboardingProvider';
import { isCipUser, isHereForBusinessManager, isHereForLearning } from '../../../../utils/navUtil';

/** Step shown at the start of the onboarding flow */
function MembershipForStepComponent({ userStore }) {
  const { setTeamOrCip, setStep } = useUserOnboarding();

  const handleClick = async (clickedOption) => {
    setTeamOrCip(clickedOption);
    if (clickedOption === USER_ONBOARDING_OPTIONS.membershipFor.TEAM) {
      setStep(USER_ONBOARDING_STEPS.TEAM_FORM);
    } else {
      setStep(USER_ONBOARDING_STEPS.CIP_FORM);
    }
  };

  // Auto-complete this step based on team role if any
  useEffect(() => {
    if (isHereForBusinessManager(userStore)) {
      handleClick(USER_ONBOARDING_OPTIONS.membershipFor.TEAM);
      return;
    }
    if (isHereForLearning(userStore)) {
      handleClick(USER_ONBOARDING_OPTIONS.membershipFor.CIP);
    }
    // Send all fresh CIP users to the CIP onboarding form
    if (isCipUser(userStore)) {
      handleClick(USER_ONBOARDING_OPTIONS.membershipFor.CIP);
    }
  }, [userStore?.teamData?.role]);

  return (
    <UserOnboardingStep title="Hey there, welcome to Cybrary!">
      <div className="flex flex-col-reverse justify-center items-center lg:flex-row lg:items-stretch lg:space-x-4 ">
        {Object.keys(MEMBERSHIP_FOR_OPTIONS).map((MEMBERSHIP_TYPE) => {
          const { key, title, features, icon = 'user-group', className, iconClassName, buttonText, buttonColor } = MEMBERSHIP_FOR_OPTIONS[MEMBERSHIP_TYPE];
          return (
            <UserOnboardingStep.ButtonModern
              key={key}
              title={title}
              icon={icon}
              iconClassName={iconClassName}
              onClick={() => handleClick(key)}
              className={twMerge('flex-1 h-auto lg:max-w-[380px] md:ml-0', className)}
              packageHighlights={features}
              buttonText={buttonText}
              buttonColor={buttonColor}
              titleClassName="text-2xl whitespace-nowrap"
            />
          );
        })}
      </div>
    </UserOnboardingStep>
  );
}

const MembershipForStep = inject('userStore')(observer(MembershipForStepComponent));

export default MembershipForStep;
