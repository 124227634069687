import React, { useMemo } from 'react';
import { twMerge } from 'tailwind-merge';

/**
 * A solid chip component to display small pieces of information
 * @param {string} className - additional classes to add to the component
 * @param {string} bgColor - color of the chip, i.e. 'black', 'red', 'green', etc.
 * @param {string} textColor - color of the text, i.e. 'black', 'red', 'green', etc.
 * @returns <Chip />
 */
function SolidChip({ children, className, bgColor = 'gray', textColor = 'black' }) {
  const classes = useMemo(() => {
    const classesToUse = `py-1 px-4 text-xs font-medium bg-${bgColor}-300 text-${textColor} rounded-full inline-block items-center justify-center flex h-fit`;
    return twMerge(classesToUse, className);
  }, [className, bgColor, textColor]);
  return <span className={classes}>{children}</span>;
}

export default SolidChip;
