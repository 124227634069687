import { v4 as uuidv4 } from 'uuid';
import { isAdmin, isFreeOrCipUser, isSATShown, redirectOrgOwnerAdminMember, teamHrefReplace, userIdHrefReplace } from '../utils/navUtil';

export const DASHBOARD_NAV = {
  label: 'Dashboard',
  icon: 'dashboard',
  href: '/',
  id: uuidv4(),
  group: 'main',
};

export const MEASURE_NAV = {
  label: 'Measure',
  icon: 'terminal',
  href: '/measure',
  id: uuidv4(),
  group: 'main',
  startsWith: true,
  redirectOn: (userStore) => redirectOrgOwnerAdminMember('/measure/baseline', '/measure/assessments', userStore),
  trackClickEvent: true,
  children: [
    {
      id: uuidv4(),
      label: 'Assessments',
      href: '/measure/assessments',
      trackClickEvent: true,
      startsWith: false,
    },
    {
      id: uuidv4(),
      label: 'Assessment Results',
      href: '/measure/assessments/results',
      startsWith: true,
      display: (userStore) => isAdmin(userStore) || !isFreeOrCipUser(userStore),
      trackClickEvent: true,
    },
    {
      id: uuidv4(),
      label: 'Baselining',
      href: '/measure/baseline',
      trackClickEvent: true,
      startsWith: true,
      display: (userStore) => isAdmin(userStore) || isFreeOrCipUser(userStore),
      /**
       * `dynamicDisplayType` denotes a condition in which this navigation item is dynamically displayed.
       * The container that intercepts this property should know how to implement the logic for a given type.
       *
       * This property takes precedence over the `display` property.
       *
       * @see `src/components/Navigation/SecondaryNavbar.js` for usage of this property.
       */
      dynamicDisplayType: 'TEAM_LEARNER_BASELINE_GOAL',
    },
  ],
  display: (userStore) => {
    // If the user is SAT Only, they should not see the measure nav item
    return !userStore.isSATOnly;
  },
};

export const FORUMS_NAV = {
  label: 'Forums',
  icon: 'forums',
  href: process.env.REACT_APP_FORUMS_URL,
  target: '_blank',
  id: uuidv4(),
  group: 'main',
  display: (userStore) => !userStore?.isSATOnly,
  // beta: 'isForumsBetaEnabled',
};

export const SECURITY_AWARENESS_NAV = {
  label: 'SAT',
  icon: 'shield',
  href: '/security-awareness',
  id: uuidv4(),
  group: 'main',
  display: isSATShown,
  children: [
    {
      label: 'My Training',
      href: '/security-awareness',
      display: isSATShown,
      id: uuidv4(),
      omitFlattening: true,
    },
    {
      divider: true,
      id: uuidv4(),
    },
    {
      label: 'View Team Progress',
      href: '/enterprise/:orgId/goals?outcome_type=security_awareness_training',
      hrefReplace: teamHrefReplace,
      display: (userStore) => isAdmin(userStore) && !window.location.pathname.startsWith('/enterprise/'),
      id: uuidv4(),
      omitFlattening: true,
    },
    {
      label: 'Browse SAT Content',
      href: '/browse/refined?terms_info=domains%7Csecurity-awareness',
      hrefReplace: teamHrefReplace,
      display: (userStore) => isAdmin(userStore) && !window.location.pathname.startsWith('/enterprise/'),
      id: uuidv4(),
      omitFlattening: true,
    },
    {
      label: 'Create Training',
      href: '/enterprise/:orgId/goals?action=add&outcome_type=security_awareness_training&owner_ids=:userId',
      hrefReplace: (href, userStore) => userIdHrefReplace(teamHrefReplace(href, userStore), userStore),
      display: (userStore) => isAdmin(userStore) && !window.location.pathname.startsWith('/enterprise/'),
      id: uuidv4(),
      omitFlattening: true,
    },
  ],
};

export const CAREER_PROGRAMS_NAV = {
  label: 'Career Paths',
  icon: 'career',
  type: 'dropdown',
  href: '/browse/paths/career-paths',
  id: uuidv4(),
  includes: ['/browse/paths/career-paths', '/browse/paths/skill-paths'],
  children: [
    {
      label: 'Career Paths',
      href: '/browse/paths/career-paths',
      includes: '/browse/paths/career-paths',
      id: uuidv4(),
    },
    {
      label: 'Skill Paths',
      href: '/browse/paths/skill-paths',
      includes: '/browse/paths/skill-paths',
      id: uuidv4(),
    },
  ],
  dropdown: [
    {
      label: 'Career Paths',
      type: 'link',
      href: '/browse/paths/career-paths',
      includes: '/browse/paths/career-paths',
      id: uuidv4(),
    },
    {
      label: 'Skill Paths',
      type: 'link',
      href: '/browse/paths/skill-paths',
      includes: '/browse/paths/skill-paths',
      id: uuidv4(),
    },
  ],
};

export const CERT_PREP_PROGRAMS_NAV = {
  label: 'Cert Prep',
  icon: 'certification',
  href: '/browse/paths/cert-prep-paths',
  id: uuidv4(),
  includes: '/browse/paths/cert-prep-paths',
};

export const STAY_AHEAD_NAV = {
  label: 'Stay Ahead',
  type: 'dropdown',
  icon: 'achievement',
  href: '-',
  id: uuidv4(),
  startsWith: true,
  children: [
    {
      label: 'MITRE ATT&CK',
      href: '/browse/mitre-attack',
      id: uuidv4(),
    },
    {
      label: 'Threat Actor Campaigns',
      startsWith: true,
      href: '/browse/threat-actor-campaigns',
      id: uuidv4(),
    },
    {
      label: 'CVE Series',
      href: '/browse/collection/cve-series',
      id: uuidv4(),
    },
    {
      label: 'Cybrary Challenges',
      type: 'link',
      href: '/browse/collection/cybrary-challenges',
      id: uuidv4(),
    },
    {
      label: 'OWASP Top 10',
      type: 'link',
      href: '/browse/collection/owasp-top-10',
      id: uuidv4(),
    },
  ],
  dropdown: [
    {
      label: 'MITRE ATT&CK',
      type: 'link',
      href: '/browse/mitre-attack',
      id: uuidv4(),
    },
    {
      label: 'Threat Actor Campaigns',
      type: 'link',
      href: '/browse/threat-actor-campaigns',
      id: uuidv4(),
    },
    {
      label: 'CVE Series',
      type: 'link',
      href: '/browse/collection/cve-series',
      id: uuidv4(),
    },
    {
      label: 'Cybrary Challenges',
      type: 'link',
      href: '/browse/collection/cybrary-challenges',
      id: uuidv4(),
    },
    {
      label: 'OWASP Top 10',
      type: 'link',
      href: '/browse/collection/owasp-top-10',
      id: uuidv4(),
    },
  ],
};
