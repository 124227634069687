import React, { useState } from 'react';
import { inject, observer } from 'mobx-react';
import { useParams } from 'react-router-dom';
import GroupSelect from './GroupSelect';
import Dropdown from '../Dropdown/Dropdown';
import DropdownFilter from '../Dropdown/DropdownFilter';
import SearchInput from '../Search/SearchInput';
import { getRoleOptions } from '../../utils/teamsUtil';
import If from '../If/If';
import Icon from '../Icon/Icon';
import Sticky from '../Sticky/Sticky';
import Button from '../Button/Button';
import AddLink from '../AddLink/AddLink';
import BugsnagUtil from '../../utils/bugsnagUtil';
import agents from '../../agents/agents';
import FormatUtil, { getPackageNames } from '../../utils/formatUtil';

const getLicenseOptions = (active_license_grants, selectedRowsLength, isGrant = false) => {
  return active_license_grants?.reduce((acc, grant) => {
    const freeSeats = grant.max_seats - grant.active_seats;
    // If the number of selected rows is greater than the number of available seats, disable the license option
    const isDisabled = selectedRowsLength > freeSeats;
    const title = grant.name || getPackageNames(grant.package_types);
    acc.push({
      value: grant.team_content_license_grant_id,
      text: (
        <span className="flex items-center" name={title}>
          <span className={`${isDisabled ? 'line-through' : ''} flex items-center`}>
            {isGrant ? '+' : '-'} {title}
          </span>
          {isDisabled ? (
            <span className="ml-2 text-xs text-red-600">
              Missing {selectedRowsLength - freeSeats} License{selectedRowsLength - freeSeats === 1 ? '' : 's'}
            </span>
          ) : (
            ''
          )}
        </span>
      ),
      disabled: isDisabled,
    });
    return acc;
  }, []);
};
const OrganizationMemberTableEditOptions = inject(
  'enterpriseStore',
  'commonStore',
  'userStore'
)(
  observer(({ enterpriseStore, commonStore, userStore, groupId }) => {
    const [isLoading, setIsLoading] = useState(false);

    const { team } = userStore;
    const { active_license_grants } = team || {};
    const isOrgOwner = userStore?.team?.permissions?.canManageTeam;
    const canManageLicenses = userStore?.team?.permissions?.canManageAdmins;
    const isGroupScope = !!groupId;
    const groupOrTeamId = isGroupScope ? groupId : team?.id;

    const { teamMemberTableData } = enterpriseStore;
    const { selectedRows } = teamMemberTableData || {};
    const bulkEditIcon = selectedRows?.length ? 'x-circle' : 'edit';
    const bulkEditIconColor = selectedRows?.length ? 'text-red-600' : 'text-black';
    const bulkEditIconTitle = selectedRows?.length ? 'Clear Selected Rows' : '';
    const selectedRowCount = selectedRows?.length;
    const pluralSuffix = selectedRowCount === 1 ? '' : 's';

    const licenseOptionsGrant = getLicenseOptions(active_license_grants, selectedRows.length, true);
    const licenseOptionsRevoke = getLicenseOptions(active_license_grants, 0, false);

    const roleOptions = getRoleOptions(isOrgOwner, isGroupScope);

    const refreshData = async (message, resetCheckboxState = false) => {
      commonStore.triggerToast('success', { content: message });
      if (isOrgOwner && !isGroupScope) {
        userStore.loadAndSetTeamData(team?.id, userStore.team?.role);
      }
      enterpriseStore.getTeamMemberTableData(groupOrTeamId, false, true, resetCheckboxState);
    };

    const clearSelectedRows = () => {
      if (selectedRowCount > 1) {
        commonStore.triggerConfirm({
          content: `Are you sure you want to clear the ${selectedRowCount} selected user${pluralSuffix}?`,
          cancel: () => commonStore.resetConfirmState(),
          continue: () => {
            commonStore.resetConfirmState();
            enterpriseStore.setAllSelectedRows('teamMemberTableData', false);
          },
        });
      } else {
        commonStore.triggerToast('error', { content: 'No users selected' });
      }
    };

    const handleChangeLicense = (option, action, grant = false) => {
      if (teamMemberTableData.selectedRows.length) {
        const { value, label } = option;
        const userIds = [];
        teamMemberTableData.selectedRows.forEach((row) => {
          const userRowData = teamMemberTableData.tableData[row];
          const userId = userRowData[0].value;
          userIds.push(userId);
        });
        const title = label.props.name;
        commonStore.triggerConfirm({
          content: (
            <div>
              Are you sure you want to {grant ? 'grant' : 'revoke'} <span className={` font-semibold`}>{title}</span> {grant ? 'to' : 'from'} the{' '}
              <span className="font-semibold">
                {userIds.length} selected user{userIds.length === 1 ? '' : 's'}
              </span>
              ?
            </div>
          ),
          cancel: () => commonStore.resetConfirmState(),
          continue: () => {
            commonStore.resetConfirmState();
            setIsLoading(true);
            // Set loading state early for UI feedback, this gets cleared downstream in the refreshData function
            enterpriseStore.setTeamMemberTableLoading(true);
            agents.enterprise
              .manageLicenses(team?.id, { users: userIds, grants: [value], grant })
              .then(() => {
                refreshData('User(s) licenses have been updated!');
              })
              .catch((err) => {
                if (err?.response?.data) {
                  const errorMessage = err.response.data.message || null;
                  const errorContent = err.response.status === 422 && errorMessage?.includes('You do not have enough licenses') ? 'All licenses are currently being used' : null;
                  commonStore.triggerToast('error', {
                    errorCode: err.response.status,
                    content: errorContent,
                  });
                  BugsnagUtil.notify(err);
                }
              })
              .finally(() => {
                setIsLoading(false);
              });
          },
          confirmBtn: grant ? 'Grant' : 'Revoke',
          cancelBtn: 'Cancel',
        });
      }
    };

    const handleChangeRoleConfirm = ({ value }) => {
      if (selectedRowCount) {
        const formattedRole = FormatUtil.convertRoleName(value, isGroupScope ? 'Group' : 'Team');
        commonStore.triggerConfirm({
          content: (
            <p>
              Are you sure you want to change the{' '}
              <b>
                {selectedRowCount} selected user{pluralSuffix ? `'${pluralSuffix}` : ''}
              </b>{' '}
              role{pluralSuffix} to <b>{formattedRole}</b>?
            </p>
          ),
          cancel: () => commonStore.resetConfirmState(),
          continue: () => {
            commonStore.resetConfirmState();
            setIsLoading(true);
            enterpriseStore
              .changeUserRoles(teamMemberTableData.selectedRows, value, groupOrTeamId, 'teamMemberTableData')
              .then(() => {
                refreshData(`${selectedRowCount} user${pluralSuffix ? `'${pluralSuffix}` : ''} role${pluralSuffix} updated to ${formattedRole || 'a new role'}!`);
              })
              .catch((error) => {
                commonStore.triggerToast('error', {
                  errorCode: (error && error.response && error.response.status) || null,
                });
                BugsnagUtil.notify(error);
              })
              .finally(() => {
                setIsLoading(false);
              });
          },
        });
      }
    };

    const handleRemoveMembers = () => {
      if (selectedRowCount) {
        const teamWarningText = 'They will lose access to paid content and will not appear in reporting.';
        const groupWarningText = "They will still be on the team, but they will no longer be included in this group's reporting.";
        commonStore.triggerConfirm({
          content: `Are you sure you want to remove the ${selectedRowCount} selected user${pluralSuffix} from ${isGroupScope ? 'this group' : 'the team'}? ${
            groupId ? groupWarningText : teamWarningText
          }`,
          cancel: () => commonStore.resetConfirmState(),
          continue: async () => {
            commonStore.resetConfirmState();
            try {
              if (teamMemberTableData.selectedRows.length) {
                setIsLoading(true);
                await enterpriseStore.removeMembers(groupOrTeamId, teamMemberTableData.selectedRows, 'teamMemberTableData');
                // Refresh data, and clear selected rows if we're removing from the team
                refreshData(`${selectedRowCount} user${pluralSuffix} removed from the ${isGroupScope ? 'selected group' : 'team'}!`, !isGroupScope);
              }
            } catch (e) {
              commonStore.triggerToast('error', {
                errorCode: e?.response?.status,
              });
              BugsnagUtil.notify(e);
            } finally {
              setIsLoading(false);
            }
          },
        });
      } else {
        commonStore.triggerToast('error', {
          content: 'No users selected',
        });
      }
    };

    const handleAddGroupMembers = (option, value) => {
      // Group members starts loading early to allow focus to drop from the select element
      // This allows the user to bail out of the confirmation if they want to, and the focus
      // won't be on the select element anymore and instantly fire a new request to save
      setIsLoading(true);
      commonStore.triggerConfirm({
        content: `Are you sure you want to add the ${selectedRowCount} selected user${pluralSuffix} to this group?`,
        cancel: () => {
          commonStore.resetConfirmState();
          setIsLoading(false);
        },
        continue: async () => {
          commonStore.resetConfirmState();
          try {
            const memberIds = teamMemberTableData.selectedRows.map((rowIndex) => teamMemberTableData?.tableData?.[rowIndex]?.[0]?.value).filter(Boolean);
            // option is the chosen groupId to add the users to
            await agents.enterprise.addTeamMember(option, { users: memberIds });
            commonStore.triggerToast('success', {
              content: 'Users added to group',
            });
            setIsLoading(false);
            refreshData(`${memberIds.length} user${pluralSuffix} added to the ${value || 'selected'} group!`);
          } catch (e) {
            BugsnagUtil.notify(e);
            setIsLoading(false);
            commonStore.triggerToast('error', {
              content: 'Something went wrong. Unable to add users to group at this time.',
            });
          }
        },
      });
    };

    return (
      <div className="actions-container">
        <div className="grid grid-cols-12 gap-y-1 items-center md:gap-x-2">
          <div className="flex col-span-12 items-center my-2 space-x-2 text-lg font-semibold whitespace-nowrap">
            <span className="text-sm font-normal">Bulk Editing {selectedRowCount} Members</span>
            <span className="text-gray-500">|</span>
            <div className="flex grow justify-between items-center space-x-2">
              <AddLink onClick={clearSelectedRows} title={bulkEditIconTitle} className={`flex items-center text-sm ${bulkEditIconColor}`}>
                <Icon name={bulkEditIcon} className={`shrink-0 mr-1 w-4 h-4 ${bulkEditIconColor}`} /> Clear selection
              </AddLink>
              <If condition={isOrgOwner || !!groupId}>
                <Button
                  loading={isLoading}
                  color="transparent-gray"
                  onClick={handleRemoveMembers}
                  icon={{ name: 'trash', className: 'w-4 h-4 mr-1 text-red-600' }}
                  className="py-1"
                >
                  Remove {selectedRows?.length} {selectedRows?.length === 1 ? 'Member' : 'Members'} From {groupId ? 'Group' : 'Team'}
                </Button>
              </If>
            </div>
          </div>
          {/** Edit Roles */}
          <If condition={isOrgOwner || !!groupId}>
            <div className="col-span-12 md:col-span-6 lg:col-span-3">
              <Dropdown
                ariaLabel="Edit selected members role"
                placeholder="Change Role"
                options={roleOptions}
                onChange={handleChangeRoleConfirm}
                selection
                className="change-role-dropdown"
                selectOnNavigation={false}
                loading={isLoading}
                disabled={isLoading}
              />
            </div>
          </If>
          {/** Edit Groups */}
          <If condition={isOrgOwner}>
            <div className="col-span-12 md:col-span-6 lg:col-span-3">
              <GroupSelect
                ariaLabel="Add Selected Members to Group"
                placeholder="Add to Group"
                handleGroupSelect={handleAddGroupMembers}
                selectedGroup=""
                disabled={isLoading}
                loading={isLoading}
                closeMenuOnSelect
                omitSelectedLabels
              />
            </div>
          </If>
          {/** Manage Licenses */}
          <If condition={active_license_grants?.length && canManageLicenses}>
            <div className="col-span-12 md:col-span-6 lg:col-span-3">
              <Dropdown
                ariaLabel="Grant License"
                placeholder="Grant License"
                options={licenseOptionsGrant}
                onChange={(option, action) => handleChangeLicense(option, action, true)}
                selection
                className="change-role-dropdown"
                selectOnNavigation={false}
                loading={isLoading}
                disabled={isLoading}
              />
            </div>
            <div className="col-span-12 md:col-span-6 lg:col-span-3">
              <Dropdown
                ariaLabel="Revoke License"
                placeholder="Revoke License"
                options={licenseOptionsRevoke}
                onChange={(option, action) => handleChangeLicense(option, action, false)}
                selection
                className="change-role-dropdown"
                selectOnNavigation={false}
                loading={isLoading}
                disabled={isLoading}
              />
            </div>
          </If>
        </div>
      </div>
    );
  })
);

const OrganizationMemberTableFilters = inject(
  'enterpriseStore',
  'userStore'
)(
  observer(({ enterpriseStore, userStore, groupId }) => {
    const { teamMemberTableData } = enterpriseStore;

    const { team } = userStore;
    const { orgId } = useParams();
    const isDemo = orgId === 'demo';

    const isOrgOwner = userStore?.team?.permissions?.canManageAdmins;
    const roleOptions = getRoleOptions(isOrgOwner, !!groupId);
    roleOptions.splice(0, 0, {
      key: 'all',
      text: 'All Roles',
      value: '',
    });

    const licenseOptions =
      team?.active_license_grants?.reduce((acc, grant) => {
        acc.push({
          text: grant.name || getPackageNames(grant.package_types),
          value: grant.team_content_license_grant_id,
        });
        return acc;
      }, []) || [];

    if (team?.active_license_grants?.length > 1) {
      licenseOptions.splice(0, 0, {
        key: 'all',
        text: 'Any License',
        value: '',
      });
    }
    licenseOptions.push({
      key: 'no',
      text: 'No Licenses',
      value: 'No',
    });

    const groupSelectDefault =
      team?.permissions && (team?.permissions.canManageTeam || team?.permissions.canViewReports === 'all')
        ? {
            text: `All Groups`,
            value: '',
          }
        : null;

    const isLoading = teamMemberTableData?.loading;
    const { totalRecords } = teamMemberTableData || {};
    const { role, license, groupId: teamMemberTableDataGroupId, searchQuery } = teamMemberTableData?.queryParams || {};
    const showResultsSummary = (role || license || teamMemberTableDataGroupId || searchQuery?.length) && !isLoading;
    const isGroupScope = !!groupId;
    const groupOrTeamId = isGroupScope ? groupId : orgId;
    const filterTable = (filter, val) => {
      enterpriseStore.setQueryParam('teamMemberTableData', 'activePg', 1);
      enterpriseStore.setQueryParam('teamMemberTableData', filter, val);
      // Query for table data based on groupId if it exists, otherwise use orgId
      enterpriseStore.getTeamMemberTableData(groupOrTeamId, false, true);
    };

    const handleClearFilters = () => {
      enterpriseStore.setQueryParam('teamMemberTableData', 'role', '');
      enterpriseStore.setQueryParam('teamMemberTableData', 'license', '');
      enterpriseStore.setQueryParam('teamMemberTableData', 'groupId', '');
      enterpriseStore.setQueryParam('teamMemberTableData', 'searchQuery', '');
      enterpriseStore.getTeamMemberTableData(groupOrTeamId, true, false);
    };

    return (
      <div className="grid actions-container">
        <div className="grid grid-cols-12 gap-y-2 items-center md:gap-x-2">
          <div className="flex col-span-12 items-center my-1 space-x-2 text-lg font-semibold whitespace-nowrap">
            <span>Filters:</span>
            <If condition={showResultsSummary}>
              <span className="text-sm font-normal">Showing {totalRecords} results</span>
              <span className="text-gray-500">|</span>
              <div className="flex grow justify-between items-center space-x-2">
                <AddLink onClick={handleClearFilters} title="Clear filters" className="flex items-center text-sm text-red-600">
                  <Icon name="x-circle" className="shrink-0 mr-1 w-4 h-4 text-red-600" /> Clear filters
                </AddLink>
              </div>
            </If>
          </div>
          <SearchInput
            placeholder="Search Members"
            onChange={(e) => {
              enterpriseStore.setQueryParam('teamMemberTableData', 'searchQuery', e.target.value);
            }}
            onKeyPress={(e) => {
              // Check to see if we pressed enter key
              if ((e.which || e.keyCode) === 13) {
                enterpriseStore.getTeamMemberTableData(orgId, true, false);
              }
            }}
            ariaLabel="Search members"
            disabled={isDemo}
            className="col-span-12 md:col-span-3"
            inputClasses="w-full"
          />
          <div className="col-span-12 md:col-span-6 lg:col-span-3">
            <DropdownFilter
              name="filter-by-role"
              ariaLabel="Filter by role"
              fluid
              placeholder="All Roles"
              options={roleOptions}
              value={role}
              onChange={({ value }) => filterTable('role', value)}
              isSearchable
              disabled={isDemo || isLoading}
              loading={isLoading}
            />
          </div>
          <div className="col-span-12 md:col-span-6 lg:col-span-3">
            <DropdownFilter
              name="filter-by-license"
              ariaLabel="Filter by license status"
              placeholder="All Licenses"
              options={licenseOptions}
              value={license}
              onChange={({ value }) => filterTable('license', value)}
              isSearchable
              disabled={isDemo || isLoading}
              loading={isLoading}
            />
          </div>
          {!groupId ? (
            <div className="col-span-12 md:col-span-6 lg:col-span-3">
              <GroupSelect
                team={team}
                ariaLabel="Filter by groups"
                handleGroupSelect={(value) => filterTable('groupId', value)}
                selectedGroup={teamMemberTableDataGroupId}
                placeholder="All Groups"
                defaultOption={groupSelectDefault}
                disabled={isLoading}
                loading={isLoading}
              />
            </div>
          ) : null}
        </div>
      </div>
    );
  })
);

const OrganizationMemberTableActions = inject('enterpriseStore')(
  observer(({ enterpriseStore, viewOnly, groupId, disableSticky }) => {
    const { teamMemberTableData } = enterpriseStore;
    return (
      <Sticky className="px-0 border-none team-actions" stickyClasses="pb-2 border-b-1 border-gray-400" unStickyClasses="pb-2 pt-12" disableSticky={disableSticky}>
        <div className="pt-1 collapse-container">
          <If condition={!viewOnly}>
            <If condition={!!teamMemberTableData?.selectedRows?.length}>
              <OrganizationMemberTableEditOptions groupId={groupId} />
            </If>
          </If>
          <If condition={!teamMemberTableData?.selectedRows?.length || viewOnly}>
            <OrganizationMemberTableFilters groupId={groupId} />
          </If>
        </div>
      </Sticky>
    );
  })
);

export default OrganizationMemberTableActions;
