import React, { useMemo, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { observer, inject } from 'mobx-react';
import Container from '../../components/Container/Container';
import StyledError from '../../components/Error/StyledError';
import CareerProgramHero from '../../components/CareerPrograms/CareerProgramHero';
import CareerProgramTabs from '../../components/CareerPrograms/Tabs';
import { useCareerPrograms } from '../../providers/CareerProgramsProvider';
import CareerProgramContinueLearningWidget from '../../components/CareerPrograms/CareerProgramContinueLearningWidget';
import { CONTENT_TYPES } from '../../constants';
import ActionUtil from '../../utils/actionsUtil';
import If from '../../components/If/If';

const CareerProgramOverview = inject('commonStore')(
  observer(({ commonStore }) => {
    const {
      isLoadingCareerProgramBrowseData,
      careerProgramBrowseDataError,
      setCurrentPageProgramPermalink,
      currentPageProgram: program,
      resetCurrentPageState,
    } = useCareerPrograms();

    // Get the permalink from the URL and prepend it with 'paths/' to match what the API expects
    const params = useParams();
    const permalink = `paths/${params?.['*']}`;
    const isLoading = isLoadingCareerProgramBrowseData?.[permalink] ?? true;
    const error = careerProgramBrowseDataError?.[permalink];
    const isComingSoon = useMemo(() => program?.status === 'Coming Soon', [program?.status]);

    useEffect(() => {
      // If the program is already loaded, return it
      if (permalink) {
        setCurrentPageProgramPermalink(permalink);
      }
    }, [permalink]);

    // Set page title based on the program title, falling back to program type while loading
    useEffect(() => {
      const programHrefType = permalink.includes('/skill-paths/') ? CONTENT_TYPES.SKILL_PROGRAM.id : CONTENT_TYPES.CAREER_PROGRAM.id;
      const programType = program?.content_type?.id || programHrefType;
      if (program?.title) {
        return commonStore.setPageTitle(`${program.title} | Cybrary`);
      }
      if (programType === CONTENT_TYPES.SKILL_PROGRAM.id) {
        return commonStore.setPageTitle('Skill Path | Cybrary');
      }
      if (programType === CONTENT_TYPES.CERT_PREP_PROGRAM.id) {
        return commonStore.setPageTitle('Certification Prep | Cybrary');
      }
      return commonStore.setPageTitle('Career Path | Cybrary');
    }, [program?.content_type?.id, program?.title]);

    useEffect(() => {
      ActionUtil.scrollToTop();
      return () => {
        // Reset on unmount to avoid showing stale data on the next page load
        resetCurrentPageState();
      };
    }, []);

    if (error || (!isLoading && !program)) {
      return (
        <Container>
          <StyledError error={error ?? { status: 404 }} />
        </Container>
      );
    }

    return (
      <>
        <Container className="pb-8" omitPadding>
          <CareerProgramHero />
        </Container>
        <Container className="px-4 lg:px-14 2xl:px-24" lg omitPadding>
          <CareerProgramContinueLearningWidget />
        </Container>
        <If condition={!isComingSoon}>
          <Container className="px-4 pb-8 lg:px-14 2xl:px-24" lg omitPadding>
            <CareerProgramTabs />
          </Container>
        </If>
      </>
    );
  })
);

export default CareerProgramOverview;
