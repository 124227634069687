import React, { useEffect } from 'react';
import { observer, inject } from 'mobx-react';
import CareerProgramOverviewTab from './CareerProgramOverviewTab';
import CareerProgramOutlineTab from './CareerProgramOutlineTab';
import BasicTabs from '../Tabs/BasicTabs';
import { useCareerPrograms } from '../../providers/CareerProgramsProvider';

const TAB_DEFINITIONS = [
  {
    id: 1,
    title: 'Overview',
    content: <CareerProgramOverviewTab />,
  },
  {
    id: 2,
    title: 'Outline',
    content: <CareerProgramOutlineTab />,
  },
];

const CareerProgramTabs = inject('enrollmentStore')(
  observer(({ enrollmentStore }) => {
    const { currentPageProgram: program, getCareerProgramBrowseData } = useCareerPrograms();

    const paths = program?.content_item?.children;

    useEffect(() => {
      if (!paths && program?.permalink) {
        getCareerProgramBrowseData(program.permalink);
        // Reload enrollment progress map to show progress on children of an unenrolled program.
        enrollmentStore.fetchEnrollmentProgressMap();
      }
    }, [paths, program]);

    return <BasicTabs tabs={TAB_DEFINITIONS} buttonClassName="lg:w-48 p-4" navClassName="justify-start" />;
  })
);

export default CareerProgramTabs;
