/**
 * Some logic that gets repeated surrounding enrollments.
 */
import GaUtil from './gaUtil';
import { CONTENT_TYPES, WWW_PRICING_PAGE } from '../constants';
import BugsnagUtil from './bugsnagUtil';

export default class EnrollmentUtil {
  /**
   * Handle start enrollment logic, consolidating it since it appears in various places.
   * @param {UserStore} userStore - a reference to the user store.
   * @param {EnrollmentStore} enrollmentStore - a reference to the enrollment store.
   * @param {CommonStore} commonStore - a reference to the common store.
   * @param {any} navigate - Reference to navigate method, for redirecting after success.
   * @param {any} content - the content we are enrolling into
   * @param {any} data - the data we are sending along with the enrollment, from an enrollment form.  May be undefined.
   * @param {any} options.preferredEnrollmentId - (Optional) preferred enrollmentId, if any
   * @param {any} options.preferredCollectionItemId - (Optional) preferred collection item id, if any, within the collection enrollment
   * @param {any} options.preferredModuleId - (Optional) preferred module id, if any, within the course enrollment
   * @param {any} options.preferredActivityId - (Optional) preferred activity id, if any, within the module
   * @returns void - no return value.
   */
  static handleStartEnroll = async (userStore, enrollmentStore, commonStore, navigate, content, data = {}, options = {}) => {
    try {
      // Assert that we have content
      if (!content?.id) {
        throw new Error('Missing or malformed content provided');
      }
      const { team } = userStore;
      const preferredEnrollmentId = options?.enrollmentId;
      const preferredCollectionItemId = options?.preferredCollectionItemId;
      const preferredModuleId = options?.preferredModuleId;
      const preferredActivityId = options?.preferredActivityId;
      const { content_type, title } = content || {};

      /**
       * Handle ACCESS GRANTED
       */
      if (userStore.checkPermissions(content)) {
        // If we have a preferred enrollment id for the permitted content, navigate to it
        if (preferredEnrollmentId) {
          // If we also have a preferred collection item id, navigate to it
          if (preferredCollectionItemId) {
            // If we also have a preferred module id, navigate to it
            if (preferredModuleId) {
              // If we also have a preferred activity id, navigate to it
              if (preferredActivityId) {
                navigate(`/immersive/${preferredEnrollmentId}/item/${preferredCollectionItemId}/module/${preferredModuleId}/activity/${preferredActivityId}`);
                return;
              }
              navigate(`/immersive/${preferredEnrollmentId}/item/${preferredCollectionItemId}/module/${preferredModuleId}`);
              return;
            }
            navigate(`/immersive/${preferredEnrollmentId}/item/${preferredCollectionItemId}`);
            return;
          }
          // If we also have a preferred module id, navigate to it
          if (preferredModuleId) {
            // If we also have a preferred activity id, navigate to it
            if (preferredActivityId) {
              navigate(`/immersive/${preferredEnrollmentId}/module/${preferredModuleId}/activity/${preferredActivityId}`);
              return;
            }
            navigate(`/immersive/${preferredEnrollmentId}/module/${preferredModuleId}`);
            return;
          }
          navigate(`/immersive/${preferredEnrollmentId}`);
          return;
        }

        // Start the enrollment
        const responseInfo = await enrollmentStore.startEnrollment(content.id, data, content_type?.nice_name);

        // Fire the GA event
        GaUtil.fireEvent(content_type?.nice_name, 'Enrollment', title);

        // Navigate to the enrollments page
        const { enrollmentId, enrollment } = responseInfo || {};
        const isActivity = enrollment?.is_activity;
        // Instead of onboarding, just jump to the enrollments page and tell it to launch the freshly enrolled item!
        let targetUrl = `/enrollments-launcher?launch=${enrollmentId}`;
        if (isActivity) {
          targetUrl += '&isActivity=true';
        }
        if (preferredCollectionItemId) {
          targetUrl += `&preferredCollectionItemId=${preferredCollectionItemId}`;
        }
        navigate(targetUrl);
        return;
      }

      /**
       * Handle ACCESS DENIED
       */

      // Enterprise User - send them to request a license
      if (team?.id) {
        window.location.href = commonStore.getRequestLicenseLink(team.id);
        return;
      }

      window.location.href = WWW_PRICING_PAGE;
      return;
    } catch (error) {
      BugsnagUtil.notifyWithNamedMetadata(error, 'EnrollmentUtil.handleStartEnroll', {
        content,
        data,
        options,
      });
      commonStore.triggerToast('error', {
        content: 'An error occurred while starting your enrollment, please try again later or contact support for assistance.',
        header: 'Enrollment Error',
      });
      throw error;
    }
  };

  static isInternalActivity = (type) => {
    const internalActivities = [
      'Course',
      'MicroCourse',
      'Video Activity',
      'Course Module',
      'Article Activity',
      'Curriculum',
      'Career Path',
      'Assessment',
      'Assessment Path',
      'Cybrary Live Series',
      'Cybrary Live Session',
      'Threat Actor Path',
      'Cybrary Select',
      'Collection',
      'Virtual Lab',
      'Challenge',
      // Below items are not 'internal', but treated as such
      'Practice Labs Lab',
      'Practice Labs Lab Module',
      'Next Tech Lab Module',
      'Next Tech Lab',
      'Next Tech Assessment',
      'Next Tech Practice Test',
      'Next Tech Article',
      'Avatao Course',
    ];
    return internalActivities.indexOf(type) > -1;
  };

  static isTypeCollection = (type) => ['Curriculum', 'Career Path', 'Threat Actor Path', 'Cybrary Select', 'Assessment Path', 'Collection'].includes(type);

  static isTypeCourse = (type) => ['Course', 'MicroCourse', 'Virtual Lab'].includes(type);

  static isCustomPath = (item) => item?.content_type?.name === 'curriculum';

  static isAssessmentCourse = (item) => item?.content_type?.id === CONTENT_TYPES.CYBRARY_ASSESSMENT_COURSE.id;

  static isCareerProgram = (item) => [CONTENT_TYPES.CERT_PREP_PROGRAM.id, CONTENT_TYPES.CAREER_PROGRAM.id, CONTENT_TYPES.SKILL_PROGRAM.id].includes(item?.content_type?.id);
}
