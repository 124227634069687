import React, { useMemo } from 'react';
import { inject, observer } from 'mobx-react';
import CountUp from 'react-countup';
import moment from 'moment';
import { useNavigate, useParams } from 'react-router-dom';
import StyledError from '../Error/StyledError';
import AvatarsList from '../Avatar/AvatarsList';
import AdminTable from '../AdminTable/AdminTable';
import FormatUtil from '../../utils/formatUtil';
import RecurringGoalIcon from './RecurringGoalIcon';
import Container from '../Container/Container';
import './goals.css';
import { GOAL_OUTCOME_NICE_NAMES } from '../../constants';
import AddLink from '../AddLink/AddLink';
import Icon from '../Icon/Icon';
import ProgressBar from '../ProgressBar/ProgressBar';

const formatGoalTitle = (col, row, headings, viewGoal, isDemo) => {
  const goalIdIndex = FormatUtil.getColIndex(headings, 'id');
  const goalId = row[goalIdIndex]?.value;

  const goalTitle = FormatUtil.formatLongText(col?.value || 'Goal title', 25);
  return (
    <AddLink title="View Goal" onClick={() => viewGoal(goalId, col)} className="hover:underline disabled:cursor-not-allowed" disabled={isDemo}>
      {goalTitle}
    </AddLink>
  );
};

const formatProgress = (col) => {
  if (!col) {
    return null;
  }
  const progress = Math.floor(col.value);
  return (
    <div className="goal-progress">
      <div className="percentage" style={{ maxWidth: '6rem' }}>
        <ProgressBar progress={progress} />
      </div>
      <CountUp className="progress" end={progress || 0} suffix="%" duration={2} />
    </div>
  );
};

const formatDate = (data, row, headings, col) => {
  if (!data || !data.value) {
    return null;
  }
  const dateFormatted = moment(data.value).format('MM/DD/YYYY');
  if (col === 'due_date') {
    const dueTypeIdx = FormatUtil.getColIndex(headings, 'due_type');
    if (dueTypeIdx && row[dueTypeIdx] && row[dueTypeIdx].value === 'recurring') {
      return (
        <span className="flex items-center">
          {dateFormatted} <RecurringGoalIcon />
        </span>
      );
    }
  }
  return dateFormatted;
};

const formatOwners = (col) => {
  if (!col || !col.value || !col.value.length) {
    return null;
  }
  return <AvatarsList data={col.value} limit={2} />;
};

const formatParticipants = (col) => {
  if (!col || !col.value || !col.value.length) {
    return null;
  }
  const participantUsers = col.value.filter((item) => {
    return !!item.avatar_url;
  });
  if (participantUsers.length) {
    return <AvatarsList data={col.value} limit={2} />;
  }
  const teamDisplays = col.value.map((team) => {
    return team.name;
  });
  return <div className="goal-participants">{teamDisplays.join(', ')}</div>;
};

const formatOutcome = (col) => {
  if (!col || !col.value) {
    return null;
  }
  return GOAL_OUTCOME_NICE_NAMES[col.value];
};

const GoalsList = inject(
  'userStore',
  'commonStore'
)(
  observer(({ userStore, commonStore, teamGoals, setQueryParams, getTeamGoals, viewGoal, editGoal, editGoalDueDate, deleteGoal }) => {
    const navigate = useNavigate();
    const { orgId } = useParams();
    const { data, loading, error, queryParams } = teamGoals || {};
    const isCompleted = queryParams?.status === 'completed';
    const isDemo = userStore?.team?.id === 'demo';

    const checkShowEditOptions = (row, headings) => {
      const { user, team } = userStore;
      const ownersIdx = FormatUtil.getColIndex(headings, 'owners');
      const owners = ownersIdx > -1 ? row[ownersIdx].value : [];
      const ownersArray = owners.map((owner) => owner.id);
      return ownersArray.indexOf(user.id) > -1 || team.permissions.canManageTeam;
    };

    const viewResults = (goalData, columns) => {
      const idIdx = FormatUtil.getColIndex(columns, 'id');
      const nameIdx = FormatUtil.getColIndex(columns, 'name');
      const newGoalId = goalData?.[idIdx]?.value;
      const newGoalName = goalData?.[nameIdx]?.value;
      if (newGoalId && orgId) {
        navigate(`/enterprise/${orgId}/reporting/report/learning-activities/?goalId=${newGoalId}&goalName=${encodeURIComponent(`Goal: ${newGoalName}`)}`);
      } else {
        commonStore.triggerToast('error', {
          content: 'Unable to view results - missing goal ID or organization ID',
        });
      }
    };

    const displayButtons = useMemo(() => {
      const buttons = [];
      if (orgId) {
        buttons.push({ text: 'View Results', action: viewResults, disabled: isDemo });
      }
      if (isCompleted) {
        buttons.push({
          text: 'Re-open',
          action: editGoalDueDate,
          displayCondition: checkShowEditOptions,
          disabled: isDemo,
        });
      } else {
        buttons.push({
          text: 'Edit Goal',
          action: editGoal,
          displayCondition: checkShowEditOptions,
          disabled: isDemo,
        });
      }
      buttons.push({ text: 'Delete', action: deleteGoal, displayCondition: checkShowEditOptions, disabled: isDemo });
      return buttons;
    }, [viewGoal, editGoal, editGoalDueDate, deleteGoal, isCompleted, checkShowEditOptions]);

    const tableSort = (heading) => {
      if (heading) {
        if (teamGoals?.queryParams?.sortCol && heading === teamGoals.queryParams.sortCol) {
          setQueryParams('teamGoals', 'sortCol', teamGoals.queryParams.sortCol);
          setQueryParams('teamGoals', 'sortDirection', teamGoals.queryParams.sortDirection === 'desc' ? 'asc' : 'desc');
        } else {
          setQueryParams('teamGoals', 'sortCol', heading);
          setQueryParams('teamGoals', 'sortDirection', 'desc');
        }
        getTeamGoals();
      }
    };

    if (error) {
      return (
        <Container>
          <StyledError error={error} />
        </Container>
      );
    }
    if (!data?.tableData?.length && !loading) {
      return (
        <div className="flex flex-col justify-center items-center py-8">
          <Icon name="filter" className="pb-4 w-12 h-12 text-gray-600" />
          <p className="text-center text-gray-600">
            No goals found!
            <br />
            <br />
            Try changing your filters or create a new goal above.
          </p>
        </div>
      );
    }

    return (
      <AdminTable
        wrapperClassName="mt-4"
        headings={data?.columns}
        headerClickFunc={tableSort}
        sortCol={queryParams.sortCol}
        sortDirection={queryParams.sortDirection}
        data={data?.tableData}
        loadingAsTable
        tableLoading={loading}
        formatColumns={[
          {
            method: (col, row, headings) => formatGoalTitle(col, row, headings, viewGoal, isDemo),
            colIdx: FormatUtil.getColIndex(data?.columns, 'name'),
          },
          {
            method: formatProgress,
            colIdx: FormatUtil.getColIndex(data?.columns, 'percent_completed'),
          },
          {
            method: formatParticipants,
            colIdx: FormatUtil.getColIndex(data?.columns, 'participants'),
          },
          {
            method: formatDate,
            colIdx: FormatUtil.getColIndex(data?.columns, 'start_date'),
          },
          {
            method: (col, row, headings) => formatDate(col, row, headings, 'due_date'),
            colIdx: FormatUtil.getColIndex(data?.columns, 'due_date'),
          },
          {
            method: formatOwners,
            colIdx: FormatUtil.getColIndex(data?.columns, 'owners'),
          },
          {
            method: formatOutcome,
            colIdx: FormatUtil.getColIndex(data?.columns, 'outcome_type'),
          },
        ]}
        rowOptions={displayButtons}
      />
    );
  })
);

export default GoalsList;
