import './admin.css';
import React, { Component } from 'react';
import queryString from 'query-string';
import { observer, inject } from 'mobx-react';
import { Navigate } from 'react-router-dom';
import moment from 'moment';
import Icon from '../../components/Icon/Icon';
import Button from '../../components/Button/Button';
import withRouter from '../../components/Router/withRouter';
import Breadcrumbs from '../../components/Breadcrumbs/Breadcrumbs';
import Loading from '../../components/Loading/Loading';
import { copyToClipboard } from '../../utils/actionsUtil';
import AdminTable from '../../components/AdminTable/AdminTable';
import StyledError from '../../components/Error/StyledError';
import OrganizationLogo from '../../components/Enterprise/OrganizationLogo';
import AddLink from '../../components/AddLink/AddLink';
import FormatUtil, { getPackageNames } from '../../utils/formatUtil';
import { getRoleOptions } from '../../utils/teamsUtil';
import Modal from '../../components/Modal/Modal';
import Container from '../../components/Container/Container';
import ClipboardList from '../../Icons/ClipboardList';
import Tooltip from '../../components/Tooltip/Tooltip';
import Dropdown from '../../components/Dropdown/Dropdown';
import ChargebeeLabel from '../../components/Label/ChargebeeLabel';
import Input from '../../components/FormFields/Input';
import AdminTeamOnboardingTools from '../../components/CybraryAdmin/CybraryAdminTeamOnboardingTools';
import Agents from '../../agents/agents';
import ConfiguredDomains from '../../components/TeamsSso/ConfiguredDomains';
import CybraryAdminLogo from './CybraryAdminLogo';
import Header from '../../components/Header/Header';
import Divider from '../../components/Divider/Divider';
import OrganizationLicenseGrants from '../Enterprise/OrganizationLicenseGrants';

const CybraryAdminViewTeam = inject(
  'commonStore',
  'adminStore',
  'authStore',
  'userStore'
)(
  observer(
    class CybraryAdminViewTeam extends Component {
      state = {
        team: null,
        selectedRole: null,
        addMembersModalOpen: false,
        copyCurriculaModalOpen: false,
        ssoButton: false,
      };

      componentDidMount() {
        const { location, match, adminStore, authStore, commonStore } = this.props;
        const queryParams = location && location.search ? queryString.parse(location.search) : {};
        const newState = {
          ...this.state,
          team: match.params.id ? match.params.id : null,
        };
        if (queryParams.bulkInvite) {
          newState.addMembersModalOpen = true;
        }
        this.setState(newState);
        // Confirm that we are allowed on the admin page
        adminStore.checkAccess(authStore);
        commonStore.hidePrimaryNav();
        if (match.params.id) {
          adminStore.getTeamDetailsData(match.params.id);
          adminStore.getTeamMemberTableData(match.params.id);
        }
      }

      componentWillUnmount() {
        this.props.adminStore.setDefaultTeamDetailsData();
        this.props.adminStore.setDefaultTeamMemberTableData();
        this.props.commonStore.showPrimaryNav();
      }

      getBreadCrumbs = () => {
        const crumbs = [];
        crumbs.push({
          href: '/admin',
          label: 'Admin',
        });
        crumbs.push({
          href: '/admin/teams',
          label: 'Browse Teams',
        });
        crumbs.push({
          href: `/admin/view-team/${this.state.team}`,
          label: 'View Team',
        });
        return crumbs;
      };

      copyInviteLink = () => {
        try {
          copyToClipboard(this.input);
          this.props.commonStore.triggerToast('success', {
            content: 'Invite link copied to clipboard',
          });
        } catch (e) {
          this.props.commonStore.triggerToast('error', {
            content: 'Failed to copy invite link to clipboard',
          });
        }
      };

      // /**
      //  * Handles checkbox changes - Accumulates selection indexes in an array
      //  */
      handleCheckBoxes = (e, { checked }, idx) => {
        this.props.adminStore.setCheckboxState(this.props.adminStore.teamMemberTableData, idx, checked);
      };

      // /**
      //  * Handles header checkbox change - Selects/deselects all child checkboxes
      //  */
      headerCheckBoxFunc = (e, { checked }) => {
        this.props.adminStore.setAllSelectedRows(this.props.adminStore.teamMemberTableData, checked);
      };

      // /**
      //  * Sets sort column and direction vars in store for service call query params
      //  */
      tableSort = (heading) => {
        if (heading) {
          if (heading === this.props.adminStore.teamMemberTableData.queryParams.sortCol) {
            this.props.adminStore.teamMemberTableData.queryParams.sortDirection = this.props.adminStore.teamMemberTableData.queryParams.sortDirection === 'desc' ? 'asc' : 'desc';
          } else {
            this.props.adminStore.teamMemberTableData.queryParams.sortCol = heading;
            this.props.adminStore.teamMemberTableData.queryParams.sortDirection = 'desc';
          }
          this.props.adminStore.teamMemberTableData.queryParams.activePg = 1;
          this.props.adminStore.getTeamMemberTableData(this.state.team);
        }
      };

      // /**
      //  * Sets page number from pagination in store for service call query params
      //  */
      pagChangePg = (pg) => {
        this.props.adminStore.teamMemberTableData.queryParams.activePg = pg;
        this.props.adminStore.getTeamMemberTableData(this.state.team);
      };

      // /**
      //  * Sets search value in store for service call searchQuery
      //  */
      setSearch = (event, data) => {
        this.props.adminStore.teamMemberTableData.queryParams.searchQuery = data.value;
      };

      sendSearch = () => {
        this.props.adminStore.getTeamMemberTableData(this.state.team, true);
      };

      handleError = (e) => {
        if (e.response.status === 403) {
          this.props.commonStore.triggerToast('error', {
            errorCode: e.response.status,
          });
        } else {
          this.props.commonStore.triggerToast('error');
        }
      };

      // /**
      //  * Changes the user's role on the team
      //  */
      changeRole = (value) => {
        this.props.adminStore
          .changeUserRoles(this.props.adminStore.teamMemberTableData.selectedRows, value, this.state.team)
          .then(() => {
            this.props.commonStore.triggerToast('success', {
              content: 'User role has been updated for this team',
            });
            // After adding user to new role, get new data set with updated info
            this.props.adminStore.getTeamMemberTableData(this.state.team, false, true);
          })
          .catch((e) => {
            this.handleError(e);
          });
      };

      changeRoleConfirm = (e) => {
        if (this.props.adminStore.teamMemberTableData.selectedRows.length) {
          const currState = {
            ...this.state,
            selectedRole: e.value,
          };
          this.setState(currState, () =>
            this.props.commonStore.triggerConfirm({
              content: 'Are you sure you want to change user roles for this team?',
              cancel: () => {
                const newState = { ...this.state, selectedRole: null };
                this.setState(newState);
                this.props.commonStore.resetConfirmState();
              },
              continue: () => {
                this.props.commonStore.resetConfirmState();
                this.changeRole(e.value);
              },
            })
          );
        }
      };

      // /**
      //  * Removes members from the team
      //  */
      removeMembers = () => {
        if (this.props.adminStore.teamMemberTableData.selectedRows.length) {
          this.props.adminStore
            .removeMembers(this.state.team, this.props.adminStore.teamMemberTableData.selectedRows)
            .then(() => {
              this.props.commonStore.triggerToast('success', {
                content: 'User(s) removed from team',
              });
              // After removing user to new group, get new data set with updated info
              this.props.adminStore.getTeamDetailsData(this.state.team);
              this.props.adminStore.getTeamMemberTableData(this.state.team, false, true);
            })
            .catch((e) => {
              this.handleError(e);
            });
        }
      };

      showRemoveConfirm = () => {
        this.props.commonStore.triggerConfirm({
          content: 'Are you sure you want to remove users from this team?',
          cancel: () => this.props.commonStore.resetConfirmState(),
          continue: () => {
            this.props.commonStore.resetConfirmState();
            this.removeMembers();
          },
        });
      };

      // /**
      //  * Deletes the team
      //  */
      deleteTeam = () => {
        this.props.adminStore
          .deleteTeam(this.state.team)
          .then(() => {
            this.props.commonStore.triggerToast('success', {
              content: 'Team has been deleted. Redirecting back to the team browse page',
            });
            // After deleting team, redirect back to browse page
            setTimeout(() => {
              this.props.navigate('/admin/teams/');
            }, 3000);
          })
          .catch((e) => {
            this.handleError(e);
          });
      };

      showDeleteConfirm = () => {
        this.props.commonStore.triggerConfirm({
          content: 'WOAH, hold on. Are you sure you want to DELETE this team?',
          cancel: () => this.props.commonStore.resetConfirmState(),
          continue: () => {
            this.props.commonStore.resetConfirmState();
            this.deleteTeam();
          },
        });
      };

      toggleModal = (modal, action) => {
        const currState = {
          ...this.state,
        };
        currState[modal] = action;
        this.setState(currState);
      };

      addMemberCloseModal = (response) => {
        this.toggleModal('addMembersModalOpen', false);
        if (response === 'success') {
          this.props.commonStore.triggerToast('success', {
            content: 'User has been added to team',
          });
          this.props.adminStore.setDefaultTeamMemberTableData();
          this.props.adminStore.getTeamMemberTableData(this.state.team, true);
          this.props.adminStore.getTeamDetailsData(this.state.team);
        } else {
          this.props.commonStore.triggerToast('error');
        }
      };

      formatLicenseCol = (col, row) => {
        if (!col.value) {
          return null;
        }
        const memberLicenses = row[5].value?.reduce((acc, license) => {
          acc.push(license?.team_content_license_grant_name || getPackageNames(license?.package_types));
          return acc;
        }, []);
        return <div className="flex items-center">{memberLicenses?.length ? <div>{memberLicenses.join(', ')}</div> : ''}</div>;
      };

      formatNameToLink = (col, row) => {
        const name = col?.value;
        if (!name) {
          return 'N/A';
        }

        const id = row?.[0]?.value;
        if (!id) {
          return name;
        }

        return (
          <AddLink className="font-semibold text-cyb-pink-500 hover:text-cyb-pink-600 underline" to={`/admin/user/${id}/dashboard`} title={`View ${name}'s profile`}>
            <span className="sr-only">View</span>
            {name}
            <span className="sr-only"> member dashboard</span>
          </AddLink>
        );
      };

      formatEmailCol = (col) => {
        const memberEmail = col.value;

        return (
          <AddLink title={memberEmail} to={`mailto:${memberEmail}`}>
            <span className="sr-only">Email</span>
            <div className="max-w-[190px] truncate">{memberEmail}</div>
          </AddLink>
        );
      };

      getTeamMembersTable = (data) => {
        if (data.loading) {
          return (
            <div>
              <Loading message="Loading..." />
            </div>
          );
        }
        if (data.error) {
          return (
            <div>
              <StyledError error={data.error} />
            </div>
          );
        }
        if (!data.totalRecords) {
          return <p className="no-results">No members on team!</p>;
        }
        return (
          <AdminTable
            /* Table Data */
            headings={data.tableHeadings}
            data={data.tableData}
            tableLoading={data.loading}
            tableError={data.error}
            displayCheckBox
            headerCheckBoxFunc={this.headerCheckBoxFunc}
            headerClickFunc={this.tableSort}
            sortCol={data.queryParams.sortCol}
            sortDirection={data.queryParams.sortDirection}
            rowCheckBoxFunc={this.handleCheckBoxes}
            headerCheckBoxState={data.headerCheckBox}
            checkBoxesState={data.ckBoxesState}
            formatColumns={[
              {
                method: this.formatNameToLink,
                colIdx: FormatUtil.getColIndex(data.tableHeadings, 'name'),
              },
              {
                method: this.formatEmailCol,
                colIdx: FormatUtil.getColIndex(data.tableHeadings, 'email'),
              },
              {
                method: this.formatLicenseCol,
                colIdx: FormatUtil.getColIndex(data.tableHeadings, 'license'),
              },
            ]}
            /* Pagination options */
            pagNumPgs={data.pagPagesCount}
            pagChangePg={this.pagChangePg}
            pagActivePg={data.queryParams.activePg}
            ignoredCols={[FormatUtil.getColIndex(data.tableHeadings, 'groups')]}
            loadingAsTable
            className="my-0 mt-2 mb-24 w-full"
            wrapperClassName="my-0 mt-2"
          />
        );
      };

      getTableOptions = () => {
        return (
          <div className="grid grid-cols-12 view-team-options">
            <div className="col-span-8">
              <div className="flex gap-x-2 items-center">
                <Button color="green" className="flex gap-x-2 items-center" onClick={() => this.toggleModal('addMembersModalOpen', true)}>
                  <Icon name="user-plus" />
                  Add Members
                </Button>
                <Button color="red" className="flex gap-x-2 items-center" onClick={this.showRemoveConfirm}>
                  <Icon name="user-minus" />
                  Remove Members
                </Button>
                <Dropdown
                  classes="w-64 inline-block ml-4"
                  placeholder="Change Role"
                  options={getRoleOptions(true)}
                  value={this.props.adminStore.roleSelected}
                  onChange={this.changeRoleConfirm}
                />
              </div>
            </div>
            <div className="col-span-4">
              <div className="flex justify-end">
                <form className="relative" onSubmit={(e) => e.preventDefault()}>
                  <Input
                    className="py-2	px-4 w-11/12 max-w-lg text-sm text-black rounded-sm border border-gray-400 md:w-40 lg:w-72"
                    placeholder="Search"
                    onChange={this.setSearch}
                    value={this.props.adminStore.teamMemberTableData?.queryParams?.searchQuery}
                  />
                  <button onClick={this.sendSearch} className="absolute top-3 right-2">
                    <Icon name="search" className="w-4 h-4" />
                  </button>
                </form>
                <button
                  onClick={() => this.props.adminStore.exportMemberData(this.state.team)}
                  className="p-2 ml-2 text-sm font-bold text-center text-black bg-gray-200 rounded-sm"
                >
                  <Icon name="download" />
                </button>
                <AddLink
                  className="p-2 ml-2 text-sm font-bold text-center text-white hover:text-white bg-cyb-pink-500 hover:bg-cyb-pink-600 rounded-sm"
                  to={`/admin/view-team/${this.state.team}/logs`}
                >
                  <ClipboardList classes="w-6 h-6" />
                </AddLink>
              </div>
            </div>
          </div>
        );
      };

      startTeamLarping = () => {
        const orgId = this.state.team;
        this.props.adminStore.startLarping(this.props.userStore, orgId, this.props.navigate);
      };

      setSsoStatus = async (enabled) => {
        const updatedState = { ...this.state, ssoButton: true };
        this.setState(updatedState);
        try {
          await Agents.admin.setSsoStatus({ enabled }, this.state.team);
          this.props.adminStore.teamDetailsData.data.sso.enabled = enabled;
          this.props.commonStore.triggerToast('success', {
            content: `Team SSO has been ${enabled ? 'enabled' : 'disabled'} successfully`,
          });
        } catch (e) {
          this.props.commonStore.triggerToast('error', {
            content: 'Something went wrong. Please try again.',
          });
        }
        this.props.adminStore.getTeamDetailsData(this.props.match.params.id);
        this.setState({ ...updatedState, ssoButton: false });
      };

      render() {
        const { denyAccess, loading, teamDetailsData } = this.props.adminStore;
        // If we should deny access, just get out now
        if (denyAccess) {
          return <Navigate to="/" />;
        }
        if (this.props.adminStore.teamDetailsData.loading || loading) {
          return (
            <div>
              <CybraryAdminLogo />
              <Loading message="Loading..." />
            </div>
          );
        }
        if (!this.props.adminStore.teamDetailsData.data) {
          return null;
        }
        const { name, member_count, team_type, license_source, status, domains, salesforce_id, hubspot_id, sso, created_at } = teamDetailsData.data;
        const isActiveTeam = status === 'Active';
        return (
          <Container size="xl" className="mt-16 admin-view-team">
            <Breadcrumbs crumbs={this.getBreadCrumbs()} sticky />
            <div className="grid grid-cols-12 mb-8">
              <div className="flex col-span-2 justify-center">
                <div className="w-44 h-44">
                  <OrganizationLogo
                    className="flex justify-center items-center w-full h-full"
                    teamId={this.state.team}
                    logoUrl={this.props.adminStore.teamDetailsData.data.logo_url}
                  />
                </div>
              </div>
              <div className="col-span-10 header-container">
                <div className="grid grid-cols-12">
                  <div className="col-span-10">
                    <div className="flex flex-col gap-x-2 items-start mb-8">
                      <Header as="h1" className="mb-2" omitPadding>
                        {name}
                      </Header>
                      <div className="flex gap-x-2 items-center pl-2 text-sm">
                        <span className="flex gap-x-2 items-center text-sm" title="Total Members">
                          <Icon name="user-group" className="w-4 h-4" /> {member_count} Members
                        </span>
                        <span className="text-gray-500">|</span>
                        <span className={`flex gap-x-1 items-center font-bold text-sm ${isActiveTeam ? 'text-green-500' : 'text-red-500'}`} title="Team Status">
                          <Icon name={isActiveTeam ? 'check' : 'x-circle'} className="w-6 h-6" /> {status}
                        </span>
                        <span className="text-gray-500">|</span>
                        <span className="flex gap-x-2 items-center text-sm" title="Team Type">
                          <Icon name="list" className="w-4 h-4" /> {team_type.name}
                        </span>
                        <span className="text-gray-500">|</span>
                        <span className="flex gap-x-1 items-center text-sm text-gray-500" title="Team Creation Date">
                          <Icon name="user-plus" className="w-4 h-4" />
                          Joined {moment(created_at).format('MMMM D, YYYY')}
                        </span>
                      </div>
                    </div>
                    <div className="grid grid-cols-2">
                      <div className="flex flex-col gap-x-2">
                        <Divider horizontal className="mr-4 mb-4">
                          License Grants
                        </Divider>
                        <OrganizationLicenseGrants isCybraryAdmin />
                      </div>
                      <div>
                        <Divider horizontal className="mr-4 mb-4">
                          Team Data
                        </Divider>
                        {salesforce_id ? (
                          <Header as="h3" className="mb-0">
                            Salesforce ID: <input readOnly className="p-0.5 font-normal border-none" value={salesforce_id} />
                          </Header>
                        ) : null}
                        {hubspot_id ? (
                          <Header as="h3" className="mb-0">
                            Hubspot ID: <input readOnly className="p-0.5 font-normal border-none" value={hubspot_id} />
                          </Header>
                        ) : null}
                        {license_source === 'chargebee' && <ChargebeeLabel basic className="ml-1 capitalize" />}
                        <Header as="h3" className="mb-0">
                          Email Domains:
                        </Header>
                        <ConfiguredDomains domains={domains} />
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col col-span-2 gap-y-2">
                    <Button href={`/admin/edit-team/${this.state.team}`} color="pink" icon={{ name: 'wrench', className: 'inline-block mr-2 w-4 h-4 fill-current text-white' }}>
                      Edit Team
                    </Button>

                    <Button color="gray" onClick={() => this.startTeamLarping()}>
                      <Icon name="spy" className="inline-block mr-2 w-4 h-4" />
                      LARP
                    </Button>
                    <Button href={`/admin/view-team/${this.state.team}/manage-programs`} color="transparent-gray" icon={{ name: 'book', className: 'inline-block mr-2 w-4 h-4' }}>
                      Manage Programs
                    </Button>
                    {sso ? (
                      <Button
                        className="mt-4 w-full"
                        loading={this.state.ssoButton}
                        disabled={this.state.ssoButton}
                        color={sso.enabled ? 'red' : 'green'}
                        onClick={() => this.setSsoStatus(!sso.enabled)}
                      >
                        {`${sso.enabled ? 'Disable' : 'Enable'} SSO`}
                      </Button>
                    ) : null}
                    <Tooltip
                      omitTabIndex
                      triggerContent={
                        <Button color="transparent-gray" onClick={this.copyInviteLink} icon={{ name: 'clipboard-list', className: 'inline-block mr-2 w-4 h-4' }}>
                          Copy Invite Link
                        </Button>
                      }
                      trigger="click"
                      content="Invite link copied!"
                      position="bottom"
                    />
                  </div>
                </div>
              </div>
            </div>
            <>{this.getTableOptions()}</>
            {this.getTeamMembersTable(this.props.adminStore.teamMemberTableData)}
            <Button color="red" className="flex gap-x-2 items-center" onClick={this.showDeleteConfirm}>
              <Icon name="x-circle" /> Delete Team
            </Button>
            <Modal
              open={this.state.addMembersModalOpen}
              toggle={() => this.toggleModal('addMembersModalOpen', false)}
              size="lg"
              className="p-4 view-team-add-members"
              ariaLabelledBy="add-members-modal"
            >
              <h2 className="mb-4 text-2xl font-bold" id="add-members-modal">
                Add Members
              </h2>
              <AdminTeamOnboardingTools team={this.state.team} teamName={name} addMember={this.addMemberCloseModal} />
            </Modal>
          </Container>
        );
      }
    }
  )
);

export default withRouter(CybraryAdminViewTeam);
