import { inject, observer } from 'mobx-react';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Dropdown from '../../components/Dropdown/Dropdown';
import { getRoleOptions } from '../../utils/teamsUtil';
import BugsnagUtil from '../../utils/bugsnagUtil';
import FormatUtil from '../../utils/formatUtil';

/**
 *
 * @param {int} memberUserId The team member's user id to manage roles for.
 * @param {string} memberName The name of the team member to manage roles for. Default is 'User'.
 * @param {function} onSave A function to call after the role is updated. Used to refresh data after save.
 * @param {string} memberRole The default role to show for the team member.
 * @returns
 */
function ManageMemberRoleComponent({ enterpriseStore, commonStore, userStore, memberUserId, isGroupScope, memberRole, onSave, memberName, isDisabled }) {
  const [isLoading, setIsLoading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const [value, setValue] = useState(memberRole);
  const { team } = userStore || {};
  const { reportsTeamGroupId, permissions } = team || {};
  const isOrgOwner = team?.role === 'org-owner';
  const { canManageAdmins } = permissions || {};

  const changeUserRole = useCallback(
    (option) => {
      const toastMessage = `${memberName || 'This user'} is now a${['Admin', 'Owner'].includes(option?.label) ? 'n' : ''} ${option?.label}!`;
      setIsSaving(true);
      setIsLoading(true);
      const groupOrTeamId = isGroupScope ? reportsTeamGroupId : team?.id;
      enterpriseStore
        .changeUserRoles(memberUserId, option?.value, groupOrTeamId)
        .then(async () => {
          setValue(option?.value);
          setIsSaving(false);
          commonStore.triggerToast('success', {
            content: toastMessage,
          });
          if (onSave && typeof onSave === 'function') {
            await onSave();
          }
        })
        .catch((e) => {
          commonStore.triggerToast('error', {
            errorCode: e.response.status,
          });
          BugsnagUtil.notify(e);
        })
        .finally(() => {
          setIsLoading(false);
          setIsSaving(false);
        });
    },
    [enterpriseStore, commonStore, memberUserId, reportsTeamGroupId, memberName]
  );

  // This is a hack to let parent components update the value when they get new data. Otherwise, we use local state.
  useEffect(() => {
    setValue(memberRole);
  }, [memberRole]);

  const changeUserRoleConfirm = useCallback(
    (option) => {
      commonStore.triggerConfirm({
        content: (
          <p>
            Are you sure you want to make <b>{memberName || 'this user'}</b> a{['Admin', 'Owner'].includes(option?.label) ? 'n' : ''} <b>{option?.label}</b>?
          </p>
        ),
        cancel: () => commonStore.resetConfirmState(),
        continue: () => {
          commonStore.resetConfirmState();
          changeUserRole(option);
        },
      });
    },
    [commonStore, memberName, changeUserRole]
  );

  const options = useMemo(() => getRoleOptions(isOrgOwner, isGroupScope, canManageAdmins), [isOrgOwner, isGroupScope, canManageAdmins]);

  const isButtonDisabled = isLoading || isSaving || isDisabled;

  // If the user does not have permission to manage admins, just show the current role
  if (!canManageAdmins || isDisabled) {
    return <div>{FormatUtil.formatRole(memberRole, isGroupScope ? 'Group' : 'Team')}</div>;
  }

  return (
    <Dropdown
      options={options}
      onChange={changeUserRoleConfirm}
      value={!(isLoading || isSaving) ? value : ' '}
      placeholder={isSaving ? 'Saving...' : 'Loading...'}
      loading={isLoading || isSaving}
      disabled={isButtonDisabled}
      classes="bg-white"
      wrapperClasses="w-full"
    />
  );
}

const ManageMemberRole = inject('enterpriseStore', 'userStore', 'commonStore')(observer(ManageMemberRoleComponent));

export default ManageMemberRole;
