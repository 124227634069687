import React from 'react';
import moment from 'moment';
import { twMerge } from 'tailwind-merge';
import AddLink from '../components/AddLink/AddLink';
import Tooltip from '../components/Tooltip/Tooltip';
import Button from '../components/Button/Button';
import Header from '../components/Header/Header';
import ManageMemberRole from '../pages/Enterprise/ManageMemberRole';
import ManageMemberLicenses from '../pages/Enterprise/ManageMemberLicenses';
import If from '../components/If/If';
import Icon from '../components/Icon/Icon';
import FormatUtil, { getPackageNames } from './formatUtil';

export const formatNameToLink = (col, row, orgId, memberIdColIndex = 0) => {
  const name = col?.value;
  if (!name) {
    return 'N/A';
  }

  const isDemo = orgId === 'demo';

  const id = row?.[memberIdColIndex]?.value;
  if (!id) {
    return name;
  }

  return (
    <AddLink
      className={`text-cyb-pink-500 hover:text-cyb-pink-600 underline ${isDemo ? 'cursor-not-allowed' : ''}`}
      to={isDemo ? null : `/enterprise/${orgId}/organization/member/${id}/dashboard`}
      title={`View ${name}'s profile`}
    >
      <span className="sr-only">View</span>
      {name}
      <span className="sr-only"> member dashboard</span>
    </AddLink>
  );
};

export const formatGroupsToLink = (col, row, orgId, commonStore, navigate) => {
  const isDemo = orgId === 'demo';

  if (!col.value || !col.value.length) {
    return (
      <div className="pl-6 text-gray-500" title="No groups">
        -
      </div>
    );
  }
  const groups = col.value;
  const memberName = row[1].value;
  if (groups.length === 1) {
    const group = groups[0];
    return (
      <Tooltip
        omitTabIndex
        triggerContent={
          <AddLink
            className={`text-cyb-pink-500 hover:text-black underline ${isDemo ? 'cursor-not-allowed' : ''}`}
            to={isDemo ? null : `/enterprise/${orgId}/organization/dashboard?group=${group.id}`}
          >
            {group.name}
          </AddLink>
        }
        content="View Dashboard"
      />
    );
  }
  return (
    <Button
      color="transparent-gray"
      onClick={() =>
        commonStore.triggerConfirm({
          content: (
            <div>
              <Header as="h2" id="user-groups" className="mb-1">
                {memberName || 'User'}
              </Header>
              <div className="ml-2 text-sm text-gray-500">{groups.length} Groups</div>
              <div className="flex flex-col gap-y-2 mt-2 ml-4 text-xl responsive-table-container">
                {groups.map((group) => {
                  const groupUrl = `/enterprise/${orgId}/organization/dashboard?group=${group.id}`;
                  const onClick = () => {
                    navigate(groupUrl);
                    commonStore.resetConfirmState();
                  };
                  return (
                    <div key={group.name} className="flex space-x-4">
                      <AddLink
                        className={`hover:text-cyb-pink-500 text-gray-900 hover:underline flex items-center text-sm ${isDemo ? 'cursor-not-allowed' : ''}`}
                        onClick={isDemo ? null : onClick}
                        title={`View ${group.name} dashboard`}
                      >
                        {group.name}
                      </AddLink>
                    </div>
                  );
                })}
              </div>
            </div>
          ),
          cancel: () => commonStore.resetConfirmState(),
          continue: isDemo
            ? null
            : () => {
                commonStore.resetConfirmState();
                navigate(`/enterprise/${orgId}/organization/member/${row[0].value}/dashboard`);
              },
          confirmBtn: 'View Member',
          cancelBtn: 'Close',
        })
      }
      className="py-0 pl-0 font-normal text-cyb-pink-600 underline whitespace-nowrap"
    >
      {`${groups.length} Groups`}
    </Button>
  );
};

export const formatRoleToDropdown = (col, row, orgId, enterpriseStore) => {
  if (!col.value) {
    return null;
  }
  const isDemo = orgId === 'demo';
  const memberUserId = row[0].value;
  const memberName = row[1].value || 'User';
  const memberRole = col.value;
  return (
    <div className="flex items-center min-w-[120px]">
      <ManageMemberRole
        memberUserId={memberUserId}
        memberName={memberName}
        memberRole={memberRole}
        onSave={async () => {
          await enterpriseStore.getTeamMemberTableData(orgId, false, true, false);
        }}
        isDisabled={isDemo}
      />
    </div>
  );
};

export const formatLicenseToDropdown = (col, row, orgId, enterpriseStore, userStore) => {
  if (!col.value) {
    return null;
  }
  const isDemo = orgId === 'demo';
  const memberUserId = row[0]?.value;
  const memberName = row[1]?.value || 'User';
  const memberLicenses = row[5]?.value?.reduce((acc, license) => {
    if (license?.team_content_license_grant_id) {
      acc.push(license?.team_content_license_grant_id);
    }
    return acc;
  }, []);

  if (isDemo) {
    const memberLicenseNames = row[5]?.value?.reduce((acc, license) => {
      if (license?.team_content_license_grant_id) {
        acc.push(license?.team_content_license_grant_name);
      }
      return acc;
    }, []);
    return <div className="flex items-center">{memberLicenseNames?.length > 0 ? memberLicenseNames.join(', ') : 'Team Full, Security Awareness'}</div>;
  }

  return (
    <div className="flex items-center">
      <ManageMemberLicenses
        memberUserId={memberUserId}
        memberName={memberName}
        onSave={async () => {
          await enterpriseStore.getTeamMemberTableData(orgId, false, true, false);
          await userStore.loadUserMeta();
          await userStore.loadAndSetTeamData(orgId, userStore.team.role);
        }}
        memberLicenses={memberLicenses}
      />
    </div>
  );
};

export const formatEmailToLink = (col) => {
  const memberEmail = col.value;
  return (
    <AddLink title={memberEmail} to={`mailto:${memberEmail}`}>
      <span className="sr-only">Email</span>
      <div className="max-w-[190px] truncate">{memberEmail}</div>
    </AddLink>
  );
};

export const formatLicensedOnToModal = (col, row, orgId, commonStore, navigate) => {
  if (!col.value) {
    return null;
  }
  const memberLicenses = row[5].value;
  const memberName = row[1].value;
  return (
    <div className="flex items-center">
      <If condition={memberLicenses.length > 0}>
        <Button
          color="transparent-gray"
          onClick={() =>
            commonStore.triggerConfirm({
              content: (
                <div>
                  <Header as="h2" id="user-groups">
                    {memberName || 'User'}
                  </Header>
                  <div className="mt-8 responsive-table-container">
                    {memberLicenses.map((license) => {
                      const packageNames = license?.team_content_license_grant_name || getPackageNames(license?.package_types);
                      return (
                        <div key={license} className="grid grid-cols-5 items-center pb-4 last:pb-0 mb-4 last:mb-0 space-x-4 border-b last:border-b-0 border-gray-200">
                          <div className="flex flex-col col-span-3 items-start">
                            <Header as="h3" className="mb-0">
                              {packageNames}
                            </Header>
                            <span className="text-cyb-pink-500">{getPackageNames(license?.package_types)}</span>
                          </div>
                          <div className="flex flex-col col-span-2 items-start">
                            <span className="flex gap-x-1 items-center text-gray-600" title="Licensed On Date">
                              <Icon name="calendar" className="shrink-0 mr-1 w-4 h-4 whitespace-nowrap" />
                              <span className="font-semibold">{moment(license?.granted_at).format('MMMM D, YYYY')}</span>
                            </span>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              ),
              cancel: () => commonStore.resetConfirmState(),
              continue: () => {
                commonStore.resetConfirmState();
                navigate(`/enterprise/${orgId}/organization/member/${row[0].value}/dashboard`);
              },
              confirmBtn: 'View Member Dashboard',
              cancelBtn: 'Close',
            })
          }
          className="py-0 pl-0 font-normal text-cyb-pink-600 underline whitespace-nowrap"
        >
          {moment(col.value).format('MM/DD/YYYY')}
        </Button>
      </If>
    </div>
  );
};

export const formatTeamRole = (col) => {
  if (col?.value) {
    return FormatUtil.convertRoleName(col.value, 'Team');
  }
  return null;
};

export const formatGroupRole = (col) => {
  if (col?.value) {
    return FormatUtil.convertRoleName(col.value, 'Group');
  }
  return null;
};

export const formatBoldColumnValue = (col, row, className = '') => {
  const classes = twMerge('font-bold', className);
  return <span className={classes}>{col.value}</span>;
};

export const formatSemiBoldColumnValue = (col, row, className = '') => {
  const classes = twMerge('font-semibold', className);
  return <span className={classes}>{col.value}</span>;
};

export const formatEventTimestamp = (col) => {
  if (!col.value) {
    return '-';
  }
  return moment.utc(col.value).format('MM/DD/YYYY HH:mm:ss');
};
