import React, { useEffect, useState, useCallback } from 'react';
import { inject, observer } from 'mobx-react';
import { debounce } from 'lodash';
import Cookies from 'js-cookie';
import { useParams } from 'react-router-dom';
import withRouter from '../../components/Router/withRouter';
import Title from '../../components/Title/Title';
import CurriculaList from '../../components/Enterprise/CurriculaList';
import ContentUpdateBanner from '../../components/ContentUpdates/ContentUpdateBanner';
import AddLink from '../../components/AddLink/AddLink';
import Container from '../../components/Container/Container';
import ResultsSummary from '../../components/AdminTable/ResultsSummary';
import SearchIcon from '../../Icons/SearchIcon';
import FormatUtil from '../../utils/formatUtil';
import './enterprise.css';
import Dropdown from '../../components/Dropdown/Dropdown';
import ActionUtil from '../../utils/actionsUtil';
import UPTrigger from '../../components/UPTrigger/UPTrigger';
import { USERPILOT_EVENTS } from '../../constants';

const SORT_OPTIONS = [
  { value: 'dateDesc', text: 'Date Created (Newest First)' },
  { value: 'dateAsc', text: 'Date Created (Oldest First)' },
  { value: 'nameAsc', text: 'Name (A-Z)' },
  { value: 'nameDesc', text: 'Name (Z-A)' },
];

const FilteredCurriculaList = observer(({ filterValue, userId, orgId, view, curricula }) => {
  const [filtered, setFiltered] = useState([]);
  const [sortBy, setSortBy] = useState(Cookies.get('cyb_curriculaSortBy') || 'dateDesc');

  const handleSortChange = ({ value }) => {
    // Toss it into a cookie so we can remember it
    Cookies.set('cyb_curriculaSortBy', value);
    setSortBy(value);
  };

  // Initialize to the full list
  useEffect(() => {
    if (!!curricula && !!curricula[view]) {
      let sorted = [...curricula[view]];

      if (view === 'assessments') {
        sorted = sorted.filter((item) => item.in_team_catalog || item.status === 'Active');
      }

      // Apply sorting
      switch (sortBy) {
        case 'nameAsc':
          sorted.sort((a, b) => a.name.localeCompare(b.name));
          break;
        case 'nameDesc':
          sorted.sort((a, b) => b.name.localeCompare(a.name));
          break;
        case 'dateAsc':
          sorted.sort((a, b) => a.created_at.localeCompare(b.created_at));
          break;
        case 'dateDesc':
        default:
          sorted.sort((a, b) => b.created_at.localeCompare(a.created_at));
          break;
      }
      setFiltered(sorted);
    }
  }, [view, curricula[view], sortBy]);

  // Whenever filterValue changes, update the list
  useEffect(() => {
    if (curricula && curricula[view]) {
      const newFiltered = curricula[view].filter((item) => {
        // Just leave if we have no filter value, every item passes
        if (filterValue === '' || typeof filterValue === 'undefined') {
          return true;
        }
        const { name, description } = item || {};
        // First check the name
        if (name && name.toLowerCase().includes(filterValue.toLowerCase())) {
          return true;
        }
        // Next, check the description
        if (description && description.toLowerCase().includes(filterValue.toLowerCase())) {
          return true;
        }
        return false;
      });
      setFiltered(newFiltered);
    }
  }, [filterValue]);
  return (
    <>
      <div className="flex justify-between">
        <div>
          <ResultsSummary srOnly={!filterValue || !filterValue.length} numOfResults={filtered.length} />
        </div>
        <div>
          <label className="pb-0 mb-0" htmlFor="sort-by-selector">
            Sort By:
          </label>
          <Dropdown
            classes="md:w-72"
            name="Sort By"
            id="sort-by-selector"
            hideSelectedOptions={false}
            onChange={(value) => handleSortChange(value)}
            value={sortBy}
            options={SORT_OPTIONS}
          />
        </div>
      </div>
      <CurriculaList userId={userId} orgId={orgId} view={view} curricula={filtered} loading={curricula.loading} error={curricula.error} />
    </>
  );
});

const getUpdatedBannerContent = (view) => {
  switch (view) {
    case 'cybrary':
      return "We've improved one or more of Cybrary's Paths! Click the blue banner to see what's changed.";
    case 'team':
    default:
      return 'We\'ve improved content items contained in your custom paths! Click "View Details" on the paths flagged with updates.';
  }
};

const getHeading = (view) => {
  switch (view) {
    case 'team':
      return 'Team Paths';
    case 'assessments':
      return 'Assessment Paths';
    case 'cybrary':
      return 'Cybrary Paths';
    default:
      return 'My Paths';
  }
};

const Curricula = inject(
  'userStore',
  'enterpriseStore',
  'commonStore',
  'authStore',
  'notificationsStore'
)(
  observer(({ userStore, enterpriseStore, commonStore, authStore, notificationsStore }) => {
    const { section, orgId } = useParams();

    const [currentView, setCurrentView] = useState(section ?? 'user');
    const [filterValue, setFilterValue] = useState('');
    const [title, setTitle] = useState(getHeading(currentView));
    const isDemo = orgId === 'demo';

    const { curricula } = enterpriseStore;
    const { team, user } = userStore;
    const currentOrgId = team?.id;
    const { contentNotifications } = notificationsStore || {};
    const notifications = contentNotifications?.Paths[currentOrgId] || 0;
    const canManageCurricula = team?.permissions?.canManageCurricula;
    const showFilter = curricula && curricula[currentView]?.length;

    const handleFilterValueChange = useCallback(
      debounce((newValue) => {
        setFilterValue(newValue);
      }, 500),
      []
    );

    useEffect(() => {
      setTitle(getHeading(section));
      setCurrentView(section ?? 'user');
    }, [section]);

    useEffect(() => {
      if (isDemo) {
        userStore.enterDemo();
        enterpriseStore.enterDemo();
      } else {
        userStore.setPreferredTeamInit('', orgId);
        enterpriseStore.loadCurricula(orgId, user?.id, team?.package_types);
        notificationsStore.getUserNotifications(userStore);
      }

      // Set the window title
      const pageSection = currentView === 'user' ? 'my' : currentView;
      const formattedPageSection = pageSection ? FormatUtil.ucwords(pageSection) : '';
      commonStore.setPageTitle(`${formattedPageSection} Paths${isDemo ? ' Demo' : ''} | Cybrary`);

      // Set the heading
      setTitle(getHeading(currentView));

      // Clear the filter
      setFilterValue('');

      authStore.fireAttributionEvent();
      ActionUtil.scrollToTop();

      return () => {
        enterpriseStore.reset();
        if (isDemo) {
          userStore.exitDemo();
          enterpriseStore.exitDemo();
        }
      };
    }, [orgId, userStore?.user, currentView]);

    useEffect(() => {
      const newView = section ?? 'user';
      if (currentView !== newView) {
        setCurrentView(newView);
      }
    }, [orgId, section, userStore]);

    return (
      <Container size="lg" className="curricula">
        <div className="pt-16 mb-4 sm:pb-4">
          <div className="items-center sm:flex">
            <Title title={title} omitPadding wrapperClasses="grow" />
            {canManageCurricula && (
              <div className="flex items-start">
                <AddLink
                  to={isDemo ? '#' : `/enterprise/${currentOrgId}/paths/create`}
                  className={`py-2.5 px-6 text-sm font-bold leading-5 text-center text-white hover:text-white bg-cyb-pink-500 hover:bg-cyb-pink-600 rounded-sm ${
                    isDemo ? 'cursor-not-allowed' : ''
                  }`}
                  disabled={isDemo}
                >
                  + Add Path
                </AddLink>
                <UPTrigger guideId={USERPILOT_EVENTS.ADD_PATH_BUTTON} className="-mt-5 -ml-1" />
              </div>
            )}
          </div>
          {!!showFilter && (
            <div className="mt-4 max-w-lg">
              <div className="flex py-2 px-4 bg-gray-200 rounded-full">
                <div className="shrink">
                  <SearchIcon classes="w-6 h-6 text-gray-600" />
                </div>
                <div className="grow pl-4">
                  <input
                    type="text"
                    name="pathfilter"
                    placeholder="Filter the list of paths"
                    className="text-gray-600 placeholder:text-gray-600 bg-gray-200 border-0 focus:border-0 outline-none focus:outline-none"
                    onChange={(e) => handleFilterValueChange(e.target.value)}
                    aria-label="Filter the list of paths."
                    autoComplete="off"
                  />
                </div>
              </div>
            </div>
          )}
          {!!notifications && !!notifications[currentView] && (
            <div className="my-8 sm:mb-0">
              <ContentUpdateBanner
                page="Paths"
                notificationId={`notification_${currentView}-path-update_${currentOrgId}`}
                content={getUpdatedBannerContent(currentView)}
                cta="Ok, Got it"
                notificationsCount={notifications[currentView]}
              />
            </div>
          )}
        </div>
        <FilteredCurriculaList
          filterValue={filterValue}
          userId={userStore.user.id}
          orgId={currentOrgId}
          view={currentView}
          curricula={curricula}
          loading={curricula.loading}
          error={curricula.error}
        />
      </Container>
    );
  })
);

export default withRouter(Curricula);
