import React from 'react';
import { inject, observer } from 'mobx-react';
import moment from 'moment';
import { twMerge } from 'tailwind-merge';
import Header from '../../components/Header/Header';
import FormatUtil from '../../utils/formatUtil';
import Icon from '../../components/Icon/Icon';
import { HELP_DESK_LINKS, LICENSE_PACKAGE_TYPE_NICE_NAMES } from '../../constants';
import HelpDeskTrigger from '../../components/HelpDeskTrigger/HelpDeskTrigger';
import If from '../../components/If/If';
import Button from '../../components/Button/Button';

function OrganizationLicenseGrantsComponent({ userStore, adminStore, className, isCybraryAdmin = false }) {
  const { active_license_grants } = (isCybraryAdmin ? adminStore?.teamDetailsData?.data : userStore.team) || {};
  const classes = twMerge('grid grid-cols-1 gap-4 md:grid-cols-2', className);
  const isDemo = userStore.team?.id === 'demo';
  const handleZETrigger = () => (!isDemo && window.zE ? window.zE('webWidget', 'open') : null);

  return (
    <div>
      {!isCybraryAdmin && <Header as="h2">License Usage</Header>}
      <div className={classes}>
        {/** Empty State */}
        <If condition={!active_license_grants?.length}>
          <div className="flex flex-col justify-center items-start h-full">
            <Header as="h3">No active license grants</Header>
            <If condition={!isCybraryAdmin}>
              <p>Your team currently has no active license grants.</p>
              <If condition={window.zE}>
                <Button onClick={handleZETrigger}>Add Licenses</Button>
              </If>
              <If condition={!window.zE}>
                <Button href={HELP_DESK_LINKS.CYBRARY_FOR_TEAMS} target="_blank">
                  Contact Sales
                </Button>
              </If>
            </If>
          </div>
        </If>
        {/** Main content */}
        {active_license_grants?.map((grant) => {
          const activeSeats = grant?.active_seats ? FormatUtil.formatNumbers(grant?.active_seats) : 0;
          const maxSeats = grant?.max_seats ? FormatUtil.formatNumbers(grant?.max_seats) : 0;
          const packageNames = grant?.package_types
            .map((packageType) => LICENSE_PACKAGE_TYPE_NICE_NAMES[packageType] || FormatUtil.ucwords(packageType.split('-').join(' ').split('_').join(' ')))
            .join(', ');
          return (
            <div key={grant.name} className="flex-col justify-start items-center ml-4">
              {/** License Name */}
              <Header as="h3" className="flex gap-x-1 items-center mb-0">
                {grant.name || packageNames} <HelpDeskTrigger to={HELP_DESK_LINKS.TEAMS_HANDBOOK} className="" />
              </Header>
              {/** Package Types */}
              <div className="flex gap-x-2 items-center w-auto text-sm text-cyb-pink-500 ">
                {/** Hide icon if there is more than one package type */}
                {grant?.package_types?.length <= 3 && <Icon name="learning" className="shrink-0 mb-auto w-4 h-4 fill-current" />}
                <span className="flex gap-x-1 items-center text-xs">{packageNames}</span>
              </div>
              {/** Active / Max licenses */}
              <div className="flex flex-wrap gap-x-2 items-center text-sm">
                <span className="flex gap-x-1 items-center text-sm tracking-wider whitespace-nowrap" title="Active / Max licenses">
                  <Icon name="license" className="mr-1 w-4 h-4" />{' '}
                  <span className="pr-1 font-semibold">
                    {activeSeats} / {maxSeats}
                  </span>{' '}
                  licenses used
                </span>
                <If condition={grant?.license_expires_at}>
                  <span className="text-gray-500">|</span>
                  <span className="flex gap-x-2 items-center text-sm text-gray-500 whitespace-nowrap" title="License Expiration Date">
                    <Icon name="calendar" className="w-4 h-4" /> {moment(grant?.license_expires_at).format('MMMM D, YYYY')}
                  </span>
                </If>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

const OrganizationLicenseGrants = inject('userStore', 'adminStore')(observer(OrganizationLicenseGrantsComponent));

export default OrganizationLicenseGrants;
