import React from 'react';
import AddLink from '../AddLink/AddLink';
import EnrollmentButton from '../BrowsePromo/EnrollmentButton';

function CertificationCtaButton({ data, fluid }) {
  if (!data) {
    return null;
  }

  const isActiveStep = data.active;
  const stepColorClasses = isActiveStep ? 'text-white bg-cyb-pink-500 hover:bg-cyb-pink-600 hover:text-white' : 'text-black bg-gray-200 hover:bg-gray-300 hover:text-black';
  const fluidClasses = fluid ? 'w-full lg:w-auto' : '';
  if (data && data.href) {
    return (
      <AddLink className={`py-2.5 px-6 text-sm font-bold leading-5 text-center ${stepColorClasses} ${fluidClasses} rounded-sm`} to={data.href} target="_blank">
        {data.ctaText}
      </AddLink>
    );
  }

  if (data && data.contentData) {
    return <EnrollmentButton color={isActiveStep ? 'pink' : 'gray'} text={data.ctaText} content={data.contentData} buttonClasses={`${fluidClasses}`} />;
  }

  return null;
}

export default CertificationCtaButton;
