import React from 'react';
import { twMerge } from 'tailwind-merge';
import Loading from './Loading';
import CybraryLogo from '../CybraryLogo/CybraryLogo';

function LoadingSquareLogo({ message, wrapperClassName, logoClassName, className }) {
  const wrapperClass = twMerge('flex justify-center items-center mb-8', wrapperClassName);
  const logoClass = twMerge('mt-auto mr-0 mb-0 ml-2', logoClassName);
  const classes = twMerge('flex gap-y-4', className);
  return (
    <div className={wrapperClass}>
      <div className={classes}>
        <Loading message={message} square />
        <CybraryLogo className={logoClass} />
      </div>
    </div>
  );
}

export default LoadingSquareLogo;
