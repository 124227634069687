import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import queryString from 'query-string';
import { inject, observer } from 'mobx-react';
import Bugsnag from '@bugsnag/js';
import { useAuth } from '../../contexts/UseAuth';
import agents from '../../agents/agents';
import { flushCache } from '../../utils/eventCacheUtil';
import LoadingPage from '../../pages/LoadingPage/LoadingPage';

let intervalRef = null;

const handleMiscInit = async (userStore, userNotificationStore, goalsStore, commonStore, enrollmentStore, adminStore, authStore) => {
  if (userStore.user) {
    // Handle Larping
    if (adminStore.checkStorageLarping(sessionStorage)) {
      adminStore.setLarping(sessionStorage.larpData);
      if (!!sessionStorage && sessionStorage.toggleReleaseTag) {
        adminStore.setToggleReleaseTag(sessionStorage.toggleReleaseTag);
      }
      try {
        await userStore.loadAndSetTeamData(adminStore.larping.teamId, adminStore.larping.larpRole, true);
        userStore.setPreferredTeam();
        userStore.setPreferredGroups();
      } catch (e) {
        Bugsnag.notify(e);
      }
    }

    /** Setup interval checking for unread notifications */
    userNotificationStore.checkForUnread();
    const pollingInterval = 180 * 1000; // every 90 seconds for now
    // Setup the interval
    intervalRef = setInterval(() => {
      userNotificationStore.checkForUnread();
    }, pollingInterval);

    /* Goals Achievement Tracker */
    goalsStore.getUserGoalsList().then(() => {
      goalsStore.setCurrentTrackingGoal();
      if (!commonStore.immersiveView) {
        goalsStore.startTrackingGoalPolling();
      }
    });

    /* Load enrollment progress map */
    enrollmentStore.fetchEnrollmentProgressMap();

    /* Load User GeoData */
    authStore.getGeoInfo();

    /* Flush the event cache, just in case there's anything leftover to send */
    flushCache();
  }
};

const AppInit = inject(
  'userStore',
  'commonStore',
  'authStore',
  'goalsStore',
  'enrollmentStore',
  'userNotificationStore',
  'adminStore'
)(
  observer(({ userStore, commonStore, goalsStore, enrollmentStore, authStore, adminStore, userNotificationStore, children }) => {
    const [init, setInit] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    const queryParams = queryString.parse(location.search);
    const auth = useAuth();
    useEffect(() => {
      if (auth.user && authStore.token && !init) {
        /* Enrollment Sync */
        if (!authStore.enrollmentSyncStatus) {
          agents.enrollments.ensureSync().then(() => {
            authStore.completeEnrollmentSync();
          });
        }

        /* Post data retrieval actions */
        handleMiscInit(userStore, userNotificationStore, goalsStore, commonStore, enrollmentStore, adminStore, authStore);

        setInit(true);
      }

      /* Kill any polling intervals */
      return () => {
        if (intervalRef) {
          // Kill the interval
          clearInterval(intervalRef);
        }
        goalsStore.stopTrackingGoalPolling();
      };
    }, [auth.user, authStore.token]);

    useEffect(() => {
      /* Refresh User */
      if (init && queryParams.refreshUser) {
        auth.refreshUser();
        const newQueryParams = queryParams;
        delete newQueryParams.refreshUser;
        navigate({
          search: queryString.stringify(newQueryParams),
        });
      }
    }, [queryParams]);

    useEffect(() => {
      // If not immersive and we have a tracking goal, get progress and start polling
      if (!commonStore.isInImmersive && auth.user && authStore.token) {
        if (goalsStore.hasTrackingGoal) {
          goalsStore.getTrackingGoalProgress();
          goalsStore.startTrackingGoalPolling();
        }
      } else {
        goalsStore.stopTrackingGoalPolling();
      }
    }, [commonStore.isInImmersive]);

    if (auth.loading) {
      return <LoadingPage />;
    }

    return children;
  })
);

export default AppInit;
