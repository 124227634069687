import React, { useState, useEffect } from 'react';
import { twMerge } from 'tailwind-merge';
import fieldClasses from './fieldClasses';

function QuantityInput({
  id,
  name,
  ariaDescribedBy,
  required,
  defaultValue,
  error,
  onChange,
  className,
  inputContainerClassName,
  inputClassName,
  onFocus,
  onBlur,
  maxValue,
  minValue = 0,
  belowMinMessage,
  aboveMaxMessage,
  callback,
  disabled,
}) {
  const [input, setInput] = useState(defaultValue || minValue);
  const [showMinMessage, setShowMinMessage] = useState(false);
  const [showMaxMessage, setShowMaxMessage] = useState(false);

  useEffect(() => {
    if (defaultValue) {
      setInput(defaultValue);
    }
  }, [defaultValue]);

  const checkMinMaxInput = (val) => {
    let isValid = true;
    if (val < minValue) {
      setShowMinMessage(true);
      isValid = false;
    } else {
      setShowMinMessage(false);
    }
    if (maxValue && val > maxValue) {
      setShowMaxMessage(true);
      isValid = false;
    } else {
      setShowMaxMessage(false);
    }
    return isValid;
  };

  useEffect(() => {
    if (input !== undefined) {
      onChange(undefined, { name, value: input }, () => (callback ? callback(input) : null));
    }
    checkMinMaxInput(input);
  }, [input]);

  const decrease = () => {
    const proposedVal = Number(input) - 1;
    if (proposedVal >= minValue) {
      setInput(proposedVal);
    } else {
      setShowMinMessage(true);
    }
  };

  const increase = () => {
    const proposedVal = Number(input) + 1;
    if (!maxValue || proposedVal <= maxValue) {
      setInput(proposedVal);
    } else {
      setShowMaxMessage(true);
    }
  };

  const validateKeyPress = (e) => {
    const validKeys = ['Backspace', 'Tab', 'ArrowLeft', 'ArrowRight'];
    if (e.key === 'ArrowUp') {
      e.preventDefault();
      increase();
    } else if (e.key === 'ArrowDown') {
      e.preventDefault();
      decrease();
    } else if (!/^[0-9]\d*$/.test(Number(e.key)) && validKeys.indexOf(e.key) === -1) {
      e.preventDefault();
    }
  };

  const handleChange = (val) => {
    const isValid = checkMinMaxInput(val);
    if (isValid) {
      // Set input without any leading zeros
      setInput(parseInt(val, 10));
    }
  };

  const inputWidth = 'w-8';
  const buttonClasses = 'bg-white px-3 pb-1 text-2xl hover:bg-gray-200';
  const inputContainerClasses = twMerge('flex rounded border border-gray-300', inputContainerClassName);
  const inputClasses = twMerge(fieldClasses(error, disabled, inputWidth), 'mb-0 box-content border-none text-center	grow', inputClassName);
  return (
    <div className={`${className || ''} mb-2`}>
      <style>{'input::-webkit-outer-spin-button, input::-webkit-inner-spin-button { -webkit-appearance: none; }'}</style>
      <div className="md:flex md:gap-x-4 md:items-center">
        <div className={inputContainerClasses}>
          <button type="button" aria-label="Decrease quantity" onClick={decrease} className={buttonClasses} disabled={disabled}>
            -
          </button>
          <input
            id={id}
            type="number"
            className={inputClasses}
            disabled={disabled}
            onKeyDown={(e) => validateKeyPress(e)}
            onChange={(e) => handleChange(e.target.value)}
            value={input}
            onFocus={onFocus}
            onBlur={onBlur}
            aria-required={required}
            aria-describedby={ariaDescribedBy}
          />
          <button type="button" aria-label="Increase quantity" onClick={increase} className={buttonClasses} disabled={disabled}>
            +
          </button>
        </div>
        {belowMinMessage && showMinMessage ? belowMinMessage : null}
        {aboveMaxMessage && showMaxMessage ? aboveMaxMessage : null}
      </div>
    </div>
  );
}
export default QuantityInput;
