import React, { useMemo, useEffect, useCallback } from 'react';
import { ResponsiveLine } from '@nivo/line';
import moment from 'moment';
import WidgetContainer from '../../Container/WidgetContainer';
import Header from '../../Header/Header';
import { useCybAssessmentPath } from '../../../providers/CybAssessmentPathProvider';
import If from '../../If/If';
import Loading from '../../Loading/Loading';
import Container from '../../Container/Container';
import { graphColorDefaults, getAssessmentPathDisplayName, CustomLineLayer, ChartPointSymbol, ChartTooltip, generateTemplateCumulativeScores } from '../../Baseline/shared';

const TICK_VALUES = [0, 20, 40, 60, 80, 100];

function AssessmentsGraphWidget({ orgId, memberId, selectedRole }) {
  const pathId = selectedRole?.['content-id'];

  const { cumulativeUserScores, isLoadingCumulativeUserScores, cumulativeUserAttempts, isLoadingCumulativeUserAttempts, actions } = useCybAssessmentPath();

  const currentCumulativeUserScores = useMemo(() => cumulativeUserScores[`${orgId}${pathId}${memberId}`], [cumulativeUserScores, pathId, orgId, memberId]);

  const currentCumulativeUserAttempts = useMemo(() => cumulativeUserAttempts[`${orgId}${pathId}${memberId}`], [cumulativeUserAttempts, pathId, orgId, memberId]);

  const isLoading = useMemo(
    () => (isLoadingCumulativeUserScores && !currentCumulativeUserScores) || (currentCumulativeUserAttempts && !currentCumulativeUserAttempts),
    [currentCumulativeUserScores, currentCumulativeUserAttempts, isLoadingCumulativeUserScores, isLoadingCumulativeUserAttempts]
  );

  const parsedData = useMemo(() => {
    if (currentCumulativeUserAttempts?.default && currentCumulativeUserScores?.default) {
      const lowAttempts = Object.entries(currentCumulativeUserAttempts?.default)
        .filter(([key]) => key.includes('_Low_Attempts'))
        .reduce((acc, [key, value]) => {
          acc[key] = value;
          return acc;
        }, {});

      return Object.entries({
        ...currentCumulativeUserScores?.default,
        ...lowAttempts,
      });
    }

    // placeholder stub of cumulativeUserScores & cumulativeUserAttempts in expected schema
    return generateTemplateCumulativeScores(selectedRole, true);
  }, [currentCumulativeUserScores?.default, currentCumulativeUserAttempts?.default, selectedRole?.['content-id']]);

  const getScatteredPointColor = useCallback(
    (lowAttemptDisplayName) => {
      // Cybersecurity Fundamentals_Low_Attempts > Cybersecurity Fundamentals
      const canonicalPathDisplayName = lowAttemptDisplayName.split('_Low_Attempts')[0];

      let graphColor = '';

      parsedData.forEach(([key], index) => {
        if (canonicalPathDisplayName === key) {
          graphColor = graphColorDefaults.d3SchemaCategory10[index];
        }
      });

      return graphColor;
    },
    [parsedData]
  );

  const chartData = useMemo(
    () =>
      parsedData?.map(([key, value]) => {
        const displayName = getAssessmentPathDisplayName(key);

        let mappedData = [];

        if (!key.includes('_Low_Attempts')) {
          const consolidatedData = {};

          value.forEach((entry, index) => {
            if (index === value.length - 1) {
              consolidatedData.endPoint = {
                ...entry,
                marker: true,
                completed_at: moment(entry.completed_at).add(1, 'months').startOf('month').format('YYYY-MM-DD'),
              };
            } else if (entry.score !== null) {
              if (!(entry.score in consolidatedData) || entry.completed_at < consolidatedData[entry.score].completed_at) {
                consolidatedData[entry.score] = entry;
              }
            } else if (index === 0) {
              consolidatedData.starterPoint = {
                ...entry,
                marker: true,
              };
            }
          });

          const result = Object.values(consolidatedData);

          mappedData = result.map((entry, index) => {
            const score = Math.round(parseFloat(entry.score ?? 0));
            let y = Number(score);

            if (index === 0 && entry.marker && result?.[index + 1]) {
              const nextScore = Math.round(parseFloat(result[index + 1].score ?? 0));
              y = Number(nextScore);
            }

            return {
              x: moment(entry.completed_at).format('YYYY-MM-DD'),
              y,
              marker: entry.marker,
            };
          });
        } else {
          mappedData = value.map((entry) => {
            const score = Math.round(parseFloat(entry.score));
            const y = Number(score);

            return {
              x: moment(entry.completed_at ?? entry.period).format('YYYY-MM-DD'),
              y,
              scattered: displayName.includes('_Low_Attempts'),
              scatteredPointColor: displayName.includes('_Low_Attempts') ? getScatteredPointColor(displayName) : undefined,
            };
          });
        }

        return {
          id: displayName,
          data: mappedData,
        };
      }),
    [parsedData]
  );

  const xScaleAndAxis = useMemo(() => {
    let maxDataPointsCount = 0;

    chartData?.forEach((struct) => {
      if (struct.data.length > maxDataPointsCount) {
        maxDataPointsCount = struct.data.length;
      }
    });

    const xScale = {
      format: '%Y-%m-%d',
      precision: memberId ? 'day' : 'month',
      type: 'time',
      useUTC: false,
    };

    const axisBottom = {
      tickSize: 10,
      tickPadding: 10,
      tickRotation: 0,
      format: memberId ? '%b %Y' : '%b %d %Y',
    };

    if (maxDataPointsCount >= 4) {
      axisBottom.format = '%b %Y';
      axisBottom.tickValues = 'every month';
    }

    return {
      xScale,
      axisBottom,
    };
  }, [chartData, memberId]);

  const layers = useMemo(() => ['grid', 'markers', 'areas', CustomLineLayer, 'points', 'axes', 'mesh', 'slices', 'crosshair'], [memberId]);

  useEffect(() => {
    if (orgId && memberId && pathId) {
      if (!currentCumulativeUserScores) {
        actions.getCumulativeUserScores(orgId, pathId, memberId);
      }
      if (!currentCumulativeUserAttempts) {
        actions.getCumulativeUserAttempts(orgId, pathId, memberId);
      }
    }
  }, [orgId, pathId, memberId]);

  return (
    <WidgetContainer className="pb-0" omitBorder>
      <Header as="h3" className="mb-3">
        Trend
      </Header>
      <div className="relative">
        <div className="relative w-full h-[300px]">
          <ResponsiveLine
            key={pathId}
            data={chartData}
            margin={{ top: 30, right: 40, bottom: 50, left: 60 }}
            curve="monotoneX"
            xFormat="time:%Y-%m-%d"
            xScale={xScaleAndAxis.xScale}
            yFormat=" >-.2f"
            yScale={{
              type: 'linear',
              min: TICK_VALUES[0],
              max: TICK_VALUES[TICK_VALUES.length - 1],
            }}
            axisLeft={{
              tickSize: 0,
              tickPadding: 10,
              tickRotation: 0,
              tickValues: TICK_VALUES,
              format: (value) => `${value}%`,
            }}
            axisBottom={xScaleAndAxis.axisBottom}
            lineWidth={2}
            colors={{ scheme: 'category10' }}
            layers={layers}
            pointSize={10}
            pointColor={{ theme: 'background' }}
            pointBorderWidth={2}
            pointBorderColor={{ from: 'serieColor' }}
            pointSymbol={ChartPointSymbol}
            tooltip={ChartTooltip}
            gridYValues={TICK_VALUES}
            crosshairType="cross"
            useMesh
          />
          <If condition={isLoading}>
            <div className="flex absolute top-0 left-0 flex-col justify-center items-center w-full h-full bg-[#FBFBFB]/90">
              <Container>
                <Loading message="Loading..." />
              </Container>
            </div>
          </If>
        </div>
        <If condition={chartData?.length > 0}>
          <div className="flex flex-row flex-wrap items-start ml-9">
            {chartData?.map((entry, index) => (
              <If condition={!entry.id.includes('_Low_Attempts')} key={entry.id + index.toString()}>
                <div className="flex flex-row items-center py-1 px-2 mt-2 mr-3 hover:bg-black/[0.03] rounded-sm cursor-default">
                  <div className="mr-2 w-2 h-2 rounded-full" style={{ backgroundColor: graphColorDefaults.d3SchemaCategory10[index] }} />
                  <span className="text-[11px] font-semibold text-gray-600 whitespace-nowrap">{entry.id}</span>
                </div>
              </If>
            ))}
          </div>
        </If>
      </div>
    </WidgetContainer>
  );
}

export default AssessmentsGraphWidget;
