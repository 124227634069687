import React, { useEffect, useMemo } from 'react';
import { inject, observer } from 'mobx-react';
import { twMerge } from 'tailwind-merge';
import UserOnboardingStep from '../../UserOnboardingStep';
import { ONBOARDING_PACKAGE_OPTIONS, USER_ONBOARDING_STEPS, useUserOnboarding } from '../../../../providers/UserOnboardingProvider';
import usePaddlePricing from '../../../../hooks/checkout/usePaddlePricing';
import { CHECKOUT_MONTHS_PER_CYCLE, PADDLE_PRICE_ID_CIP_ANNUAL, PADDLE_PRICE_ID_CIP_MONTHLY, PADDLE_PRICE_ID_TEAM_ANNUAL } from '../../../../constants';

/** Step shown at the start of the onboarding flow */
function SelectPackageStepComponent({ userStore, authStore }) {
  const { setStep, setPackageSelected } = useUserOnboarding();

  const geoLocation = useMemo(async () => authStore.getGeoInfo(), [userStore.user]);
  const countryCode = useMemo(() => {
    // Ensure we have a country code and postal code
    if (geoLocation?.countryCode && geoLocation?.zip) {
      return geoLocation.countryCode;
    }
    return 'US';
  }, [geoLocation]);
  const postalCode = useMemo(() => {
    // Ensure we have a country code and postal code
    if (geoLocation?.countryCode && geoLocation?.zip) {
      return geoLocation.zip;
    }
    return '12345';
  }, [geoLocation]);

  // pricesCip = {
  //   transactionPreview, // entire transaction preview object
  //   currencyCode, // currency code of the transaction preview
  //   totalPrices, // object of total prices from the transaction preview
  //   isLoading,
  //   error,
  // }
  const pricesCipMonthly = usePaddlePricing([{ price_id: PADDLE_PRICE_ID_CIP_MONTHLY, quantity: 1 }], null, countryCode, postalCode);
  const pricesCipAnnual = usePaddlePricing([{ price_id: PADDLE_PRICE_ID_CIP_ANNUAL, quantity: 1 }], null, countryCode, postalCode);
  const pricesTeams = usePaddlePricing([{ price_id: PADDLE_PRICE_ID_TEAM_ANNUAL, quantity: 1 }], null, countryCode, postalCode);
  const isPricesLoading = pricesCipMonthly.isLoading || pricesCipAnnual.isLoading || pricesTeams.isLoading;

  const pricesButtonMap = {
    [ONBOARDING_PACKAGE_OPTIONS.CIP.key]: {
      monthly: pricesCipMonthly,
      annual: pricesCipAnnual,
    },
    [ONBOARDING_PACKAGE_OPTIONS.TEAMS.key]: {
      annual: pricesTeams,
    },
  };

  const handleClick = async (clickedOption) => {
    setPackageSelected(clickedOption);
    setStep(USER_ONBOARDING_STEPS.FINISHED);
  };

  useEffect(() => {
    // If the user already has a full subscription package, set the package to "skip"
    if (userStore.isCip || userStore.isEnterprise) {
      handleClick('skip');
    }
  }, [userStore.user]);

  return (
    <UserOnboardingStep title="Select Your Package">
      <div className="flex flex-col-reverse justify-center items-center align-middle lg:flex-row lg:items-stretch">
        {Object.values(ONBOARDING_PACKAGE_OPTIONS).map(
          ({ key, title, titleClasses, description, featuresTitle, features, icon = 'user', iconClassName, className, bannerText, buttonText, buttonColor, priceSubtitle }) => {
            // Main sticker price should be the annual price / 12 to show monthly pricing
            const priceData = pricesButtonMap[key]?.annual;
            const priceTotals = priceData?.totalPrices;
            const priceId = priceData?.items?.[0]?.price_id;
            const annualAsMonthlyPrice = Math.floor((priceTotals?.total ? parseInt(priceTotals.total, 10) / (CHECKOUT_MONTHS_PER_CYCLE[priceId] || 1) : 0) / 100);
            return (
              <UserOnboardingStep.ButtonModern
                key={key}
                title={title}
                titleClassName={titleClasses}
                description={description}
                icon={icon}
                iconClassName={iconClassName}
                onClick={() => handleClick(key)}
                className={twMerge('flex-1 lg:max-w-[380px] h-auto md:ml-0 lg:m-0', className)}
                packageHighlights={features}
                featuresTitle={featuresTitle}
                bannerText={bannerText}
                buttonText={buttonText}
                buttonColor={buttonColor}
                // This trailing period is a hack to hide the cents place in the price
                price={`${annualAsMonthlyPrice}.`}
                priceCurrencyCode={priceTotals?.currency_code}
                priceClasses="my-4"
                priceSubtitle={priceSubtitle}
                isPriceLoading={isPricesLoading}
              />
            );
          }
        )}
      </div>
    </UserOnboardingStep>
  );
}

const SelectPackageStep = inject('userStore', 'authStore')(observer(SelectPackageStepComponent));

export default SelectPackageStep;
