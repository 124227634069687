import React, { useState } from 'react';
import { inject, observer } from 'mobx-react';
import withRouter from '../Router/withRouter';
import RequestLicenseTeamSelect from './RequestLicenseTeamSelect';
import Modal from '../Modal/Modal';
import Divider from '../Divider/Divider';

import './upgrade-button.css';
import Button from '../Button/Button';
import { WWW_PRICING_PAGE } from '../../constants';

function EnterpriseUpgradeOptions({ activeTeams, userTeams, upgradeLink, getRequestLicenseLink }) {
  return (
    <div className="flex flex-col items-center w-full">
      <div className="w-full">
        <p className="mb-4 font-semibold">Upgrade to Cybrary Insider Pro</p>
        <Button href={upgradeLink}>Upgrade</Button>
      </div>
      <Divider className="block self-stretch py-8" horizontal>
        OR
      </Divider>
      <div className="">
        <RequestLicenseTeamSelect activeTeams={activeTeams} userTeams={userTeams} getRequestLicenseLink={getRequestLicenseLink} message="Request a License from your Team Owner" />
      </div>
    </div>
  );
}

const openChat = () => {
  window.zE('webWidget', 'open');
  window.zE('webWidget', 'show');
};

const UpgradeButton = inject(
  'userStore',
  'commonStore'
)(
  observer(function UpgradeButton({
    commonStore,
    userStore,
    upgradeText,
    upgradeLinkParams,
    openChatOnly,
    color,
    buttonClasses,
    icon,
    title = 'Unlock access to this content',
    content,
  }) {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const { isEnterprise, userTeams, activeTeams } = userStore;

    const handleOpenModal = () => {
      setIsModalOpen(true);
      commonStore.setUpgradeContentDescriptionId(content?.id);
    };

    const handleCloseModal = () => {
      setIsModalOpen(false);
      commonStore.setUpgradeContentDescriptionId(null);
    };

    // If openChatOnly prop is provided, do nothing more than open chat window
    if (openChatOnly) {
      return (
        <Button onClick={openChat} color={color} icon={icon} title={title}>
          {upgradeText}
        </Button>
      );
    }

    const upgradeLink = `${WWW_PRICING_PAGE}/${upgradeLinkParams || ''}`;

    // If not enterprise, or is enterprise but with no active teams, show only upgrade button
    const needUpgrade = !isEnterprise || (isEnterprise && !activeTeams?.length);
    if (needUpgrade) {
      return (
        <Button href={upgradeLink} color={color} className={buttonClasses} icon={icon} title={title}>
          {upgradeText}
        </Button>
      );
    }

    // If user is on an active team(s) show both the upgrade button and request license links in a modal
    return (
      <Modal
        size="sm"
        position="center"
        className="overflow-none "
        ariaLabel="Upgrade Options"
        trigger={
          <Button onClick={handleOpenModal} color={color} icon={icon} className={buttonClasses} title={title}>
            {upgradeText}
          </Button>
        }
        toggle={handleCloseModal}
        open={isModalOpen}
      >
        <div className="p-4 text-center">
          <h2 className="text-2xl font-black">Unlock unlimited learning</h2>
          <div className="py-4">
            <EnterpriseUpgradeOptions
              openChat={openChat}
              activeTeams={activeTeams}
              userTeams={userTeams}
              upgradeLink={upgradeLink}
              getRequestLicenseLink={commonStore.getRequestLicenseLink}
            />
          </div>
        </div>
      </Modal>
    );
  })
);

export default withRouter(UpgradeButton);
