import React from 'react';
import { useNavigate } from 'react-router-dom';
import TeamSelectionButtons from '../TeamSelection/TeamSelectionButtons';

function RequestLicenseTeamSelect({ activeTeams, userTeams, getRequestLicenseLink, message = 'Request a license', ctaText = 'Request License' }) {
  const navigate = useNavigate();
  const activeUserTeams = activeTeams.map((team) => userTeams[team]);
  return (
    <div>
      <p className="mb-2 font-semibold">{message}</p>
      <TeamSelectionButtons teams={activeUserTeams} handleSelection={(selectedTeam) => navigate(getRequestLicenseLink(selectedTeam.id))} ctaText={ctaText} />
    </div>
  );
}

export default RequestLicenseTeamSelect;
