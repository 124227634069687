import React from 'react';
import LoadingSquareLogo from '../../components/Loading/LoadingSquareLogo';

function LoadingPage() {
  return (
    <div className="flex overflow-hidden flex-col justify-center items-center pt-8 h-screen bg-gray-200">
      <LoadingSquareLogo />
    </div>
  );
}

export default LoadingPage;
