import React from 'react';
import { observer, inject } from 'mobx-react';
import Header from '../../components/Header/Header';
import ValidationUtil from '../../utils/validationUtil';
import Icon from '../../components/Icon/Icon';
import Input from '../../components/FormFields/Input';
import Loading from '../../components/Loading/Loading';
import Segment from '../../components/Segment/Segment';
import Tooltip from '../../components/Tooltip/Tooltip';

const getResetPassword = (account, handleVerificationAndForgotPassword) => {
  if (!account.verified || account.sso) {
    return null;
  }
  return (
    <button className="link-look reset-password" onClick={() => handleVerificationAndForgotPassword(account.email, true)}>
      Reset Password
    </button>
  );
};

const getOtherEmailsList = (accounts, triggerConfirm, resetConfirmState, handleRemoveAdditionalEmail, handleMakeEmailPrimary, handleVerificationAndForgotPassword) => {
  return accounts.map((account) => {
    return (
      <li className="flex mb-6" key={account.email}>
        <div className="flex-auto">{account.email}</div>
        <div className="flex-none email-actions">
          {account.verified ? (
            <button
              className="mr-6 link-look make-primary"
              onClick={() => {
                triggerConfirm({
                  content: 'Are you sure you want to make this your primary email? Your primary email is used for email communications',
                  cancel: () => resetConfirmState(),
                  continue: () => {
                    handleMakeEmailPrimary(account.email);
                    resetConfirmState();
                  },
                });
              }}
            >
              Make Primary
            </button>
          ) : (
            <span className="inline-flex items-center mr-6 w-auto unverified">
              Unverified
              {!account.sso ? (
                <Tooltip
                  position="top-end"
                  omitTabIndex
                  triggerContent={
                    <button
                      onClick={() => {
                        handleVerificationAndForgotPassword(account.email);
                      }}
                    >
                      <Icon className="ml-2 w-5 h-5 text-cyb-pink-500" name="refresh" />
                    </button>
                  }
                  content="Re-send verification email"
                />
              ) : null}
            </span>
          )}
          {getResetPassword(account, handleVerificationAndForgotPassword)}
          <button
            className="underline link-look remove"
            onClick={() => {
              triggerConfirm({
                content: 'Are you sure you want to delete this backup email?',
                cancel: () => resetConfirmState(),
                continue: () => {
                  handleRemoveAdditionalEmail(account.email);
                  resetConfirmState();
                },
              });
            }}
          >
            Remove
          </button>
        </div>
      </li>
    );
  });
};

const EmailForm = inject(
  'authStore',
  'commonStore'
)(
  observer(
    class EmailForm extends React.Component {
      state = {
        addEmailVisible: false,
        submitDisabled: true,
      };

      componentDidMount() {
        this.props.authStore.fireAttributionEvent();
      }

      componentWillUnmount() {
        clearInterval(this.state.intervalId);
      }

      toggleAddEmail = () => {
        const newState = {
          ...this.state,
          addEmailVisible: !this.state.addEmailVisible,
          submitDisabled: true,
        };
        this.setState(newState);
      };

      setEmailInput = (email) => {
        this.props.setNewEmailInput(email);
        const currentState = { ...this.state };
        // Test the email input is valid
        currentState.submitDisabled = !ValidationUtil.checkValidEmail(email);
        this.setState(currentState);
      };

      render() {
        const { userEmails, handleAddAdditionalEmail, handleVerificationAndForgotPassword } = this.props;
        const { handleRemoveAdditionalEmail, handleMakeEmailPrimary } = this.props;
        const { data, loading } = userEmails;
        const additionalEmailClasses =
          'ml-4 rounded-sm bg-cyb-pink-500 text-white font-bold text-center hover:text-white hover:bg-cyb-pink-600 leading-5 py-2.5 px-6 text-sm disabled:opacity-50';
        if (loading) {
          return <Loading message="Loading ..." />;
        }
        if (!data || !data.primary) {
          return null;
        }
        return (
          <Segment className="px-0 border-none email-management">
            <Header as="h3">Email addresses you&apos;ve added:</Header>
            <ul className="list-none emails-list">
              <li className="flex mb-6">
                <div className="flex-auto">{data.primary.email}</div>
                <div className="flex flex-none primary">
                  {getResetPassword(data.primary, handleVerificationAndForgotPassword)}
                  <Tooltip triggerContent={<span className="text-gray-600">Primary</span>} content="Your primary email is used for email communications" />
                </div>
              </li>
              {data.other && Object.values(data.other).length
                ? getOtherEmailsList(
                    Object.values(data.other),
                    this.props.commonStore.triggerConfirm,
                    this.props.commonStore.resetConfirmState,
                    handleRemoveAdditionalEmail,
                    handleMakeEmailPrimary,
                    handleVerificationAndForgotPassword
                  )
                : null}
            </ul>
            {data.other && Object.values(data.other).length < 2 && (
              <div className="add-email-container">
                {this.state.addEmailVisible ? (
                  <div className="add-email">
                    <div className="input-container">
                      <Input
                        onChange={(event, val) => {
                          this.setEmailInput(val.value);
                        }}
                        className="py-2 px-4 w-64 rounded border border-gray-400"
                        value={this.props.userNewEmailInput}
                      />
                    </div>
                    <button
                      onClick={() => {
                        this.toggleAddEmail();
                        this.props.setNewEmailInput('');
                      }}
                      className="py-2.5 px-6 text-sm font-bold leading-5 text-center text-black bg-gray-200 rounded-sm"
                    >
                      Cancel
                    </button>
                    <button
                      onClick={() => {
                        this.toggleAddEmail();
                        handleAddAdditionalEmail();
                      }}
                      disabled={this.state.submitDisabled}
                      className={additionalEmailClasses}
                    >
                      Send Verification
                    </button>
                  </div>
                ) : (
                  <button className="ml-8 underline add-email-toggle link-look" onClick={() => this.toggleAddEmail()}>
                    Add email address
                  </button>
                )}
              </div>
            )}
          </Segment>
        );
      }
    }
  )
);

export default EmailForm;
