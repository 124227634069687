import React from 'react';
import { Fade } from 'react-awesome-reveal';
import { inject, observer } from 'mobx-react';
import { twMerge } from 'tailwind-merge';
import Icon from '../Icon/Icon';

function getColorConfig(color) {
  switch (color) {
    case 'red':
      return {
        bgColor: 'bg-white',
        textColor: 'text-red-700',
        borderColor: 'border-red-700',
      };
    case 'blue':
      return {
        bgColor: 'bg-white',
        textColor: 'text-blue-700',
        borderColor: 'border-blue-700',
      };
    case 'yellow':
      return {
        bgColor: 'bg-white',
        textColor: 'text-yellow-600',
        borderColor: 'border-yellow-600',
      };
    case 'pink':
      return {
        bgColor: 'bg-white',
        textColor: 'text-pink-700',
        borderColor: 'border-pink-700',
      };
    case 'green':
    default:
      return {
        bgColor: 'bg-white',
        textColor: 'text-green-700',
        borderColor: 'border-green-700',
      };
  }
}

function ToastMessageCard({ color, icon, header, content, className, iconClassName }) {
  const colorConfig = getColorConfig(color);
  const toastContainerBaseClasses = twMerge('p-4 flex items-center space-x-4 rounded-sm border border-t-4', className);
  return (
    <div className={`${toastContainerBaseClasses} ${colorConfig.bgColor} ${colorConfig.textColor} ${colorConfig.borderColor}`}>
      <Icon name={icon} className={twMerge('w-16 h-16 shrink-0', iconClassName)} />
      <div className={`${colorConfig.textColor} focus:ring-2 focus:ring-cyb-blue-500`} id="toast-container" role="status">
        <h3 className="mb-1 text-lg font-bold">{header}</h3>
        <div className="font-medium">{content}</div>
      </div>
    </div>
  );
}

function ToastMessageComponent({ commonStore }) {
  const { toastState } = commonStore;
  const { toastDisplay, color, icon, header, content } = toastState || {};
  if (!toastDisplay) {
    return null;
  }
  return (
    <div style={{ zIndex: 2001 }} className="fixed bottom-2 w-full sm:left-1/10 sm:w-4/5 md:left-1/4 md:w-1/2" tabIndex="-1">
      <Fade duration={500} direction="up">
        <ToastMessageCard color={color} icon={icon} header={header} content={content} />
      </Fade>
    </div>
  );
}

const ToastMessage = inject('commonStore')(observer(ToastMessageComponent));
ToastMessage.Card = ToastMessageCard;

export default ToastMessage;
