import moment from 'moment';
import React, { useEffect, useState } from 'react';
import Bugsnag from '@bugsnag/js';
import { Navigate } from 'react-router-dom';
import { observer, inject } from 'mobx-react';
import { groupBy } from 'lodash';
import FocusLock from 'react-focus-lock';
import agents from '../../agents/agents';
import withRouter from '../../components/Router/withRouter';
import Container from '../../components/Container/Container';
import Message from '../../components/Message/Message';
import Loading from '../../components/Loading/Loading';
import Title from '../../components/Title/Title';
import Icon from '../../components/Icon/Icon';
import ScreenClose from '../../components/ScreenClose/ScreenClose';

function getCourseProviders(instructors, alliances, vendor) {
  const providers = [];
  let joinedProviders = '';
  if (instructors && instructors.length) {
    instructors.forEach((instructor) => {
      providers.push(instructor.name);
    });
  }
  if (alliances && alliances.length) {
    alliances.forEach((alliance) => {
      providers.push(alliance.name);
    });
  }
  if (vendor) {
    providers.push(vendor.name);
  }
  if (providers.length) {
    joinedProviders = providers.join(', ');
  }
  return joinedProviders;
}

function Course({ parentCourse }) {
  const { title, instructors_info: instructors, alliances_info: alliances, vendor, status, released_at: releasedAt, thumbnail_url: image } = parentCourse;
  const providers = getCourseProviders(instructors, alliances, vendor);
  return (
    <div className="grid gap-4 md:grid-cols-2">
      <div>
        <h2 className="mb-4 text-xl font-bold break-words">{title}</h2>
        <p>Provided by: {providers}</p>
        <p>Status: {status}</p>
        <p>Released: {releasedAt ? moment(releasedAt).format('LLL') : ''}</p>
      </div>
      {image ? (
        <div>
          <img alt={title} src={image} width="200" className="mx-auto" />
        </div>
      ) : null}
    </div>
  );
}

function ModuleTitle({ module, moduleId }) {
  const title = module[0].module_title;
  const isActive = !!module.filter((mod) => mod.module_id === moduleId)[0];
  return (
    <div className="pr-4 pl-7 w-full">
      <div className="flex items-center mb-2 text-2xs leading-3">
        <span className="font-semibold text-gray-600 uppercase">Module</span>
      </div>
      <p className={`${isActive ? 'text-black font-extrabold' : 'text-gray-600 font-bold'} text-left text-lg mr-3`}>{title}</p>
    </div>
  );
}

function ActivityList({ module, activityId, handleActivityClick }) {
  return (
    <ul className="mt-3">
      {module.map((activity) => {
        const activeActivity = activity.id === activityId;
        const activityClasses = activeActivity
          ? 'text-black hover:text-black bg-gray-200 font-semibold border-l-2 border-cyb-pink-500'
          : 'text-gray-600 hover:text-gray-600 hover:bg-gray-200';
        return (
          <li key={`${activity.module_title}: ${activity.title}`}>
            <button
              onClick={() => handleActivityClick(activity.module_id, activity.id)}
              className={`w-full flex flex-col ${activityClasses} text-sm py-4 pl-7 pr-4 hover:bg-gray-200`}
            >
              <span className="truncate">{activity.title}</span>
            </button>
          </li>
        );
      })}
    </ul>
  );
}

function ActivityListWrapper({ modules, moduleId, activityId, handleActivityClick }) {
  return Object.keys(modules).map((id) => {
    const module = modules[id];
    return (
      <div key={id} className="pt-6 pb-4 border-b border-gray-300">
        <ModuleTitle module={module} moduleId={moduleId} />
        <ActivityList module={module} activityId={activityId} handleActivityClick={handleActivityClick} />
      </div>
    );
  });
}

function Outline({ modules, moduleId, activityId, toggleDropdown, setToggleDropdown, handleActivityClick }) {
  const hasSelectedModuleAndActivity = moduleId && activityId;
  const title = hasSelectedModuleAndActivity ? modules[moduleId].filter((activity) => activity.id === activityId)[0].title : '';
  const outlineDropDownClasses = `${toggleDropdown ? 'block' : 'hidden'} z-40 absolute border w-80 max-h-96 rounded-lg shadow-lg bg-white overflow-y-scroll`;
  return (
    <>
      <ScreenClose isOpen={toggleDropdown} handleClose={() => setToggleDropdown(false)} />
      <div id="outline-dropdown-container" className="flex relative justify-end items-center">
        <div className="mr-4">Filter:</div>
        <button
          className="inline-flex items-center py-2 px-4 bg-white hover:bg-gray-100 rounded-sm border border-gray-300 border-collapse"
          onClick={() => setToggleDropdown(!toggleDropdown)}
          aria-label={`${toggleDropdown ? 'Collapse' : 'Expand'} Outline Dropdown`}
          aria-expanded={toggleDropdown}
        >
          <span title={title} className="pr-2 w-48 font-semibold truncate">
            {title}
          </span>
          <Icon name="chevron-down" className="w-4 h-4" />
        </button>
        <div className="ml-4">
          <button
            disabled={!hasSelectedModuleAndActivity}
            onClick={hasSelectedModuleAndActivity ? () => handleActivityClick(null, null) : () => {}}
            className={hasSelectedModuleAndActivity ? 'text-black underline hover:no-underline cursor-pointer' : 'text-gray-600 cursor-not-allowed'}
          >
            Clear
          </button>
        </div>
        {toggleDropdown ? (
          <FocusLock autoFocus={false} returnFocus>
            <div id="outline-dropdown" className={outlineDropDownClasses} style={{ top: '40px', right: '-10px' }}>
              <ActivityListWrapper modules={modules} moduleId={moduleId} activityId={activityId} handleActivityClick={handleActivityClick} />
            </div>
          </FocusLock>
        ) : null}
      </div>
    </>
  );
}

function Options({ options, answers }) {
  return (
    <>
      <ul className="mx-4 mt-4 md:mx-8">
        {options.map((option) => {
          return (
            <li key={`option-${option.label}`}>
              <span className="mr-2">{option.value}.</span> {option.label}
            </li>
          );
        })}
      </ul>
      <div className="mx-4 mt-4 italic md:mx-8">Answer:</div>
      <ul className="mx-4 mb-8 md:mx-8">
        {options
          .filter((option) => answers.indexOf(option.value) !== -1)
          .map((ans) => (
            <li key={`answer-${ans.label}`}>
              <span className="mr-2">{ans.value}.</span> {ans.label}
            </li>
          ))}
      </ul>
    </>
  );
}

function Answers({ answers }) {
  const joinedAnswers = answers && answers.length ? answers.join(', ') : null;
  if (!joinedAnswers) {
    return null;
  }
  return (
    <>
      <div className="mx-4 mt-4 italic md:mx-8">Answer:</div>
      <div className="mx-4 mb-8 md:mx-8">{joinedAnswers}</div>
    </>
  );
}

function Questions({ questions }) {
  return questions.map((question, idx) => {
    if (!question || typeof question !== 'object') {
      return null;
    }
    const { label, answers, options } = question;
    return (
      <div key={label} className="mx-4 mt-4 md:mx-8">
        <div className="flex">
          <div className="mr-4">{idx + 1}.</div>
          <div className="flex-1">{label}</div>
        </div>
        {options && options.length && answers && answers.length ? <Options options={options} answers={answers} /> : <Answers answers={answers} />}
      </div>
    );
  });
}

function Activities({ module, activityId }) {
  return module.map((activity) => {
    if (!activity || typeof activity !== 'object') {
      return null;
    }
    const { id, title, questions } = activity;
    const activityActive = activityId ? activityId === Number(id) : true;
    if (!questions || !questions.length || !questions[0].answers) {
      return null;
    }
    return (
      <div key={activity.id} className={activityActive ? 'mt-4 ml-4' : 'hidden'}>
        <span className="block underline">Activity: {title}</span>
        <Questions questions={questions} />
      </div>
    );
  });
}

function Modules({ modules, moduleId, activityId }) {
  return Object.keys(modules).map((id) => {
    const module = modules[id];
    const moduleActive = moduleId ? moduleId === Number(id) : true;
    if (!module || !module.length) {
      return null;
    }
    return (
      <div key={id} className={moduleActive ? 'mt-8 block' : 'hidden'}>
        <h3 className="text-xl font-bold">Module: {module[0].module_title}</h3>
        <Activities module={module} activityId={activityId} />
      </div>
    );
  });
}

function ModulesAndActivitiesWrapper({ activitiesWithAChallenge }) {
  const [toggleDropdown, setToggleDropdown] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [modules, setModules] = useState({});
  const [moduleId, setModuleId] = useState(null);
  const [activityId, setActivityId] = useState(null);

  useEffect(() => {
    if (activitiesWithAChallenge) {
      const formatted = groupBy(activitiesWithAChallenge, 'module_id');
      setModules(formatted);
      setIsLoading(false);
    }
  }, []);

  const handleActivityClick = (mId, aId) => {
    setIsLoading(true);
    const formatModuleId = mId ? Number(mId) : null;
    const formatActivityId = aId ? Number(aId) : null;
    setModuleId(formatModuleId);
    setActivityId(formatActivityId);
    setToggleDropdown(false);
    setTimeout(() => {
      setIsLoading(false);
    }, 500);
  };

  return (
    <div className="pt-8 mt-8 border-t border-gray-400">
      <Outline
        modules={modules}
        toggleDropdown={toggleDropdown}
        setToggleDropdown={setToggleDropdown}
        activityId={activityId}
        moduleId={moduleId}
        handleActivityClick={handleActivityClick}
      />
      {isLoading ? (
        <Loading message="Loading..." className="w-12 h-12 border-8" wrapperClassName="my-10" />
      ) : (
        <Modules modules={modules} moduleId={moduleId} activityId={activityId} />
      )}
    </div>
  );
}

function CourseWrapper({ breadCrumbs, parentCourse, activitiesWithAChallenge }) {
  return (
    <>
      <Title title="Challenge Key" breadcrumbs={breadCrumbs} wrapperClasses="mb-8" omitPadding />
      {!parentCourse ? <Message icon="exclamation-circle" msgBody="Unfortunately, there was a problem fetching this course." /> : <Course parentCourse={parentCourse} />}
      {parentCourse && (!activitiesWithAChallenge || !activitiesWithAChallenge.length) ? (
        <Message icon="exclamation-circle" msgBody="This course does not have activities with challenges." />
      ) : null}
      {parentCourse && activitiesWithAChallenge && activitiesWithAChallenge.length ? <ModulesAndActivitiesWrapper activitiesWithAChallenge={activitiesWithAChallenge} /> : null}
    </>
  );
}

const CybraryAdminChallengeKey = inject(
  'adminStore',
  'commonStore'
)(
  observer(({ adminStore, commonStore, match }) => {
    const { denyAccess } = adminStore;
    const { id: contentDescriptionId } = match.params;
    const [isLoading, setIsLoading] = useState(true);
    const [parentCourse, setParentCourse] = useState(null);
    const [activitiesWithAChallenge, setActivitiesWithAChallenge] = useState(null);

    useEffect(() => {
      commonStore.hidePrimaryNav();
      return () => commonStore.showPrimaryNav();
    }, []);

    useEffect(() => {
      async function fetchData() {
        try {
          const [course, challenge] = await Promise.all([agents.catalog.getContentDescriptionById(contentDescriptionId), agents.admin.getChallengeData(contentDescriptionId)]);
          setParentCourse(course);
          setActivitiesWithAChallenge(challenge);
          setIsLoading(false);
        } catch (error) {
          Bugsnag.notify(error);
          setIsLoading(false);
        }
      }
      fetchData();
    }, [contentDescriptionId]);

    const getBreadCrumbs = () => {
      const crumbs = [];
      crumbs.push(
        {
          href: '/admin',
          label: 'Admin',
        },
        {
          href: '/admin/challenge/courses',
          label: 'Browse Courses',
        },
        {
          label: 'Challenge Key',
        }
      );
      return crumbs;
    };

    if (denyAccess) {
      return <Navigate to="/" />;
    }

    return (
      <Container>
        <div className="p-4 mt-4 rounded border border-gray-400" style={{ marginTop: '1em' }}>
          {isLoading ? (
            <Loading message="Loading..." className="w-12 h-12 border-8" wrapperClassName="my-10" />
          ) : (
            <CourseWrapper breadCrumbs={getBreadCrumbs()} parentCourse={parentCourse} activitiesWithAChallenge={activitiesWithAChallenge} />
          )}
        </div>
      </Container>
    );
  })
);

export default withRouter(CybraryAdminChallengeKey);
