import React, { useMemo } from 'react';
import { twMerge } from 'tailwind-merge';
import { CYBRARY_LOGO_URL } from '../../constants';
import AddLink from '../AddLink/AddLink';

/**
 * Cybrary Logo component
 * @typedef {Object} CybraryLogoProps
 * @param {number} props.width - The width of the logo
 * @param {number} props.height - The height of the logo
 * @param {string} props.className - The class name of the logo
 * @param {string} props.alt - The alt text of the logo
 * @param {string} props.href - The href of the logo
 * @returns {React.ReactNode}
 */
function CybraryLogo({ width = 224, height = 58, className = '', alt = 'Cybrary Logo', href }) {
  const mergedClasses = useMemo(() => {
    const defaultClasses = 'm-auto mb-8';
    return twMerge(defaultClasses, className);
  }, [className]);

  if (href) {
    return (
      <AddLink to={href}>
        <img alt={alt} width={width} height={height} className={mergedClasses} src={CYBRARY_LOGO_URL} />
      </AddLink>
    );
  }

  return <img alt={alt} width={width} height={height} className={mergedClasses} src={CYBRARY_LOGO_URL} />;
}

export default CybraryLogo;
