import React, { useEffect, useState } from 'react';
import moment from 'moment';
import FormatUtil, { getPackageNames } from '../../utils/formatUtil';
import useTableData from '../../hooks/useTableData';
import AdminTable from '../AdminTable/AdminTable';
import Collapsible from '../Collapsible/Collapsible';
import AddLink from '../AddLink/AddLink';
import Icon from '../Icon/Icon';
import DEMO_ACTIVITY_LOG from '../../pages/Enterprise/Demo/DEMO_ACTIVITY_LOG.json';
import { formatEventTimestamp, formatNameToLink, formatSemiBoldColumnValue } from '../../utils/tableUtil';

const getDefaultParams = (defaultStartDate) => {
  const defaults = {
    query: {
      format: 'table',
      activePg: 1,
      recordsPerPage: 25,
    },
  };
  if (defaultStartDate) {
    defaults.query.startDate = defaultStartDate;
  }
  return defaults;
};

const formatKey = (key) => {
  return key.split('_').join(' ');
};

const formatChangeVal = (key, val) => {
  if (val === null) {
    return null;
  }
  if (key === 'license_expires_at') {
    return moment(val).format('MM/DD/YYYY');
  }
  if (Array.isArray(val)) {
    return val.join(', ');
  }
  if (typeof val === 'object') {
    return (
      <div className="ml-2">
        <DetailsList list={val} />
      </div>
    );
  }
  if (typeof val === 'boolean') {
    return val.toString();
  }
  return val;
};

function DetailsList({ list }) {
  return (
    <ul className="list-disc list-inside">
      {Object.keys(list).map((changeKey) => {
        const changeVal = formatChangeVal(changeKey, list[changeKey]);
        return (
          <li className="ml-2" key={changeKey}>
            <span className="font-semibold">{FormatUtil.ucFirstLetter(formatKey(changeKey))}:</span> {changeVal}
          </li>
        );
      })}
    </ul>
  );
}

function EventMessageDetails({ message, context }) {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <Collapsible
      open={isOpen}
      handleTriggerClick={() => setIsOpen(!isOpen)}
      trigger={
        <div className="flex gap-x-2 items-center">
          {message} <Icon name="chevron-down" className={`w-4 h-4 transition-transform ${isOpen && 'transform rotate-180'}`} />
        </div>
      }
      ariaLabel="View more details"
    >
      <div className="p-2 my-2 max-w-sm text-sm border border-gray-400">
        <p className="mb-2 font-bold">Details</p>
        <DetailsList list={context} />
      </div>
    </Collapsible>
  );
}

const getLicenseNameFromContext = (context, team) => {
  const licenseId = context?.team_content_license_grant_id;
  const license = team?.active_license_grants.find((grant) => grant.team_content_license_grant_id === licenseId);
  if (license) {
    return license.name || getPackageNames(license.package_types);
  }
  return null;
};

const formatTeamRoleInMessage = (message) => {
  // Replace ["team-admin", "team-reporting-admin", "team-member"] in the message with "Team Admin", "Team Reporting Admin", "Team Member"
  const teamRoles = ['team-admin', 'team-reporting-admin', 'team-member'];
  return teamRoles.reduce((acc, teamRole) => {
    const formattedTeamRole = FormatUtil.ucFirstLetter(teamRole.replace(/-/g, ' '));
    return acc.replace(teamRole, formattedTeamRole);
  }, message);
};

function ActivityLog({ team, filters, changePage, agentObj, headerMappings = [] }) {
  const { id, created_at } = team;
  const isDemo = team?.id === 'demo';
  const defaultStartDate = team && created_at ? moment(created_at).startOf('day').format('YYYY-MM-DD HH:mm:ss') : new Date();
  // when demoing, replace tableHookData with the demo json data
  // DO NOT CALL useTableData hook! It will reload the activity log
  let tableHookData;
  if (isDemo) {
    tableHookData = DEMO_ACTIVITY_LOG;
    // add anon function to avoid errors when this is invoked later
    tableHookData.setQueryParams = () => {};
  } else {
    const agentOptions = {
      id,
      name: 'getActivityLogs',
    };
    tableHookData = useTableData(getDefaultParams(defaultStartDate), agentOptions, agentObj);
  }
  const { loading, tableData, error, setQueryParams } = tableHookData;

  useEffect(() => {
    setQueryParams({ ...filters, format: 'table', recordsPerPage: 25 });
  }, [filters]);

  if (!loading && !error && !tableData) {
    return null;
  }

  const formatMessageColumn = (teamId, data, row, headings) => {
    const message = FormatUtil.ucFirstLetter(data.value);
    const contextIdx = FormatUtil.getColIndex(headings, 'context');
    const context = contextIdx > -1 && row[contextIdx] ? row[contextIdx].value : null;
    const eventIdx = FormatUtil.getColIndex(headings, 'event');
    const event = eventIdx > -1 && row[eventIdx] ? row[eventIdx].value : null;

    // Format team-member role in message
    const formattedMessage = formatTeamRoleInMessage(message);

    if (event === 'Team Invite Batch Created') {
      return (
        <AddLink className="font-bold text-cyb-pink-500" to={`/admin/view-team/${teamId}/?bulkInvite=1`}>
          {formattedMessage}
        </AddLink>
      );
    }

    if (event === 'Team License Seat Revoked') {
      const licenseName = getLicenseNameFromContext(context, team);
      if (licenseName) {
        return `Removed "${licenseName}" license from member`;
      }
      return formattedMessage;
    }

    if (event === 'Team License Seat Granted') {
      const licenseName = getLicenseNameFromContext(context, team);
      if (licenseName) {
        return `Granted "${licenseName}" license to member`;
      }
      return formattedMessage;
    }

    if (!context || !context.changed) {
      return formattedMessage;
    }
    return <EventMessageDetails message={formattedMessage} context={context.changed} />;
  };

  return (
    <AdminTable
      wrapperClassName="my-0 text-sm"
      /* Table Data */
      headings={tableData?.tableHeadings}
      data={tableData?.data}
      tableLoading={loading || tableData?.loading}
      loadingAsTable
      tableError={error || tableData?.error}
      formatColumns={[
        // Format the user name column to be a link to the user's profile
        {
          method: (col, row) => formatNameToLink(col, row, team?.id),
          colIdx: FormatUtil.getColIndex(tableData?.tableHeadings, 'user_name'),
        },
        // Format the member column to be a link to the member's profile
        {
          method: (col, row) => formatNameToLink(col, row, team?.id, FormatUtil.getColIndex(tableData?.tableHeadings, 'member_id')),
          colIdx: FormatUtil.getColIndex(tableData?.tableHeadings, 'member_name'),
        },
        // Format event name to be bold
        {
          method: formatSemiBoldColumnValue,
          colIdx: FormatUtil.getColIndex(tableData?.tableHeadings, 'event'),
        },
        {
          method: formatEventTimestamp,
          colIdx: FormatUtil.getColIndex(tableData?.tableHeadings, 'created_at'),
        },
        {
          method: (data, row, headings) => formatMessageColumn(id, data, row, headings),
          colIdx: FormatUtil.getColIndex(tableData?.tableHeadings, 'message'),
        },
      ]}
      headerMappings={{ Timestamp: 'Timestamp (UTC)', ...headerMappings }}
      pagNumPgs={tableData?.pagPagesCount}
      pagChangePg={changePage}
      pagActivePg={filters.activePg || 1}
    />
  );
}

export default ActivityLog;
