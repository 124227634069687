import { useState, useEffect, useMemo } from 'react';
import agents from '../../agents/agents';
import BugsnagUtil from '../../utils/bugsnagUtil';

/**
 * Manages fetching of Paddle pricing through the /transaction/preview endpoint
 * This is different from usePaddleCheckout in that it does not handle the checkout process, only the pricing and updating of existing subscriptions
 * @see `usePaddleCheckout` For creating new checkouts
 * @see `usePaddlePricing` For getting pricing estimates
 * @see `usePaddleSubscription` For managing exsisting subscription items
 * @param {array} initItems Initial items to fetch a tx preview for ex: [{ price_id: 'pri_1k2hb4, quantity: 1 }
 * @param {string} initDiscountId Initial discount ID to apply to intial request only
 * @param {string} initCountryCode Initial country code to fetch prices for
 * @example Let the hook manage the transaction preview and fetch it on mount
 * const {
      transactionPreview, // entire transaction preview object
      items, // array of items from the transaction preview
      lineItems, // array of line items from the transaction preview
      currencyCode, // currency code of the transaction preview
      totalPrices, // object of total prices from the transaction preview
      isLoading,
      error,
    } = usePaddlePricing([{ price_id: 'pri_1k2hb4', quantity: 1 }], null, 'US');
  * @example Or manage the transaction preview yourself and just use the fetchTransactionPreview function
    const {
      fetchTransactionPreview, // function to fetch a single transaction preview
      transactionPreview,
      isLoading,
      error,
    } = usePaddlePricing();
    fetchTransactionPreview({ items: [{ price_id: 'pri_1k2hb4', quantity: 1 }], discountId: null, countryCode: 'US', postalCode: null })
 * @see https://developer.paddle.com/v1/api-reference/transactions/preview-transaction
 */
function usePaddlePricing(initItems = [], initDiscountId = null, initCountryCode = 'US', initPostalCode = null) {
  const [transactionPreview, setTransactionPreview] = useState(null); // entire transaction preview object
  const [isLoading, setIsLoading] = useState(true); // loading state
  const [error, setError] = useState(null); // error state

  /**
   * Fetches a transaction preview from Paddle.
   * @param {array} items Array of items to fetch prices for ex: [{ price_id: 'pri_1k2hb4, quantity: 1 }
   * @param {string} discountId Discount ID to apply to items
   * @param {string} countryCode Country code to fetch prices for
   * @param {string} postalCode Postal code to fetch prices for
   * @returns {object} Preview of transaction. @see https://developer.paddle.com/v1/api-reference/transactions/preview-transaction
   */
  const fetchTransactionPreview = async ({ items, discountId, countryCode, postalCode }) => {
    setIsLoading(true);
    let preview = null;
    try {
      if (!items.length) {
        throw new Error('No items available to preview in a transaction.');
      }
      // NOTE: For the new marketing website, they will just need to update this to use their request logic to hit our transaction preview in API-Auth
      preview = await agents.paddle.getTransactionPreview({ items, discountId, countryCode, postalCode });
      setTransactionPreview(preview);
    } catch (err) {
      BugsnagUtil.notifyWithNamedMetadata(err, 'Paddle', { items, discountId, countryCode, postalCode });
      setError(err);
    } finally {
      setIsLoading(false);
    }
    return preview;
  };

  // Fetch transaction preview on mount if items are passed
  useEffect(() => {
    if (initItems.length) {
      fetchTransactionPreview({ items: initItems, discountId: initDiscountId, countryCode: initCountryCode, postalCode: initPostalCode });
    }
  }, []);

  /**
   * Line items for the transaction preview.
   * This is where you will find prices of individual items.
   * @example 
   * [{
      "price_id": "pri_01h0nysw4b58dnha9pbfn51myq",
      "quantity": 1,
      "totals": {
          "subtotal": "5463",
          "tax": "350",
          "discount": "1093",
          "total": "4720"
      },
      "product": {
          "id": "pro_01h0nyra3pq7k5q09ex60mt0me",
          "name": "Cybrary Insider Pro",
          "description": "Cybrary Insider Pro",
          "tax_category": "standard",
          "image_url": "",
          "status": "active"
      },
      "tax_rate": "0.08",
      "unit_totals": {
          "subtotal": "5463",
          "tax": "350",
          "discount": "1093",
          "total": "4720"
      }
    }]
   */
  const lineItems = useMemo(() => {
    return transactionPreview?.details?.line_items || [];
  }, [transactionPreview]);

  /**
   * Items for the transaction preview.
   * Has things like billing interval, alternative region pricing, and quantity min/max
   * @example
   * [{
  "price": {
      "id": "pri_01h0nysw4b58dnha9pbfn51myq",
      "description": "CIP Monthly",
      "product_id": "pro_01h0nyra3pq7k5q09ex60mt0me",
      "billing_cycle": {
          "interval": "month",
          "frequency": 1
      },
      "trial_period": null,
      "tax_mode": "account_setting",
      "unit_price": {
          "amount": "5900",
          "currency_code": "USD"
      },
      "unit_price_overrides": [
          {
              "country_codes": [
                  "IN"
              ],
              "unit_price": {
                  "amount": "2999",
                  "currency_code": "USD"
              }
          }
      ],
      "quantity": {
          "minimum": 1,
          "maximum": 100
      },
      "status": "active"
  },
  "quantity": 1,
  "proration": null,
  "include_in_totals": true
  }]
   */
  const items = useMemo(() => {
    return transactionPreview?.details?.line_items || [];
  }, [transactionPreview]);

  /**
   * Currency code for the transaction preview.
   * @example 'USD', 'EUR', 'GBP', 'INR'
   * @default 'USD'
   */
  const currencyCode = useMemo(() => {
    return transactionPreview?.currency_code || 'USD';
  }, [transactionPreview]);

  /**
   * Total prices for the entire transaction.
   * @example
   * {
      "subtotal": "5463",
      "tax": "350",
      "discount": "1093",
      "total": "4720",
      "fee": null,
      "credit": "0",
      "balance": "4720",
      "earnings": null,
      "currency_code": "USD"
    }
   */
  const totalPrices = useMemo(() => {
    return transactionPreview?.details?.totals || {};
  }, [transactionPreview]);

  return useMemo(() => {
    return {
      transactionPreview,
      fetchTransactionPreview,
      items,
      lineItems,
      currencyCode,
      totalPrices,
      isLoading,
      error,
    };
  }, [fetchTransactionPreview, transactionPreview, items, lineItems, currencyCode, totalPrices, isLoading, error]);
}

export default usePaddlePricing;
