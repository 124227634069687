import React from 'react';
import moment from 'moment';
import { inject, observer } from 'mobx-react';
import { twMerge } from 'tailwind-merge';
import Title from '../Title/Title';
import UPTrigger from '../UPTrigger/UPTrigger';
import If from '../If/If';
import Icon from '../Icon/Icon';
import AddLink from '../AddLink/AddLink';
import InvitedTeamMembersModal from '../../pages/Enterprise/InvitedTeamMembersModal';
import TeamJoinRequestsModal from '../../pages/Enterprise/TeamJoinRequestsModal';
import FormatUtil from '../../utils/formatUtil';

const OrganizationDashboardHeading = inject('userStore')(
  observer(({ userStore, groupScope, title, button, className }) => {
    const { team } = userStore;
    const canManageAdmins = team?.permissions?.canManageAdmins;
    const isDemo = !team || team?.id === 'demo';
    const handleZETrigger = () => (!isDemo && window.zE ? window.zE('webWidget', 'open') : null);

    const headingClasses = twMerge('flex flex-col px-8 pt-12 print:pt-4 print:pb-0 pb-6 lg:flex-row header-container', className);
    const csmName = team?.csm?.first_name || team?.csm?.last_name ? `${team?.csm?.first_name || ''} ${team?.csm?.last_name || ''}` : 'Support';
    const csmEmail = team?.csm?.email || 'support@cybrary.it';
    return (
      <div className={headingClasses}>
        <div className="print:flex grow lg:flex">
          <div className="w-full">
            <div className="print:flex items-center mb-2 md:flex">
              {team?.logo_url && (
                <div className="mr-8 mb-8 md:mb-0 header-image">
                  <img style={{ maxWidth: '140px' }} src={team?.logo_url} alt="Logo" />
                </div>
              )}
              <div className="flex-1 grow md:mb-0">
                <div className="flex-1 print:mb-4 mb-8 md:mb-2">
                  <Title title={title || team?.name} omitPadding />
                </div>
                <div className="gap-x-2 items-center md:flex">
                  <AddLink to={`/enterprise/${team?.id}/organization/members`}>
                    <span className="flex gap-x-2 items-center hover:underline whitespace-nowrap" title="View Members">
                      <Icon name="user-group" className="w-4 h-4" /> {FormatUtil.formatNumbers(team?.member_count)} Member{team?.member_count > 1 ? 's' : ''}
                    </span>
                  </AddLink>
                  <span className="hidden text-gray-500 md:block">|</span>
                  <If condition={team?.team_type?.name !== 'Enterprise'}>
                    <span className="flex gap-x-2 items-center whitespace-nowrap" title="Team Type">
                      <Icon name="list" className="w-4 h-4" /> {team?.team_type.name}
                    </span>
                    <span className="hidden text-gray-500 md:block">|</span>
                  </If>
                  <span className="flex gap-x-1 text-sm text-gray-500" title="Team Creation Date">
                    <Icon name="calendar" className="shrink-0 my-auto w-4 h-4 whitespace-nowrap" />
                    <span>Joined {moment(team?.created_at).format('MMMM D, YYYY')}</span>
                  </span>
                </div>
                <div className="flex gap-x-2 items-center mt-1">
                  <Icon name="help" className="shrink-0 w-4 h-4 text-cyb-pink-500" />
                  <span className="text-sm text-cyb-pink-500">
                    Contact: {csmName} (
                    <AddLink to={`mailto:${csmEmail}`} className="text-cyb-pink-500 hover:underline">
                      {csmEmail}
                    </AddLink>
                    )
                  </span>
                </div>

                <div className="flex gap-x-6 mt-1">
                  <InvitedTeamMembersModal />
                  <TeamJoinRequestsModal />
                </div>
              </div>
              <div className="flex gap-x-4 items-center my-4 md:flex-col md:gap-y-4 md:items-end md:my-0 md:ml-4 md:text-right">
                <If condition={canManageAdmins && !groupScope && !isDemo && !button}>
                  <div className="flex items-start my-4">
                    <button
                      className={`print:hidden py-2.5 px-6 text-sm font-bold leading-5 text-center text-white hover:text-white bg-cyb-pink-500 hover:bg-cyb-pink-600 rounded-sm ${
                        isDemo ? 'cursor-not-allowed' : ''
                      }`}
                      onClick={handleZETrigger}
                      disabled={isDemo}
                    >
                      + Add Licenses
                    </button>
                    <UPTrigger guideId={null} onClick={handleZETrigger} className="-mt-5 -ml-1" />
                  </div>
                </If>
                <If condition={button && !isDemo}>{button}</If>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  })
);

export default OrganizationDashboardHeading;
