import React, { createContext, useCallback, useContext, useMemo } from 'react';
import { inject, observer } from 'mobx-react';
import { useNavigate } from 'react-router-dom';
import EnrollmentUtil from '../utils/enrollmentUtil';

const EnrollmentContext = createContext();

function EnrollmentProviderComponent({ children, userStore, enrollmentStore, commonStore }) {
  const navigate = useNavigate();
  const { enrollmentProgressMap } = enrollmentStore;

  /**
   * Start an enrollment for a given content.
   * @param {Object} content - The content to enroll in. Must have an id and a content_type field.
   * @param {Object} data - The data to pass to the enrollment utility.
   * @param {string} data.exclude_progress_before - The date to exclude progress before. 'now' will exclude progress before the current time.
   * @param {Object} options - The options to pass to the enrollment utility.
   * @param {number} options.preferredEnrollmentId - The enrollment id to use if the content is already enrolled in.
   * @param {number} options.preferredCollectionItemId - The collection item id to use if the content is already enrolled in.
   */
  const startEnrollment = useCallback(
    async (content, data, options) => {
      await EnrollmentUtil.handleStartEnroll(userStore, enrollmentStore, commonStore, navigate, content, data, options);
    },
    [userStore, enrollmentStore, commonStore, navigate]
  );

  /**
   * Check if a given content description is completed.
   * @param {number} contentDescriptionId - The content description id to check.
   * @returns {boolean} Whether the content description is completed.
   */
  const checkIfContentIsCompleted = useCallback(
    (contentDescriptionId) => {
      const idKey = `id_${contentDescriptionId}`;
      if (typeof enrollmentProgressMap?.[idKey] !== 'undefined') {
        return !!enrollmentProgressMap[idKey]?.completed_at;
      }
      return null;
    },
    [enrollmentProgressMap]
  );

  const values = useMemo(
    () => ({ startEnrollment, checkIfContentIsCompleted, checkPermissions: userStore?.checkPermissions }),
    [startEnrollment, checkIfContentIsCompleted, userStore?.checkPermissions]
  );

  return <EnrollmentContext.Provider value={values}>{children}</EnrollmentContext.Provider>;
}

export const useEnrollment = () => useContext(EnrollmentContext);
const EnrollmentProvider = inject('userStore', 'enrollmentStore', 'commonStore')(observer(EnrollmentProviderComponent));
export default EnrollmentProvider;
