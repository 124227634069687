import React, { useState } from 'react';
import Bugsnag from '@bugsnag/js';
import { useParams } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import { twMerge } from 'tailwind-merge';
import Modal from '../../components/Modal/Modal';
import Icon from '../../components/Icon/Icon';
import List, { ListItem } from '../../components/List/List';
import Loading from '../../components/Loading/Loading';
import Container from '../../components/Container/Container';
import FormatUtil, { getPackageNames } from '../../utils/formatUtil';
import Pagination from '../../components/Pagination/Pagination';
import './enterprise.css';
import Tooltip from '../../components/Tooltip/Tooltip';
import Header from '../../components/Header/Header';
import AddLink from '../../components/AddLink/AddLink';
import Button from '../../components/Button/Button';

const deleteTeamInvitation = (inviteId, orgId, enterpriseStore, commonStore) => {
  commonStore.triggerConfirm({
    content: 'Are you sure you want to delete this invitation?',
    continueBtn: 'Delete',
    cancelBtn: 'Cancel',
    continue: () => {
      enterpriseStore
        .deleteTeamInvitation(orgId, inviteId)
        .then(() => {
          enterpriseStore.getInvitedTeamMembers(orgId);
          commonStore.triggerToast('success', {
            content: 'Successfully deleted invitation',
          });
        })
        .catch((error) => {
          Bugsnag.notify(error);
          commonStore.triggerToast('error', {
            content: 'Something went wrong. Unable to delete invitation',
          });
        });
      commonStore.resetConfirmState();
    },
    cancel: () => commonStore.resetConfirmState(),
  });
};

const resendTeamInvitation = (inviteId, orgId, enterpriseStore, commonStore, email) => {
  enterpriseStore
    .resendTeamInvitation(orgId, inviteId)
    .then(() => {
      commonStore.triggerToast('success', {
        content: `Successfully resent invitation to ${email}`,
      });
    })
    .catch((error) => {
      Bugsnag.notify(error);
      commonStore.triggerToast('error', {
        content: 'Something went wrong. Unable to resend invitation',
      });
    });
};

const InvitedTeamMembersModal = inject(
  'enterpriseStore',
  'commonStore',
  'userStore'
)(
  observer(({ enterpriseStore, commonStore, userStore }) => {
    const { invitedTeamMembers } = enterpriseStore || {};
    const { loading, data, queryParams, pageCount } = invitedTeamMembers || {};
    const { activePg } = queryParams || {};
    const [invitedOpen, setInvitedOpen] = useState(false);
    const { orgId } = useParams();
    const { team } = userStore || {};

    const changePage = (pg) => {
      enterpriseStore.setTeamInviteQueryParams('activePg', pg);
      enterpriseStore.getInvitedTeamMembers(orgId);
    };

    if (!data || !data.totalRecords) {
      if (invitedOpen) {
        setInvitedOpen(false);
      }
      return null;
    }
    const listData = FormatUtil.convertTableDataToList(data.columns, data.tableData);
    const labelText = `${data.totalRecords} Pending Invite${data.totalRecords > 1 ? 's' : ''}`;
    const liClasses = twMerge('overflow-hidden justify-between items-center p-2 hover:bg-gray-50 border-t last:border-b border-gray-400', loading && 'opacity-40');
    return (
      <>
        <AddLink onClick={() => setInvitedOpen(true)}>
          <span className="flex gap-x-2 items-center text-sm text-cyb-blue-600 hover:underline" title="View Pending Invites">
            <Icon name="mail" className="w-4 h-4" /> {labelText}
          </span>
        </AddLink>
        <Modal open={invitedOpen} toggle={() => setInvitedOpen(false)} paddingBottom="p-4" position="center" size="lg" ariaLabelledBy="invited-users">
          <div className="pt-8 bg-white lg:p-4 invited-members-list">
            <Header as="h2" className="mb-2">
              Invited Users
            </Header>
            <p>These users have been invited to your team, but have not accepted their invite.</p>
            {loading && !listData.length ? (
              <Container>
                <Loading message="Loading..." />
              </Container>
            ) : (
              <div className="flex flex-col">
                <List className="overflow-y-auto mt-4 max-h-[60vh] border-gray-400">
                  {listData.map((item) => {
                    // Map item.license_ids to license names from team data
                    const licenseNames = item?.license_ids?.map((licenseId) => {
                      const license = team.active_license_grants.find((teamLicense) => teamLicense.team_content_license_grant_id === licenseId);
                      if (!license?.name && license?.package_types) {
                        return getPackageNames(license.package_types);
                      }
                      return license?.name || '';
                    });
                    // Filter out empty strings
                    const filteredLicenseNames = licenseNames?.filter((name) => name !== '');
                    const grantedText = filteredLicenseNames?.length ? filteredLicenseNames.join(', ') : 'None';

                    const roleMeta = FormatUtil.getTeamRoleMeta(item.role);
                    const resendMessage = `Resend invitation to ${item.email.slice(0, 47)}${item.email.length > 47 ? '...' : ''}`;
                    const deleteMessage = `Delete invitation for ${item.email.slice(0, 47)}${item.email.length > 47 ? '...' : ''}`;
                    return (
                      <ListItem active={!loading} key={item.id} className={liClasses}>
                        <div className="py-4">
                          <div className="overflow-auto pb-4 font-semibold">{item.email}</div>
                          <div className="items-center text-sm text-gray-600 lg:flex lg:space-x-4">
                            <div className={`flex items-center space-x-2 text-${roleMeta.color}-500 `}>
                              <Icon name="user" className="w-4 h-4" />
                              <span className="ml-2">{FormatUtil.convertRoleName(item.role)}</span>
                            </div>
                            <div className="flex items-center space-x-2">
                              <Icon name="calendar" className="w-4 h-4" /> <span>{FormatUtil.convertUtcToLocal(item.created_at).format('M/D/YYYY')}</span>
                            </div>
                            <div className="flex items-center space-x-2">
                              <Icon name="license" className="w-4 h-4" /> <span>{grantedText}</span>
                            </div>

                            <div className="flex gap-x-4 justify-between pr-4">
                              <Tooltip
                                omitTabIndex
                                triggerContent={
                                  <Button
                                    disabled={loading}
                                    aria-label={resendMessage}
                                    onClick={() => resendTeamInvitation(item.id, orgId, enterpriseStore, commonStore, item.email)}
                                    icon={{ name: 'refresh', className: 'w-4 h-4', position: 'left' }}
                                    className="px-0 text-xs"
                                    color="transparent-gray"
                                  >
                                    Resend
                                  </Button>
                                }
                                content={resendMessage}
                              />
                              <Tooltip
                                omitTabIndex
                                triggerContent={
                                  <Button
                                    disabled={loading}
                                    aria-label={deleteMessage}
                                    color="transparent-gray"
                                    onClick={() => deleteTeamInvitation(item.id, orgId, enterpriseStore, commonStore)}
                                    icon={{ name: 'trash', className: 'w-4 h-4', position: 'left' }}
                                    className="px-0 text-xs"
                                  >
                                    Delete
                                  </Button>
                                }
                                content={deleteMessage}
                              />
                            </div>
                          </div>
                        </div>
                      </ListItem>
                    );
                  })}
                </List>
                <div className="grow-0 mx-auto mt-3">
                  <Pagination totalPages={pageCount || 0} onPageChange={changePage} activePage={activePg} />
                </div>
              </div>
            )}
          </div>
        </Modal>
      </>
    );
  })
);

export default InvitedTeamMembersModal;
