import React from 'react';
import Icon from '../Icon/Icon';
import RadioButtons from '../FormFields/RadioButtons';
import BoxIconField from './BoxIconField';

const DEFAULT_ICON_COUNT = 1;

class BoxIconSingleSelect extends React.Component {
  state = { value: this.props.defaultValue || null };

  componentDidMount() {
    if (this.props.defaultValue) {
      this.handleChange(undefined, {
        name: this.props.name,
        value: this.props.defaultValue,
      });
    }
  }

  handleClick = (e, data) => {
    this.handleChange(e, data);
  };

  handleChange = (e, data) => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }
    // Manage LOCAL state of the radio group, while calling the parents onChange function.
    this.setState({
      value: data.value,
    });
    this.props.onChange(e, data);
  };

  getDifficultyIcon = (option) => {
    const iconCount = option.iconCount ? option.iconCount : DEFAULT_ICON_COUNT;
    const icons = [];
    let i;
    for (i = 0; i < iconCount; i++) {
      const iconColor = this.state.value === option.value ? 'text-cyb-pink-500' : 'text-black';
      icons.push(<Icon name={option.icon} className={`w-4 h-4 inline ${iconColor}`} key={i} />);
    }
    return icons;
  };

  render() {
    const { fieldsPerRow, options, name } = this.props;
    // Generate our radio fields from the provided options.
    if (!options) {
      return null;
    }

    const radiosOuput = options.map((currOption) => {
      const option = { ...currOption };
      const label = option.text ? option.text : option.value;
      option.name = name;
      const isSelected = this.state.value === option.value;
      return (
        <BoxIconField
          key={currOption.key}
          fieldsPerRow={fieldsPerRow}
          option={option}
          isSelected={isSelected}
          handleClick={this.handleClick}
          headerClasses="text-xl"
          icon={<div className="mb-4">{this.getDifficultyIcon(option)}</div>}
        >
          <RadioButtons
            label={label}
            value={option.value}
            options={[option]}
            name={`${name}-${label}`}
            defaultValue={this.state.value}
            onChange={this.handleChange}
            block
            inputClassName="hidden"
            labelClassName="text-black text-sm"
          />
        </BoxIconField>
      );
    });

    return <div className="flex flex-wrap justify-evenly items-center mx-auto w-full">{radiosOuput}</div>;
  }
}

export default BoxIconSingleSelect;
