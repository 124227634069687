import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import PrivateRoute from '../../middleware/PrivateRoute';
import CheckoutRoute from '../../middleware/CheckoutRoute';
// Pages
import BrowseRefined from '../../pages/BrowseRefined/BrowseRefined';
import NewReleases from '../../pages/BrowseRefined/NewReleases';
import Mitre from '../../pages/Mitre/Mitre';
import CVESeries from '../../pages/BrowseRefined/CVESeries';
import AppPage from '../../pages/AppPage/AppPage';
import LODLimit from '../../pages/ImmersivePage/LODLimit';
import Enrollments from '../../pages/Enrollments/Enrollments';
import EnrollmentsDashboard from '../../pages/Enrollments/EnrollmentsDashboard';
import MyLearning from '../../pages/MyLearning/MyLearning';
import Assessments from '../../pages/Assessments/Assessments';
import Dashboard from '../../pages/Dashboard/Dashboard';
import Community from '../../pages/Community/Community';
import Reporting from '../../pages/Reports/Reporting';
import ScheduledReports from '../../pages/Reports/ScheduledReports';
import ManageScheduledReports from '../../pages/Reports/ManageScheduledReports';
import ReportDownload from '../../pages/Reports/ReportDownload';
import Reports from '../../pages/Reports/Reports';
import LiveSeries from '../../pages/Live/Series';
import LiveSession from '../../pages/Live/Session';
import LiveHome from '../../pages/Live/Home';
import Assignments from '../../pages/Assignments/Assignments';
import Assignment from '../../pages/Assignments/Assignment';
import EnterpriseAssignment from '../../pages/Enterprise/EnterpriseAssignment';
import CreateAssignment from '../../pages/Enterprise/CreateAssignment';
import Enterprise from '../../pages/Enterprise/Enterprise';
import TeamsLinkRouter from '../../pages/Enterprise/TeamsLinkRouter';
import RequestTeamLicenseTeamSelector from '../../pages/Enterprise/RequestTeamLicenseTeamSelector';
import RequestTeamLicense from '../../pages/Enterprise/RequestTeamLicense';
import ActivityLog from '../../pages/Enterprise/ActivityLog';
import OrganizationMembersDashboard from '../../pages/Enterprise/OrganizationMembersDashboard';
import OrganizationMemberDashboard from '../../pages/Enterprise/OrganizationMemberDashboard';
import OrganizationAssignmentDashboard from '../../pages/Enterprise/OrganizationAssignmentDashboard';
import ContentDashboard from '../../pages/Enterprise/ContentDashboard';
import OrganizationDashboard from '../../pages/Enterprise/OrganizationDashboard';
import OrganizationGroups from '../../pages/Enterprise/OrganizationGroups';
import TeamGoals from '../../pages/Goals/TeamGoals';
import TeamSettings from '../../pages/Enterprise/TeamSettings';
import MyGoals from '../../pages/Goals/MyGoals';
import GoalPath from '../../pages/Goals/GoalPath';
import Curricula from '../../pages/Enterprise/Curricula';
import CurriculumBuild from '../../pages/Enterprise/CurriculumBuild';
import Curriculum from '../../pages/Enterprise/Curriculum';
import InsiderProLinkPage from '../../pages/LinkPage/InsiderProLinkPage';
import Logout from '../../pages/Logout/Logout';
import CybraryAdminDashboard from '../../pages/CybraryAdmin/CybraryAdminDashboard';
import CybraryAdminBrowseTeams from '../../pages/CybraryAdmin/CybraryAdminBrowseTeams';
import CybraryAdminViewTeam from '../../pages/CybraryAdmin/CybraryAdminViewTeam';
import CybraryAdminTeamActivityLog from '../../pages/CybraryAdmin/CybraryAdminTeamActivityLog';
import CybraryAdminTeamManagePrograms from '../../pages/CybraryAdmin/CybraryAdminTeamManagePrograms';
import CybraryAdminBrowseUser from '../../pages/CybraryAdmin/CybraryAdminBrowseUser';
import CybraryAdminBrowseVendorResults from '../../pages/CybraryAdmin/CybraryAdminBrowseVendorResults';
import CybraryAdminMemberDashboard from '../../pages/CybraryAdmin/CybraryAdminMemberDashboard';
import CybraryAdminManageTeam from '../../pages/CybraryAdmin/CybraryAdminManageTeam';
import TOSPage from '../../pages/Onboarding/TOSPage';
import Recommendations from '../../pages/BrowseRefined/Recommendations';

import BookMarks from '../../pages/Bookmark/Bookmarks';
import StyledError from '../Error/StyledError';
import SettingsAccount from '../../pages/Settings/SettingsAccount';
import SettingGDPRReportDownload from '../../pages/Settings/SettingsGDPRReportDownload';
import SettingsMembership from '../../pages/Settings/SettingsMembership';
import CIPCancellation from '../../pages/Cancellation/CIPCancellation';
import TeamInvite from '../../pages/Enterprise/TeamInvite';
import TeamInviteInterstitial from '../../pages/Enterprise/TeamInviteInterstitial';
import ComingSoon from '../../pages/BrowseRefined/ComingSoon';
import NotificationsPage from '../../pages/Notifications/NotificationsPage';
import Certificates from '../../pages/Certificates/Certificates';
import ProfilePage from '../../pages/Profile/Profile';
import LoginPage from '../../pages/Login/Login';
import EmailLogin from '../../pages/Login/EmailLogin';
import RegisterCode from '../../pages/Login/RegisterCode';
import SamlResult from '../../pages/Login/SamlResult';
import ForgotPasswordPage from '../../pages/Login/ForgotPassword';
import ResetPassword from '../../pages/Login/ResetPassword';
import AuthRedirects from '../../pages/Login/AuthRedirects';
import LinkAccount from '../../pages/LinkAccount/LinkAccount';
import AccountChange from '../../pages/Account/AccountChange';
import { AdminJwt } from '../../pages/DevPages/AdminJwt';
import CybraryAdminUnverifiedDashboard from '../../pages/CybraryAdmin/CybraryAdminUnverifiedDashboard';
import RecoveryEmail from '../../pages/Onboarding/RecoveryEmail';
import CybraryAdminBrowseChallengeCourses from '../../pages/CybraryAdmin/CybraryAdminBrowseChallengeCourses';
import CybraryAdminChallengeKey from '../../pages/CybraryAdmin/CybraryAdminChallengeKey';
import BrowseThreatActorPaths from '../../pages/ThreatActor/BrowseThreatActorPaths';
import ThreatActorPath from '../../pages/ThreatActor/ThreatActorPath';
import CybraryChallenges from '../../pages/BrowseRefined/CybraryChallenges';
import RequestAddOnLicense from '../../pages/Enterprise/RequestAddOnLicense';
import RequestAddOnLicenseTeamSelector from '../../pages/Enterprise/RequestAddOnLicenseTeamSelector';
import BrowseCareerPrograms from '../../pages/CareerPrograms/BrowseCareerPrograms';
import CareerProgramOverview from '../../pages/CareerPrograms/CareerProgramOverview';
import BrowseCertificationPrep from '../../pages/CertificationPrep/BrowseCertificationPrep';
import BrowseCybrarySelectPaths from '../../pages/CybrarySelect/BrowseCybrarySelectPaths';
import CybrarySelectCollectionPage from '../../pages/CybrarySelect/CybrarySelectCollectionPage';
import IconsPage from '../../pages/DevPages/IconsPage';
import LocalRoute from '../../middleware/LocalRoute';
import GoogleMarketplaceOnboardingPage from '../../pages/GoogleMarketplaceOnboardingPage/GoogleMarketplaceOnboardingPage';
import PublicProfilePage from '../../pages/Profile/PublicProfile';
import RegisterPage from '../../pages/Register/RegisterPage';
import WelcomeToTeams from '../../pages/Enterprise/WelcomeToTeams';
import FreeAssessment from '../../pages/Enterprise/FreeAssessment';
import CustomPathsViewAll from '../../pages/CustomPaths/CustomPathsViewAll';
import CustomPathsDetails from '../../pages/CustomPaths/CustomPathsDetails';
import ReferralInviteForm from '../ReferralModal/ReferralInviteForm';
import AssessmentPathResults from '../../pages/Enterprise/AssessmentPathResults';
import AssessmentResults from '../../pages/Enterprise/AssessmentResults';
import AssessmentResultsSummary from '../../pages/Enterprise/AssessmentResultsSummary';
import DiscourseAuthRedirect from '../../pages/Login/DiscourseAuthRedirect';
import PaddleUpgradeExistingSubscriptionPage from '../../pages/Paddle/PaddleUpgradeExistingSubscription';
import MeasureAssessments from '../../pages/Measure/Assessments';
import MeasureAssessmentResults from '../../pages/Measure/AssessmentResults';
import MeasureBaseline from '../../pages/Measure/Baseline';
import MeasureBaselineGoal from '../../pages/Measure/BaselineGoal';

// New immersive components
import ImmersiveEnrollmentWithActivity from '../../pages/ImmersivePage/ImmersiveEnrollmentWithActivity';
import ImmersiveJustEnrollment from '../../pages/ImmersivePage/ImmersiveJustEnrollment';
import ImmersiveEnrollmentWithItemNoActivity from '../../pages/ImmersivePage/ImmersiveEnrollmentWithItemNoActivity';
import ImmersiveEnrollmentWithItemAndActivity from '../../pages/ImmersivePage/ImmersiveEnrollmentWithItemAndActivity';
import ImmersiveEnrollmentActivity from '../../pages/ImmersivePage/ImmersiveEnrollmentActivity';
import ImmersiveEnrollmentWithItemActivity from '../../pages/ImmersivePage/ImmersiveEnrollmentWithItemActivity';
import ComponentsPage from '../../pages/DevPages/ComponentsPage';
import { CONTENT_TYPES } from '../../constants';
import useSandboxCookie from '../../hooks/cookies/useSandboxCookie';
import TeamOnboardingPage from '../Onboarding/TeamOnboardingPage';
import UserOnboardingPage from '../Onboarding/UserOnboardingPage';
import SecurityAwarenessHubPage from '../../pages/SecurityAwarenessHub/SecurityAwarenessHubPage';

function Redirect({ path }) {
  window.location.pathname = path;
  return null;
}

/**
 * Site Router
 */
function Router() {
  const { isDesignComponentSandboxEnabled } = useSandboxCookie();
  return (
    <Routes>
      {/* Public Routes */}
      <Route path="/logout" element={<Logout />} />
      <Route path="/login" element={<LoginPage />} />
      <Route path="/register" element={<RegisterPage />} />
      <Route path="/email-login" element={<EmailLogin />} />
      <Route path="/forgot-password" element={<ForgotPasswordPage />} />
      <Route path="/reset-password" element={<ResetPassword />} />
      <Route path="/link-account" element={<LinkAccount />} />
      <Route path="/acctmgmt" element={<AuthRedirects />} />
      <Route path="/account-change" element={<AccountChange />} />
      <Route path="/verification-code" element={<RegisterCode />} />
      <Route path="/saml-result" element={<SamlResult />} />
      <Route path="/profile/:username" element={<PublicProfilePage />} />

      {/* DEFAULT CHECKOUT PAGES */}
      <Route path="/upgrade/checkout" element={<CheckoutRoute isPaddle />} />
      <Route path="/upgrade/teams-checkout" element={<CheckoutRoute isPaddle isTeams />} />
      <Route path="/upgrade" element={<Navigate replace to="/upgrade/checkout" />} />
      <Route path="/checkout" element={<Navigate replace to="/upgrade/checkout" />} />
      {/* PADDLE CHECKOUT PAGES */}
      <Route path="/upgrade/checkout/paddle" element={<CheckoutRoute isPaddle />} />
      <Route path="/upgrade/teams-checkout/paddle" element={<CheckoutRoute isPaddle isTeams />} />
      {/* PADDLE UPDATE SUBSCRIPTION PAGE */}
      <Route
        path="/upgrade/update-subscription"
        element={
          <PrivateRoute>
            <PaddleUpgradeExistingSubscriptionPage />
          </PrivateRoute>
        }
      />

      {/* CUSTOM PACKAGES CHECKOUT */}
      <Route path="/upgrade/checkout/:checkoutPackageId" element={<CheckoutRoute isPaddle />} />

      {/* Dev/Netlify Login */}
      <Route
        path="/local-login"
        element={
          <LocalRoute>
            <AdminJwt />
          </LocalRoute>
        }
      />

      {/* Dev Register Route */}
      <Route
        path="/local-register"
        element={
          <LocalRoute>
            <RegisterPage />
          </LocalRoute>
        }
      />

      {/* Dev Icon Browse */}
      <Route
        path="/icons"
        element={
          <LocalRoute allowBWB isSandboxEnabled={isDesignComponentSandboxEnabled}>
            <IconsPage />
          </LocalRoute>
        }
      />

      {/* Dev Components Browse */}
      <Route
        path="/components"
        element={
          <LocalRoute allowBWB isSandboxEnabled={isDesignComponentSandboxEnabled}>
            <ComponentsPage />
          </LocalRoute>
        }
      />

      {/* DISCOURSE AUTH REDIRECT */}
      <Route
        path="/discourse/auth"
        element={
          <PrivateRoute>
            <DiscourseAuthRedirect />
          </PrivateRoute>
        }
      />

      {/* Team Invite interstitial */}
      <Route path="/team-invite/:uuid/key/:key" element={<TeamInviteInterstitial />} />

      {/* Referral Page */}
      <Route
        path="/refer-a-friend"
        element={
          <PrivateRoute>
            <ReferralInviteForm />
          </PrivateRoute>
        }
      />

      {/* HOME PAGE */}
      <Route
        path="/"
        element={
          <PrivateRoute>
            <Dashboard />
          </PrivateRoute>
        }
      />

      {/* MY ASSIGNMENTS PAGES -- Now redirect to my-learning/assignments */}
      <Route
        path="/assignments"
        element={
          <PrivateRoute>
            <Assignments />
          </PrivateRoute>
        }
      />
      <Route
        path="/assignments/:id/content/:contentId"
        element={
          <PrivateRoute>
            <Assignment />
          </PrivateRoute>
        }
      />

      {/* MY CAREER PATHS PAGES -- Now redirect to my-learning/career-paths */}
      <Route
        path="/career-paths"
        element={
          <PrivateRoute>
            <Navigate replace to="/my-learning/career-paths" />
          </PrivateRoute>
        }
      />
      <Route
        path="/career-paths/:id/content/:contentId"
        element={
          <PrivateRoute>
            <Navigate replace to="/my-learning/career-paths" />
          </PrivateRoute>
        }
      />

      <Route
        path="/recommendations"
        element={
          <PrivateRoute>
            <Recommendations />
          </PrivateRoute>
        }
      />
      <Route
        path="/bookmarks"
        element={
          <PrivateRoute>
            <BookMarks />
          </PrivateRoute>
        }
      />

      {/* ONBOARDING PAGES */}
      <Route
        path="/onboarding/terms-of-service"
        element={
          <PrivateRoute>
            <TOSPage />
          </PrivateRoute>
        }
      />
      <Route
        path="/onboarding/recovery-email"
        element={
          <PrivateRoute>
            <RecoveryEmail />
          </PrivateRoute>
        }
      />
      <Route
        path="/onboarding/international"
        element={
          <PrivateRoute>
            <UserOnboardingPage />
          </PrivateRoute>
        }
      />
      <Route
        path="/onboarding"
        element={
          <PrivateRoute>
            <UserOnboardingPage />
          </PrivateRoute>
        }
      />
      <Route
        path="/onboarding/teams/:teamId"
        element={
          <PrivateRoute>
            <TeamOnboardingPage />
          </PrivateRoute>
        }
      />
      <Route
        path="/onboarding/teams"
        element={
          <PrivateRoute>
            <TeamOnboardingPage />
          </PrivateRoute>
        }
      />
      <Route
        path="/onboarding/team"
        element={
          <PrivateRoute>
            <TeamOnboardingPage />
          </PrivateRoute>
        }
      />

      {/* MY ENROLLMENTS PAGES */}
      <Route
        path="/enrollments-launcher"
        element={
          <PrivateRoute>
            <Enrollments />
          </PrivateRoute>
        }
      />
      <Route
        path="/enrollments"
        element={
          <PrivateRoute>
            <EnrollmentsDashboard />
          </PrivateRoute>
        }
      />
      <Route
        path="/my-learning"
        element={
          <PrivateRoute>
            <MyLearning />
          </PrivateRoute>
        }
      />
      <Route
        path="/my-learning/certificates"
        element={
          <PrivateRoute>
            <Certificates />
          </PrivateRoute>
        }
      />
      <Route
        path="/my-learning/:section"
        element={
          <PrivateRoute>
            <MyLearning />
          </PrivateRoute>
        }
      />
      <Route
        path="/my-learning/:section/:status"
        element={
          <PrivateRoute>
            <MyLearning />
          </PrivateRoute>
        }
      />
      {/* route designated for `/assessments/results/:trackId/:assessmentId` */}
      <Route
        path="/my-learning/:section/:status/:trackId/:assessmentId"
        element={
          <PrivateRoute>
            <MyLearning />
          </PrivateRoute>
        }
      />

      {/* ASSESSMENTS */}
      <Route
        path="/assessments"
        element={
          <PrivateRoute>
            <Assessments />
          </PrivateRoute>
        }
      />

      {/* MEASURE PAGES */}

      {/* Measure > Assessments Page */}
      <Route
        path="/measure/assessments"
        element={
          <PrivateRoute>
            <MeasureAssessments />
          </PrivateRoute>
        }
      />

      {/* Measure > Assessments Results Page */}
      <Route
        path="/measure/assessments/results"
        element={
          <PrivateRoute>
            <MeasureAssessmentResults />
          </PrivateRoute>
        }
      />

      {/* Measure > Baseline Page */}
      <Route
        path="/measure/baseline"
        element={
          <PrivateRoute>
            <MeasureBaseline />
          </PrivateRoute>
        }
      />

      {/* Measure > Baseline > Goal Page */}
      <Route
        path="/measure/baseline/goal/:goalId"
        element={
          <PrivateRoute>
            <MeasureBaselineGoal />
          </PrivateRoute>
        }
      />

      {/* Measure > Assessments > Assessment Path > Results Summary Page */}
      <Route
        path="/measure/assessments/results/:orgId/paths/assessments/:pathId/:contentId/summary"
        element={
          <PrivateRoute>
            <AssessmentResultsSummary />
          </PrivateRoute>
        }
      />

      {/* Measure > Assessments > Assessment Path > Results Summary Page */}
      <Route
        path="/measure/assessments/:orgId/paths/assessments/:pathId/:contentId/summary"
        element={
          <PrivateRoute>
            <AssessmentResultsSummary />
          </PrivateRoute>
        }
      />

      {/* Measure > Baseline Teams > Assessment Path > Results Summary Page */}
      <Route
        path="/measure/baseline/goal/:goalId/paths/assessments/:pathId/:contentId/summary"
        element={
          <PrivateRoute>
            <AssessmentResultsSummary />
          </PrivateRoute>
        }
      />
      {/* Immersive Routes */}
      <Route
        path="/immersive/:enrollmentId"
        element={
          <PrivateRoute>
            <ImmersiveJustEnrollment />
          </PrivateRoute>
        }
      />
      <Route
        path="/immersive/enrollment/activity/:enrollmentActivityId"
        element={
          <PrivateRoute>
            <ImmersiveEnrollmentActivity />
          </PrivateRoute>
        }
      />
      <Route
        path="/immersive/enrollment/activity/:enrollmentActivityId/complete"
        element={
          <PrivateRoute>
            <ImmersiveEnrollmentActivity onCompletePage />
          </PrivateRoute>
        }
      />
      <Route
        path="/immersive/:enrollmentId/activity/:activityId"
        element={
          <PrivateRoute>
            <ImmersiveEnrollmentWithActivity />
          </PrivateRoute>
        }
      />
      <Route
        path="/immersive/:enrollmentId/activity/:activityId/complete"
        element={
          <PrivateRoute>
            <ImmersiveEnrollmentWithActivity onCompletePage />
          </PrivateRoute>
        }
      />
      <Route
        path="/immersive/:enrollmentId/item/:itemId"
        element={
          <PrivateRoute>
            <ImmersiveEnrollmentWithItemNoActivity />
          </PrivateRoute>
        }
      />
      <Route
        path="/immersive/:enrollmentId/item/enrollment/activity/:itemActivityId"
        element={
          <PrivateRoute>
            <ImmersiveEnrollmentWithItemActivity />
          </PrivateRoute>
        }
      />
      <Route
        path="/immersive/:enrollmentId/item/enrollment/activity/:itemActivityId/complete"
        element={
          <PrivateRoute>
            <ImmersiveEnrollmentWithItemActivity onCompletePage />
          </PrivateRoute>
        }
      />
      <Route
        path="/immersive/:enrollmentId/item/:itemId/activity/:activityId"
        element={
          <PrivateRoute>
            <ImmersiveEnrollmentWithItemAndActivity />
          </PrivateRoute>
        }
      />
      <Route
        path="/immersive/:enrollmentId/item/:itemId/activity/:activityId/complete"
        element={
          <PrivateRoute>
            <ImmersiveEnrollmentWithItemAndActivity onCompletePage />
          </PrivateRoute>
        }
      />

      <Route
        path="/lod-limit"
        element={
          <PrivateRoute>
            <LODLimit />
          </PrivateRoute>
        }
      />

      {/* CATALOG BROWSE PAGES  */}
      <Route path="/browse" element={<Redirect path="/browse/refined" />} />
      <Route
        path="/browse/app/:id"
        element={
          <PrivateRoute>
            <AppPage />
          </PrivateRoute>
        }
      />
      <Route
        path="/browse/refined"
        element={
          <PrivateRoute>
            <BrowseRefined />
          </PrivateRoute>
        }
      />

      {/* Career Programs - Career Paths */}
      <Route
        path="/browse/paths/career-paths"
        element={
          <PrivateRoute>
            <BrowseCareerPrograms contentTypeId={CONTENT_TYPES.CAREER_PROGRAM.id} />
          </PrivateRoute>
        }
      />

      {/* Career Programs - Skill Paths */}
      <Route
        path="/browse/paths/skill-paths"
        element={
          <PrivateRoute>
            <BrowseCareerPrograms contentTypeId={CONTENT_TYPES.SKILL_PROGRAM.id} />
          </PrivateRoute>
        }
      />
      {/* Career Programs - Certification Prep */}
      <Route
        path="/browse/paths/cert-prep-paths"
        element={
          <PrivateRoute>
            <BrowseCareerPrograms contentTypeId={CONTENT_TYPES.CERT_PREP_PROGRAM.id} />
          </PrivateRoute>
        }
      />

      {/* Old Certification Prep */}
      <Route
        path="/browse/certification-prep"
        element={
          <PrivateRoute>
            <BrowseCertificationPrep />
          </PrivateRoute>
        }
      />

      {/* Career Programs Browse Page (All Types) */}
      <Route
        path="/browse/paths/*"
        element={
          <PrivateRoute>
            <CareerProgramOverview />
          </PrivateRoute>
        }
      />

      {/* Threat Actor Paths PAGES */}
      <Route
        path="/browse/threat-actor-campaigns"
        element={
          <PrivateRoute>
            <BrowseThreatActorPaths />
          </PrivateRoute>
        }
      />
      <Route
        path="/browse/threat-actor-campaigns/:permalink"
        element={
          <PrivateRoute>
            <ThreatActorPath />
          </PrivateRoute>
        }
      />
      {/* Cybrary Select PAGES */}
      <Route
        path="/browse/cybrary-select"
        element={
          <PrivateRoute>
            <BrowseCybrarySelectPaths />
          </PrivateRoute>
        }
      />
      <Route
        path="/browse/cybrary-select/:permalink"
        element={
          <PrivateRoute>
            <CybrarySelectCollectionPage />
          </PrivateRoute>
        }
      />
      {/* MITRE PAGES */}
      <Route
        path="/browse/mitre-attack"
        element={
          <PrivateRoute>
            <Mitre />
          </PrivateRoute>
        }
      />
      <Route
        path="/browse/cve-series"
        element={
          <PrivateRoute>
            <CVESeries />
          </PrivateRoute>
        }
      />

      {/* Content pages */}
      <Route
        path="/browse/*"
        element={
          <PrivateRoute>
            <AppPage />
          </PrivateRoute>
        }
      />

      {/* SPECIAL BROWSE REFINED PAGES */}
      <Route
        path="/new-releases"
        element={
          <PrivateRoute>
            <NewReleases />
          </PrivateRoute>
        }
      />
      <Route
        path="/coming-soon"
        element={
          <PrivateRoute>
            <ComingSoon />
          </PrivateRoute>
        }
      />
      <Route path="/browse/weekly-challenges" element={<Redirect path="/browse/cybrary-challenges" />} />
      <Route
        path="/browse/cybrary-challenges"
        element={
          <PrivateRoute>
            <CybraryChallenges />
          </PrivateRoute>
        }
      />

      {/* PRIVATE USER PROFILE PAGE AND REDIRECTS */}
      <Route
        path="/profile"
        element={
          <PrivateRoute>
            <ProfilePage />
          </PrivateRoute>
        }
      />
      <Route path="/profile/*" element={<Redirect path="/profile" />} />
      <Route path="/talent/profile" element={<Redirect path="/profile" />} />

      {/* COMMUNITY PAGE */}
      <Route
        path="/community"
        element={
          <PrivateRoute>
            <Community />
          </PrivateRoute>
        }
      />

      {/* SETTINGS PAGES */}
      <Route
        path="/settings/account"
        element={
          <PrivateRoute>
            <SettingsAccount />
          </PrivateRoute>
        }
      />
      <Route
        path="/settings/account/gdpr/download"
        element={
          <PrivateRoute>
            <SettingGDPRReportDownload />
          </PrivateRoute>
        }
      />
      <Route
        path="/settings/membership"
        element={
          <PrivateRoute>
            <SettingsMembership />
          </PrivateRoute>
        }
      />

      {/* Catch all for any other settings routes */}
      <Route
        path="/settings/*"
        element={
          <PrivateRoute>
            <SettingsAccount />
          </PrivateRoute>
        }
      />

      {/* TEAM INVITE! */}
      <Route
        path="/team-join/:uuid/key/:key"
        element={
          <PrivateRoute>
            <TeamInvite />
          </PrivateRoute>
        }
      />

      {/* REQUEST A LICENSE - Team selection page */}
      <Route
        path="/request-license"
        element={
          <PrivateRoute>
            <RequestTeamLicenseTeamSelector />
          </PrivateRoute>
        }
      />
      <Route
        path="/request-cybrary-select"
        element={
          <PrivateRoute>
            <RequestAddOnLicenseTeamSelector />
          </PrivateRoute>
        }
      />

      {/* Custom Paths Routes */}
      <Route
        path="/custom-paths/all"
        element={
          <PrivateRoute>
            <CustomPathsViewAll />
          </PrivateRoute>
        }
      />
      <Route
        path="/custom-paths/:teamId/:pathId"
        element={
          <PrivateRoute>
            <CustomPathsDetails />
          </PrivateRoute>
        }
      />
      {/* ENTERPRISE/ORGANIZATION GENERIC LINK ROUTING - FOR MARKETING USE WHEN TEAM ID NOT AVAILABLE */}
      <Route
        path="/enterprise/router/:section"
        element={
          <PrivateRoute>
            <TeamsLinkRouter />
          </PrivateRoute>
        }
      />

      {/* ENTERPRISE/ORGANIZATION PAGES */}
      <Route
        path="/enterprise/:orgId"
        element={
          <PrivateRoute>
            <Enterprise />
          </PrivateRoute>
        }
      />
      <Route
        path="/enterprise/:orgId/request-license"
        element={
          <PrivateRoute>
            <RequestTeamLicense />
          </PrivateRoute>
        }
      />
      <Route
        path="/enterprise/:orgId/request-cybrary-select"
        element={
          <PrivateRoute>
            <RequestAddOnLicense />
          </PrivateRoute>
        }
      />
      <Route
        path="/enterprise/:orgId/settings"
        element={
          <PrivateRoute>
            <TeamSettings />
          </PrivateRoute>
        }
      />

      {/* ENTERPRISE/ORGANIZATION REPORTING PAGES */}
      <Route
        path="/enterprise/:orgId/reporting/report/:id"
        element={
          <PrivateRoute>
            <Reports />
          </PrivateRoute>
        }
      />
      <Route
        path="/enterprise/:orgId/reporting/scheduled-reports"
        element={
          <PrivateRoute>
            <ScheduledReports />
          </PrivateRoute>
        }
      />
      <Route
        path="/enterprise/:orgId/reporting/download"
        element={
          <PrivateRoute>
            <ReportDownload />
          </PrivateRoute>
        }
      />
      <Route
        path="/enterprise/:orgId/reporting/scheduled-reports/new/"
        element={
          <PrivateRoute>
            <ManageScheduledReports />
          </PrivateRoute>
        }
      />
      <Route
        path="/enterprise/:orgId/reporting/scheduled-reports/edit/:id"
        element={
          <PrivateRoute>
            <ManageScheduledReports />
          </PrivateRoute>
        }
      />
      <Route
        path="/enterprise/:orgId/reporting"
        element={
          <PrivateRoute>
            <Reporting />
          </PrivateRoute>
        }
      />
      <Route
        path="/enterprise/:orgId/reporting/:section"
        element={
          <PrivateRoute>
            <Reporting />
          </PrivateRoute>
        }
      />

      {/* ENTERPRISE/ORGANIZATION ASSIGNMENT PAGES */}
      <Route
        path="/enterprise/:orgId/assignments"
        element={
          <PrivateRoute>
            <EnterpriseAssignment />
          </PrivateRoute>
        }
      />
      <Route
        path="/enterprise/:orgId/assignments/:section"
        element={
          <PrivateRoute>
            <EnterpriseAssignment />
          </PrivateRoute>
        }
      />
      <Route
        path="/enterprise/:orgId/assignment/create/:id"
        element={
          <PrivateRoute>
            <CreateAssignment />
          </PrivateRoute>
        }
      />
      <Route
        path="/enterprise/:orgId/assignment/edit/:assignid"
        element={
          <PrivateRoute>
            <CreateAssignment />
          </PrivateRoute>
        }
      />

      {/* ENTERPRISE/ORGANIZATION MANAGEMENT PAGES */}

      <Route
        path="/enterprise/:orgId/organization/welcome"
        element={
          <PrivateRoute>
            <WelcomeToTeams />
          </PrivateRoute>
        }
      />
      <Route
        path="/enterprise/:orgId/organization/free-assessment"
        element={
          <PrivateRoute>
            <FreeAssessment />
          </PrivateRoute>
        }
      />
      <Route
        path="/enterprise/:orgId/organization/members"
        element={
          <PrivateRoute>
            <OrganizationMembersDashboard />
          </PrivateRoute>
        }
      />
      <Route
        path="/enterprise/:orgId/organization/member/:id/dashboard"
        element={
          <PrivateRoute>
            <OrganizationMemberDashboard />
          </PrivateRoute>
        }
      />
      <Route
        path="/enterprise/:orgId/organization/member/:id/paths/assessments/:pathId/:contentId/summary"
        element={
          <PrivateRoute>
            <AssessmentResultsSummary />
          </PrivateRoute>
        }
      />
      <Route
        path="/enterprise/:orgId/organization/assignment/:id/dashboard"
        element={
          <PrivateRoute>
            <OrganizationAssignmentDashboard />
          </PrivateRoute>
        }
      />
      <Route
        path="/enterprise/:orgId/organization/content/:id/dashboard"
        element={
          <PrivateRoute>
            <ContentDashboard />
          </PrivateRoute>
        }
      />
      <Route
        path="/enterprise/:orgId/organization/dashboard"
        element={
          <PrivateRoute>
            <OrganizationDashboard />
          </PrivateRoute>
        }
      />
      <Route
        path="/enterprise/:orgId/organization/groups"
        element={
          <PrivateRoute>
            <OrganizationGroups />
          </PrivateRoute>
        }
      />

      {/* ENTERPRISE/ORGANIZATION CURRICULA PAGES */}
      <Route
        path="/enterprise/:orgId/paths"
        element={
          <PrivateRoute>
            <Curricula />
          </PrivateRoute>
        }
      />
      <Route
        path="/enterprise/:orgId/paths/:section"
        element={
          <PrivateRoute>
            <Curricula />
          </PrivateRoute>
        }
      />
      <Route
        path="/enterprise/:orgId/paths/:section"
        element={
          <PrivateRoute>
            <Curricula />
          </PrivateRoute>
        }
      />
      <Route
        path="/enterprise/:orgId/paths/assessments/:id"
        element={
          <PrivateRoute>
            <AssessmentPathResults />
          </PrivateRoute>
        }
      />
      <Route
        path="/enterprise/:orgId/paths/assessments/:pathId/:contentId"
        element={
          <PrivateRoute>
            <AssessmentResults />
          </PrivateRoute>
        }
      />
      <Route
        path="/enterprise/:orgId/paths/assessments/:pathId/:contentId/summary"
        element={
          <PrivateRoute>
            <AssessmentResultsSummary />
          </PrivateRoute>
        }
      />
      <Route
        path="/enterprise/:orgId/paths/show/:id"
        element={
          <PrivateRoute>
            <Curriculum />
          </PrivateRoute>
        }
      />
      <Route
        path="/enterprise/:orgId/paths/edit/:id"
        element={
          <PrivateRoute>
            <CurriculumBuild />
          </PrivateRoute>
        }
      />
      <Route
        path="/enterprise/:orgId/paths/copy/:copyid"
        element={
          <PrivateRoute>
            <CurriculumBuild />
          </PrivateRoute>
        }
      />
      <Route
        path="/enterprise/:orgId/paths/create"
        element={
          <PrivateRoute>
            <CurriculumBuild />
          </PrivateRoute>
        }
      />
      <Route
        path="/enterprise/:orgId/logs"
        element={
          <PrivateRoute>
            <ActivityLog />
          </PrivateRoute>
        }
      />
      {/* GOALS */}
      <Route
        path="/enterprise/:orgId/goals"
        element={
          <PrivateRoute>
            <TeamGoals />
          </PrivateRoute>
        }
      />
      <Route
        path="/enterprise/:orgId/goals/:section"
        element={
          <PrivateRoute>
            <TeamGoals />
          </PrivateRoute>
        }
      />
      <Route
        path="/goals"
        element={
          <PrivateRoute>
            <MyGoals />
          </PrivateRoute>
        }
      />
      <Route
        path="/goals/:id/content/:contentId"
        element={
          <PrivateRoute>
            <GoalPath />
          </PrivateRoute>
        }
      />

      {/* CYBRARY LIVE PAGES */}
      <Route
        path="/cybrary-live"
        element={
          <PrivateRoute>
            <LiveHome />
          </PrivateRoute>
        }
      />
      <Route
        path="/cybrary-live/series/:permalink"
        element={
          <PrivateRoute>
            <LiveSeries />
          </PrivateRoute>
        }
      />
      <Route
        path="/cybrary-live/session/:permalink"
        element={
          <PrivateRoute>
            <LiveSession />
          </PrivateRoute>
        }
      />

      {/* CYBRARY INSIDER PRO PRAGES */}
      <Route
        path="/cybrary-insider-pro"
        element={
          <PrivateRoute>
            <InsiderProLinkPage />
          </PrivateRoute>
        }
      />
      <Route
        path="/cybrary-insider-pro/cancel"
        element={
          <PrivateRoute>
            <CIPCancellation />
          </PrivateRoute>
        }
      />
      <Route
        path="/cybrary-insider-pro/steps"
        element={
          <PrivateRoute>
            <CIPCancellation />
          </PrivateRoute>
        }
      />
      <Route
        path="/cybrary-insider-pro/survey"
        element={
          <PrivateRoute>
            <CIPCancellation />
          </PrivateRoute>
        }
      />
      <Route
        path="/cybrary-insider-pro/thanks"
        element={
          <PrivateRoute>
            <CIPCancellation />
          </PrivateRoute>
        }
      />
      <Route
        path="/cybrary-insider-pro/confirm"
        element={
          <PrivateRoute>
            <CIPCancellation />
          </PrivateRoute>
        }
      />

      {/* CYBRARY INTERNAL ADMIN PAGES */}
      <Route
        path="/admin"
        element={
          <PrivateRoute>
            <CybraryAdminDashboard />
          </PrivateRoute>
        }
      />
      <Route
        path="/admin/teams"
        element={
          <PrivateRoute>
            <CybraryAdminBrowseTeams />
          </PrivateRoute>
        }
      />
      <Route
        path="/admin/user"
        element={
          <PrivateRoute>
            <CybraryAdminBrowseUser />
          </PrivateRoute>
        }
      />
      <Route
        path="/admin/vendor-results"
        element={
          <PrivateRoute>
            <CybraryAdminBrowseVendorResults />
          </PrivateRoute>
        }
      />
      <Route
        path="/admin/user/:id/vendor-results"
        element={
          <PrivateRoute>
            <CybraryAdminBrowseVendorResults />
          </PrivateRoute>
        }
      />
      <Route
        path="/admin/add-team"
        element={
          <PrivateRoute>
            <CybraryAdminManageTeam />
          </PrivateRoute>
        }
      />
      <Route
        path="/admin/view-team/:id"
        element={
          <PrivateRoute>
            <CybraryAdminViewTeam />
          </PrivateRoute>
        }
      />
      <Route
        path="/admin/edit-team/:id"
        element={
          <PrivateRoute>
            <CybraryAdminManageTeam />
          </PrivateRoute>
        }
      />
      <Route
        path="/admin/view-team/:id/logs"
        element={
          <PrivateRoute>
            <CybraryAdminTeamActivityLog />
          </PrivateRoute>
        }
      />
      <Route
        path="/admin/view-team/:id/manage-programs"
        element={
          <PrivateRoute>
            <CybraryAdminTeamManagePrograms />
          </PrivateRoute>
        }
      />
      <Route
        path="/admin/user/:id/dashboard"
        element={
          <PrivateRoute>
            <CybraryAdminMemberDashboard />
          </PrivateRoute>
        }
      />
      <Route
        path="/admin/unverified-user/:id/dashboard"
        element={
          <PrivateRoute>
            <CybraryAdminUnverifiedDashboard />
          </PrivateRoute>
        }
      />
      <Route
        path="/admin/challenge/courses"
        element={
          <PrivateRoute>
            <CybraryAdminBrowseChallengeCourses />
          </PrivateRoute>
        }
      />
      <Route
        path="/admin/challenge/courses/:id"
        element={
          <PrivateRoute>
            <CybraryAdminChallengeKey />
          </PrivateRoute>
        }
      />

      {/* Incomeplete Google Marketplace Checkouts */}
      <Route
        path="/google-marketplace-onboarding"
        element={
          <PrivateRoute>
            <GoogleMarketplaceOnboardingPage />
          </PrivateRoute>
        }
      />

      {/* NOTIFICATIONS */}
      <Route
        path="/notification-center"
        element={
          <PrivateRoute>
            <NotificationsPage />
          </PrivateRoute>
        }
      />

      {/* SECURITY AWARENESS HUB */}
      <Route
        path="/security-awareness"
        element={
          <PrivateRoute>
            <SecurityAwarenessHubPage />
          </PrivateRoute>
        }
      />
      {/* PAGE NOT FOUND 404 PAGE */}
      <Route path="*" element={<StyledError error={{ status: 404 }} />} />
    </Routes>
  );
}

export default Router;
