import React, { useEffect, useMemo, useState } from 'react';
import { inject, observer } from 'mobx-react';
import { useNavigate } from 'react-router-dom';
import queryString from 'query-string';
import moment from 'moment';
import { twMerge } from 'tailwind-merge';
import Container from '../../components/Container/Container';
import Title from '../../components/Title/Title';
import GoalsUtil from '../../utils/goalsUtil';
import BugsnagUtil from '../../utils/bugsnagUtil';
import ContentOverlay from '../../components/ContentOverlay/ContentOverlay';
import GoalDetails from '../../components/Goals/GoalDetails';
import Header from '../../components/Header/Header';
import AddLink from '../../components/AddLink/AddLink';
import Icon from '../../components/Icon/Icon';
import ProgressBar from '../../components/ProgressBar/ProgressBar';
import If from '../../components/If/If';
import Collapsible from '../../components/Collapsible/Collapsible';
import Loading from '../../components/Loading/Loading';
import Divider from '../../components/Divider/Divider';
import { isAdmin } from '../../utils/navUtil';
import { LICENSE_PACKAGE_TYPES } from '../../constants';
import Button from '../../components/Button/Button';

function SecurityAwarenessGoalCard({ goal, toggleDetails }) {
  const daysUntilDue = moment(goal.due_date).diff(moment(), 'days');
  const isExpired = daysUntilDue < 0;
  return (
    <div key={goal.id} className="p-0 m-4 border border-gray-400 shadow-md">
      <AddLink onClick={() => toggleDetails(goal.id)} className="group flex flex-col w-full">
        <If condition={goal?.due_date}>
          <div
            className={`flex justify-between items-center p-2 pl-2 mt-0 mb-2 w-full text-sm text-white tracking-wider ${
              !isExpired ? 'bg-cyb-pink-600 group-hover:bg-cyb-pink-500' : 'bg-gray-600 group-hover:bg-gray-700'
            }`}
          >
            <div className="flex items-center font-semibold">
              <Icon name="clock" className="mr-1 w-5 h-5" />
              <span className="mr-1 font-semibold">{isExpired ? 'Expired' : `Due in ${daysUntilDue} days`}</span>
            </div>
          </div>
        </If>
        <Header as="h3" className="flex items-center pl-4 mb-1 text-left group-hover:text-cyb-pink-600">
          {goal?.name || 'Goal'} <Icon name="chevron-right" className="shrink-0 ml-1 group-hover:ml-2 w-4 h-4 group-hover:text-cyb-pink-600 transition-all duration-300" />
        </Header>
      </AddLink>
      <div className="px-4 my-2 max-w-sm text-xs text-gray-600">
        <ProgressBar progress={goal?.progress?.percent_completed} progressClassName="h-3 bg-purple-700" wrapperClassName="h-3" />
        <div className="flex justify-between my-2">
          <If condition={goal?.progress?.activities_total}>
            <span className="font-semibold">
              {goal?.progress?.activities_completed || '0'} of {goal?.progress?.activities_total || '0'} lessons completed
            </span>
          </If>

          <If condition={!goal?.progress?.percent_completed}>
            <span className="font-semibold">{goal?.progress?.percent_completed || '0'}% Complete</span>
          </If>
        </div>

        <div className="flex my-2">
          <Header as="h4" className="my-2 mr-1 text-xs">
            Assigned by:
          </Header>
          <div className="flex items-center ml-2 space-x-4">
            {goal?.owners?.map((owner) => (
              <div key={owner.id} className="flex items-center">
                <img src={owner.avatar_url} alt={owner.name} className="w-6 h-6 rounded-full" />
                <span className="ml-2 text-sm">{owner.name || 'Owner'}</span>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

function SecurityAwarenessGoalSectionHeader({ title, children, icon }) {
  return (
    <div className="flex justify-between items-center mb-4 w-full">
      <div className="flex shrink-0 items-center">
        <If condition={icon}>
          <Icon name={icon} className="mr-4 mb-1 w-6 h-6" />
        </If>
        <Header as="h2" className="mb-1">
          {title}
        </Header>
      </div>
      {children}
    </div>
  );
}
function SecurityAwarenessGoalSection({ title, goals, toggleDetails, loading, icon, description }) {
  if (loading) {
    return (
      <>
        <SecurityAwarenessGoalSectionHeader title={title} icon={icon} />
        <p className="ml-4 text-sm text-gray-600">{description}</p>
        <Loading />
      </>
    );
  }
  return (
    <Collapsible
      open
      trigger={
        <SecurityAwarenessGoalSectionHeader title={title} icon={icon}>
          <div className="flex gap-x-1 items-center font-semibold text-right text-md">
            <Icon name="chevron-down" />
            <span className="hidden md:block">Show {title}</span>
          </div>
        </SecurityAwarenessGoalSectionHeader>
      }
      triggerWhenOpen={
        <SecurityAwarenessGoalSectionHeader title={title} icon={icon}>
          <div className="flex gap-x-1 justify-end items-center w-full font-semibold text-right text-md">
            <Icon name="chevron-up" />
            <span className="hidden md:block">Hide {title}</span>
          </div>
        </SecurityAwarenessGoalSectionHeader>
      }
      triggerClassName="text-right"
    >
      <p className="ml-4 text-sm text-gray-600">{description}</p>
      <If condition={goals?.length > 0}>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
          {goals?.map((goal) => (
            <SecurityAwarenessGoalCard key={goal.id} goal={goal} toggleDetails={toggleDetails} />
          ))}
        </div>
      </If>
      <If condition={goals?.length === 0}>
        <div className="flex flex-col justify-center items-center py-8">
          <Icon name="person-shield" className="pb-2 w-24 h-24 text-gray-600" />
          <p className="ml-4 text-sm text-gray-600">No {title} found!</p>
        </div>
      </If>
    </Collapsible>
  );
}

function SecurityAwarenessHubUpgradeMessage() {
  const SAT_FEATURES = [
    'Comprehensive Security Awareness Library',
    'Automated Training Campaigns',
    'Compliance Training Modules',
    'Team Progress Tracking',
    'Detailed Analytics & Reporting',
    'Custom Learning Paths',
    'Automated Reminders & Notifications',
  ];
  return (
    <Container size="lg">
      <div>
        <div className="my-6 text-5xl font-black text-black">Security Awareness Training</div>
        <div className="flex justify-center my-4 mx-auto text-[20px] text-black">Protect your organization with comprehensive security awareness training</div>
        <div className="flex flex-col py-4 mx-auto mb-8 max-w-240 md:flex-row">
          <div className="flex justify-center items-center w-full md:w-1/2">
            <Icon name="person-shield" className="m-auto max-h-72 text-cyb-pink-500" />
          </div>
          <div className="px-6 w-full md:w-1/2">
            <div className="mt-4 mb-2 font-bold md:mt-0 md:text-left">Everything you need to build a security-first culture:</div>
            {SAT_FEATURES.map((li) => (
              <div className="flex mb-1 ml-4 text-left" key={li}>
                <Icon name="check" className="mr-2 w-6 h-6 text-green-600" />
                <div>{li}</div>
              </div>
            ))}
            <Button className="mt-4" onClick={() => window.zE('webWidget', 'open')}>
              Contact Sales
            </Button>
          </div>
        </div>
      </div>
    </Container>
  );
}

function SecurityAwarenessHubPageComponent({ userStore, goalsStore, wrapperClassName, omitHeader = false }) {
  const navigate = useNavigate();
  const queryParams = useMemo(() => queryString.parse(window.location.search), [window.location.search]);
  const [goalId, setGoalId] = useState(queryParams.goalId);
  const [detailsOpen, setDetailsOpen] = useState(false);

  const getUserGoals = async (refreshOwnerFilterList) => {
    const { setGoalOwnersList } = goalsStore;

    try {
      const goalsResponse = await goalsStore.getUserGoals();
      if (refreshOwnerFilterList) {
        setGoalOwnersList('userGoals', goalsResponse || []);
      }
    } catch (error) {
      BugsnagUtil.notify(error);
    }
  };

  useEffect(() => {
    getUserGoals(true);
    goalsStore.setQueryParams('userGoals', 'ownerIds', []);
    goalsStore.setDefaultGoalData();

    if (goalId) {
      setDetailsOpen(true);
      setGoalId(goalId);
    }

    return () => {
      goalsStore.setDefaultUserGoals();
    };
  }, []);

  const toggleDetails = (newGoalId) => {
    setGoalId(newGoalId || null);
    setDetailsOpen(!detailsOpen);

    if (goalId) {
      delete queryParams.goalId;
      navigate({
        search: queryString.stringify(queryParams),
      });
    }
  };

  const continueLearning = (goal) => {
    GoalsUtil.continueLearning(goal, goalsStore, navigate);
  };

  const { userGoals } = goalsStore || {};
  const { loading, data } = userGoals || {};
  const allSATGoals = useMemo(
    () =>
      GoalsUtil.getSATGoals(data, userStore?.teamData?.id)?.sort((a, b) => {
        // Put goals with no due date at the end
        if (!a.due_date) return 1;
        if (!b.due_date) return -1;
        // Sort by due date ascending
        return new Date(a.due_date) - new Date(b.due_date);
      }),
    [data, userStore?.teamData?.id]
  );
  const expiredSATGoals = useMemo(() => allSATGoals?.filter((goal) => goal.status === 'expired'), [allSATGoals]);
  const incompleteSATGoals = useMemo(() => allSATGoals?.filter((goal) => !goal.completed_at && goal.status !== 'expired'), [allSATGoals]);
  const completedSATGoals = useMemo(() => allSATGoals?.filter((goal) => goal.completed_at), [allSATGoals]);
  const timeSensitiveSATGoals = useMemo(
    () => allSATGoals?.filter((goal) => goal.due_date && !goal.completed_at && moment(goal.due_date).isBefore(moment().add(1, 'month')) && goal.status !== 'expired'),
    [allSATGoals]
  );

  // If we have a team admin on a team with no license, we should show a message to the user to upgrade for their team
  const teamHasSATLicense = userStore?.teamData?.active_license_grants?.some((grant) => grant.package_types.includes(LICENSE_PACKAGE_TYPES.SECURITY_AWARENESS_TRAINING));
  const showUpgradeMessage = isAdmin(userStore) && !teamHasSATLicense;

  if (showUpgradeMessage) {
    return <SecurityAwarenessHubUpgradeMessage />;
  }

  const wrapperClasses = useMemo(() => twMerge('mt-8', wrapperClassName), [wrapperClassName]);
  return (
    <Container size="lg" className={wrapperClasses}>
      <If condition={!omitHeader}>
        <Title title="Security Awareness Training" classes="inline-block mb-4" omitPadding />
        <p className="text-sm text-gray-600">Here you can view all of your security awareness training goals and the status of each goal.</p>
      </If>
      <If condition={!omitHeader}>
        <Divider />
      </If>
      <If condition={timeSensitiveSATGoals?.length > 0}>
        <SecurityAwarenessGoalSection
          title="Training Due Soon"
          description="This is a list of all of your assigned security awareness training that is due within the next month. Complete these soon to avoid missing your deadline."
          goals={timeSensitiveSATGoals}
          toggleDetails={toggleDetails}
          loading={loading}
          icon="clock"
        />

        <Divider />
      </If>
      <SecurityAwarenessGoalSection
        title="Incomplete Training"
        description="This is a list of all of your assigned security awareness training that is not yet completed. Complete these when you can to avoid missing your deadline."
        goals={incompleteSATGoals}
        toggleDetails={toggleDetails}
        loading={loading}
        icon="bullseye"
      />
      <Divider />
      <SecurityAwarenessGoalSection
        title="Completed Training"
        description="This is a list of all of your completed security awareness training. You can review these at any time."
        goals={completedSATGoals}
        toggleDetails={toggleDetails}
        loading={loading}
        icon="completion-check"
      />
      <If condition={expiredSATGoals?.length > 0}>
        <Divider />
        <SecurityAwarenessGoalSection
          title="Expired Training"
          description="This is a list of all of your expired security awareness training. You can review these at any time."
          goals={expiredSATGoals}
          toggleDetails={toggleDetails}
          loading={loading}
          icon="exclamation-circle"
        />
      </If>
      <ContentOverlay open={detailsOpen} dismiss={toggleDetails} width="lg:w-[600px]" ariaLabelledBy="goal-title">
        <GoalDetails goalId={goalId} continueLearning={continueLearning} />
      </ContentOverlay>
    </Container>
  );
}

const SecurityAwarenessHubPage = inject('userStore', 'goalsStore')(observer(SecurityAwarenessHubPageComponent));

export default SecurityAwarenessHubPage;
