import React from 'react';
import moment from 'moment';
import { inject, observer } from 'mobx-react';
import FormatUtil from '../../utils/formatUtil';
import AddLink from '../AddLink/AddLink';
import BookmarkToggle from '../Bookmarks/BookmarkToggle';
import EnrollmentButton from './EnrollmentButton';
import ComingSoonButton from './ComingSoonButton';
import Details from '../Details/Details';
import Label from '../Label/Label';
import Tooltip from '../Tooltip/Tooltip';
import If from '../If/If';
import InformationCircle from '../../Icons/InformationCircle';
import ShareButton from '../Share/ShareButton';
import InviteReferralButton from '../Navigation/InviteReferralButton';
import useSandboxCookie from '../../hooks/cookies/useSandboxCookie';
import { useEnrollment } from '../../providers/EnrollmentProvider';

function getProgressPercent(enrollmentStore, id) {
  return enrollmentStore?.getEnrollmentProgressForContentDescriptionId(id)?.progress || 0;
}

function getItemPrefix(item) {
  return !!item && !!item.content_type && item.content_type.permalink_prefix ? item.content_type.permalink_prefix : '';
}

function getItemPermalink(item) {
  return item && item.permalink ? item.permalink : '';
}

function getFormattedGroupInfo(item) {
  return item && item.groups_info ? FormatUtil.formatGroupInfo(item.groups_info) : [];
}

function EnrollmentButtonWrapper({ comingSoon, id, title, authStore, content }) {
  const hasComingSoonPreviewAccess = authStore.hasComingSoonPreviewAccess();
  if (comingSoon) {
    return (
      <>
        <If condition={hasComingSoonPreviewAccess}>
          <div className="self-start mr-0 mb-4 md:mr-4 md:mb-0">
            <EnrollmentButton content={content} />
          </div>
        </If>
        <ComingSoonButton contentDescriptionId={id} title={title} />
      </>
    );
  }

  return <EnrollmentButton content={content} />;
}

function MitreNote({ isMitreCourse }) {
  return isMitreCourse ? (
    <div className="text-white">
      Note: Per our partnership agreement with MITRE Engenuity, MITRE will have access to learner usage data regarding this course. By enrolling in this course you consent to
      sharing this data with MITRE.
    </div>
  ) : null;
}

function ProgressLabelWrapper({ progressPercent }) {
  const baseLabelClass = 'py-1 px-2 text-xs whitespace-nowrap border uppercase rounded-sm';
  return progressPercent !== null ? (
    <div className="mt-4 lg:m-4">
      <Label key="Progress" className={baseLabelClass} basic>
        {progressPercent}% Complete
      </Label>
    </div>
  ) : null;
}

function UpgradeToUnlockLink({ isFreeToEnroll, hasPaidContent, userIsFree, isUserOnActiveTeam, type }) {
  // if user is not free or if free course (aka isFreeToEnroll and doesn't have paid content)... don't show
  if (!userIsFree || isUserOnActiveTeam || (isFreeToEnroll && !hasPaidContent) || !['Course', 'Career Path'].includes(type)) {
    return null;
  }

  let tooltipText = '';
  let linkText = '';
  let upgradeLink = `${process.env.REACT_APP_V2_SITE_URL}`;
  // id Product will use for pendo testing
  let upgradeId = '';

  const contentType = `${type || ''}`;

  // if free user on course page
  if (type === 'Course') {
    upgradeLink += '/upgrade/?appnav=course%type=free';
    tooltipText = `Your free account includes access to the first 2 learning activities of this ${contentType.toLowerCase()}.
     Upgrade to Cybrary Insider Pro to unlock the full ${contentType.toLowerCase()}`;
    linkText = `Upgrade to Unlock Full Course`;
    upgradeId = 'upgrade-to-unlock-courses-free';
  }

  // if free user on career path
  if (type === 'Career Path') {
    upgradeLink += '/upgrade/?appnav=careerpath&type=free';
    tooltipText = `Your free account includes access to a limited number of courses. Upgrade to Cybrary Insider Pro to unlock this ${contentType}.`;
    linkText = `Upgrade to Unlock Career Path`;
    upgradeId = 'upgrade-to-unlock-careerpaths-free';
  }

  // Give tooltip smaller width in mobile view so it does not go off screen
  const tooltipMaxWidth = window.innerWidth < 768 ? 200 : 500;

  return (
    <div id={upgradeId} className="flex mt-4 md:mt-2">
      <div>
        <AddLink className="text-sm font-normal text-white hover:text-white underline hover:no-underline" to={`${upgradeLink}`} target="_blank">
          {linkText}
        </AddLink>
      </div>
      <div className="flex items-center ml-1">
        <Tooltip position="right" content={tooltipText} maxWidth={tooltipMaxWidth}>
          <InformationCircle classes="text-white w-4 h-4" accessibilityProps={{ 'aria-label': 'Upgrade cta additional information icon' }} outline />
        </Tooltip>
      </div>
    </div>
  );
}

function TimeToCompleteDetails({ time, contentTypeDurations }) {
  return (
    <div className="flex items-end md:items-center">
      <div>{time}</div>
      {contentTypeDurations ? (
        <Tooltip
          triggerContent={
            <InformationCircle
              classes="text-white w-4 h-4 mb-1 md:mb-0 sm:ml-2"
              accessibilityProps={{ 'aria-label': 'View Content Duration Breakdown - information icon' }}
              outline
            />
          }
          content={
            <div>
              {Object.keys(contentTypeDurations).map((contentType) => {
                return (
                  <div key={contentType} className="flex items-center text-black">
                    <div className="font-semibold">{FormatUtil.pluralize(contentTypeDurations[contentType], contentType)}: </div>
                    <div className="ml-2"> {FormatUtil.formatTime(contentTypeDurations[contentType], 'hm')}</div>
                  </div>
                );
              })}
            </div>
          }
        />
      ) : null}
    </div>
  );
}

function ExtraDetails({ type, time, ceu, difficulty, enrollments, xp, contentTypeDurations }) {
  return (
    <div className="flex flex-row flex-wrap items-start mb-8">
      <div className="mt-6 min-w-[180px]">
        <Details header="Activity Type" classes="text-white" content={type} isBrowseHeader />
      </div>
      <If condition={Boolean(time)}>
        <div className="mt-6 mr-12 min-w-[180px]">
          <Details header="Time" classes="text-white" content={<TimeToCompleteDetails time={time} contentTypeDurations={contentTypeDurations} />} isBrowseHeader />
        </div>
      </If>
      <If condition={Boolean(difficulty)}>
        <div className="mt-6 mr-3 min-w-[180px]">
          <Details header="Difficulty" classes="text-white" content={difficulty} isBrowseHeader />
        </div>
      </If>
      <If condition={Boolean(enrollments)}>
        <div className="mt-6 mr-3 min-w-[180px]">
          <Details header="Enrollments" classes="text-white" content={enrollments} isBrowseHeader />
        </div>
      </If>
      <div className="mt-6 min-w-[140px]">
        <Details header="XP" classes="text-white" content={xp ?? 0} isBrowseHeader />
      </div>
      <If condition={Boolean(ceu)}>
        <div className="mt-6 min-w-[180px]">
          <Details header="CEU/CPE" classes="text-white" content={ceu} isBrowseHeader />
        </div>
      </If>
    </div>
  );
}

function DisplayHeroLabels({ item, baseLabelClass, thirtyDaysAgo, currTime, releasedAtTimestamp }) {
  const labels = [];
  const tags = item?.tags_info;
  const domain = item?.terms_info?.find((term) => term.includes('Domains|'));
  const topic = item?.terms_info?.find((term) => term.includes('Topics|'));
  const isComingSoon = item.status === 'Coming Soon';

  // First label we don't care about margin left
  if (item.vendor && item.vendor.name) {
    labels.push(
      <Label color="black" basic className="py-1 px-2 text-xs uppercase whitespace-nowrap rounded-sm border" key="activity-vendor">
        {item.vendor.name}
      </Label>
    );
  }

  // Display Label for content released within 30 days
  if (currTime >= releasedAtTimestamp && thirtyDaysAgo <= releasedAtTimestamp && !isComingSoon) {
    labels.push(
      <Label color="blue" className={baseLabelClass} key="new">
        New
      </Label>
    );
  }

  if (tags?.includes('Cybrary Select')) {
    labels.push(
      <Label color="black" className={baseLabelClass} key="beta">
        Cybrary Select
      </Label>
    );
  }

  if (domain) {
    labels.push(
      <Label color="black" basic className={baseLabelClass} key="domain">
        {domain.replace('Domains', 'domain').replace('|', ': ')}
      </Label>
    );
  }

  if (topic) {
    labels.push(
      <Label color="black" basic className={baseLabelClass} key="topic">
        {topic.replace('Topics', 'topic').replace('|', ': ')}
      </Label>
    );
  }

  return labels;
}

function RetakeButtonComponent({ commonStore, content, requiredType, modalContent, label, buttonText }) {
  const { startEnrollment, checkIfContentIsCompleted } = useEnrollment();
  if (!content) {
    return null;
  }
  const isComplete = checkIfContentIsCompleted(content.id);
  const isComingSoon = content.status === 'Coming Soon';
  const contentType = content.content_type.nice_name || content.content_type.name;
  if (!contentType || contentType !== requiredType || isComingSoon || !isComplete) {
    return null;
  }

  const retakeConfirm = () => {
    commonStore.triggerConfirm({
      content: modalContent,
      cancel: () => commonStore.resetConfirmState(),
      confirmBtn: 'Continue',
      continue: () => {
        commonStore.resetConfirmState();
        startEnrollment(content, { exclude_progress_before: 'now' });
      },
    });
  };

  return (
    <p className="mt-4 mb-0 text-white">
      {`${label} `}
      <button type="button" className="text-cyb-pink-500 hover:text-white underline" onClick={retakeConfirm}>
        {buttonText}
      </button>
    </p>
  );
}

const RetakeButton = inject('commonStore')(observer(RetakeButtonComponent));

function RetakeCourseButton({ content }) {
  const modalContent = `Are you sure you want to retake this course? If you click Continue, you will create a new attempt that has no progress associated with it. Any previous
  completions of this item and certificates of completion (if applicable) will be unaffected.`;
  return <RetakeButton content={content} modalContent={modalContent} label="Want to start over?" buttonText="Retake this Course!" requiredType="Course" />;
}

function RetakeAssessmentButton({ content }) {
  return (
    <RetakeButton
      content={content}
      modalContent="Are you sure you want to restart this assessment?"
      label="Want a fresh start?"
      buttonText="Retake this Assessment!"
      requiredType="Assessment Path"
    />
  );
}

function ContentHeroComponent({
  enrollmentStore,
  userStore,
  authStore,
  commonStore,
  shortDescription,
  careerPathSubTitle,
  item: content,
  otherNames,
  careerPathPage,
  contentTypeDurations,
}) {
  const { userType } = userStore;
  const { bookmarks_count, title, id, syllabus, content_type, duration_seconds, ceu_count: ceu, replacedBy, replacing } = content || {};
  const type = FormatUtil.ucwords(content_type?.nice_name || content_type?.name);
  const time = FormatUtil.formatTime(duration_seconds || 0, 'hm');
  const difficulty = content?.level?.name;
  const progressPercent = getProgressPercent(enrollmentStore, id);
  const prefix = getItemPrefix(content);
  const permalink = getItemPermalink(content);
  const formattedGroupsInfo = getFormattedGroupInfo(content);
  const isMitreCourse = formattedGroupsInfo.includes('certifications|mitre-engenuity');
  const currTime = 1 * moment().format('X');
  const thirtyDaysAgo = 1 * moment().subtract(30, 'days').format('X');
  const baseLabelClass = 'ml-1 py-1 px-2 text-xs whitespace-nowrap border uppercase rounded-sm';
  const subTitleClass = careerPathSubTitle ? 'text-sm' : 'font-semibold';
  const { isContentIdSandboxEnabled } = useSandboxCookie();
  return (
    <div className="py-18 px-4">
      <If condition={isContentIdSandboxEnabled}>
        <p className="text-white">
          Content ID: <span>{id}</span>
        </p>
      </If>
      <div className="grid grid-cols-1 lg:grid-cols-6 lg:gap-8">
        <div className="lg:col-span-4">
          <DisplayHeroLabels
            currTime={currTime}
            thirtyDaysAgo={thirtyDaysAgo}
            item={content}
            releasedAtTimestamp={content?.released_at_timestamp}
            baseLabelClass={baseLabelClass}
          />
        </div>
        <div className="pt-2 lg:col-span-2 lg:pt-0">
          <div className="flex flex-wrap justify-end justify">
            <div className="m-2">
              <BookmarkToggle contentDescriptionId={id} title={title} bookmarkCount={bookmarks_count || 0} textColor="text-white" theme="light" />
            </div>
            <div className="m-2">
              <ShareButton
                onClick={() =>
                  commonStore.triggerShareComponent({
                    permalink,
                    prefix,
                    title,
                    type,
                  })
                }
                textColor="text-white"
              />
            </div>
            <div className="m-2">
              <Tooltip content={<span className="font-semibold">Refer a friend</span>} omitTabIndex>
                <InviteReferralButton iconClasses="w-6 h-6" buttonClasses="hover:text-gray-600 p-0" />
              </Tooltip>
            </div>
          </div>
        </div>
      </div>
      <div className="grid grid-cols-1 lg:grid-cols-6 lg:gap-8 lg:gap-y-1">
        <div className="lg:col-span-4">
          <div>
            <h1 className="mr-4 mb-4 text-3xl font-black text-white lg:text-5xl">{title}</h1>
            <If condition={careerPathPage && otherNames}>
              <p className="my-6 text-lg font-bold text-white">
                Also Known as:{' '}
                {otherNames?.map?.((name, index) => {
                  return <span key={name}>{(index ? ', ' : '') + name}</span>;
                })}
              </p>
            </If>
            <If condition={replacedBy?.permalink}>
              <div className="mb-4 text-sm text-white">
                This content is being replaced by
                <AddLink className="text-cyb-pink-500 hover:text-white underline" to={`/browse/${replacedBy?.permalink}`}>
                  {replacedBy?.title || 'this content'}
                </AddLink>
              </div>
            </If>
            <If condition={replacing?.permalink}>
              <div className="mb-4 text-sm text-white">
                This replaces{' '}
                <AddLink className="text-cyb-pink-500 hover:text-white underline" to={`/browse/${replacing?.permalink}`}>
                  {replacing?.title || 'this content'}
                </AddLink>
              </div>
            </If>
            <If condition={shortDescription || careerPathSubTitle}>
              <div className="mb-4">
                <Details classes={`text-white ${subTitleClass}`} content={shortDescription || careerPathSubTitle} isBrowseHeader />
              </div>
            </If>
          </div>
        </div>
        <div className="lg:col-span-2">
          <ProgressLabelWrapper progressPercent={progressPercent} />
        </div>
        <div className="col-span-1 lg:col-span-6">
          <ExtraDetails
            type={type}
            time={time}
            ceu={ceu}
            difficulty={difficulty}
            enrollments={content.enrollments_total}
            xp={content.experience_points_total}
            contentTypeDurations={contentTypeDurations}
          />
          <div className="mb-4">
            <div className="md:flex md:items-center">
              <EnrollmentButtonWrapper id={id} content={content} title={title} authStore={authStore} />
              <If condition={syllabus && userType !== 'enterprise'}>
                <div className="mt-4 md:mt-0">
                  <AddLink to={syllabus} target="_blank">
                    <button className="py-2.5 px-6 text-sm font-bold leading-5 text-center text-black hover:text-black bg-gray-200 hover:bg-cyb-gray-500 rounded-sm md:ml-4">
                      Download Syllabus
                    </button>
                  </AddLink>
                </div>
              </If>
            </div>
            <RetakeCourseButton content={content} />
            <RetakeAssessmentButton content={content} />
          </div>
          <UpgradeToUnlockLink
            isFreeToEnroll={content.is_free}
            hasPaidContent={content.has_paid_content}
            userIsFree={userStore.isFree}
            isUserOnActiveTeam={userStore.isUserOnActiveTeam}
            type={type}
            userStore={userStore}
          />
          <MitreNote isMitreCourse={isMitreCourse} />
        </div>
      </div>
    </div>
  );
}

const ContentHero = inject('enrollmentStore', 'userStore', 'authStore', 'commonStore')(observer(ContentHeroComponent));

export default ContentHero;
