import React, { createContext, useContext, useState, useMemo, useEffect } from 'react';
import moment from 'moment';
import useWebSocket from 'react-use-websocket';
import queryString from 'query-string';
import Bugsnag from '@bugsnag/js';
import agents from '../agents/agents';
import { getApiRequest, Infra } from '../components/Clab/agents';
import BugsnagUtil from '../utils/bugsnagUtil';

const ClabContext = createContext();

/**
 * Array.sort logic for resources, where we sort by resource index if it is provided.
 * @param {Object} a - first machine being compared in sorting function
 * @param {Object} b - second machine
 * @returns {int} - value used in Array.sort
 */
function sortMachines(a, b) {
  // Sort our machines/resources based on the provided resource index, will default to natural order if the index is not provided.
  const aIndex = parseInt(`${a?.resourceIndex || 0}`, 10);
  const bIndex = parseInt(`${b?.resourceIndex || 0}`, 10);
  return aIndex - bIndex;
}

function ClabProvider({ children, version = 'legacy' }) {
  const [launched, setLaunched] = useState(false);
  const [loading, setLoading] = useState(false);
  const [labInstanceId, setLabInstanceId] = useState(null);
  const [token, setToken] = useState(null);
  const [error, setError] = useState(null);
  const [session, setSession] = useState(null);
  const [account, setAccount] = useState(null);
  const [resources, setResources] = useState([]);
  const [activeResource, setActiveResource] = useState(null);
  const [lab, setLab] = useState(null);
  const [socketUrl, setSocketUrl] = useState(null);
  const [lastHeartbeat, setLastHeartbeat] = useState(0);

  // SEE: https://github.com/robtaussig/react-use-websocket
  const {
    sendJsonMessage,
    lastJsonMessage,
    readyState: webSocketState,
  } = useWebSocket(socketUrl, {
    shouldReconnect: (closeEvent) => {
      // See https://github.com/robtaussig/react-use-websocket?tab=readme-ov-file#reconnecting for more info
      // If the event wasn't trusted AND clean (aka we initiated it), do not reconnect
      return !closeEvent?.isTrusted || !closeEvent?.wasClean;
    },
    reconnectAttempts: 10,
    reconnectInterval: 2500,
    onClose: (e) => {
      // If it was NOT a clean closure, this is likely an error due to a bad token. Prevent infinite retry attempts and show an error message.
      if (!e?.wasClean) {
        const formattedError = `Your lab connection encountered an error. Click Reconnect to refresh the page and attempt to reconnect to your lab session.`;
        setError(formattedError);
        setSocketUrl(null);
      }
    },
  });

  useEffect(() => {
    if (version !== 'new') {
      return;
    }
    // When the token is set, set the websocket url, which will open the connection
    if (!token) {
      return;
    }
    const newSocketUrl = process.env.REACT_APP_CLAB2_API_URL.replace('http', 'ws');
    setSocketUrl(`${newSocketUrl}/api/v1/events?token=${encodeURIComponent(token)}`);
  }, [token, version]);

  useEffect(() => {
    if (version !== 'new') {
      return () => {};
    }
    let intervalId = null;
    // When the websocket state goes to ready, and we have a lab id, subscribe to the events
    if (webSocketState === 1 && labInstanceId) {
      sendJsonMessage({ event: 'subscribe_lab_instance', payload: { id: labInstanceId } });
      // Setup our heartbeat interval
      intervalId = setInterval(() => {
        sendJsonMessage({ event: 'client_heartbeat', payload: { id: labInstanceId } });
      }, 15000);
    }
    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [webSocketState, labInstanceId, version]);

  useEffect(() => {
    if (version !== 'new') {
      return;
    }
    const { event, payload } = lastJsonMessage || {};
    if (!event) {
      return;
    }

    const { state, expires, id, machines } = payload || {};
    const now = moment().unix();
    const threshold = 20;

    // Update the lab and resources based on the json message
    switch (event) {
      case 'lab_instance_state':
        if (state === 'error') {
          const newError = payload?.reason || 'There was an unknown error';
          const formattedError = `Your lab encountered an error: ${newError}. Click Reconnect to refresh the page and attempt to reconnect to your lab session.`;
          setError(formattedError);
          BugsnagUtil.notifyWithNamedMetadata(new Error(newError), 'clab_lab_error', { event, payload });
          return;
        }
        setLab({
          id,
          // Expires timestamp is sent in microseconds apparently, so normalize it to milliseconds
          expires: Math.round(expires / 1000000),
          resources: machines.sort(sortMachines),
          state,
          // A few params for backwards compatibility (for the time-being)
          labId: id,
          status: state,
        });
        break;

      case 'heartbeat':
        // If we have not heard from the keep alive hearbeat in X amount of time, throw a bugsnag and send along event info
        if (lastHeartbeat && now - lastHeartbeat > threshold) {
          BugsnagUtil.notifyWithNamedMetadata(new Error(`Threshold of ${threshold} crossed, now: ${now}, prior heartbeat ${lastHeartbeat}`), 'clab_heartbeat', { event, payload });
        }
        setLastHeartbeat(now);
        break;

      default:
        BugsnagUtil.notifyWithNamedMetadata(new Error('Unknown Clab Event'), 'clab_unknown_event', { event, payload, bs: 'v1' });
        break;
    }
  }, [lastJsonMessage, version]);

  useEffect(() => {
    if (version !== 'new') {
      return;
    }
    const { resources: labResources, state } = lab || {};
    const formattedResources = labResources?.length
      ? labResources
          .map((resource) => {
            return {
              ...resource,
              status: state,
              connectable: true,
              activeTab: resource.id === activeResource,
              connected: resource.id === activeResource,
            };
          })
          .sort(sortMachines)
      : [];
    setResources(formattedResources);
  }, [lab, activeResource]);

  // Original code to launch a clab
  const launchV1 = async (clabId) => {
    setLoading(true);
    try {
      const launchResult = await agents.catalog.launch(clabId);
      const { url } = launchResult;
      const parsed = queryString.parseUrl(url);
      setLabInstanceId(parsed.query.labInstanceIdentifier);
      setToken(parsed.query.token);
      setSession(parsed.query.session);
      setLaunched(true);
    } catch (err) {
      Bugsnag.notify(err);
    }
    setLoading(false);
  };

  // The new version of launch, we only get a token and "instance id" (the labId)
  const launchV2 = async (clabContentDescriptionId) => {
    setLoading(true);
    try {
      const launchResult = await agents.catalog.launch(clabContentDescriptionId);
      setLabInstanceId(launchResult.id);
      const tokenResult = await agents.clab.getToken();
      setToken(tokenResult.token);
      setLaunched(true);
    } catch (err) {
      BugsnagUtil.notifyWithNamedMetadata(err, 'clab_launchv2_error', { clabContentDescriptionId });
    } finally {
      setLoading(false);
    }
  };

  // Conditionally launch the original clab, or the new one, depending on the version prop.
  // This will allow us to quickly swap between implementations in the provider.
  const launch = async (clabId) => {
    if (version === 'legacy') {
      await launchV1(clabId);
    } else {
      await launchV2(clabId);
    }
  };

  // Reset to the original state, for when we exit a clab
  const reset = () => {
    setLaunched(false);
    setLoading(false);
    setLabInstanceId(null);
    setToken(null);
    setSession(null);
    setAccount(null);
    setResources([]);
    setLab(null);
    setSocketUrl(null);
    setLastHeartbeat(0);
  };

  const endLab = async () => {
    // If we have an open websocket connection, close it
    try {
      if (version === 'new' && labInstanceId) {
        await agents.clab.endLab(labInstanceId);
      } else {
        // LEGACY CLAB DELETE!
        Infra.deleteLab(labInstanceId);
      }
      if (version === 'new' && webSocketState === 1 && labInstanceId) {
        sendJsonMessage({ event: 'unsubscribe_lab_instance', payload: { id: labInstanceId } });
      }
    } catch (err) {
      BugsnagUtil.notifyWithNamedMetadata(err, 'clab_end_lab_error', { labInstanceId });
    } finally {
      reset();
    }
  };

  const connect = (resource) => {
    setActiveResource(resource.id);
  };

  const disconnect = () => {
    setActiveResource(null);
  };

  // Load our clab account - This is ONLY used in LEGACY CLAB, can be removed once legacy is retired
  const getAccount = async () => {
    if (version === 'new') {
      return;
    }
    const result = await getApiRequest('/admin/account');
    const { accountId, accountName, email } = result;
    setAccount({
      id: accountId,
      name: accountName,
      email,
    });
  };

  const state = useMemo(
    () => ({
      launched,
      loading,
      labInstanceId,
      token,
      session,
      account,
      resources,
      lab,
      activeResource,
      lastJsonMessage,
      error,
      launch,
      reset,
      setResources,
      setLab,
      getAccount,
      setActiveResource,
      endLab,
      connect,
      disconnect,
    }),
    [launched, loading, labInstanceId, token, session, account, resources, lab, activeResource, lastJsonMessage, error]
  );

  return <ClabContext.Provider value={state}>{children}</ClabContext.Provider>;
}

export const useClab = () => useContext(ClabContext);
export default ClabProvider;
