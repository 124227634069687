import React, { useEffect, useState } from 'react';
import CreatableSelect from 'react-select/creatable';

function TextSelectCreate({ defaultOptions, isClearable, isDisabled, isLoading, name, onChange, defaultValue }) {
  const [isCreating, setIsCreating] = useState(false);
  const [options, setOptions] = useState(defaultOptions);
  const [value, setValue] = useState(defaultValue);

  const handleCreate = (inputValue) => {
    setIsCreating(true);
    setTimeout(() => {
      setIsCreating(false);
      setOptions((prev) => [
        ...prev,
        {
          label: inputValue,
          value: inputValue,
        },
      ]);
      setValue(inputValue);
    }, 500);
  };

  const handleChange = (newValue) => {
    setValue(newValue.label);
  };

  useEffect(() => {
    // onChange handler for dynamic form
    onChange(null, { name, value });
  }, [value]);

  useEffect(() => {
    if (defaultValue && !options.some((option) => option.value === defaultValue)) {
      handleCreate(defaultValue);
    }
  }, [defaultValue]);

  const customStyles = {
    control: (provided) => ({
      ...provided,
      '&:hover': { borderColor: 'transparent' },
      border: 'transparent',
      boxShadow: 'none',
      backgroundColor: 'transparent',
    }),
    multiValue: (styles) => {
      return {
        ...styles,
        backgroundColor: '#0db350',
        color: '#fff',
        borderRadius: '0.25rem',
      };
    },
    multiValueLabel: (styles) => ({
      ...styles,
      color: '#ff',
      fontSize: '0.7rem',
      paddingRight: '0.5rem',
      paddingLeft: '0.75rem',
      paddingTop: '0.25rem',
      paddingBottom: '0.25rem',
    }),
  };

  return (
    <CreatableSelect
      isClearable={isClearable}
      isDisabled={isDisabled || isLoading || isCreating}
      isLoading={isLoading || isCreating}
      onChange={handleChange}
      onCreateOption={handleCreate}
      options={options}
      value={{ value, label: value }}
      styles={customStyles}
      className="px-1 mb-2 placeholder:text-gray-600 rounded border border-gray-300 focus:outline-none"
    />
  );
}

export default TextSelectCreate;
