import FormatUtil from './formatUtil';

export function formatPath(pathData) {
  if (!pathData) {
    return null;
  }
  const { short_description, progress, progress_event, content_type } = pathData;
  const percentCompleted = parseInt(progress, 10);
  return {
    ...pathData,
    shortDescription: FormatUtil.formatLongText(short_description, 350),
    progressEvent: progress_event,
    percentCompleted,
    progressData: {
      content_progress: {
        progress_event,
        percent_complete: percentCompleted,
      },
    },
    type: content_type.nice_name,
  };
}

export const getOptions = (commonStore, shareData) => {
  return [
    {
      text: 'Share',
      action: () => commonStore.triggerShareComponent(shareData),
    },
  ];
};

export default { formatPath };
