import React, { useState, useEffect } from 'react';
import queryString from 'query-string';
import { useParams, useNavigate } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import AddLink from '../../components/AddLink/AddLink';
import FormInviteModal from '../../components/Enterprise/FormInviteModal';
import OrganizationMemberTableActions from '../../components/Enterprise/OrganizationMemberTableActions';
import Container from '../../components/Container/Container';
import NewTeamPurchaseMessage from '../../components/Enterprise/NewTeamPurchaseMessage';
import './enterprise.css';
import Header from '../../components/Header/Header';
import { HELP_DESK_LINKS, USERPILOT_EVENTS } from '../../constants';
import ActionUtil from '../../utils/actionsUtil';
import If from '../../components/If/If';
import UPTrigger from '../../components/UPTrigger/UPTrigger';
import OrganizationDashboardHeading from '../../components/Enterprise/OrganizationDashboardHeading';
import OrganizationLicenseGrants from './OrganizationLicenseGrants';
import OrganizationMembersTable from './OrganizationMembersTable';

function FooterMessage() {
  return (
    <div className="border-none">
      <Header as="h2" className="mb-4 text-2xl font-black">
        Need help managing your account?
      </Header>
      <h3 className="mb-4 text-lg font-bold">Customer Success Manager</h3>
      <p className="mb-6 text-gray-600">
        <button className="text-cyb-pink-500 hover:text-black underline cursor-pointer" onClick={() => window.zE('webWidget', 'open')}>
          Contact a Customer Success Manager
          <span className="sr-only">Opens a chat window</span>
        </button>{' '}
        to manage team licenses and provide guidance and support for managing your team.
      </p>
      <h3 className="mb-4 text-lg font-bold">Knowledge Center</h3>
      <p className="text-gray-600">
        Search our{' '}
        <AddLink className="text-cyb-pink-500 hover:text-black underline" to={HELP_DESK_LINKS.CYBRARY_FOR_TEAMS} target="_blank">
          Knowledge Center
        </AddLink>{' '}
        for resources and tutorials to help learn about Cybrary for Business.
      </p>
    </div>
  );
}
const OrganizationMembersDashboard = inject(
  'commonStore',
  'userStore',
  'enterpriseStore',
  'authStore'
)(
  observer(({ commonStore, userStore, enterpriseStore, authStore }) => {
    const navigate = useNavigate();
    const { orgId } = useParams();
    const isDemo = orgId === 'demo';
    const [isInviteModalOpen, setIsInviteModalOpen] = useState(false);

    const openModal = () => {
      enterpriseStore.getTeamInviteUrl(orgId);
      setIsInviteModalOpen(true);
    };

    const closeModal = () => {
      const queryParams = queryString.parse(window.location.search);
      if (queryParams.invite) {
        delete queryParams.invite;
        navigate({
          search: queryString.stringify(queryParams),
        });
      }
      setIsInviteModalOpen(false);
    };

    useEffect(() => {
      if (isDemo) {
        userStore.enterDemo();
        enterpriseStore.enterDemo();
      } else {
        userStore.setPreferredTeamInit('', orgId, (initOrgId) => {
          const queryParams = queryString.parse(window.location.search);
          if (queryParams.invite) {
            openModal();
          }

          enterpriseStore.setDefaultTeamMemberTableData();
          enterpriseStore.setQueryParam('teamMemberTableData', 'searchQuery', queryParams?.searchQuery || '');
          enterpriseStore.setQueryParam('teamMemberTableData', 'role', queryParams?.role || '');
          enterpriseStore.setQueryParam('teamMemberTableData', 'license', queryParams?.license || '');
          enterpriseStore.setQueryParam('teamMemberTableData', 'groupId', queryParams?.group || queryParams?.groupId || '');
          enterpriseStore.getTeamMemberTableData(initOrgId, false, false);

          enterpriseStore.setDefaultInvitedTeamMembers();
          enterpriseStore.getInvitedTeamMembers(initOrgId);

          enterpriseStore.getJoinRequests(initOrgId);
        });
      }

      commonStore.setPageTitle(`Members${isDemo ? ' Demo' : ''} | Cybrary`);
      authStore.fireAttributionEvent();
      ActionUtil.scrollToTop();

      return () => {
        enterpriseStore.setDefaultTeamMemberTableData();
        enterpriseStore.setDefaultGroupList();
        enterpriseStore.setDefaultInvitedTeamMembers();
        enterpriseStore.setDefaultJoinRequests();
        if (isDemo) {
          userStore.exitDemo();
          enterpriseStore.exitDemo();
        }
      };
    }, [orgId, isDemo, userStore, enterpriseStore, authStore, commonStore]);

    if (!userStore.team) {
      return null;
    }

    return (
      <Container size="xl" className="org-members">
        <OrganizationDashboardHeading
          className="px-0"
          button={
            <div className="flex items-start my-4">
              <button
                className="py-2.5 px-6 text-sm font-bold leading-5 text-center text-white hover:text-white bg-cyb-pink-500 hover:bg-cyb-pink-600 rounded-sm"
                onClick={() => openModal()}
              >
                + Invite to Team
              </button>
              <UPTrigger guideId={USERPILOT_EVENTS.INVITE_TO_TEAM_BUTTON} className="-mt-5 -ml-1" />
            </div>
          }
        />
        <OrganizationLicenseGrants />
        {/* <Divider marginTop="mt-8" marginBottom="mb-2" /> */}
        <OrganizationMemberTableActions />
        <OrganizationMembersTable />
        <If condition={!isDemo}>
          <FooterMessage />
        </If>
        <NewTeamPurchaseMessage clearSubscriptionStatus={userStore.removeSubscriptionStatus} />
        <FormInviteModal modalOpen={isInviteModalOpen} modalClose={closeModal} />
      </Container>
    );
  })
);

export default OrganizationMembersDashboard;
