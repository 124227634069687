import React from 'react';
import moment from 'moment';
import { inject, observer } from 'mobx-react';
import Title from '../Title/Title';
import Loading from '../Loading/Loading';
import Dropdown from '../Dropdown/Dropdown';
import StyledError from '../Error/StyledError';
import FormatUtil from '../../utils/formatUtil';
import AddLink from '../AddLink/AddLink';
import OrganizationGroupCards from './OrganizationGroupCards';
import Label from '../Label/Label';
import Tooltip from '../Tooltip/Tooltip';
import Container from '../Container/Container';
import { HELP_DESK_LINKS } from '../../constants';
import ManageMemberRole from '../../pages/Enterprise/ManageMemberRole';
import ManageMemberLicenses from '../../pages/Enterprise/ManageMemberLicenses';
import Header from '../Header/Header';
import If from '../If/If';
import HelpDeskTrigger from '../HelpDeskTrigger/HelpDeskTrigger';

const OrganizationMemberDashboardHeading = inject('enterpriseStore')(
  observer(
    class OrganizationMemberDashboardHeading extends React.Component {
      getRoleTag = (role) => {
        const roleMeta = FormatUtil.getTeamRoleMeta(role);
        roleMeta.tooltip += '. Click to learn more about user roles.';

        return (
          <Tooltip omitTabIndex html={roleMeta.tooltip}>
            <AddLink to={HELP_DESK_LINKS.ROLES_PERMS_AND_USER_ROLES} target="_blank">
              <Label className="p-1 text-xs rounded" color={roleMeta.color}>
                {FormatUtil.convertRoleName(role)}
              </Label>
            </AddLink>
          </Tooltip>
        );
      };

      changeRoleConfirm = ({ value }) => {
        if (value !== this.props.data.data.role) {
          this.props.commonStore.triggerConfirm({
            content: 'Are you sure you want to change user roles for this team?',
            cancel: () => this.props.commonStore.resetConfirmState(),
            continue: () => {
              this.props.commonStore.resetConfirmState();
              this.props.changeUserRole(value);
            },
          });
        }
      };

      addGroupConfirm = ({ value }) => {
        this.props.commonStore.triggerConfirm({
          content: 'Are you sure you want to add user to selected group?',
          cancel: () => this.props.commonStore.resetConfirmState(),
          continue: () => {
            this.props.commonStore.resetConfirmState();
            this.props.addUserToGroup(value);
          },
        });
      };

      toggleUserLicenseConfirm = (e, { checked }) => {
        this.props.commonStore.triggerConfirm({
          content: 'Are you sure you want to change the license status for this user?',
          cancel: () => this.props.commonStore.resetConfirmState(),
          continue: () => {
            this.props.commonStore.resetConfirmState();
            this.props.changeLicense(checked);
          },
        });
      };

      getGroupDropdown = (groupList) => {
        if (groupList.loading) {
          return (
            <Container>
              <Loading message="Loading..." />
            </Container>
          );
        }
        if (groupList.error) {
          return (
            <Container>
              <StyledError error={groupList.error} />
            </Container>
          );
        }
        return (
          <Dropdown
            placeholder="Add to Group"
            isSearchable
            classes="text-left"
            selectOnBlur={false}
            options={groupList?.data?.data?.slice()}
            value=""
            onChange={this.addGroupConfirm}
          />
        );
      };

      render() {
        const { loading, error, data } = this.props.data;
        if (loading) {
          return (
            <Container>
              <Loading message="Loading..." wrapperClassName="py-8" />
            </Container>
          );
        }
        if (error) {
          return (
            <Container>
              <StyledError error={error} />
            </Container>
          );
        }
        const memberName = data.real_name ? data.real_name : data.name;
        const memberLicenseIds = this.props.data?.data?.licenses?.reduce((acc, license) => {
          if (license?.team_content_license_grant_id) {
            acc.push(license?.team_content_license_grant_id);
          }
          return acc;
        }, []);
        const roleMeta = FormatUtil.getTeamRoleMeta(data.role);
        roleMeta.tooltip += '. Click to learn more about user roles.';
        return (
          <div className="pt-4 pb-8 border-b border-gray-400 header-container">
            <div className="flex flex-col items-baseline pt-16 md:flex-row">
              <div className="flex flex-col gap-y-4 w-full md:gap-y-2 header-content">
                <div className="col-span-12 pb-0 name-container">
                  <Title title={memberName} omitPadding breadcrumbs={this.props.breadcrumbs} />
                </div>
                <div className="flex grow justify-start items-center header-info">
                  <Tooltip omitTabIndex html={roleMeta.tooltip}>
                    <AddLink to={HELP_DESK_LINKS.ROLES_PERMS_AND_USER_ROLES} target="_blank" className="flex">
                      <Label className="p-1 text-xs whitespace-nowrap rounded" color={roleMeta.color}>
                        {FormatUtil.convertRoleName(data.role)}
                      </Label>
                      <HelpDeskTrigger to={HELP_DESK_LINKS.ROLES_PERMS_AND_USER_ROLES} className="-mt-3 -ml-1" />
                    </AddLink>
                  </Tooltip>
                  <div className="flex grow justify-between w-full">
                    <div className="flex flex-col ml-4">
                      {data.job_title ? <p className="text-gray-600">{data.job_title}</p> : null}

                      {data.email ? (
                        <p className="text-gray-600">
                          <span className="font-semibold text-black">Email:</span> {data.email}
                        </p>
                      ) : null}
                      {data.member_status ? (
                        <p className="text-gray-600">
                          <span className="font-semibold text-black">Status:</span> {data.member_status}
                        </p>
                      ) : null}
                    </div>
                  </div>
                </div>
                <div className="flex gap-x-4 mt-2 text-sm">
                  {data.created_at ? (
                    <p className="text-gray-600 whitespace-nowrap">
                      <span className="font-semibold ">Joined:</span> {FormatUtil.convertUtcToLocal(data.created_at).format('MMMM D, YYYY')}
                    </p>
                  ) : null}
                  {data.last_login ? (
                    <p className="text-gray-600 whitespace-nowrap">
                      <span className="font-semibold ">Last Login:</span> {moment(data.last_login).format('MMMM D, YYYY')}
                    </p>
                  ) : null}
                </div>
                <div className="flex flex-col col-span-12 gap-y-2 lg:mr-8">
                  <Header as="h4" className="mt-4 mb-2 text-left">
                    Groups
                  </Header>
                  <OrganizationGroupCards groups={data.groups} orgId={this.props.orgId} groupPermissions={this.props.groupPermissions} />
                </div>
              </div>
              <If condition={this.props.canManageTeam && this.props.canManageAdmins}>
                <div className="print:hidden flex flex-col gap-y-4 mt-auto w-full max-w-xs">
                  <Header as="h3" className="mb-0 text-left">
                    Manage User
                  </Header>
                  <div className="flex flex-col gap-y-2 w-full">
                    <Header as="h4" className="mb-0 text-left">
                      Role
                    </Header>
                    <ManageMemberRole
                      memberUserId={this.props.memberUserId}
                      memberName={this.props.data?.data?.real_name}
                      memberRole={this.props.data?.data?.role}
                      onSave={() => this.props.enterpriseStore.getMemberDashboardHeading(this.props.orgId, this.props.memberUserId)}
                    />
                  </div>
                  <div className="flex flex-col gap-y-2 w-full">
                    <Header as="h4" className="mb-0 text-left">
                      Groups
                    </Header>
                    {this.props.canManageTeam ? this.getGroupDropdown(this.props.groupList) : null}
                  </div>
                  <div className="flex flex-col gap-y-2 w-full">
                    <Header as="h4" className="mb-0 text-left">
                      Licenses
                    </Header>
                    <ManageMemberLicenses
                      memberUserId={this.props.memberUserId}
                      memberName={this.props.data?.data?.real_name}
                      memberLicenses={memberLicenseIds}
                      onSave={() => this.props.enterpriseStore.getMemberDashboardHeading(this.props.orgId, this.props.memberUserId)}
                    />
                  </div>
                </div>
              </If>
            </div>
          </div>
        );
      }
    }
  )
);

export default OrganizationMemberDashboardHeading;
