import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { observer, inject } from 'mobx-react';
import { Navigate, useNavigate } from 'react-router-dom';
import moment from 'moment';
import Bugsnag from '@bugsnag/js';
import Header from '../../components/Header/Header';
import withRouter from '../../components/Router/withRouter';
import Breadcrumbs from '../../components/Breadcrumbs/Breadcrumbs';
import Loading from '../../components/Loading/Loading';
import Segment from '../../components/Segment/Segment';
import DynamicForm from '../../components/DynamicForm/DynamicForm';
import StyledError from '../../components/Error/StyledError';
import Icon from '../../components/Icon/Icon';
import Container from '../../components/Container/Container';
import './admin.css';
import Divider from '../../components/Divider/Divider';
import OrganizationLicenseGrants from '../Enterprise/OrganizationLicenseGrants';
import Button from '../../components/Button/Button';
import If from '../../components/If/If';
import { getPackageNames } from '../../utils/formatUtil';
import Collapsible from '../../components/Collapsible/Collapsible';
import { CONTENT_TYPES } from '../../constants';

// Store risk info fields on the component since they are referenced in various places
const RISK_INFO_FIELDS = [
  'risk_info_value',
  'risk_info_maintenance_hours',
  'risk_info_value_at_risk',
  'risk_info_max_improvement_delta',
  'risk_info_max_improvement_hours',
  'risk_info_breach_likelihood',
  'risk_info_max_regression_delta',
  'risk_info_max_regression_hours',
];

const INITIAL_LICENSES_COUNT = 1; // initial number of licenses to show
// const MAX_LICENSES_COUNT = 10; // max number of licenses to show

function ChargebeeMessage({ licenseSource }) {
  if (!licenseSource || licenseSource !== 'chargebee') {
    return null;
  }
  return (
    <div className="flex gap-x-2 items-center p-4 text-sm font-semibold bg-yellow-100 rounded border border-black">
      <Icon name="exclamation-circle" className="shrink-0 w-8 h-8" />
      If you change the Team &quot;Package Type&quot;, &quot;Contract End Date&quot;, or &quot;Max Licenses&quot;, you MUST go into Chargebee and cancel the recurring Team
      subscription.
    </div>
  );
}

function AboutPackages({ availableLicensePackages }) {
  return (
    <>
      <Divider horizontal className="mt-12 mb-8 text-2xl font-bold">
        License Grants
      </Divider>
      <OrganizationLicenseGrants isCybraryAdmin className="mb-8" />

      <Divider horizontal className="mt-12 mb-4 text-2xl font-bold">
        Manage Licenses
      </Divider>
      <p className="pb-4 text-xs text-gray-600">
        Licenses contain one or more packages. Packages grant access to specific content / content types. Licenses are created here, and can be assigned to users by Team Owners on
        the Teams People page.
        <br />
        <br />
        To remove a license, remove all packages and set the quantity to 0.
      </p>
      <Collapsible
        trigger={
          <div className="flex gap-x-1 justify-end items-center w-full font-bold text-right text-md">
            <Icon name="help-icon" /> View Packages Info
          </div>
        }
        triggerWhenOpen={
          <div className="flex gap-x-1 justify-end items-center w-full font-bold text-right text-md">
            <Icon name="chevron-up" /> Hide Packages Info
          </div>
        }
        triggerClassName="text-right"
      >
        <Divider horizontal className="mt-12 mb-8 text-2xl font-bold">
          About Packages
        </Divider>
        <p className="pb-8 text-xs text-gray-600">
          Packages are created in Contentful using the content type <code className="font-bold">package</code>, and are linked to their respective Paddle product by matching the
          Contentful package&apos;s <code className="font-bold">name</code> field to the Paddle Product&apos;s custom field <code className="font-bold">package</code>.
        </p>
        <div className="mb-12">
          {availableLicensePackages?.map((licensePackage) => {
            const title = getPackageNames([licensePackage.name]);
            const contentTypeNiceNames = licensePackage.content_type_ids.map((id) => {
              const contentType = Object.values(CONTENT_TYPES).find((ct) => ct.id === parseInt(id, 10));
              return `${contentType?.niceName || 'Unknown'} (ID: ${contentType?.id})`;
            });
            const formattedPackage = {
              ...licensePackage,
              content_type_ids: contentTypeNiceNames,
            };
            return (
              <Collapsible
                key={licensePackage.id}
                trigger={
                  <div className="flex gap-x-1 items-center w-full font-bold text-right text-md">
                    <Icon name="chevron-right" /> {title}
                  </div>
                }
                triggerWhenOpen={
                  <div className="flex gap-x-1 items-center w-full font-bold text-right text-md">
                    <Icon name="chevron-down" /> {title}
                  </div>
                }
              >
                <div className="p-4 space-y-4 text-sm">
                  <div className="flex gap-x-2">
                    <span className="font-semibold">ID:</span>
                    <span>{formattedPackage.id}</span>
                  </div>
                  <div className="flex gap-x-2">
                    <span className="font-semibold">Name:</span>
                    <span>{formattedPackage.name}</span>
                  </div>
                  <div className="flex gap-x-2">
                    <span className="font-semibold">Description:</span>
                    <span>{formattedPackage.description}</span>
                  </div>
                  <div className="flex gap-x-2">
                    <span className="font-semibold">Created:</span>
                    <span>{new Date(formattedPackage?.created_at).toLocaleDateString()}</span>
                  </div>
                  <div className="flex gap-x-2">
                    <span className="font-semibold">Updated:</span>
                    <span>{new Date(formattedPackage?.updated_at).toLocaleDateString()}</span>
                  </div>
                  <div className="flex flex-col gap-y-1">
                    <span className="font-semibold">Content Types:</span>
                    <span>{formattedPackage?.content_type_ids?.length ? formattedPackage?.content_type_ids?.join(', ') : '-'}</span>
                  </div>
                  <div className="flex flex-col gap-y-1">
                    <span className="font-semibold">Root Content Description IDs:</span>
                    <span>{formattedPackage?.root_content_description_ids?.length ? formattedPackage?.root_content_description_ids?.join(', ') : '-'}</span>
                  </div>
                  <div className="flex flex-col gap-y-1">
                    <span className="font-semibold">Content Description IDs:</span>
                    <span className="break-all">{formattedPackage?.content_description_ids?.length ? formattedPackage?.content_description_ids?.join(', ') : '-'}</span>
                  </div>

                  <Divider horizontal className="mt-12 mb-8 text-2xl font-bold" />
                </div>
              </Collapsible>
            );
          })}
        </div>
      </Collapsible>
    </>
  );
}

// Require any risk_info field if a single one of them is filled out.
const checkRiskInfo = (data) => {
  const riskInfoDataFieldsWithValues = RISK_INFO_FIELDS.filter((fieldName) => !!data?.[fieldName]);
  return riskInfoDataFieldsWithValues.length === 0 || riskInfoDataFieldsWithValues.length === RISK_INFO_FIELDS.length
    ? null
    : 'If any risk information is provided, all risk info fields become required.';
};

function getDefaultTeamForm(availableLicensePackages, licenseCount, isSaving, setLicenseCount) {
  return {
    order: [],
    fields: {
      team_data_header: {
        type: 'insert',
        insertComponent: (
          <Divider horizontal className="my-8 text-2xl font-bold">
            Team Data
          </Divider>
        ),
      },
      name: {
        type: 'text',
        label: 'Team Name',
        defaultValue: '',
        validations: ['required'],
      },
      domains: {
        type: 'text',
        label: 'Email Domain(s)',
        defaultValue: '',
        description: 'Email domain(s) associated with this team (Do not include "@"). If including more than one domain, separate with a comma (e.g. cybrary.it,cybrary.com)',
      },

      type: {
        type: 'select',
        label: 'Team Type',
        defaultValue: 'Enterprise',
        validations: ['required'],
        options: [
          {
            key: 'Enterprise',
            text: 'Enterprise',
            value: 'Enterprise',
          },
          {
            key: 'Internal',
            text: 'Internal',
            value: 'Internal',
          },
          {
            key: 'Creator',
            text: 'Creator',
            value: 'Creator',
          },
          {
            key: 'Demo',
            text: 'Demo',
            value: 'Demo',
          },
        ],
      },
      hubspot_id: {
        type: 'text',
        label: 'Hubspot Account ID',
        defaultValue: '',
        validations: ['required', 'alphanumeric'],
        conditions: (formState) => formState.type === 'Enterprise',
      },
      license_grants_header: {
        type: 'insert',
        insertComponent: <AboutPackages availableLicensePackages={availableLicensePackages} />,
      },
      /**
       * Dynamic license fields get inserted here
       */

      license_add_button: {
        type: 'insert',
        insertComponent: (
          <Button className="mb-8 font-bold" onClick={() => setLicenseCount(licenseCount + 1)} icon={{ name: 'plus' }}>
            Add License
          </Button>
        ),
      },

      risk_info_header: {
        type: 'insert',
        insertComponent: (
          <Divider horizontal className="mt-32 mb-8 text-2xl font-bold text-orange-600">
            Risk Information
          </Divider>
        ),
      },
      risk_info_value: {
        type: 'number',
        label: 'Business Value',
        defaultValue: '',
        icon: 'dollar-sign',
        validations: ['nullable', 'number', checkRiskInfo],
      },
      risk_info_maintenance_hours: {
        type: 'quantity',
        label: 'Training Required for Maintenance',
        defaultValue: '',
        description: '(0% change in Breach Likelihood)',
        placeholder: 'hrs/learner/month',
        validations: ['nullable', 'number', checkRiskInfo],
        inputContainerClassName: 'w-full',
      },
      risk_info_value_at_risk: {
        type: 'quantity',
        label: 'Percent of Value at Risk',
        defaultValue: '',
        validations: ['nullable', 'number', checkRiskInfo],
        inputContainerClassName: 'w-full',
      },
      risk_info_max_improvement_delta: {
        type: 'quantity',
        label: 'Max Training Based Improvement',
        defaultValue: '',
        description: 'Percent',
        validations: ['nullable', 'number', checkRiskInfo],
        inputContainerClassName: 'w-full',
      },
      risk_info_max_improvement_hours: {
        type: 'quantity',
        label: 'Learning Hours',
        defaultValue: '',
        description: 'Based on this many learning hours invested',
        validations: ['nullable', 'number', checkRiskInfo],
        inputContainerClassName: 'w-full',
      },
      risk_info_breach_likelihood: {
        type: 'number',
        label: 'Likelihood of Breach',
        defaultValue: '',
        validations: ['nullable', 'number', checkRiskInfo],
      },
      risk_info_max_regression_delta: {
        type: 'quantity',
        label: 'Max Training Based Regression',
        defaultValue: '',
        description: 'Percent',
        validations: ['nullable', 'number', checkRiskInfo],
        inputContainerClassName: 'w-full',
      },
      risk_info_max_regression_hours: {
        type: 'quantity',
        label: 'Learning Hours',
        defaultValue: '',
        description: 'Based on this many learning hours invested',
        validations: ['nullable', 'number', checkRiskInfo],
        inputContainerClassName: 'w-full',
      },

      other_settings_header: {
        type: 'insert',
        insertComponent: (
          <Divider horizontal className="mt-16 mb-8 text-2xl font-bold">
            Other Settings
          </Divider>
        ),
      },
      clab_access: {
        type: 'boolean',
        label: 'Assessments Beta',
        description: 'Toggle access for team admins to access the Cybrary Assessments beta',
      },
      submit: {
        type: 'button',
        color: 'pink',
        icon: 'save outline',
        label: 'Save Team',
        className: 'fixed top-1 right-4 z-10',
        disabled: isSaving,
        loading: isSaving,
      },
    },
  };
}

const CybraryAdminManageTeam = inject(
  'commonStore',
  'adminStore',
  'authStore'
)(
  observer(({ commonStore, adminStore, authStore, match }) => {
    const [view, setView] = useState(null);
    const [isSaving, setIsSaving] = useState(false);
    const [licenseCount, setLicenseCount] = useState(adminStore?.teamDetailsData?.data?.active_license_grants?.length || INITIAL_LICENSES_COUNT);
    const teamId = match?.params?.id;
    const { availableLicensePackages } = adminStore;
    const navigate = useNavigate();

    useEffect(() => {
      setView(teamId ? 'edit' : 'add');
      if (teamId) {
        adminStore.getTeamDetailsData(teamId);
      }
      // Confirm that we are allowed on the admin page
      adminStore.checkAccess(authStore);
      commonStore.hidePrimaryNav();
      return () => {
        adminStore.setDefaultTeamDetailsData();
      };
    }, [teamId]);

    useEffect(() => {
      if (adminStore?.hasAccess) {
        adminStore.getAvailableLicensePackages();
      }
    }, [adminStore?.hasAccess]);

    useEffect(() => {
      if (adminStore?.teamDetailsData?.data?.active_license_grants?.length) {
        setLicenseCount(adminStore?.teamDetailsData?.data?.active_license_grants?.length);
      }
    }, [adminStore?.teamDetailsData?.data?.active_license_grants?.length]);

    const breadCrumbs = useMemo(() => {
      const crumbs = [];
      crumbs.push({
        href: '/admin',
        label: 'Admin',
      });
      if (view === 'edit') {
        crumbs.push({
          href: '/admin/teams',
          label: 'Browse Teams',
        });
        crumbs.push({
          href: `/admin/view-team/${teamId}`,
          label: 'View Team',
        });
        crumbs.push({
          href: `/admin/edit-team/${teamId}`,
          label: 'Edit Team',
        });
      } else {
        crumbs.push({
          href: '/admin/teams',
          label: 'Browse Teams',
        });
        crumbs.push({
          href: '/admin/add-team',
          label: 'Add Team',
        });
      }
      return crumbs;
    }, [view, teamId]);

    const display422Error = useCallback(
      (err) => {
        if (err?.data?.errors) {
          let errorMsg = null;
          const firstError = err.data.errors[Object.keys(err.data.errors)[0]];
          if (firstError[0].indexOf('domain has already been taken') > -1) {
            errorMsg = 'Sorry, that domain has already been taken by another team';
          } else if (firstError[0].indexOf('format is invalid') > -1) {
            errorMsg = 'Email domain format is not valid. Please try again';
          } else if (firstError[0].indexOf('This domain is not allowed') > -1) {
            errorMsg = 'This email domain is not allowed. Please try again';
          }
          commonStore.triggerToast('error', {
            errorCode: err.status,
            content: errorMsg,
          });
        } else {
          commonStore.triggerToast('error', {
            errorCode: err.status,
          });
        }
      },
      [commonStore]
    );

    const handleSubmit = useCallback(
      (data) => {
        try {
          const submitData = { ...data };

          let emailDomains = [];
          if (submitData.domains && submitData.domains.length) {
            // Format domains to an array for submissions
            const domainsArr = submitData.domains.split(',');
            emailDomains = domainsArr.map((domain) => {
              if (!domain || !domain.trim().length) {
                return null;
              }
              return {
                domain: domain.trim(),
                enabled: true,
              };
            });
          }
          submitData.domains = emailDomains;

          // Transform the risk_info data for submission
          const riskInfo = {};
          RISK_INFO_FIELDS.forEach((fieldName) => {
            const riskInfoName = fieldName.replace('risk_info_', '');
            if (submitData[fieldName]) {
              riskInfo[riskInfoName] = submitData[fieldName];
            }
          });
          submitData.risk_info = riskInfo;

          setIsSaving(true);

          adminStore
            .manageTeamData(submitData, teamId)
            .then((response) => {
              setIsSaving(false);
              commonStore.triggerToast('success', {
                content: 'Team has been saved. Redirecting you to the team view page for additional actions.',
              });
              setTimeout(() => {
                // Send the user to the team details page
                navigate(`/admin/view-team/${response.id}`);
              }, 300);
            })
            .catch((e) => {
              setIsSaving(false);
              Bugsnag.notify(e);
              if (!!e && e.response && (e.response.status === 403 || e.response.status === 422)) {
                if (e.response.status === 422) {
                  display422Error(e.response);
                } else {
                  commonStore.triggerToast('error', {
                    errorCode: e.response.status,
                  });
                }
              } else {
                commonStore.triggerToast('error', {
                  content: 'Something went wrong. Information not submitted. Please try again',
                });
              }
            });
        } catch (e) {
          setIsSaving(false);
          Bugsnag.notify(e);
          commonStore.triggerToast('error', {
            content: 'Something went wrong. Information not submitted. Please try again',
          });
        }
      },
      [adminStore, commonStore, navigate]
    );

    const prepareSubmit = useCallback(
      (data) => {
        if (data) {
          const submitData = { ...data };
          const currentTeamData = adminStore?.teamDetailsData?.data;

          // Build array of licenses based on the selected package types
          const licenses = [];

          // Transform the license data for submission
          for (let i = 1; i <= licenseCount; i++) {
            if (submitData[`license_${i}_name`] && submitData[`license_${i}_package_types`] && submitData[`license_${i}_max_seats`] && submitData[`license_${i}_expires_at`]) {
              licenses.push({
                name: submitData[`license_${i}_name`],
                package_types: submitData[`license_${i}_package_types`],
                max_seats: submitData[`license_${i}_max_seats`],
                expires_at: submitData[`license_${i}_expires_at`],
              });
            }
          }

          // Add the licenses to the submit data
          submitData.licenses = licenses;

          // HANDLE WARNING FOR DANGEROUS CHANGES HERE
          if (view === 'edit') {
            // Check if we're editing any licenses
            const hasLicenses = currentTeamData?.active_license_grants?.length;
            const isRemovingLicenses = hasLicenses && currentTeamData?.active_license_grants?.length > submitData?.licenses?.length;
            if (isRemovingLicenses) {
              commonStore.triggerConfirm({
                content: (
                  <>
                    <p className="font-bold">
                      <span className="text-red-500">WARNING:</span> You are removing licenses from this team.
                    </p>
                    <p>When removing a license type all user assigned that license have it removed upon saving.</p>
                    <p>If the license type is added back to the team after this step, you will have to manually reassign licenses to users.</p>
                  </>
                ),
                // This is intentionally backwards from the default behavior of the confirm component
                continue: () => {
                  commonStore.resetConfirmState();
                },
                cancel: () => {
                  commonStore.resetConfirmState();
                  handleSubmit(submitData);
                },
                confirmBtn: 'Cancel',
                cancelBtn: 'Remove Licenses',
                omitBgCancel: true,
              });
            } else {
              handleSubmit(submitData);
            }
          } else {
            handleSubmit(submitData);
          }
        }
      },
      [view, teamId, adminStore, commonStore, navigate, licenseCount]
    );

    // Transform array of domains into comma separated string
    const formatDomainsField = useCallback((domains) => {
      if (!domains || !domains.length) {
        return '';
      }
      const validDomains = [];
      domains.forEach((domain) => {
        if (domain.enabled) {
          validDomains.push(domain.domain);
        }
      });
      return validDomains.length ? validDomains.join(',') : '';
    }, []);

    const addDefaultValues = useCallback(
      (form) => {
        const storeData = adminStore?.teamDetailsData?.data || {};
        if (!storeData) return form; // There is no store data (user is adding a new team), so just return the form as-is
        const newForm = { ...form };
        let fieldName = '';

        // Set form fields based on the store data
        Object.keys(newForm.fields).forEach((item) => {
          // See if the store data has a value that is different from the default value, and set it if found
          if (!!newForm.fields[item] && !!storeData[item] && storeData[item] !== newForm.fields[item].defaultValue) {
            newForm.fields[item].defaultValue = storeData[item];
          }
          switch (true) {
            case item === 'type':
              if (storeData?.team_type?.name) {
                newForm.fields[item].defaultValue = storeData.team_type.name;
              }
              break;
            case item === 'domains':
              newForm.fields[item].defaultValue = formatDomainsField(storeData[item]);
              break;
            case item.startsWith('risk_info_'):
              fieldName = item.replace('risk_info_', '');
              newForm.fields[item].defaultValue = storeData?.risk_info?.[fieldName] || newForm.fields[item].defaultValue || '';
              break;
            default:
              break;
          }
        });

        // Set the license fields based on the store data
        if (storeData?.active_license_grants?.length) {
          storeData.active_license_grants.forEach((grant, i) => {
            if (newForm.fields[`license_${i + 1}_name`]) {
              newForm.fields[`license_${i + 1}_name`].defaultValue = grant.name;
            }
            if (newForm.fields[`license_${i + 1}_package_types`]) {
              newForm.fields[`license_${i + 1}_package_types`].defaultValue = grant.package_types;
            }
            if (newForm.fields[`license_${i + 1}_max_seats`]) {
              newForm.fields[`license_${i + 1}_max_seats`].defaultValue = grant.max_seats;
            }
            if (newForm.fields[`license_${i + 1}_expires_at`]) {
              newForm.fields[`license_${i + 1}_expires_at`].defaultValue = moment(grant.license_expires_at).format('YYYY-MM-DD HH:mm:ss');
            }
          });
        }

        return newForm;
      },
      [adminStore?.teamDetailsData?.data, formatDomainsField, licenseCount]
    );

    const nameOptions = availableLicensePackages.map((licensePackage) => ({
      label: getPackageNames([licensePackage.name]),
      value: licensePackage.name,
    }));
    // Push generic license names to end as suggestions
    ['Engineering', 'Sales', 'Marketing', 'HR', 'Finance', 'Legal', 'Customer Success', 'Support'].forEach((licenseName) => {
      nameOptions.push({
        label: licenseName,
        value: licenseName,
      });
    });

    const addTeamForm = useMemo(() => {
      const teamForm = getDefaultTeamForm(availableLicensePackages, licenseCount, isSaving, setLicenseCount);

      const headerColors = ['', 'text-cyb-blue-500', 'text-cyb-green-500', 'text-cyb-pink-500'];

      const order = ['team_data_header', 'name', 'domains', 'type', 'hubspot_id', 'license_grants_header'];

      // Add dynamic licenses section
      for (let i = 1; i <= licenseCount; i++) {
        // Add header field for this license
        teamForm.fields[`license_${i}_header`] = {
          type: 'insert',
          insertComponent: (
            <Header as="h2" className={`flex gap-x-4 items-center ${headerColors[(i - 1) % headerColors.length]}`}>
              <Icon name="license" className="w-8 h-8" /> License {i}
            </Header>
          ),
        };

        teamForm.fields[`license_${i}_name`] = {
          type: 'textSelectCreate',
          label: 'Name',
          defaultValue: '',
          defaultOptions: nameOptions,
          openMenuOnClick: true,
          isClearable: false,
          description: 'Display name for this license. Select from common names or create a one specific to this team.',
          labelClass: `${headerColors[(i - 1) % headerColors.length]}`,
        };
        teamForm.fields[`license_${i}_package_types`] = {
          type: 'multi-select',
          label: 'Package(s)',
          defaultValue: [],
          options: availableLicensePackages.map((licensePackage) => ({
            key: licensePackage.name,
            text: getPackageNames([licensePackage.name]),
            value: licensePackage.name,
          })),
          description:
            'Packages grant access to specific content / types. Licenses contain one or more packages and are assigned to users to grant access to the packages content.',
          labelClass: `${headerColors[(i - 1) % headerColors.length]}`,
        };
        teamForm.fields[`license_${i}_max_seats`] = {
          type: 'quantity',
          label: 'Quantity',
          description: 'Number of seats this license will permit.',
          defaultValue: '',
          inputContainerClassName: 'w-full',
          labelClass: `${headerColors[(i - 1) % headerColors.length]}`,
        };
        teamForm.fields[`license_${i}_expires_at`] = {
          type: 'datePicker',
          label: 'Expiration Date',
          dateFormat: 'YYYY-MM-DD HH:mm:ss',
          wrapperClasses: 'w-full',
          inputClassName: 'w-full',
          description: 'Contract end date for this license.',
          labelClass: `${headerColors[(i - 1) % headerColors.length]}`,
        };
        // Add footer field for this license
        teamForm.fields[`license_${i}_footer`] = {
          type: 'insert',
          insertComponent: <div className="my-8 border-t border-gray-300" />,
          hidden: i > licenseCount,
        };

        // Make name, package_types, max_seats, _expires_at required if name, package_types, max_seats is not empty
        const isLicenseTouched = (formState) => {
          const hasPackageTypes = formState[`license_${i}_package_types`] && formState[`license_${i}_package_types`]?.length;
          const hasMaxSeats = formState[`license_${i}_max_seats`] && formState[`license_${i}_max_seats`] > 0;
          return hasPackageTypes || hasMaxSeats;
        };
        const errorMessage = `Required for License`;

        teamForm.fields[`license_${i}_name`].validations = [
          (formState) => {
            if (isLicenseTouched(formState) && !formState[`license_${i}_name`]?.length) {
              return errorMessage;
            }
            return undefined;
          },
        ];
        teamForm.fields[`license_${i}_package_types`].validations = [
          (formState) => {
            if (isLicenseTouched(formState) && !formState[`license_${i}_package_types`]?.length) {
              return errorMessage;
            }
            return undefined;
          },
        ];
        teamForm.fields[`license_${i}_max_seats`].validations = [
          (formState) => {
            if (isLicenseTouched(formState) && !formState[`license_${i}_max_seats`]) {
              return errorMessage;
            }
            return undefined;
          },
        ];
        teamForm.fields[`license_${i}_expires_at`].validations = [
          (formState) => {
            if (isLicenseTouched(formState) && !formState[`license_${i}_expires_at`]) {
              return errorMessage;
            }
            return undefined;
          },
        ];

        // Build array of order keys for this license
        const licenseOrder = [`license_${i}_name`, `license_${i}_max_seats`, `license_${i}_expires_at`];

        // Push header before the license fields
        order.push(`license_${i}_header`);
        // Push license fields
        order.push(licenseOrder);
        // Push package types after the license name
        order.push(`license_${i}_package_types`);
        // Push footer after the license fields
        order.push(`license_${i}_footer`);
      }

      // Add the footer fields
      const footerOrder = [
        'license_add_button',
        'risk_info_header',
        ['risk_info_value', 'risk_info_maintenance_hours'],
        ['risk_info_value_at_risk', 'risk_info_max_improvement_delta', 'risk_info_max_improvement_hours'],
        ['risk_info_breach_likelihood', 'risk_info_max_regression_delta', 'risk_info_max_regression_hours'],
        'other_settings_header',
        'clab_access',
        'submit',
      ];
      footerOrder.forEach((field) => {
        order.push(field);
      });

      teamForm.order = order;

      return addDefaultValues(teamForm, adminStore?.teamDetailsData?.data);
    }, [availableLicensePackages?.length, licenseCount, isSaving, setLicenseCount, adminStore?.teamDetailsData?.data]);

    const { denyAccess, teamDetailsData } = adminStore;

    // If we should deny access, just get out now
    if (denyAccess) {
      return <Navigate to="/" />;
    }

    const isLoadingEdit = adminStore?.teamDetailsData?.loading && view === 'edit';
    const isErrorEdit = adminStore?.teamDetailsData?.error && view === 'edit';

    const isLoadingAdd = adminStore?.isAvailableLicensePackagesLoading;

    return (
      <Container className="py-4 admin" md>
        <Segment className="pt-16 border-none">
          <Breadcrumbs crumbs={breadCrumbs} sticky />
          <ChargebeeMessage licenseSource={teamDetailsData.data ? teamDetailsData.data.license_source : null} />
          <If condition={view === 'edit'}>
            <Header as="h1">Edit Team</Header>
            <If condition={isLoadingEdit}>
              <Container>
                <Loading message="Loading..." />
              </Container>
            </If>
            <If condition={isErrorEdit}>
              <Container>
                <StyledError error={adminStore?.teamDetailsData?.error} />
              </Container>
            </If>
            <If condition={!isLoadingEdit && !isErrorEdit}>
              <DynamicForm form={addTeamForm} formName="teamForm" onSubmit={prepareSubmit} />
            </If>
          </If>
          <If condition={view === 'add'}>
            <Header as="h1">Add Team</Header>
            <If condition={isLoadingAdd}>
              <Container>
                <Loading message="Loading..." />
              </Container>
            </If>
            <If condition={!isLoadingAdd}>
              <DynamicForm form={addTeamForm} formName="teamForm" onSubmit={prepareSubmit} />
            </If>
          </If>
        </Segment>
      </Container>
    );
  })
);

export default withRouter(CybraryAdminManageTeam);
