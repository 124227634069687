import React from 'react';
import { useContinueLearningContext } from '../../../providers/ContinueLearningProvider';
import ContinueLearningContentRow from '../ContinueLearningContentRow';
import ContinueLearningFooterRow from '../ContinueLearningFooterRow';
import ContinueLearningNoContent from '../ContinueLearningNoContent';

/**
 * Tab for showing the first X bookmarked items a user has saved
 * within the continue learning block on the dashboard
 * @returns <ContinueLearningBookmarksTab />
 */
export default function ContinueLearningBookmarksTab() {
  const { bookmarkedContent, isLoading, MAX_BOOKMARKS, initBookmarksError } = useContinueLearningContext();
  if (!isLoading && initBookmarksError) {
    return <ContinueLearningNoContent title="Uh-oh, something went wrong!" body={initBookmarksError?.message} />;
  }
  if (!isLoading && !bookmarkedContent?.length) {
    return <ContinueLearningNoContent title="No bookmarked content found!" body="You have no current bookmarks to show here." />;
  }
  return (
    <div className="overflow-auto w-full max-h-80 lg:max-h-40">
      {bookmarkedContent
        ?.slice(0, MAX_BOOKMARKS)
        ?.filter((enrollment) => !enrollment.error)
        ?.map((bookmarkedContentData) => (
          <ContinueLearningContentRow key={bookmarkedContentData.id} bookmarkedContentData={bookmarkedContentData} isBookmark />
        ))}
      <ContinueLearningFooterRow title="Want to see all your saved content?" to="/bookmarks" cta="View Saved Content" />
    </div>
  );
}
