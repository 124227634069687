import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Helmet } from 'react-helmet';
import queryString from 'query-string';
import './app-page.css';
import BrowsePromo from '../../components/BrowsePromo/BrowsePromo';
import CareerPathDynamic from '../DynamicCollectionPage/DynamicCollectionPage';
import CertificationPrep from '../../components/Certifications/Certification';
import Container from '../../components/Container/Container';
import Loading from '../../components/Loading/Loading';
import StyledError from '../../components/Error/StyledError';
import withRouter from '../../components/Router/withRouter';

import FormatUtil from '../../utils/formatUtil';
import If from '../../components/If/If';

const AppPage = inject(
  'catalogStore',
  'commonStore',
  'userStore',
  'certificateStore',
  'feedbackStore',
  'authStore'
)(
  observer(
    class AppPage extends Component {
      state = {
        queryID: '',
        objectID: '',
      };

      componentDidMount() {
        const { match, location } = this.props;
        const { params } = match;
        const { id } = params;
        const { search } = location;
        const permalink = this.getPermalinkFromParams(params);
        const errorTrackingPayload = {
          href: window.location.href,
          user: this.props.userStore?.user,
        };

        if (search) {
          const queryParms = queryString.parse(search);
          const { queryID, objectID } = queryParms;
          if (!!queryID && !!objectID) {
            const newState = { ...this.state };
            newState.queryID = queryID;
            newState.objectID = objectID;
            this.setState(newState);
          }
        }

        if (id) {
          this.fetchById(id, errorTrackingPayload);
        } else if (permalink) {
          this.fetchByPermalink(permalink, errorTrackingPayload);
          this.fetchComprehensiveCourses(permalink);
          this.fetchComprehensiveCareerPaths(permalink);
        }
      }

      componentDidUpdate(prevProps) {
        const oldId = prevProps.match.params.id;
        const currId = this.props.match.params.id;
        const errorTrackingPayload = {
          href: window.location.href,
          user: this.props.userStore?.user,
        };

        if (currId && oldId !== currId) {
          this.fetchById(currId, errorTrackingPayload);
        } else {
          // See if we have an old Permalink (instead of an ID)
          const oldParams = prevProps.match.params;
          const currParams = this.props.match.params;
          const oldPermalink = this.getPermalinkFromParams(oldParams);
          const currPermalink = this.getPermalinkFromParams(currParams);
          if (oldPermalink !== currPermalink) {
            this.fetchByPermalink(currPermalink, errorTrackingPayload);
          }
        }
        if (!!this.props.catalogStore && this.props.catalogStore.item) {
          this.props.commonStore.setPageTitle(`${this.props.catalogStore.item.title} | Cybrary`);
        }
      }

      componentWillUnmount() {
        const updateState = { ...this.state };
        updateState.queryID = '';
        updateState.objectID = '';
        this.setState(updateState);
        // Remove our content description
        this.props.catalogStore.reset();
      }

      getPermalinkFromParams = (params) => {
        return params && typeof params === 'object' && params['*'] ? params['*'] : null;
      };

      fetchComprehensiveCourses = (permalink) => {
        // fetch comprehensive learning courses data
        this.props.catalogStore.getComprehensiveLearningCourses(permalink);
      };

      fetchComprehensiveCareerPaths = (permalink) => {
        // fetch comprehensive learning career paths data
        this.props.catalogStore.getComprehensiveLearningCareerPaths(permalink);
      };

      fetchById = (id, errorTrackingPayload) => {
        // Fetch our content description from the server.
        this.props.catalogStore.getContentDescriptionById(id, errorTrackingPayload).then(this.fetchCallback);
      };

      fetchByPermalink = (permalink, errorTrackingPayload) => {
        // Fetch our content description from the server.
        this.props.catalogStore.getContentDescriptionByPermalink(permalink, errorTrackingPayload).then(this.fetchCallback);
      };

      fetchCallback = (item) => {
        if (item) {
          this.props.authStore.fireAttributionEvent();
          // If we are looking at a cybrary live series or session, go directly to the page for that session/series
          if (item.content_type && ['Cybrary Live Series', 'Cybrary Live Session'].indexOf(item.content_type.nice_name) !== -1) {
            this.props.navigate(`/${item.permalink}`);
          } else {
            this.props.certificateStore.setCertificateFromItem(item.content_item);
            this.props.certificateStore.getCourseProgress(item.id);
            this.props.feedbackStore.setContentDescriptionId(item.id);
            this.props.catalogStore.getCollectionContent(item.id);
          }
        }
      };

      // Helper to format a sub-item
      formatSubItem = (lesson) => {
        return {
          icon: FormatUtil.getModalityMeta(lesson.type).icon,
          href: `/browse/${lesson.permalink}`,
          content: {
            title: lesson.title,
            description: FormatUtil.formatLongText(lesson.short_description, 150),
            duration: FormatUtil.formatTime(lesson.duration, 'hma'),
            type: lesson.type,
            is_free: lesson.is_free,
            id: lesson.id,
          },
          experience_points_total: lesson.experience_points_total,
          optional: lesson.optional,
        };
      };

      getCourseDurationByContentType = (learningActivities = []) => {
        const contentTypeDurations = {};
        // if no learning activities do nothing
        if (!learningActivities || !learningActivities.length) {
          return null;
        }

        // loop over each module
        learningActivities.forEach((module) => {
          // check for activities && activities length
          if (module.activities && module.activities.length) {
            // loop over "activity"
            module.activities.forEach((activity) => {
              // convert activity type to more generic type name
              const genericActivityType = FormatUtil.formatContentType(activity.type);
              // if it doesn't exist, add it and initialize the total duration of that activity to 0
              if (!contentTypeDurations[genericActivityType]) {
                contentTypeDurations[genericActivityType] = 0;
              }
              contentTypeDurations[genericActivityType] += activity.duration;
            });
          }
        });

        return contentTypeDurations;
      };

      render() {
        const { catalogStore } = this.props;

        if (catalogStore.loading) {
          return (
            <Container>
              <div className="app-page">
                <Loading message="Loading..." wrapperClassName="py-8" />
              </div>
            </Container>
          );
        }

        if (catalogStore.error) {
          return (
            <Container>
              <div className="app-page">
                <StyledError error={catalogStore.error} />
              </div>
            </Container>
          );
        }

        const { item } = catalogStore;
        if (!item) {
          return null;
        }

        const typeName = item?.content_type?.nice_name || item?.content_type?.name;
        const type = typeName ? FormatUtil.ucwords(typeName) : null;

        if (type === 'Career Path' || type === 'Collection' || type === 'Assessment Path') {
          return <CareerPathDynamic />;
        }

        if (type === 'Certification Prep') {
          return <CertificationPrep />;
        }

        const provider = [];
        if (item?.instructors_info?.length) {
          item.instructors_info.forEach((instructor) => {
            provider.push({
              type: 'Instructor',
              image: instructor?.thumbnail,
              description: instructor.short_bio,
              name: instructor.name,
              title: instructor.title,
              permalink: instructor.permalink,
            });
          });
        } else if (item?.author) {
          provider.push({
            type: 'Instructor',
            image: item.author.avatar_url,
            description: item.author.bio,
            name: item.author.name,
          });
        } else if (item?.vendor) {
          provider.push({
            type: 'Vendor',
            name: item.vendor.name,
            image: item.vendor.thumbnail_url,
            description: item.vendor.description,
            permalink: item.vendor.permalink,
            vendor: item.vendor,
          });
        }

        // Not including this with above if/else because you can have an alliance with an instructor separately
        if (item?.alliances_info?.length) {
          item.alliances_info.forEach((alliance) => {
            provider.push({
              type: 'Alliance',
              image: alliance.thumbnail,
              name: alliance.name,
              permalink: alliance.permalink,
            });
          });
        }

        const learningActivities = [];
        const { content_item } = item || {};

        if (content_item?.learning_modules) {
          content_item.learning_modules.forEach((module) => {
            if (module?.activities?.length) {
              const moduleLessons = [];
              module.activities.forEach((lesson) => {
                if (lesson.id) {
                  moduleLessons.push(this.formatSubItem(lesson));
                }
              });
              learningActivities.push({
                title: module.title,
                lessons: moduleLessons,
                duration: FormatUtil.formatTime(module.duration, 'hma'),
              });
            }
          });
        }
        const contentTypeDurations = this.getCourseDurationByContentType(content_item?.learning_modules);
        return (
          <>
            <If condition={item?.thumbnail_url}>
              <Helmet>
                <meta property="og:image" content={item.thumbnail_url} />
              </Helmet>
            </If>
            <BrowsePromo item={item} type={type} provider={provider} learningActivities={learningActivities} contentTypeDurations={contentTypeDurations} />
          </>
        );
      }
    }
  )
);

export default withRouter(AppPage);
