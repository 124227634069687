import React from 'react';
import moment from 'moment';
import Icon from '../Icon/Icon';
import AddLink from '../AddLink/AddLink';
import { WWW_PRICING_PAGE } from '../../constants';
import If from '../If/If';

/**
 * Button component
 * @param popup
 * @returns {*}
 */
function CTAButton({ button }) {
  const { link, text } = button;
  return (
    <AddLink to={link} target="_blank">
      <button className="px-6 h-10 text-sm font-bold leading-5 text-center text-white bg-cyb-pink-500 hover:bg-cyb-pink-600 rounded-sm">{text}</button>
    </AddLink>
  );
}

/**
 * The container for the user type component
 */
function UserTypeId({ userStore }) {
  const { user } = userStore;
  const upgradeLink = `${WWW_PRICING_PAGE}/?membership=btn`;

  let userContent = {};
  if (user.is_cip) {
    userContent = {
      icon: 'diamond',
      color: 'yellow',
      text: 'Cybrary Insider Pro',
    };
  } else if (user.is_enterprise) {
    userContent = {
      icon: 'globe',
      color: 'grey',
      text: 'Enterprise',
    };
  } else {
    userContent = {
      icon: 'user',
      color: 'pink',
      text: 'Free',
      button: {
        link: upgradeLink,
        text: 'Upgrade',
      },
    };
  }

  return (
    <div className="mt-4 ml-2 w-80">
      <div className="text-left">
        <div className="flex justify-start items-center">
          <If condition={userContent.icon}>
            <Icon name={userContent.icon} className="mr-2 w-4 h-4" />
          </If>
          <If condition={userContent.text}>
            <h3 className="text-lg font-bold">{userContent.text}</h3>
          </If>
        </div>
        <If condition={user.registered_at}>
          <p className="text-sm text-gray-600">
            <span className="date">Cybrarian since {moment(user.registered_at).format('MMM Do, YYYY')}</span>
          </p>
        </If>
      </div>
      <If condition={userContent.button}>
        <div className="pt-4 mt-4">
          <CTAButton button={userContent.button} />
        </div>
      </If>
    </div>
  );
}

export default UserTypeId;
