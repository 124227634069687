import React, { useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';
import DropdownFilter from '../Dropdown/DropdownFilter';

const GroupSelect = inject(
  'teamHierarchyStore',
  'userStore'
)(
  observer(
    ({
      ariaLabel,
      teamHierarchyStore,
      userStore,
      selectedGroup,
      placeholder,
      groupFilter,
      defaultOption,
      handleGroupSelect,
      className = '',
      isCompact,
      disabled,
      loading,
      closeMenuOnSelect = false,
      omitSelectedLabels = false,
    }) => {
      const { team } = userStore;
      const isDemo = team?.id === 'demo';
      const [groupMap, setGroupMap] = useState({});
      const [teamHiearchy, setTeamHiearchy] = useState({});

      const options = teamHierarchyStore?.trees?.[team?.id]?.selectOptions;

      /* Simplify the hierarchy to a flat map of group id: group name without relationships
       * - for finding groups on selection and sending ID & name
       */
      const buildGroupMap = (data) => {
        let newGroupMap = {};
        if (!data || !data.length) {
          return newGroupMap;
        }

        data.forEach((group) => {
          newGroupMap[group.id] = group.name;
          if (group.children && group.children.length) {
            newGroupMap = {
              ...newGroupMap,
              ...buildGroupMap(group.children),
            };
          }
        });
        return newGroupMap;
      };

      /* Get the hierarchy of groups to nest subgroups within parent */
      const getHierarchy = async () => {
        const newTeamHiearchy = await teamHierarchyStore.getTeamHierarchy(team);
        teamHierarchyStore.setHierarchySelectOptions(team?.id, groupFilter, null, defaultOption, false);
        setTeamHiearchy(newTeamHiearchy);
        const newGroupMap = buildGroupMap(teamHiearchy);
        setGroupMap(newGroupMap);
      };

      const handleChange = ({ value }) => {
        handleGroupSelect(value, groupMap[value]);
      };

      useEffect(() => {
        /** while in demo skip anything that sends backend requests */
        if (team && !isDemo) {
          getHierarchy();
        }
      }, [team?.id]);

      return (
        <div className={`group-dropdown ${className}`}>
          <DropdownFilter
            name="group-dropdown"
            placeholder={placeholder || 'Select a Group...'}
            loading={teamHierarchyStore.isLoading(team?.id) || loading}
            ariaLabel={ariaLabel || 'Select group for filter'}
            options={options}
            value={selectedGroup}
            onChange={handleChange}
            isSearchable
            disabled={isDemo || disabled}
            isCompact={isCompact}
            className="bg-white"
            closeMenuOnSelect={closeMenuOnSelect}
            omitSelectedLabels={omitSelectedLabels}
          />
        </div>
      );
    }
  )
);

export default GroupSelect;
