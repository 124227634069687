import React from 'react';
import { twMerge } from 'tailwind-merge';
import Collapsible from '../Collapsible/Collapsible';

/**
 * Collapsible code block for examples
 * @param {string|JSX} children - the code to be displayed
 * @param {string} title - the title of the code block
 * @param {string} className - the class name of the code block
 * @param {string} wrapperClassName - the class name of the wrapper
 * @param {string} triggerClassName - the class name of the trigger
 * @returns {JSX.Element} - the collapsible code block
 */
function Code({ children, title = 'Show Code', className = '', wrapperClassName = '', triggerClassName = '' }) {
  const classes = twMerge('rounded-md border border-gray-200', className);
  const wrapperClasses = twMerge('flex flex-col gap-1.5 pb-8 my-2', wrapperClassName);
  const triggerClasses = twMerge('text-sm font-medium text-gray-500', triggerClassName);
  return (
    <div className={wrapperClasses}>
      <Collapsible trigger={<span className={triggerClasses}>{title}</span>}>
        <div className={classes}>
          <pre className="p-2 rounded-md language-javascript">
            <code className="language-javascript">{children}</code>
          </pre>
        </div>
      </Collapsible>
    </div>
  );
}

export default Code;
